import { mapGetters } from 'vuex';
import get from 'lodash/get'

export default {
    props: {
        date: {
            type: String,
            default: ''
        },
        title: {
            type: String,
            default: ''
        },
        name: {
            type: String,
            default: ''
        },
        picture: {
            type: [ String, Object ],
            default: null
        },
        description: {
            type: String,
            default: null
        },
        htmlDescription: {
            type: String,
            default: null
        },
        caption: {
            type: [String, Number],
            default: null
        },
        price: {
            type: [String, Number],
            default: null
        },
        link: {
            type: String,
            default: null
        },
        linkName: {
            type: String,
            default: null
        },
        url: {
            type: String,
            default: null
        },
        routeTo: {
            type: [String, Object],
            default: null
        },
        rating: {
            type: [String, Number],
            default: 0
        },
        commentsCount: {
            type: [String, Number],
            default: null
        },
        commentsCaption: {
            type: [String, Boolean],
            default: null
        },
        producedFrom: {
            type: [String, Number],
            default: null
        },
        genderCode: {
            type: String,
            default: ''
        },
        targetLink: {
            type: Boolean,
            default: true
        },
        count: {
            type: [String, Number],
            default: null
        },
        withComment: {
            type: Boolean,
            default: true
        },
        withRating: {
            type: Boolean,
            default: false
        },
        withRatingBottom: {
            type: Boolean,
            default: false
        },
        centeredContent: {
            type: Boolean,
            default: false
        },
        withCounter: {
            type: Boolean,
            default: true
        },
        directionColumn: {
            type: Boolean,
            default: false
        },
        entityType: {
            type: String,
            default: 'user'
        }
    },
    computed: {
        dateTranslations() {
            return {
                lastday_in: this.$t('date.lastday_in'),
                today_in: this.$t('date.today_in'),
                in: this.$t('date.in'),
            }
        },
        getDate() {
            return this.$options.filters.datePost(this.date, this.dateTranslations)
        },
        ...mapGetters(['getNoImage', 'getEntityNoImage']),
        computedRating() {
            const rate = this.rating || -1;
            return Math.round(rate / 0.5) * 0.5;
        },
        getPicture() {
            const picture =
                typeof this.picture === 'string' ?
                    this.picture :
                    get(this.picture, 'url')

            return picture || this.getEntityNoImage(this.entityType)
        }
    }
}
