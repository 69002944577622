/* eslint-disable no-useless-constructor */
import Model from '@/models/Model'

export default class Badge extends Model {
    resource() {
        return 'badges'
    }

    primaryKey() {
        return 'code'
    }

    availableBadges() {
        return this.request({
            method: 'GET',
            url: '/users/accounts/available-badges'
        }).then(response => {
            if(response) {
                return response.data;
            }
        });
    }
}
