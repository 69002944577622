/* eslint-disable no-extra-boolean-cast */
import pick from 'lodash/pick';
import forEach from 'lodash/forEach';

export const state = () => ({
    counters: {
        "perfumes": null,
        "brands": null,
        "perfumers": null,
        "notes": null
    },
    oAuthServices: {
        "vkontakte": {},
        "facebook": {},
        "googleoauth": {},
        "twitter": {},
        "odnoklassniki": {}
    },  
    loading: false
})

export const mutations = {
    counters(state, value) {
        state.counters = value;
    },
    oAuthServices(state, value) {
        state.oAuthServices = value;
    },
    loading(state, value) {
        state.loading = value;
    }
}

export const actions = {
    async getCounters({commit, state}, force = false) {

        if (!state.loading || force) {
            commit('loading', true);

            await this.$api.get('statistics', {
                progress: false
            }).then((response) => {

                commit('counters', pick(response.data.catalog, Object.keys(state.counters)));
            });
        }
    },

    async getOAuthServices({commit, state}, force = false) {
        await this.$api.get('oauth-services', {
            progress: false
        }).then((response) => {

            const socialServices = {};
            forEach(response.data, (item) => {

                if(item.htmlUrl){
                    const backUrl = encodeURIComponent(encodeURIComponent(window.location.href));
                    const redirectParam = item.code === 'twitter' ? 'backurl=' : 'redirect_url%3D';
                    const regepx = new RegExp('('+redirectParam+')(.*?)(&|%26|$)', 'm');

                    item.htmlUrl = item.htmlUrl.replace(regepx, (m, g1, g2, g3) => {
                        return g1 + backUrl + g3;
                    });
                }
                
                item.onclick = item.htmlUrl 
                    ? "window.open('"+ item.htmlUrl +"', '', 'scrollbars=1,height='+Math.min(400, screen.availHeight)+',width='+Math.min(700, screen.availWidth)+',left='+Math.max(0, (screen.availWidth - 700)/2)+',top='+Math.max(0, (screen.availHeight - 400)/2))"
                    : ''; 
                socialServices[item.code] = item;
            });
            commit('oAuthServices', socialServices);
        });
    }
}
