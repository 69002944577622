import assign from 'lodash/assign'
import isEmpty from 'lodash/isEmpty';

import { mapGetters } from 'vuex'

// импорт напрямую, т.к. внутри через = () => import;
import EditorModals from '@/components/editor/modals';

const Signin = () => import('@/components/modal/auth/Signin').then(m => m.default || m);
const Signup = () => import('@/components/modal/auth/Signup').then(m => m.default || m);
const PasswordRecovery = () => import('@/components/modal/auth/PasswordRecovery').then(m => m.default || m);

const Actions = () => import('@/components/modal/ui/Actions').then(m => m.default || m);
const ActionAccept = () => import('@/components/modal/ui/ActionAccept').then(m => m.default || m);
const Tooltip = () => import('@/components/modal/ui/Tooltip').then(m => m.default || m);
const Share = () => import('@/components/modal/ui/Share').then(m => m.default || m);
const Notices = () => import('@/components/modal/ui/Notices').then(m => m.default || m);
const PopupComment = () => import('@/components/modal/ui/PopupComment').then(m => m.default || m);
const PopupRubrics = () => import('@/components/modal/ui/Rubrics').then(m => m.default || m);
const PopupCrop = () => import('@/components/modal/ui/PopupCrop').then(m => m.default || m);
const Subscribe = () => import('@/components/modal/ui/Subscribe').then(m => m.default || m);
const PopupSubscription = () => import('@/components/modal/ui/PopupSubscription').then(m => m.default || m);
const PopupVideo = () => import('@/components/modal/ui/PopupVideo').then(m => m.default || m);

const CommentGallery = () => import('@/components/modal/gallery/ModalCommentGallery').then(m => m.default || m);
const CommentsFilter = () => import('@/components/modal/perfume/CommentsFilter').then(m => m.default || m);
const CommentsSort = () => import('@/components/modal/perfume/CommentsSort').then(m => m.default || m);

const Gallery = () => import('@/components/modal/gallery/Gallery').then(m => m.default || m);
const GalleryCommunity = () => import('@/components/modal/gallery/ModalGalleryCommunity').then(m => m.default || m);

const Complaint = () => import('@/components/modal/news/Complaint').then(m => m.default || m);
const ComplaintPost = () => import('@/components/modal/posts/Complaint').then(m => m.default || m);

const ShopRate = () => import('@/components/modal/shop/ShopRate').then(m => m.default || m);
const PopupSort = () => import('@/components/modal/catalog/PopupSort').then(m => m.default || m);
const NotesFilter = () => import('@/components/modal/catalog/NotesFilter').then(m => m.default || m);
const NotesPyramid = () => import('@/components/modal/notes/NotesPyramid').then(m => m.default || m);

const AddPerfume = () => import('@/components/modal/catalog/AddPerfume').then(m => m.default || m);
const PopupRate = () => import('@/components/modal/perfume/rate/PopupRate').then(m => m.default || m);
const RateMore = () => import('@/components/modal/perfume/rate/RateMore').then(m => m.default || m);
const RateSimilarPerfume = () => import('@/components/modal/perfume/rate/RateSimilarPerfume').then(m => m.default || m);
const PopupReview = () => import('@/components/modal/perfume/rate/PopupReview').then(m => m.default || m);
const RemovePhoto = () => import('@/components/modal/perfume/rate/RemovePhoto').then(m => m.default || m);
const CharacteristicsActions = () => import('@/components/modal/perfume/CharacteristicsActions').then(m => m.default || m);

const PerfumesSort = () => import('@/components/modal/perfume/PerfumesSort').then(m => m.default || m);
const PerfumeCollections = () => import('@/components/modal/perfume/PopupCollections').then(m => m.default || m);

const BadgeInfo = () => import('@/components/modal/profile/BadgeInfo').then(m => m.default || m);
const BadgeGet = () => import('@/components/modal/profile/BadgeGet').then(m => m.default || m);
const ChangeTextStatus = () => import('@/components/modal/profile/ChangeTextStatus').then(m => m.default || m);
const CurrentPerfume = () => import('@/components/modal/profile/CurrentPerfume').then(m => m.default || m);
const PerfumeNowWear = () => import('@/components/modal/profile/PerfumeNowWear').then(m => m.default || m);
const HistoryPerfume = () => import('@/components/modal/profile/HistoryPerfume').then(m => m.default || m);
const PhotoCrop = () => import('@/components/modal/profile/PhotoCrop').then(m => m.default || m);
const ModalUserDialog = () => import('@/components/modal/profile/messages/ModalUserDialog').then(m => m.default || m);

const PerfumeShelves = () => import('@/components/modal/perfume/PerfumeShelves').then(m => m.default || m);
const AddPerfumeShelf = () => import('@/components/modal/wardrobe/AddPerfumeShelf').then(m => m.default || m);
const EditPerfumeShelf = () => import('@/components/modal/wardrobe/EditPerfumeShelf').then(m => m.default || m);
const CreateShelf = () => import('@/components/modal/wardrobe/CreateShelf').then(m => m.default || m);
const RenameShelf = () => import('@/components/modal/wardrobe/RenameShelf').then(m => m.default || m);
const RemoveShelf = () => import('@/components/modal/wardrobe/RemoveShelf').then(m => m.default || m);
const CardPerfume = () => import('@/components/modal/wardrobe/CardPerfume').then(m => m.default || m);
const ModalWardrobeAddPerfume = () => import('@/components/modal/profile/ModalWardrobeAddPerfume').then(m => m.default || m);

const CreateRecord = () => import('@/components/modal/feed/CreateRecord').then(m => m.default || m);
const Unsubscribe = () => import('@/components/modal/feed/Unsubscribe').then(m => m.default || m);
const UserSubscribe = () => import('@/components/modal/feed/UserSubscribe').then(m => m.default || m);

const SendAds = () => import('@/components/modal/info/SendAds').then(m => m.default || m);
const Game404 = () => import('@/components/modal/404/game').then(m => m.default || m);
const BaseDocument = () => import('@/components/modal/documents/BaseDocument').then(m => m.default || m);

const Diagnostic = () => import('@/components/modal/diagnostic/Diagnostic').then(m => m.default || m);
const DiagnosticFail = () => import('@/components/modal/diagnostic/DiagnosticFail').then(m => m.default || m);

const PerfumersFilter = () => import('@/components/modal/catalog/PerfumersFilter').then(m => m.default || m);

const SplitAccountsConfirm = () => import('@/components/modal/profile/SplitAccountsConfirm').then(m => m.default || m);

const AddShop = () => import('@/components/modal/catalog/AddShop').then(m => m.default || m);
const ShopRepresentative = () => import('@/components/modal/shop/ShopRepresentative').then(m => m.default || m);
const SuccessPopup = () => import('@/components/modal/ui/SuccessPopup').then(m => m.default || m);

const DelayedPublish = () => import('@/components/editor/modals/DelayedPublish').then(m => m.default || m);

const FeedbackPopup = () => import('@/components/modal/info/FeedbackPopup').then(m => m.default || m);

const popups = {
    ...EditorModals,
    signin: {
        component: Signin,
        options: {
            draggable: false,
        },
        optionsDesktop: {
            // classes: 'modal__box_desktop-top',
        }
    },
    notices: {
        component: Notices,
        options: {
            type: 'top',
            width: '100%',
            showClose: false
        },
    },
    'popup-crop': {
        component: PopupCrop,
        options: {
            fullscreen: true,
            draggable: false,
            classes: 'modal__box_no_radius',
            height: '100%',
            width: '100%',
        },
    },
    subscribe: {
        component: Subscribe,
        options: {
            type: 'bottom',
            width: '100%',
            swipeY: true
        },
        optionsDesktop: {
            type: 'centered',
            width: '375'
        }
    },
    actions: {
        component: Actions,
        options: {
            type: 'bottom',
            width: '100%',
            name: 'actions-popup',
            swipeY: true
        },
        optionsDesktop: {
            type: 'centered'
        }
    },
    actionAccept: {
        component: ActionAccept,
        options: {
            type: 'bottom',
            width: '100%',
            swipeY: true
        },
        optionsDesktop: {
            type: 'centered',
            width: '385'
        }
    },
    'game-404': {
        component: Game404,
    },
    'notes-filter': {
        component: NotesFilter,
        options: {
            fullscreen: true,
            draggable: false,
            classes: 'modal__box_no_radius',
            width: '100%',
            height: '100%'
        },
        optionsDesktop: {
            loadedOverlay: false,
            fullscreen: false,
            draggable: false,
            type: 'right',
            classes: 'modal__box_no_radius',
            width: '375',
            height: '100%'
        }
    },
    'add-perfume': {
        component: AddPerfume,
        options: {
            fullscreen: true,
            draggable: false,
            classes: 'modal__box_no_radius',
            width: '100%',
            height: '100%'
        },
        optionsDesktop: {
            type: 'centered',
            boxPadding: 70
        }
    },
    'shop-rate': {
        component: ShopRate,
        options: {
            fullscreen: true,
            draggable: false,
            classes: 'modal__box_no_radius',
            width: '100%',
            height: '100%'
        }
    },
    'popup-rate': {
        component: PopupRate,
        options: {
            fullscreen: true,
            draggable: false,
            classes: 'modal__box_no_radius',
            width: '100%',
            height: '100%'
        },
        optionsDesktop: {
            type: 'centered',
            boxPadding: 70,
            classes: 'modal__box_sticky-footer'
        }
    },
    'notes-pyramid': {
        component: NotesPyramid,
        options: {
            classes: 'modal__box_no_radius',
            width: '100%',
            height: '100%',
            draggable: false,
            fullscreen: true
        },
        optionsDesktop: {
            type: 'centered',
            boxPadding: 70,
            width: '600'
        }
    },
    rateSimilarPerfume: {
        component: RateSimilarPerfume,
        options: {
            classes: 'modal__box_no_radius',
            width: '100%',
            height: '100%',
            draggable: false,
            fullscreen: true
        },
        optionsDesktop: {
            type: 'centered',
            boxPadding: 70,
            classes: 'modal__box_sticky-footer'
        }
    },
    rubrics: {
        component: PopupRubrics,
        options: {
            type: 'bottom',
            // closeByDash: true,
            width: '100%',
            swipeY: true
        },
    },
    share: {
        component: Share,
        options: {
            type: 'bottom',
            width: '100%',
            swipeY: true
        },
        optionsDesktop: {
            type: 'centered'
        }
    },
    signup: {
        component: Signup,
        options: {
            draggable: false,
        },
        optionsDesktop: {
            // classes: 'modal__box_desktop-top',
        }
    },
    shelves: {
        component: PerfumeShelves,
        options: {
            type: 'bottom',
            // closeByDash: true,
            width: '100%',
            swipeY: true
        }
    },
    'rate-more': {
        component: RateMore,
        options: {
            type: 'centered',
            draggable: false
        }
    },
    'remove-photo': {
        component: RemovePhoto,
        options: {
            type: 'bottom',
            width: '100%',
            swipeY: true
        },
        optionsDesktop: {
            type: 'centered'
        }
    },
    'popup-user-dialog': {
        component: ModalUserDialog,
        options: {
            fullscreen: true,
            width: '100%',
            height: '100%',
            closable: false,
            classes: 'modal__box_no_radius'
        }
    },
    'popup-comment': {
        component: PopupComment,
        options: {
            type: 'bottom',
            width: '100%',
            swipeY: true
        },
        optionsDesktop: {
            type: 'centered',
            width: '600'
        }
    },
    'popup-review': {
        component: PopupReview,
        options: {
            type: 'bottom',
            width: '100%',
            closable: true,
            showClose: true,
            swipeY: true
            // closeByDash: true
        },
        optionsDesktop: {
            type: 'centered',
            closable: false,
            showClose: true
        }
    },
    'popup-add-record': {
        component: CreateRecord,
        options: {
            fullscreen: true,
            width: '100%',
            height: '100%',
            closable: false
        },
    },
    'characteristics-actions': {
        component: CharacteristicsActions,
        options: {
            type: 'bottom',
            width: '100%',
            swipeY: true
        },
        optionsDesktop: {
            type: 'centered'
        }
    },
    'perfumes-sort': {
        component: PerfumesSort,
        options: {
            type: 'bottom',
            width: '100%',
            swipeY: true
        },
        optionsDesktop: {
            type: 'centered'
        }
    },
    'popup-sort': {
        component: PopupSort,
        options: {
            type: 'bottom',
            width: '100%',
            swipeY: true
        },
        optionsDesktop: {
            type: 'centered'
        }
    },
    'comments-sort': {
        component: CommentsSort,
        options: {
            type: 'bottom',
            width: '100%',
            swipeY: true
        },
        optionsDesktop: {
            type: 'centered'
        }
    },
    'perfume-collections': {
        component: PerfumeCollections,
        options: {
            loadedOverlay: false,
            fullscreen: false,
            draggable: false,
            type: 'right',
            classes: 'modal__box_no_radius',
            width: '26%',
            height: '100%'
        }
    },
    'profile-photo-crop': {
        component: PhotoCrop,
        options: {
            fullscreen: true,
            draggable: false,
            classes: 'modal__box_no_radius',
            height: '100%',
            width: '100%',
        },
        optionsDesktop: {
            type: 'centered',
            width: '600'
        }
    },
    'comments-filter': {
        component: CommentsFilter,
        options: {
            draggable: false,
            fullscreen: true,
            classes: 'modal__box_no_radius',
            height: '100%',
            width: '100%'
        }
    },
    'comments-filter-desktop': {
        component: CommentsFilter,
        options: {
            loadedOverlay: false,
            fullscreen: false,
            draggable: false,
            type: 'right',
            classes: 'modal__box_no_radius',
            width: '26%',
            height: '100%'
        }
    },
    video: {
        component: PopupVideo,
        options: {
            fullscreen: true,
            classes: 'modal__box_no_radius modal__box_no_bg',
            height: '100%',
            width: '100%'
        },
        optionsDesktop: {
            fullscreen: false,
            type: 'centered',
            width: '1030px',
            height: '692px'
        }
    },
    gallery: {
        component: Gallery,
        options: {
            fullscreen: true,
            draggable: true,
            classes: 'modal__box_no_radius',
            height: '100%',
            width: '100%',
            swipeY: true
        },
        optionsDesktop: {
            type: 'centered'
        }
    },
    'comment-gallery': {
        component: CommentGallery,
        options: {
            fullscreen: true,
            classes: 'modal__box_no_radius',
            height: '100%',
            width: '100%',
            swipeY: true
        },
        optionsDesktop: {
            type: 'centered'
        }
    },
    galleryCommunity: {
        component: GalleryCommunity,
        options: {
            fullscreen: true,
            classes: 'modal__box_no_radius',
            height: '100%',
            width: '100%',
            swipeY: true
        },
        optionsDesktop: {
            type: 'centered'
        }
    },
    complaint: {
        component: Complaint,
        options: {
            fullscreen: true,
            draggable: false,
            classes: 'modal__box_no_radius',
            height: '100%',
            width: '100%'
        },
        optionsDesktop: {
            type: 'centered',
            boxPadding: 20,
            width: '521'
        }
    },
    'complaint-post': {
        component: ComplaintPost,
        options: {
            fullscreen: true,
            classes: 'modal__box_no_radius',
            draggable: false,
            height: '100%',
            width: '100%'
        },
        optionsDesktop: {
            type: 'centered',
            boxPadding: 20,
            width: '521'
        }
    },
    tooltip: {
        component: Tooltip,
        options: {
            type: 'bottom',
            width: '100%',
            swipeY: true
        },
        optionsDesktop: {
            type: 'centered',
            width: '375'
        }
    },
    password: {
        component: PasswordRecovery,
        optionsDesktop: {
            // classes: 'modal__box_desktop-top',
        }
    },
    'terms-of-use': {
        component: BaseDocument,
        props: {
            id: 'terms-of-use'
        },
        options: {
            closable: false
        }
    },
    'privacy-policy': {
        component: BaseDocument,
        props: {
            id: 'privacy-policy',
            documentFullWidth: true
        },
        options: {
            closable: false,
            width: "91%"
        }
    },
    'for-right-holgers': {
        component: BaseDocument,
        props: {
            id: 'for-right-holgers'
        },
        options: {
            closable: false
        }
    },
    'unsubscribe': {
        component: Unsubscribe,
        options: {
            type: 'bottom',
            width: '100%',
            swipeY: true
        },
        optionsDesktop: {
            type: 'centered',
            width: '375px',
            height: '274px'
        }
    },
    'subscribeToUser': {
        component: UserSubscribe,
        options: {
            type: 'bottom',
            width: '100%',
            swipeY: true
        },
        optionsDesktop: {
            type: 'centered',
            width: '375px'
        }
    },
    'create-shelf': {
        component: CreateShelf,
        options: {
            type: 'bottom',
            width: '100%',
            swipeY: true
        },
        optionsDesktop: {
            type: 'centered',
            width: '600px',
        }
    },
    'rename-shelf': {
        component: RenameShelf,
        options: {
            type: 'bottom',
            width: '100%',
            swipeY: true
        },
        optionsDesktop: {
            type: 'centered',
            width: '600px',
        }
    },
    'add-perfume-shelf': {
        component: AddPerfumeShelf,
        options: {
            fullscreen: true,
            draggable: false,
            classes: 'modal__box_no_radius',
            width: '100%',
            height: '100%'
        },
        optionsDesktop: {
            type: 'centered',
            width: '600px',
        }
    },
    'edit-perfume-shelf': {
        component: EditPerfumeShelf,
        options: {
            fullscreen: true,
            draggable: false,
            classes: 'modal__box_no_radius',
            width: '100%',
            height: '100%'
        },
        optionsDesktop: {
            type: 'centered',
            width: '600px',
            height: '620px',
        }
    },
    'remove-shelf': {
        component: RemoveShelf,
        options: {
            type: 'bottom',
            width: '100%',
            swipeY: true
        },
    },
    'card-perfume': {
        component: CardPerfume,
        options: {
            type: 'bottom',
            width: '100%',
            classes: 'modal__box_perfume',
            swipeY: true
        },
        optionsDesktop: {
            type: 'centered',
            width: '375px'
        }
    },
    'change-text-status': {
        component: ChangeTextStatus,
        options: {
            type: 'bottom',
            width: '100%',
            swipeY: true
        },
        optionsDesktop: {
            type: 'centered',
            width: '600px',
        }
    },
    'current-perfume': {
        component: CurrentPerfume,
        options: {
            type: 'bottom',
            width: '100%',
            swipeY: true
        },
        optionsDesktop: {
            type: 'centered',
            width: '375px',
        }
    },
    'modal-wardrobe-add-perfume': {
        component: ModalWardrobeAddPerfume,
        options: {
            fullscreen: true,
            width: '100%',
            height: '100%',
            closable: true,
            classes: 'modal__box_no_radius',
            draggable: false
        },
        optionsDesktop: {
            type: 'centered',
            width: '600px',
            height: '616px'
        }
    },
    'perfume-wear': {
        component: PerfumeNowWear,
        options: {
            fullscreen: true,
            width: '100%',
            height: '100%',
            closable: true,
            classes: 'modal__box_no_radius',
            draggable: false
        },
        optionsDesktop: {
            type: 'centered',
            width: '600px',
            height: '638px'
        }
    },
    'history-perfume': {
        component: HistoryPerfume,
        options: {
            fullscreen: true,
            width: '100%',
            height: '100%',
            closable: true,
            classes: 'modal__box_no_radius',
            draggable: false
        },
        optionsDesktop: {
            type: 'centered',
            width: '600px',
            height: '618px'
        }
    },
    'badge-info': {
        component: BadgeInfo,
        options: {
            type: 'bottom',
            width: '100%',
            swipeY: true
        },
        optionsDesktop: {
            type: 'centered',
            width: '375px'
        }
    },
    'badge-get': {
        component: BadgeGet,
        optionsDesktop: {
            type: 'centered',
            width: '375px'
        }
    },
    'popup-subscription': {
        component: PopupSubscription,
        options: {
            width: "91%"
        },
        optionsDesktop: {
            type: 'centered',
            width: '375px'
        }
    },
    'send-ads-request': {
        component: SendAds,
        options: {
            fullscreen: true,
            draggable: false,
            classes: 'modal__box_no_radius',
            width: '100%'
        },
        optionsDesktop: {
            type: 'centered',
            draggable: false,
            boxPadding: 16,
            width: '600'
        }
    },
    'diagnostic': {
        component: Diagnostic,
        options: {
            fullscreen: true,
            draggable: false,
            classes: 'modal__box_no_radius',
            width: '100%',
            showClose: false
        },
        optionsDesktop: {
            fullscreen: true,
            draggable: false,
            classes: 'modal__box_no_radius',
            width: '100%',
            showClose: false
        }
    },
    'diagnostic-fail': {
        component: DiagnosticFail,
        options: {
            type: 'centered',
            width: '340',
            draggable: false
        },
        optionsDesktop: {
            draggable: false,
            type: 'centered',
            width: '340'
        }
    },
    'perfumers-filter': {
        component: PerfumersFilter,
        options: {
            fullscreen: true,
            draggable: false,
            classes: 'modal__box_no_radius',
            width: '100%',
            height: '100%'
        },
        optionsDesktop: {
            loadedOverlay: false,
            fullscreen: false,
            draggable: false,
            type: 'right',
            classes: 'modal__box_no_radius',
            width: '375',
            height: '100%'
        }
    },
    'split-accounts-confirm': {
        component: SplitAccountsConfirm,
        options: {
            type: 'bottom',
            width: '100%',
            swipeY: true
        },
        optionsDesktop: {
            type: 'centered',
            width: '375px'
        }
    },
    'add-shop': {
        component: AddShop,
        options: {
            fullscreen: true,
            draggable: false,
            classes: 'modal__box_no_radius',
            width: '100%',
            height: '100%'
        },
        optionsDesktop: {
            type: 'centered',
            width: '600',
            boxPadding: 70
        }
    },
    'shop-representative': {
        component: ShopRepresentative,
        options: {
            fullscreen: true,
            draggable: false,
            classes: 'modal__box_no_radius',
            width: '100%',
            height: '100%',
            swipeY: true
        },
        optionsDesktop: {
            type: 'centered',
            width: '600'
        }
    },
    'success-popup': {
        component: SuccessPopup,
        options: {
            draggable: false,
            width: '340',
            swipeY: true
        },
        optionsDesktop: {
            type: 'centered',
            width: '436'
        }
    },
    'delayed-publish': {
        component: DelayedPublish,
        options: {
            type: 'fullscreen',
            width: '100%',
            height: '100%',
            classes: 'modal__box_no_radius',
            swipeY: true
        },
        optionsDesktop: {
            type: 'centered',
            classes: 'modal__box_sticky-footer',
            width: '474px'
        }
    },
    feedback: {
        component: FeedbackPopup,
        options: {
            type: 'fullscreen',
            width: '100%',
            height: '100%',
            classes: 'modal__box_no_radius',
            swipeY: true
        },
        optionsDesktop: {
            type: 'centered',
            width: '600px'
        }
    }
}

export default {

    computed: {
        ...mapGetters(['isDeviceDesktop']),
    },
    methods: {
        showPopup(name, props, options, events) {
            const params = popups[name];
            let modalOptions = {};

            if (params.component.name && params.component.name !== 'component') {
                if (this.$modal.isShow === params.component.name) {
                    return;
                }
            }

            if (!isEmpty(options)) {
                modalOptions = options
            } else if (this.isDeviceDesktop && params.optionsDesktop) {
                modalOptions = params.optionsDesktop
            } else {
                modalOptions = params.options
            }

            this.$modal.isShow = params.component.name || true;
            this.$modal.show(
                params.component,
                params.props || props,
                modalOptions,
                params.events || events
            )
        },
        togglePopup(name, props) {
            this.$modal.hide();

            let modalOptions = {}

            if (this.isDeviceDesktop && popups[name].optionsDesktop) {
                modalOptions = popups[name].optionsDesktop
            } else {
                modalOptions = popups[name].options || {}
            }

            this.$modal.show(
                popups[name].component,
                assign(popups[name].props || {}, props || {}),
                modalOptions,
                popups[name].events || {}
            );
        },
        triggerPopup(name, props) {
            if (this.$modal.isShow) {
                this.$modal.hide();
            }
            else {
                let modalOptions = {}

                if (this.isDeviceDesktop && popups[name].optionsDesktop) {
                    modalOptions = popups[name].optionsDesktop
                } else {
                    modalOptions = popups[name].options || {}
                }

                this.$modal.show(
                    popups[name].component,
                    assign(popups[name].props || {}, props || {}),
                    modalOptions,
                    popups[name].events || {}
                );
            }
        },
        closePopup(name) {
            this.$modal.isShow = false;
            this.$modal.hide(popups[name]);
        }
    }
}
