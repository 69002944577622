<i18n>
    {
        "en": {
            "read_all": "Read more ...",
            "read_less": "Roll up"
        },
        "ru": {
            "read_all": "Читать полностью...",
            "read_less": "Свернуть"
        }
    }
</i18n>
<template>
    <div class="show-more"
        :class="showMoreClasses"
        v-observe-visibility="!disabled ? observerOptions : false">
 
        <div class="show-more__wrapper"
            :style="wrapperStyle">

            <component
                :is="collapsible ? 'slide-up-down' : 'div'"
                :active="isExpanded"
                :use-hidden="false"
                ref="container"
                class="show-more__container"
                @open-end="showGradient = false"
                @close-start="showGradient = !noGradient">

                <slot></slot>

            </component>
        </div>

        <ButtonMore
            v-if="isButtonVisible"
            class="show-more__button"
            :isOpened="isExpanded"
            :centered="buttonCentered"
            :buttonIconName="buttonIcon"
            :transformBtnY="buttonTransformY"
            @click.native.prevent="expand">
            {{text}}
        </ButtonMore>

    </div>
</template>

<script>
import includes from 'lodash/includes'
import { ObserveVisibility } from 'vue-observe-visibility'
import SlideUpDown from 'vue-slide-up-down'
import ButtonMore from '@/components/ui/buttons/ButtonMore'

export default {
    name: 'ShowMore',
    components: {
        SlideUpDown,
        ButtonMore
    },
    directives: {
        'observe-visibility': ObserveVisibility
    },
    props: {
        container: {
            type: String,
            default: 'body'
        },
        buttonText: {
            type: String,
            default() {
                return this.$t('read_all')
            }
        },
        buttonLess: {
            type: String,
            default() {
                return this.$t('read_less')
            }
        },
        buttonIcon: {
            type: String,
            default: ''
        },
        buttonTransformY: {
            type: Number,
            default: 0
        },
        buttonCentered: {
            type: Boolean,
            default: false
        },
        noGradient: {
            type: Boolean,
            default: false
        },
        // text смотрит на количество строк
        // block на max-height
        type: {
            type: String,
            default: 'text',
            validator(value) {
                return includes(['text', 'block'], value)
            }
        },
        collapseFrom: {
            type: String,
            default: ''
        },
        collapseTo: {
            type: String,
            default: ''
        },
        maxHeight: {
            type: Number,
            default: 0
        },
        maxRows: {
            type: Number,
            default: 6
        },
        initMaxHeight: {
            type: String,
            default: null
        },
        withoutButton: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        expanded: {
            type: Boolean,
            default: false
        },
        hideLessButton: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            isExpanded: false,
            collapsedHeight: 0,
            height: null,
            collapsible: false,
            isLoaded: false,
            showGradient: !this.noGradient,
            observerOptions: {
                callback: this.init,
                throttle: 200,
                once: true,
                throttleOptions: {
                    leading: 'visible',
                },
                intersection: {
                    threshold: 0,
                },
            },
            wrapperStyle: {
                maxHeight: this.initMaxHeight || null 
            }
        }
    },
    // watch: {
    //     expanded(value){
    //         if(value)
    //             this.init()
    //     }
    // },
    computed: {
        showMoreClasses(){
            return {
                'show-more_loaded': this.isLoaded,
                'show-more_disabled': this.disabled,
                'show-more_expanded': this.isExpanded,
                'show-more_gradient': this.showGradient && this.collapsible && !this.expanded
            }
        },
        isButtonVisible(){
            if((this.hideLessButton && this.isExpanded) || this.withoutButton || this.disabled)
                return false

            return this.collapsible
        },
        text(){
            if(this.isExpanded)
                return this.buttonLess

            return this.buttonText
        }
    },
    beforeCreate() {
        this.wrapperStyle = {color: 'blue'};
    },
    mounted() {
        if (!this.disabled){
            // setTimeout(() => {
            this.init();
            this.isLoaded = true
            // }, 500000)
        }
    },
    methods: {
        expand(){
            this.isExpanded = !this.isExpanded

            if(this.collapseFrom && this.isExpanded)
                this.scrollTo(this.collapseFrom)

            if(this.collapseTo && !this.isExpanded)
                this.scrollTo(this.collapseTo)

            // this.$emit('expand', this.isExpanded)
        },
        async init() {
            this.height = null
            this.collapsedHeight = 0
            this.collapsible = false

            await this.$nextTick()

            if (this.expanded)
                this.isExpanded = true

            const el = this.$refs.container

            if (el){
                this.height = this.$refs.container.scrollHeight
                const styles = getComputedStyle(el)
                const lineHeight = parseInt(styles.lineHeight) // Высота одной строки
                const rowsCount = Math.round(this.height/lineHeight) // Всего строк в блоке

                if (this.type === 'block'){
                    this.collapsible = this.height > this.maxHeight
                } else {
                    // Если строк больше переданного количества
                    // То компонент collapsible
                    this.collapsible = rowsCount > this.maxRows
                }

                if (this.collapsible){
                    this.$nextTick(() => {
                        if(this.type === 'block'){
                            this.collapsedHeight = this.maxHeight
                        }else{
                            this.collapsedHeight = this.maxRows * lineHeight
                        }

                        this.$refs.container.$el.style.minHeight = this.collapsedHeight + 'px'
                    })
                }
                this.wrapperStyle.maxHeight = null;
            }
        },
        scrollTo(elem) {
            this.$scrollTo(elem, 750, {
                offset: -72,
                container: this.container
            })
        }
    }
}
</script>

<style lang="less">
.show-more {

    &_gradient{
        .show-more{
            &__wrapper{
                &::after{
                    max-height: rem(50);
                    opacity: 1;
                }
            }
        }
    }

    &_loaded,
    &_disabled{
        .show-more{
            &__wrapper{
                min-height: 0;
            }

            &__container{
                position: relative;
            }
        }
    }

    &_disabled,
    &_expanded{
        .show-more{
            &__container{
                overflow: visible;
            }
        }
    }

    &__wrapper{
        position: relative;
        overflow: hidden;
        min-height: rem(115);

        &::after {
            content: '';
            display: block;
            background: linear-gradient(rgba(255, 255, 255, 0) 24.82%, white 100%);
            height: rem(50);
            width: 100%;
            max-height: rem(50);
            position: absolute;
            bottom: rem(-1);
            left: 0;
            z-index: 1;
            opacity: 0;
            transition: opacity .15s ease-out, max-height .15s ease;
            will-change: opacity, max-height;
            pointer-events: none;
        }
    }

    &__container{
        overflow: hidden;
        // position: absolute;
        // width: 100%;

        p{
            &:last-child{
                margin-bottom: 0;
            }
        }
    }

    &__button{
        padding: 0;
        font-size: rem(15);
        line-height: rem(23);
    }
}
</style>
