<template>

    <div class="nav" :class="['nav_' + type, mode ? 'nav_' + mode : null]">
        <div class="nav__item"
            v-for="(item, key) of visibleItems"
            :key="key">
            <nuxt-link
                @click.native="onLinkClick"
                :to="get(item, 'url', '/')"
                class="nav__link"
                :class="{nav__link_current: isCurrent(item), 'nav__link_new': isNew(item), 'nav__link_marked': item.isMarked}">
                <svg-icon v-if="showIcons"
                    class="nav__icon"
                    :class="'nav__icon_' + key"
                    :name="item.icon || ['nav', type, key].join('-')"/>
                <span class="nav__link-text">
                    {{ getTranslation(key) }}
                    <span class="nav__counter" v-if="showCounters && counters[key] && item.showCounter">{{ getCountersDeclension(counters[key].total) }}</span>
                </span>
            </nuxt-link>
        </div>

        <div @mouseover="onMouseEvent(true)"
            @mouseleave="onMouseEvent(false)"
            class="nav__toggle-wrapper">
            <div class="nav__hidden"
                :data-visible="expanded"
                v-if="showItems">

                <div class="nav__item"
                    v-for="(item, key) of hiddenItems"
                    :key="key">
                    <nuxt-link
                        exact
                        @click.native="onLinkClick"
                        :to="get(item, 'url', '/')"
                        class="nav__link"
                        :class="{nav__link_current: isCurrent(item), 'nav__link_new': isNew(item), 'nav__link_marked': item.isMarked}">
                        <svg-icon v-if="showIcons"
                            class="nav__icon"
                            :class="'nav__icon_' + key"
                            :name="item.icon || ['nav', type, key].join('-')"/>
                        <span class="nav__link-text">
                            {{ getTranslation(key) }}
                            <span class="nav__counter" v-if="showCounters && counters[key] && item.showCounter">{{ getCountersDeclension(counters[key].total) }}</span>
                        </span>
                    </nuxt-link>
                </div>
            </div>
            <div v-if="isDeviceDesktop && showItems" class="nav__item">
                <span class="nav__link nav__link_toggle nav__link-text">
                    {{ $t('nav.more') }}
                </span>
            </div>
            <span class="nav__toggle"
                v-else-if="showItems"
                ref="navToggle"
                @click="toggle">
                <span class="nav__toggle-text" 
                    :data-show="$t('nav.show_more')"
                    :data-hide="$t('nav.hide')"></span>
            </span>
        </div>
    </div>

</template>

<script>
import { mapState, mapGetters } from 'vuex'
import forIn from 'lodash/forIn'
import pick from 'lodash/pick'
import get from 'lodash/get'
import includes from 'lodash/includes'

export default {
    name: 'NavList',
    props: {
        type: {
            type: String,
            default: ''
        },
        isFooter: {
            type: Boolean,
            default: false
        },
        mixinType: {
            type: String,
            default: ''
        },
        mode: {
            type: String,
            default: null
        },
        showItems: {
            type: Number,
            default: null
        },
        showIcons: {
            type: Boolean,
            default: false
        },
        showCounters: {
            type: Boolean,
            default: false
        },
        sort: {
            type: Array,
            default() {
                return null;
            }
        }
    },
    data() {
        return {
            expanded: false
        }
    },
    computed: {
        ...mapState(['nav']),
        ...mapState('entities', ['counters']),
        ...mapGetters(['isDeviceDesktop', 'getNewSections']),
        items: {
            get() {
                let items = this.nav[this.type] || {};

                if(this.mixinType) {
                    items = {
                        ...items,
                        ...this.nav[this.mixinType]
                    }
                }

                if (this.sort) {
                    return pick(items, this.sort);
                }

                return items;
            }
        },
        visibleItems: {
            get() {
                let i = 0;
                const items = {};

                forIn(this.items, (item, key) => {
                    if (!this.showItems || (++i <= this.showItems)) {
                        items[key] = item;
                    }
                });
                return items;
            }
        },
        hiddenItems: {
            get() {
                let i = 0;
                const items = {};

                forIn(this.items, (item, key) => {
                    if (this.showItems && (++i > this.showItems)) {
                        items[key] = item;
                    }
                });
                return items;
            }
        }
    },
    methods: {
        get,
        setExpanded(value) {
            this.expanded = value
        },
        onMouseEvent(value) {
            if (this.isDeviceDesktop) this.setExpanded(value)
        },
        getTranslation(key) {
            const navKey = this.isFooter ? 'nav.footer.' : 'nav.'

            return this.$t(navKey + key)
        },
        toggle($event) {
            this.expanded = !this.expanded;

            const $target = $event.target.childElementCount ? $event.target : $event.target.parentNode;
            this.$nextTick(() => {
                $target.setAttribute('data-toggled', this.expanded);
            })
        },
        onLinkClick() {
            if (this.expanded) {
                this.expanded = !this.expanded;

                if(!this.isDeviceDesktop) {
                    this.$nextTick(() => {
                        this.$refs.navToggle.setAttribute('data-toggled', this.expanded);
                    })
                }
            }

            this.$emit('linkclicked', true)
        },
        getCountersDeclension(number) {
            return this.$options.filters.numberFormat(number);
        },
        isCurrent(item) {

            if (process.server) {
                return false;
            }

            return this.$route.path.includes(item.url) || 
                this.$route.path.includes('fragrance/') && item.url === '/perfumes/';
        },
        isNew(item){
            return includes(this.getNewSections, get(item, 'url', null))
        }
    }
}
</script>

<style lang="less">
.nav{

    &__hidden{
        max-height: 0px;

        visibility: hidden;
        opacity: 0;
        display: none;
        .animate(all);

        &[data-visible="true"] {
            display: block;

            max-height: rem(999);

            visibility: visible;
            opacity: 1;
        }
    }

    &__item{
        padding: rem(11) 0;
    }

    &__link{
        display: block;

        color: @color-black;
        font-size: 0;

        padding: rem(7) rem(20);

        position: relative;

        .hover({
            color: @color-violet;
        });

        &-text{
            display: inline-block;
            vertical-align: middle;

            font-size: rem(16);
            font-weight: 500;
            line-height: rem(18);

            position: relative;
        }

        &_current{
            color: @color-primary;
        }

        &_new{
            .sidebar & {
                &::after{
                    display: inline-block;
                    vertical-align: middle;
                    margin-left: rem(8);
                    content: 'new';
                    text-transform: uppercase;
                    color: @color-white;
                    font-size: rem(11);
                    line-height: rem(14);
                    padding: rem(3) rem(4) rem(1);
                    background-color: @color-gray-2;
                    border-radius: rem(3);
                    font-weight: 500;
                    position: relative;
                    top: rem(-1);
                }
            }
        }

        &_marked{
            white-space: nowrap;

            .nav__link-text{
                color: @color-primary;
            }
        }
    }

    &__counter{
        color: @color-gray-light;
        font-size: rem(13);
        font-weight: 400;
        line-height: rem(13);
        white-space: nowrap;

        margin-left: rem(8);

        position: absolute;
        bottom: rem(2);
    }

    &__icon{
        display: inline-block;
        vertical-align: middle;

        margin-top: rem(-1.5);
        margin-right: rem(17);

        height: rem(20);
        width: rem(20);
    }

    &__toggle{
        display: block;
        cursor: pointer;

        color: @color-gray-light;
        font-size: rem(14);
        font-weight: 500;

        margin: rem(6) rem(20);

        .hover({
            color: @color-violet;
        });

        &[data-toggled="true"] {
            .nav__toggle-text{
                &:before{
                    content: attr(data-hide);
                }
                &:after{
                    .rotate(180deg);
                }
            }
        }

        &-text{
            display: inline-block;

            padding-right: rem(20);

            .wi(icon-chevron-down, 12, 12, 4, @right: 0, @ab: after);

            &:before{
                content: attr(data-show);
            }

            &:after{
                background-size: contain;

                opacity: .3;

                transition: .2s;
            }
        }
    }

    &_sections{
        .nav{
            &__item{
                padding-top: rem(3);
                padding-bottom: rem(3);
            }
            &__link{
                padding-top: rem(12);
                padding-bottom: rem(12);
            }
        }
    }

    &_pages{
        .nav{
            &__link.link_active{
                color: @color-gray;

                &-text{
                    font-size: rem(15);
                    line-height: rem(15);
                    font-weight: 400;
                }
            }
            &__item{
                @{mobile} &{
                    padding: .2rem 0;
                }
            }
        }
    }

    &_footer-sections{
        .nav{
            &__item{
                display: inline-block;
                vertical-align: top;

                padding-top: rem(3);
                padding-bottom: rem(3);

                width: 50%;

                

                &:nth-child(1n){
                    width: 45%;
                }
                &:nth-child(2n){
                    width: 55%;
                }

                @{mobile} & {
                    white-space: nowrap;
                }

                @{desktop} & {
                    margin-right: rem(20);

                    width: rem(182);
                }
            }
            &__link{
                color: @color-gray;

                padding-top: rem(7);
                padding-bottom: rem(7);
                padding-left: 0;

                @{desktop} & {
                    color: @color-gray;
                }

                .hover({
                    .nav__link-text {
                        text-decoration: underline;
                    }
                });

                &-text{
                    font-size: rem(15);
                    line-height: rem(15);
                    font-weight: 400;
                }
            }
        }
    }

    &_footer-pages{
        .nav{
            &__item{
                padding-top: rem(3);
                padding-bottom: rem(3);
            }
            &__link{
                padding-top: rem(7);
                padding-bottom: rem(7);
                padding-left: 0;

                @{desktop} & {
                    color: @color-gray;
                }

                .hover({
                    .nav__link-text {
                        text-decoration: underline;
                    }
                });

                &-text{
                    font-size: rem(15);
                    line-height: rem(15);
                }
            }
        }
    }

    &__toggle-wrapper {
        position: relative;
    }
}

@{desktop} .nav {
    &__hidden {
        background: @color-white;
        border-radius: rem(3);
        box-shadow: 0 rem(3) rem(16) rgba(0, 0, 0, 0.08);

        padding: rem(4) 0;

        min-width: rem(216);

        position: absolute;
        top: rem(54);
        left: rem(-87);
        display: block;

        &:before{
            content: '';
            display: block;
            position: absolute;
            left: 0;
            right: 0;
            top: -30px;
            height: 30px;
        }

        .nav__item {
            padding-top: 0;
            padding-bottom: 0;
            .hover({
                background: @color-gray-light-2;
            })
        }

        .nav__link {
            color: @color-black;
            font-size: rem(15);

            padding: rem(13) rem(20);

            &_current{
                color: @color-primary;
            }
        }
    }

    &__toggle {
        font-size: rem(15);
    }

    &__item{
        padding: rem(3) 0;
    }

    &__link {
        cursor: pointer;

        &_toggle {
            .wi(icon-chevron-down, 12, 12, 4, @right: 0, @ab: after);

            &:after {
                position: relative;
                top: rem(2);
                left: rem(2);
            }
        }

        &_new{
            &::after{
                display: none;
            }
        }
    }
}
</style>
