<template>
    <div class="app__wrap"
        :class="{'with-p-block': banner}">
        <template v-if="!monitoringResponse">
            <div class="wrapper">
                <!-- eslint-disable-next-line -->
                <div v-if="banner" v-html="banner"
                    @click="onBannerClick"
                    class="p-block"></div>
                <AppHeader :view-desktop="isDeviceDesktop" />
                <Search class="app__search" ref="search" />
                <div class="page-content">
                    <nuxt :view-desktop="isDeviceDesktop" />
                </div>
                <AppFooter :view-desktop="isDeviceDesktop" />
                <ButtonUp />
            </div>

            <sidebar v-if="!isDeviceDesktop" ref="sidebar" />
        </template>

        <template v-else>
            <AppMonitoringBlock :monitoringData="monitoringResponse"/>
        </template>

        <client-only>
            <modals-container />
        </client-only>
    </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';

import AppHeader from '@/components/layout/AppHeader'
import AppFooter from '@/components/layout/AppFooter'
import Sidebar from '@/components/layout/Sidebar'
import Search from '@/components/layout/search'
import ButtonUp from '@/components/ui/buttons/ButtonUp'
import AppMonitoringBlock from '@/components/layout/AppMonitoringBlock'

export default {
    name: 'Default',
    components: {
        AppHeader,
        AppFooter,
        ButtonUp,
        Sidebar,
        Search,
        AppMonitoringBlock
    },
    async fetch(){

        this.monitoringResponse = this.getMonitoringResponse;
        await this.getCounters();
    },
    data(){
        return{
            oldScroll: 0,
            monitoringResponse: null
        }
    },
    computed: {
        ...mapGetters(['isDeviceDesktop', 'getHeaderBanner', 'getMonitoringResponse', 'getBugReportStatus']),
        banner(){
            if (this.$route.name === 'Diagnostic' || this.monitoringResponse) return false;
            return this.getHeaderBanner;
        },
        isBannerDiagnostic(){
            return this.getHeaderBanner.includes('target="diagnostic"');
        }
    },
    watch: {
        $route(to, from) {
            this.$auth.refreshTokenIfNeeded();
        },
    },
    globalBus: {
        'show-signin-popup'(params) {
            this.showPopup('signin', params)
        },
        'user-logout'() {
            this.$api.delete('/users/accounts/token')
                .then(() => {
                    this.$auth.logoutExt().then(() => {
                        this.$bus.$emit('user-logout-after');
                    });
                });
        },
        'open-sidebar'() {
            this.$refs.sidebar.open()
        },
        'show-monitoring-response'(data){
            this.$scrollTo('body');
            this.monitoringResponse = data;
        }
    },
    mounted() {
        
        this.readAndExecuteHash();
        this.checkExternalAuth();
        this.$auth.refreshTokenIfNeeded();
        
        window.addEventListener('beforeunload', () => {
            this.$scrollTo('html', 750)
        })

        if (!this.isDeviceDesktop){
            window.addEventListener('scroll', this.stickyHeader)
        };
        
        if(this.getBugReportStatus){
            window.onerror = (errorText,file,line,col,full) => {
                if (file && file.length > 0 && line > 0) {
                    this.$store.dispatch('errors/report', {
                        url: this.$route.fullPath,
                        error: {
                            type: 'console',
                            data: [
                                errorText, file + ' ' + [line,col].join(':')
                            ]
                        }
                    });
                }
            }
        }
    },
    destroyed(){
        if (!this.isDeviceDesktop){
            window.removeEventListener('scroll', this.stickyHeader)
        }
    },
    methods: {
        ...mapActions({
            'getCounters': 'entities/getCounters',
        }),
        stickyHeader(){
            if (this.banner){
                window.pageYOffset > 120 ? document.body.classList.add('fixed-header-hidden') : document.body.classList.remove('fixed-header-hidden')

                if (window.pageYOffset > 120){
                    document.body.classList.add('fixed-header');
                    this.oldScroll > window.pageYOffset ? document.body.classList.add('fixed-header-show') : document.body.classList.remove('fixed-header-show')
                }

                if (window.pageYOffset <= 56 && document.body.getAttribute('data-cutted') !== true){
                    document.body.classList.remove('fixed-header-hidden', 'fixed-header', 'fixed-header-show')
                }
            } else {
                window.pageYOffset > 60 ? document.body.classList.add('fixed-header-hidden') : document.body.classList.remove('fixed-header-hidden')

                if (window.pageYOffset > 60){
                    document.body.classList.add('fixed-header')

                    this.oldScroll > window.pageYOffset ? document.body.classList.add('fixed-header-show') : document.body.classList.remove('fixed-header-show')
                }

                if (window.pageYOffset === 0 && !document.body.getAttribute('data-cutted')){
                    document.body.classList.remove('fixed-header-hidden', 'fixed-header', 'fixed-header-show')
                }
            }

            this.oldScroll = window.pageYOffset
        },
        /**
         * Проверяет авторизацию и необходимость авторизовать через внешний сервис
         * 
         * @return
         */
        checkExternalAuth() {

            if (!this.$auth.userLoggedIn()) {
                const tokens = this.$cookies.get('auth_tokens');
                if (tokens && tokens.accessToken) {
                    this.$auth.setUserToken(tokens.accessToken, tokens.refreshToken)
                        .then(() => {
                            this.$alert.success(this.$t('alerts.auth-welcome', {name: this.$auth.user.name}));
                            this.$bus.$emit('auth-complete');
                            this.$cookies.remove('auth_tokens');
                            this.$auth.storeTokens(tokens);
                            this.executeAuthPendingEvent();
                        });
                }
            }
        },

        /**
         * Выполняет отложенное до внешней авторизации событие
         * 
         * @return
         */
        executeAuthPendingEvent() {
            if (process.client && sessionStorage) {
                let pendingEvent = sessionStorage.getItem('authPendingEvent');
                if (pendingEvent) {
                    pendingEvent = JSON.parse(pendingEvent);
                    if (pendingEvent.eventName) {
                        if (pendingEvent.url && pendingEvent.url !== window.location.pathname) {
                            sessionStorage.setItem('authPendingEvent', JSON.stringify({
                                eventName: pendingEvent.eventName,
                                params: pendingEvent.params
                            }));
                            window.location.href = pendingEvent.url + '#auth_pending';
                        } else {
                            this.$nextTick(() => {
                                this.$authEmit(pendingEvent.eventName, pendingEvent.params);
                            });
                        }
                    }
                }
            }
        },
        onBannerClick(e){
            if (this.isBannerDiagnostic) {
                e.preventDefault();
                e.stopPropagation();

                if (localStorage.getItem('diagnostics')) {
                    this.$router.push({name: 'Diagnostic'});
                } else {
                    this.showPopup('diagnostic');
                }
            }
        }
    },
    head() {
        if (this.showCaptcha) {
            return {
                title: 'Aromo',
                meta: [
                    {
                        hid: 'robots',
                        name: 'robots',
                        content: 'noindex, nofollow'
                    }
                ]
            }
        }

        return {
            htmlAttrs: {
                class: this.isDeviceDesktop ? 'html view_desktop' : 'html view_mobile',
                ...this.$nuxtI18nHead().htmlAttrs
            },
            meta: this.$nuxtI18nHead().meta
        }
    }
}
</script>

<style lang="less">
.with-p-block{
    .wrapper{
        padding-top: rem(112);
    }

    .header{
        top: @header-height-mobile;

        .fixed-header &{
            top: 0;
        }

        .body_notices-popup & {
            position: fixed;
            top: 0;
        }

        @{desktop} & {
            top: 0;
        }

    }
}

.p-block{
    overflow: hidden;

    @{desktop} & {
        height: rem(75);
        margin: 0 auto;
        padding: 0 rem(20);
        width: @widthWrapperDesktop;
    }

    @{mobile} & {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        height: rem(56);
    }

    &>a{
        display: block;
        height: 100%;
    }

    img{
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

</style>