import Vue from 'vue';
import upperFirst from 'lodash/upperFirst';
import filters from '@/filters';


export default (context) => {
    Object.keys(filters).forEach(entity => {
        Object.keys(filters[entity].default).forEach(key => {
            Vue.filter(`${entity}${upperFirst(key)}`, filters[entity].default[key]);
        });
    });
};
