<template>
    <div
        class="base-tag"
        :class="classes">

        <html-link
            v-if="htmlUrl"
            :url="htmlUrl">
            {{ name }}
        </html-link>

        <div v-else>
            {{ name }}
        </div>
    </div>
</template>

<script>

export default {
    name: 'BaseTag',
    props: {
        small: {
            type: Boolean,
            default: false
        },
        name: {
            type: String,
            default: null
        },
        htmlUrl: {
            type: String,
            default: null
        }
    },
    computed: {
        classes() {
            return {
                'base-tag_small': this.small
            }
        }
    },
}
</script>

<style lang="less">
.base-tag {
    display: inline-block;

    border: rem(1) solid @color-gray-white;
    border-radius: rem(3);
    font-size: rem(15);
    /* text-align: center; */
    /* white-space: nowrap; */

    position: relative;

    div,
    a {
        display: flex;
        align-items: center;

        color: @color-black;
        text-decoration: none;

        padding: rem(9) rem(16);
    }

    &_small {
        font-size: rem(11);

        div, a{
            padding: rem(4) rem(8) rem(3);
        }
    }

    @{desktop} & {
        transition: box-shadow 0.2s ease;

        &:hover,
        &:focus {
            @media @hover {
                box-shadow: 0 rem(1) rem(8) rgba(0, 0, 0, 0.1);
            }
        }
    }
}
</style>
