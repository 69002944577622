<i18n>
{
"ru": {
"formats": "JPG, PNG, HEIF",
"file_size": "Файл не больше 10 MB"
}
}
</i18n>
<template>
    <label
        class="upload-area"
        :class="rootClasses">

        <svg-icon
            name="icon-upload"
            class="upload-area__icon"/>

        <div class="upload-area__text">
            <div class="upload-area__formats">{{ formats || $t('formats') }}</div>

            <div class="upload-area__description">{{ fileDescription || $t('file_size') }}</div>

            <div class="upload-area__description" v-if="description">{{ description }}</div>

            <input
                class="upload-area__files"
                ref="fileInput"
                @change="onFilesChange($event)"
                type="file"
                :multiple="multiple"
                :value="null"
                :accept="acceptFiles"/>
        </div>

    </label>
</template>

<script>
import includes from 'lodash/includes'

export default {
    name: 'BaseUploadArea',
    inheritAttrs: false,
    props: {
        size: {
            type: String,
            default: 'regular',
            validator(value) {
                return includes(['small', 'regular'], value)
            }
        },
        multiple: {
            type: Boolean,
            default: false
        },
        formats: {
            type: String,
            default: ''
        },
        fileDescription: {
            type: String,
            default: null
        },
        description: {
            type: String,
            default: ''
        },
        acceptFiles: {
            type: String,
            default: '.heic, .heif, .png, .jpg, .jpeg'
        }
    },
    computed: {
        rootClasses() {
            return {
                'upload-area_small': this.size === 'small'
            }
        }
    },
    methods: {
        onFilesChange(e) {
            this.$emit('change', e.target.files);
            this.reset();
        },
        reset(){
            this.$refs.fileInput.value = '';
        }
    }

}
</script>

<style lang="less">
.upload-area {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    background: @color-white;
    border: rem(1) dashed @color-gray-2;
    border-radius: rem(3);
    font-size: rem(13);

    min-height: rem(220);

    transition: border-color .15s ease, color .15s ease;

    .hover({
        color: @color-primary;
        border-color: @color-primary;

    });

    &:hover &__description,
    &:hover &__icon,
    &:hover &__formats {
        @media @hover {
            color: @color-primary;
        }
    }

    &__icon {
        margin-bottom: rem(24);

        height: rem(45);
        width: rem(34);

        transition: color .15s ease;
    }

    &__formats {
        color: @color-gray-3;
        font-weight: 500;

        margin-bottom: rem(2);

        transition: color .15s ease;
    }

    &__description {
        color: @color-gray-light;

        transition: color .15s ease;
    }

    &__files {
        display: none;
    }

    &__text {
        text-align: center;
    }

    &_small {
        flex-flow: row nowrap;
        justify-content: flex-start;

        min-height: rem(80);
        .page-padding();

        @{desktop} & {
            padding-right: @dim-page-padding;
            padding-left: @dim-page-padding;
        }
    }

    &_small &__icon {
        margin-right: rem(28);
        margin-bottom: 0;
    }

    &_small &__text {
        text-align: left;
    }
}
</style>
