<i18n>
{
    "ru": {
        "title": "404 - тут пусто и ничем не пахнет",
        "h2": "Это не то, что вы искали?",
        "text_first": "Да, тут пусто, и ничем не пахнет.",
        "text_second": "Но это лишь на первый взгляд…",
        "go_home": "Перейти на главную",
        "secret_one": "На самом деле – это секретное место, где можно получить",
        "secret_two": "5 мл",
        "secret_three": "на свой счёт!",
        "adwice": "Просто ответьте правильно: какой аромат вы видите на картинке ниже?",
        "share": "Увы, мы не вправе запретить вам рассказать об этом секретном месте своим друзьям!",
        "success_title": "Поздравляем!",
        "success_text_1": "Вы заработали",
        "success_text_2": "на ваш счёт",
        "fail_title": "Не расстраивайтесь!",
        "fail_text": "Попробуйте еще раз, и у вас все получится!",
        "credited": "На ваш счёт начислено"
    }
}
</i18n>
<template>
    <div class="page-not-found" :data-loading="!loaded">

        <block-price-panel />

        <div class="page-not-found__wrap">
            <div class="page-not-found__first">
            
                <h1>{{ statusCode }}</h1>
                <h2>{{ $t('h2') }}</h2>
                <p>
                    <span>{{ $t('text_first') }}</span><br />
                    <span>{{ $t('text_second') }}</span>
                </p>
                <ButtonBase class="page-not-found__button"
                    @click.native="goHome">{{ $t('go_home') }}</ButtonBase>
                
                <div class="page-not-found__arrows">
                    <img v-lazy-load data-src="/img/arrow_68x68.gif" alt="" />
                </div>
            </div>
            <div class="page-not-found__second">
                <h3 id="secret">{{ $t('secret_one') }} <b>{{ $t('secret_two') }}</b> {{ $t('secret_three') }}</h3>
                <div class="page-not-found__success" v-if="success && !alreadySuccess">
                    <svg-icon name="blob"/>
                    <p>{{ $t('credited') }} <b>{{ $t('secret_two') }}</b></p>
                </div>
                <div class="page-not-found__game" v-else>
                    <p>{{ $t('adwice') }}</p>
                    <picture v-if="perfume">
                        <img
                            v-lazy-load :data-src="get(perfume, 'picture.url', getEntityNoImage('perfume'))"
                            :alt="get(perfume, 'picture.alt', perfume.name)" />
                    </picture>

                    <div class="page-not-found__options">
                        <button v-for="item in options"
                            :key="item.code"
                            @click="doAnswer(item.code)">
                            {{ item.name }}
                        </button>
                    </div>

                </div>

                <SocialButtons theme="light"
                    class="page-not-found__share"
                    :caption="$t('share')"/>
            </div>

            <div class="page-not-found__compilation" v-if="!isDeviceDesktop">
                <PageBlock :title="'Интересные подборки'">
                    <div class="page-not-found__compilation-items">
                        <div class="page-not-found__compilation-item"
                            v-for="(comp,index) in compilation"
                            :key="index">
                            <a href="/">{{ comp.title }}</a>
                        </div>
                    </div>
                </PageBlock>
            </div>
        </div>
    </div>
</template>

<script>
import get from 'lodash/get'
import random from 'lodash/random'
import { mapGetters } from 'vuex'

import Game404 from '@/models/services/game404'

import SocialButtons from '@/components/ui/SocialButtons'
import ButtonBase from '@/components/ui/buttons/ButtonBase'
import PageBlock from '@/components/layout/AppPageBlock';
import BlockPricePanel from '@/components/catalog/BlockPricePanel';

export default {
    name: 'NotFound',
    components: {
        ButtonBase,
        SocialButtons,
        PageBlock,
        BlockPricePanel
    },
    props: {
        error: {
            type: Object,
            default: null
        }
    },
    fetch() {
        return Promise.all([
                (new Game404()).get()
            ])
            .then((results) => {
                this.options = results[0];
            });
    },
    fetchOnServer: true,
    data() {
        return {
            success: false,
            loaded: false,
            options: [],
            clickedAnswer: null,
            rightAnswer: false,
            alreadySuccess: false,
            compilation: [
                {
                    title: 'главная'
                },
                {
                    title: 'каталог'
                },
                {
                    title: 'лучшие ароматы'
                },
                {
                    title: 'самые новые новости'
                },
                {
                    title: 'фотографии пользователей'
                },
                {
                    title: 'популярные пользователи'
                },
                {
                    title: 'блогеры експерты'
                },
                {
                    title: 'ароматы для осени'
                },
                {
                    title: 'лучшие бренды 2019'
                },
                {
                    title: 'парфюмерные експерты'
                }
            ]
        }
    },
    computed: {
        ...mapGetters(['isDeviceDesktop', 'getEntityNoImage']),
        statusCode() {
            return get(this.error, 'statusCode', '404')
        },
        perfume() {
            return this.options[random(0, 2)]
        }
    },
    async beforeCreate() {

        if (process.server) {
            const path = this.$route.path;
            if (path !== '/404/') {
                this.loaded = false;

                await this.$store.dispatch('404/checkUrl', path)
                    .then(() => {
                        const result = this.$store.state['404'].result[path];
                        if (result && result.code === 'need-redirect') {
                            this.$nuxt.context.redirect(
                                result.status || 301, 
                                this.$options.filters.urlModify(result.location, this.$route.query)
                                );
                        } else {
                            this.loaded = true;
                            this.$nuxt.error({
                                statusCode: 404,
                                message: this.$t('layout.page-not-found')
                            });
                        }
                    })
                    .catch(() => {
                        this.loaded = true;
                        this.$nuxt.error({
                            statusCode: 404,
                            message: this.$t('layout.page-not-found')
                        });
                    });
            } else {
                this.loaded = true;
                this.$nuxt.error({
                    statusCode: 404,
                    message: this.$t('layout.page-not-found')
                });
            }
        }
    },
    async created() {
        const path = this.$route.path;

        if (path === '/404/') {
            this.loaded = true;
        } else if (process.browser) {
            await this.$store.dispatch('404/checkUrl', path)
                .then(() => {
                    const result = this.$store.state['404'].result[path];
                    if (result && result.code === 'need-redirect') {
                        this.$router.push(this.$options.filters.urlModify(result.location, this.$route.query));
                        return
                    }
                })
                .catch(err => {
                    console.log(err)
                });
        };

        this.setStatus(404)
    },
    globalBus: {
        'auth-complete'() {
            if (this.rightAnswer) {
                this.processRightAnswer();
            }
        },
    },
    methods: {
        get,
        goHome() {
            this.$router.push('/')
        },
        setStatus(statusCode) {

            this.loaded = true;
            return this.$nuxt.error({
                statusCode,
                message: this.$t('layout.page-not-found')
            });
        },  
        showResult() {
            this.showPopup('game-404', {
                result: this.success,
                alreadySuccess: !!this.alreadySuccess
            })
            this.reloadGame();
        },
        doAnswer(code) {
            if (this.perfume.code === code) {
                this.clickedAnswer = code;
                this.rightAnswer = code;

                if (!this.$auth.loggedIn) {
                    this.$bus.$emit('show-signin-popup', {hasNextAction: true});
                } else {
                    this.processRightAnswer();
                }
            }  else {
                this.success = false;
                this.rightAnswer = null;
                this.showResult();
            }
        },
        processRightAnswer() {

            const gameObj = new Game404();

            this.$scrollTo('#secret', {
                offset: -76,
                onDone: () => {
                    this.$nextTick(() => {
                        gameObj.save()
                            .then(() => {
                                this.success = true;
                                this.showResult();
                            })
                            .catch((error) => {
                                switch (error.response.data.code) {
                                case 'game-is-already-won':
                                    this.success = true;
                                    this.alreadySuccess = true;
                                    this.showResult();
                                    break;
                                }
                            })
                    })
                }
            })
        },
        reloadGame(){
            const gameObj = new Game404();
            this.rightAnswer = null;

            const requests = [
                gameObj.get()
            ]

            return Promise.all(requests).then((results) => {
                this.options = results[0]
            })
        }
    },
    head() {
        return {
            title: this.$t('title')
        }
    },
}
</script>

<style lang="less">
@keyframes speaker {
    0% {
        opacity: 0.5;
    }
    75% {
        opacity: 0.75;
    }
    100% {
        opacity: 1;
    }
}
.page-not-found {
    padding-bottom: rem(196);
    background-color: @color-white;
    position: relative;
    min-height: 70vh;

    &[data-loading="true"],
    &[data-loading="1"] {
        background: url('~static/img/loading-sm.gif') no-repeat 50%;

        .page-not-found__wrap{
            opacity: 0;
            display: none;
        }
    }

    @{mobile} & {
        padding-bottom: rem(24);
    }

    &__first {
        height: calc(100vh - rem(124));
        display: flex;
        flex-flow: column;
        align-items: center;
        justify-content: center;

        background: @color-white;
        text-align: center;

        min-height: rem(485);

        position: relative;

        .with-p-block & {
            height: calc(100vh - rem(200));

            @{mobile} & {
                height: calc(86vh - rem(156));
            }
        }

        @{mobile} & {
            height: calc(86vh - rem(100));

            @media @landscape {
                padding-top: rem(8);
                padding-bottom: rem(100);

                height: auto;
            }
        }

        h1 {
            font-size: rem(72);
            font-weight: normal;
            line-height: rem(108);
            margin: 0;

            @{mobile} & {
                @media @landscape {
                    font-size: rem(60);

                    margin-bottom: 0;
                    padding-top: rem(10);
                    padding-bottom: 0;
                }
            }
        }

        h2 {
            font-size: rem(18);
            line-height: rem(27);
            margin-bottom: rem(6);

            @{mobile} & {
                @media @landscape {
                    font-size: rem(15);

                    margin-bottom: 0;
                }
            }
        }

        p {
            color: @color-gray;
            font-size: rem(15);
            line-height: rem(22.5);

            margin-bottom: rem(32);

            position: relative;

            @{mobile} & {
                @media @landscape {
                    font-size: rem(13);

                    margin-bottom: rem(24);
                }
            }
        }
    }

    &__arrows {
        width: rem(34);
        height: rem(34);
        position: absolute;
        bottom: rem(21);
        left: 0;
        right: 0;
        margin: 0 auto;

        &>img{
            display: block;
            width: 100%;
            height: 100%;
        }
    }

    &__button {
        padding-right: rem(24);
        padding-left: rem(24);

        position: relative;
    }

    &__second {
        background: @color-white;
        text-align: center;
        max-width: 432px;
        margin: 0 auto;
        padding-top: rem(32);

        h3 {
            font-size: rem(16);
            font-weight: 500;
            line-height: rem(24);

            margin-bottom: rem(12);

            b {
                color: @color-primary;
                font-weight: inherit;
            }
        }

        @{mobile} & {
            .page-padding();
            padding-bottom: rem(24);

            &:last-child{
                padding-bottom: 0;
            }

            h3{
                .page-padding();
            }
        }
    }

    &__success {
        padding: rem(20) 0 rem(56);

        svg {
            margin-bottom: rem(20);

            width: rem(92);
            height: rem(133);
        }

        p {

            b {
                font-weight: 500;
            }
        }
    }

    &__game {

        p {
            color: @color-gray;
            font-size: rem(14);
            line-height: rem(21);
            max-width: 310px;
            margin: 0 auto rem(32);
        }

        picture {
            display: block;

            margin: 0 auto rem(24);

            height: rem(208);
            width: rem(208);

            img {
                object-fit: contain;

                height: 100%;
                width: 100%;
            }
        }
    }

    &__options {
        max-width: 335px;
        margin: 0 auto rem(56);

        button {
            display: block;

            background: @color-white;
            border: rem(1) solid @color-gray-white;
            font-weight: 500;
            text-align: center;

            margin-top: rem(10);
            padding: rem(11) rem(24) rem(9);

            border-radius: 3px;

            width: 100%;
            
            transition: border-color .15s ease;

            &:hover {
                border-color: @color-violet;
            }
        }

        @{mobile} & {
            max-width: none;
            margin-bottom: rem(35);
        }
    }

    &__share {

        p {
            margin-bottom: rem(24);
        }

        @{mobile} & {
            .page-padding();
        }
    }

    &__compilation{
        border-top: rem(16) solid @color-gray-light-2;
        border-bottom: rem(16) solid @color-gray-light-2;

        &-items{
            padding: rem(12) 0 rem(8);
            .page-padding();
        }

        &-item{
            margin: 0 0 rem(8);

            &:last-child{
                margin-bottom: 0;
            }

            &>a{
                color: @color-gray;
                font-size: rem(15);
                line-height: rem(22.5);
            }
        }
    }
}
</style>