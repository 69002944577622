<template>
    <button
        class="button-more"
        :class="classes"
        :style="cssProps">
        <slot></slot>

        <span
            v-if="buttonIconName"
            class="button-more__icon"
            :class="iconClasses">
            <svg-icon :name="buttonIconName"/>
        </span>
    </button>
</template>

<script>
export default {
    name: 'ButtonMore',
    props: {
        centered: {
            type: Boolean,
            default: false
        },
        buttonIconName: {
            type: String,
            default: ''
        },
        transformBtnY: {
            type: Number,
            default: null
        },
        isOpened: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        classes() {
            return {
                'button-more_base': !this.centered,
                'button-more_centered': this.centered,
                'button-more_with-icon': this.buttonIconName.length > 0
            }
        },
        iconClasses() {
            return {
                'button-more__icon_chevron-down': this.buttonIconName === 'icon-chevron-down',
                'button-more__icon_opened': this.isOpened
            }
        },
        cssProps() {
            return {
                '--transformY': (this.transformBtnY / 15).toFixed(7) + 'rem'
            }
        }
    }
}
</script>

<style lang="less">
.button-more {
    background: none;
    border: none;
    color: @color-violet;
    outline: none;

    padding: rem(4) 0;

    transition: background-color .15s ease;

    transform: translateY(var(--transformY));

    user-select: none;

    will-change: transform, opacity;

    &__icon {
        --size-icon: rem(14);
        display: flex;
        align-items: center;
        justify-content: center;

        margin-left: rem(4);

        height: var(--size-icon);
        width: var(--size-icon);

        overflow: hidden;

        svg {
            max-height: 100%;
            max-width: 100%;

            transition: transform 0.3s;
        }

        &_opened {
            svg {
                transition: transform 0.3s;

                transform: rotate(180deg);
            }
        }
    }

    &_centered {
        text-align: center;

        padding: rem(12) 0 rem(12);

        width: 100%;

        .hover({
            background-color: @color-gray-light-2;
        });
    }

    &_narrow{
        @{desktop} &{
            max-width: rem(335);
            border: 1px solid @color-gray-white;
            border-radius: rem(3);
            margin: 0 auto;
            padding: 0 rem(12);
            height: rem(44);
            line-height: rem(44);
            .animate(border-color);

            .hover({
                border-color: @color-violet;
                background: transparent;
            });

            &:active{
                color: @color-violet;
            }
        }
    }

    &_base {

        .hover({
            text-decoration: underline;
        });
    }

    &_with-icon {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
</style>
