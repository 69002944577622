import filter from 'lodash/filter'
const state = () => {
    return {
        perfumes: [],
    }
}

const getters = {
    getPerfumeByCode: (s, code) => {
        return filter(s.perfumes, { code })
    },
}

const mutations = {
    setPerfumes(s, array) {
        s.perfumes = array
    },
}

const actions = {
    getData({ commit }) {
        const array = [];
        commit('setPerfumes', array)
    }
}

export default {
    state,
    getters,
    mutations,
    actions
}
