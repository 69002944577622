<template>
    <transition
        :name="overlayTransition">
        <div
            v-if="preRender ? true : visibility.overlay"
            ref="overlay"
            class="modal__overlay modal"
            :class="overlayClasses"
            :style="overlayStyles"
            :aria-expanded="visibility.overlay.toString()"
            :data-modal="name"
            @mousedown.self="onMouseDown"
            @mouseup.self="onMouseUp"
            @click.self="handleBackgroundClick"
            v-touch:moving="!isDeviceDesktop && swipeY ? onTouchMove : false"
            v-touch:start="!isDeviceDesktop && swipeY ? onTouchStart : false"
            v-touch:end="!isDeviceDesktop && swipeY ? onTouchEnd : false">

            <div v-if="preRender ? false : visibility.computeModal"
                ref="computeModal"
                style="visibility: hidden">
                <slot></slot>
            </div>

            <transition
                :name="transition"
                :duration="110"
                @before-enter="beforeTransitionEnter"
                @after-enter="afterTransitionEnter"
                @before-leave="beforeTransitionLeave"
                @after-leave="afterTransitionLeave">
                <div v-if="preRender ? true : visibility.modal"
                    ref="modal"
                    v-touch:swipe.prevent="closeByDash || swipeY ? () => {} : handleBackgroundSwipe"
                    :class="modalClass"
                    :style="modalStyle">
                    <div
                        v-if="!isDeviceDesktop && closeByDash"
                        v-touch:swipe.prevent="handleBackgroundSwipe"
                        v-touch-options="touchOptions"
                        class="modal__block-touch-detect"></div>
                    <slot></slot>
                    <button
                        v-if="showClose"
                        @touchstart.self="onCloseClick"
                        @click="onCloseClick"
                        class="modal__close">
                        <svg-icon name="icon-close" />
                    </button>
                </div>
            </transition>
        </div>
    </transition>

</template>

<script>
import get from 'lodash/get';
import includes from 'lodash/includes'
import isEqual from 'lodash/isEqual'
import throttle from 'lodash/throttle';
import {mapGetters} from "vuex";

/* eslint-disable vue/no-dupe-keys */
/* eslint-disable-next-line vue/require-default-prop */
/* eslint-disable vue/require-default-prop */
import {
    createModalEvent,
    getMutationObserver,
    blurActiveElement,
    stringStylesToObject
} from './utils'
import { parseNumber, validateNumber } from './parser'
import Modal from './index'

export default {
    name: 'VueJsModal',
    props: {
        name: {
            required: true,
            type: String
        },
        boxPadding: {
            type: [ String, Number ],
            default: 0
        },
        inline: {
            type: Boolean,
            default: false
        },
        closable: {
            type: Boolean,
            default: true
        },
        loadedOverlay: {
            type: Boolean,
            default: false
        },
        draggable: {
            type: Boolean,
            default() {
                return !!this.closable;
            }
        },
        overlayTransition: {
            type: String,
            default: 'overlay-fade'
        },
        transition: {
            type: String,
            default: 'modal-fade'
        },
        showClose: {
            type: Boolean,
            default() {
                return !!this.closable;
            }
        },
        clickToClose: {
            type: Boolean,
            default() {
                return !!this.closable;
            }
        },
        classes: {
            type: [String, Array],
            default: ''
        },
        styles: {
            type: [String, Array, Object],
        },
        fullscreen: {
            type: Boolean,
            default: false
        },
        width: {
            type: [Number, String],
            default: 'auto',
            validator(value) {
                return value === 'auto' || validateNumber(value)
            }
        },
        height: {
            type: [Number, String],
            default: 'auto',
            validator(value) {
                return value === 'auto' || validateNumber(value)
            }
        },
        closeByDash: {
            type: Boolean,
            default: false
        },
        type: {
            type: String,
            default: 'fullscreen',
            validator(value) {
                const types = [
                    'top',
                    'fullscreen',
                    'centered',
                    'bottom',
                    'right'
                ]

                return includes(types, value)
            }
        },
        preRender: {
            type: Boolean,
            default: false
        },
        swipeY: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            closeDelayAnimate: 250,
            closeDelayDefault: 100,
            delay: 110,
            downed: false,
            visible: false,

            visibility: {
                computeModal: false,
                modal: false,
                overlay: false
            },

            modal: {
                width: 0,
                widthType: 'rem',
                height: 0,
                heightType: 'rem',
                renderedHeight: 0
            },

            viewport: {
                width: 0,
                height: 0
            },

            mutationObserver: null,
            tapTolerance: 10,
            dragging: this.draggable,
            touchY: null,
            touchX: null,
            moving: false,
            xSwipe: false,
            closing: false
        }
    },
    computed: {
        ...mapGetters(['isDeviceDesktop']),
        clicked() {
            return !this.isDeviceDesktop || this.downed && this.upped
        },
        overlayStyles() {
            return {
                'padding-top': this.boxPadding + 'px',
                'padding-bottom': this.boxPadding + 'px'
            }
        },
        overlayClasses() {
            return {
                'modal__overlay_loaded': this.loadedOverlay,
                'modal__overlay_hidden': this.preRender && !this.visibility.overlay,
                'modal__overlay_prerender': this.preRender,
                'modal__overlay_moving': this.moving,
                'modal__overlay_swipe-y': this.swipeY,
                'modal__overlay_bottom': this.type === 'bottom'
            }
        },
        touchOptions() {
            // return this.type === 'bottom' ?
            //     {swipeTolerance: 200, tapTolerance: this.tapTolerance} :
            //     {swipeTolerance: 50, tapTolerance: 10}
            return {swipeTolerance: 50, tapTolerance: 10}

        },
        additionalClasses() {
            return {
                'modal__box_bottom': this.type === 'bottom',
                'modal__box_top modal__animate-top': this.type === 'top',
                'modal__box_unselectable': this.downed || this.upped,
                'modal__box_right modal__animate-right': this.type === 'right'
            }
        },
        /**
		 * Returns true if height is set to "auto"
		*/
        isAutoHeight() {
            return this.height === 'auto'
        },
        /**
		 * Returns pixel width (if set with %) and makes sure that modal size
		 * fits the window
		*/
        trueModalWidth() {
            const { modal, fullscreen } = this

            const value = fullscreen ? '100%' : modal.width

            return value
        },
        /**
		 * Returns pixel height (if set with %) and makes sure that modal size
		 * fits the window.
		 *
		 * Returns modal.renderedHeight if height set as "auto"
		*/
        trueModalHeight() {
            const { modal, isAutoHeight, fullscreen } = this

            const value = fullscreen ? '100%' : modal.height

            if (isAutoHeight) {
                // use renderedHeight when height 'auto'
                return this.modal.renderedHeight
            }

            return value
        },
        /**
		 * Returns class list for modal itself
		*/
        modalClass() {
            return ['modal__box', this.classes, this.additionalClasses]
        },
        stylesProp() {
            return typeof this.styles === 'string'
                ? stringStylesToObject(this.styles)
                : this.styles
        },
        /**
		 * CSS styles for position and size of the modal
		 */
        modalStyle() {
            return [this.stylesProp, {
                width: this.fullscreen ? '100%' : this.trueModalWidth + this.modal.widthType,
                height: this.fullscreen ? '100%' : ( this.isAutoHeight ? 'auto' : this.trueModalHeight + this.modal.heightType )
            }, ]
        }
    },
    watch: {
        /**
		 * Sets the visibility of overlay and modal.
		 * Events 'opened' and 'closed' is called here
		 * inside `setTimeout` and `$nextTick`, after the DOM changes.
		 * This fixes `$refs.modal` `undefined` bug (fixes #15)
		*/
        'visibility.computeModal'(value) {
            if(value) {

                // this.$nextTick(() => {
                // if(this.$refs.computeModal.clientHeight > window.innerHeight - 100) {
                // this.tapTolerance = 150;
                // }
                // });

                this.visibility.modal = true;
                this.visibility.computeModal = false;
            }
        },
        visible(value) {
            if (value) {
                this.visibility.overlay = true
                setTimeout(() => {
                    this.visibility.computeModal = true

                    this.$nextTick(() => {
                        this.callAfterEvent(true)
                    })
                }, this.delay)
            } else {
                this.visibility.computeModal = false

                setTimeout(() => {
                    this.visibility.overlay = false
                    this.$nextTick(() => {
                        this.callAfterEvent(false)
                    })
                }, this.delay)
            }
        }
    },
    created() {
        this.setInitialSize();
    },
    /**
	 * Sets global listeners
	*/
    beforeMount() {
        Modal.event.$on('toggle', this.handleToggleEvent)
        window.addEventListener('resize', this.handleWindowResize)
    	this.handleWindowResize()

        /**
		 * Only observe when using height: 'auto'
		 * The callback will be called when modal DOM changes,
		 * this is for updating the `top` attribute for height 'auto' modals.
		*/
        if (this.isAutoHeight) {
            /**
			 * MutationObserver feature detection:
			 *
			 * Detects if MutationObserver is available, return false if not.
			 * No polyfill is provided here, so height 'auto' recalculation will
			 * simply stay at its initial height (won't crash).
			 * (Provide polyfill to support IE < 11)
			 *
			 * https://developer.mozilla.org/en-US/docs/Web/API/MutationObserver
			 *
			 * For the sake of SSR, MutationObserver cannot be initialized
			 * before component creation >_<
			*/
            const MutationObserver = getMutationObserver()

            if (MutationObserver) {
                this.mutationObserver = new MutationObserver(mutations => {
                    this.updateRenderedHeight()
                })
            } else {
                console.warn('MutationObserver was not found. Vue-modal automatic resizing relies heavily on MutationObserver. Please make sure to provide shim for it.')
            }
        }
    },
    /**
	 * Removes global listeners
	*/
    beforeDestroy() {
        Modal.event.$off('toggle', this.handleToggleEvent)
        window.removeEventListener('resize', this.handleWindowResize)
        window.removeEventListener('resize', this.toggleDraggable)
    },
    methods: {
        onMouseDown() {
            this.downed = true
        },
        onMouseUp() {
            this.upped = true
        },
        onCloseClick() {
            this.toggle(false)
            this.$emit('closeClick')
            this.$emit('showAlert')
        },
        toggleDraggable() {
            const {
                modal,
                viewport
            } = this

            viewport.height >= modal.renderedHeight ? this.dragging = true : this.dragging = false
        },

        handleToggleEvent(name, state, params) {
            if (this.name === name || typeof name === 'undefined') {
                const nextState = typeof state === 'undefined'
                    ? !this.visible
                    : state
                if (!nextState && this.type === 'top') {
                    this.$refs.modal.classList.add('modal__animate-top');
                    this.close(this.closeDelayAnimate);
                }
                else {
                    this.toggle(nextState, params)
                }
            }
        },
        setInitialSize() {
            const { modal } = this
            const width = parseNumber(this.width)
            const height = parseNumber(this.height)

            modal.width = width.value
            modal.widthType = width.type
            modal.height = height.value
            modal.heightType = height.type
        },

        handleWindowResize() {
            const {
                viewport
            } = this

            viewport.width = window.innerWidth
            viewport.height = window.innerHeight

            this.toggleDraggable()
        },
        /**
		 * Generates event object
		*/
        createModalEvent(args = {}) {
            return createModalEvent({
                name: this.name,
                ref: this.$refs.modal,
                ...args
            })
        },
        /**
		 * Event handler which is triggered on modal resize
		*/
        handleModalResize(event) {
            this.modal.widthType = 'rem'
            this.modal.width = event.size.width

            this.modal.heightType = 'rem'
            this.modal.height = event.size.height

            const { size } = this.modal

            this.$emit(
                'resize',
                this.createModalEvent({ size })
            )
        },
        /**
		 * Event handler which is triggered on $modal.show and $modal.hide
		 * BeforeEvents: ('before-close' and 'before-open') are `$emit`ed here,
		 * but AfterEvents ('opened' and 'closed') are moved to `watch.visible`.
		*/
        toggle(nextState, params) {
            // this.$modal.isShow = !this.$modal.isShow;
            const { visible } = this
            const countModal = document.querySelectorAll('[data-modal]').length

            if (visible === nextState) {
                return
            }

            const beforeEventName = visible
                ? 'beforeClose'
                : 'beforeOpen'

            const afterEventName = visible
                ? 'afterClose'
                : 'afterOpen';

            if (beforeEventName === 'beforeOpen') {
                blurActiveElement()

                this.$body.cut();

                // if (countModal === 0) {
                //     $body.style.top = '-' + window.scrollY + 'px'
                // }
            } else if (countModal === 1) {
                this.$body.uncut();
            }

            let stopEventExecution = false

            const stop = () => {
                stopEventExecution = true
            }

            const beforeEvent = this.createModalEvent({
                stop,
                state: nextState,
                params
            })

            this.$emit(beforeEventName, beforeEvent);

            if (!stopEventExecution) {
                if (nextState && this.$modal.isShow) {
                    nextState = this.$modal.isShow;
                }
                this.$modal.isShow = nextState;
                this.visible = nextState;
                this.$emit(afterEventName, beforeEvent);
            }
        },

        /**
		 * Event handler that is triggered when background overlay is clicked
		*/
        handleBackgroundClick(e) {
            if (
                isEqual(e.target, this.$refs.overlay) &&
                this.clicked &&
                this.clickToClose
            ) {
                if (this.type === 'right') {
                    this.$refs.modal.classList.add('modal__animate-right');
                    this.close(this.closeDelayAnimate);
                }
                else if (this.type === 'bottom') {
                    this.$refs.modal.classList.add('modal__animate-bottom');
                    this.close(this.closeDelayAnimate);
                }
                else if (this.type === 'top') {
                    this.$refs.modal.classList.add('modal__animate-top');
                    this.close(this.closeDelayAnimate);
                }
                else {
                    this.close(this.closeDelayDefault);
                }
            }

            this.downed = this.upped = false
        },
        handleBackgroundSwipe(direction) {
            if(this.isDeviceDesktop) {
                return
            }

            if (this.draggable && this.dragging) {
                if (this.type === 'bottom') {
                    if (direction === 'bottom') {
                        this.$refs.modal.classList.add('modal__animate-bottom');
                        this.close(this.closeDelayAnimate);
                    }
                } else {
                    // eslint-disable-next-line no-undef
                    switch (direction) {
                    case 'top':
                        this.$refs.modal.classList.add('modal__animate-top');
                        this.close(500);
                        break;
                    case 'bottom':
                        this.$refs.modal.classList.add('modal__animate-bottom');
                        this.close(500);
                        break;
                    default:
                        break;
                    }
                }
            }
        },

        close(timeout = this.closeDelayAnimate) {
            setTimeout(() => {
                this.toggle(false);
            }, timeout);
        },


        /**
		*'opened' and 'closed' events are `$emit`ed here.
		* This is called in watch.visible.
		* Because modal DOM updates are async,
		* wrapping afterEvents in `$nextTick` fixes `$refs.modal` undefined bug.
		* (fixes #15)
		*/
        callAfterEvent(state) {
            if (state) {
                this.connectObserver()
            } else {
                this.disconnectObserver()
            }
            const eventName = state ? 'opened' : 'closed'
            const event = this.createModalEvent({ state })
            this.$emit(eventName, event)
        },

        /**
		 * Update $data.modal.renderedHeight using getBoundingClientRect.
		 * This method is called when:
		 * 1. modal opened
		 * 2. MutationObserver's observe callback
		*/
        updateRenderedHeight() {
            if (this.$refs.modal) {
                this.modal.renderedHeight = this.$refs.modal.getBoundingClientRect().height;
                this.toggleDraggable()
            }
        },
        /**
		 * Start observing modal's DOM, if childList or subtree changes,
		 * the callback (registered in beforeMount) will be called.
		*/
        connectObserver() {
            if (this.mutationObserver) {
                this.mutationObserver.observe(this.$refs.overlay, {
                    childList: true,
                    attributes: true,
                    subtree: true
                })
            }
        },
        /**
		 * Disconnects MutationObserver
		*/
        disconnectObserver() {
            if (this.mutationObserver) {
                this.mutationObserver.disconnect()
            }
        },

        beforeTransitionEnter() {
            this.connectObserver()
        },

        afterTransitionEnter() {
            this.$refs.modal.classList.remove('modal__animate-bottom');
            this.$refs.modal.classList.remove('modal__animate-top');
            this.$refs.modal.classList.remove('modal__animate-right');
        },

        beforeTransitionLeave() {

        },

        afterTransitionLeave() {
        },
        onTouchStart(e){
            if(e.target.tagName === 'TEXTAREA'){
                return false;
            }

            this.touchY = get(e, 'touches[0].clientY');
            this.touchX = get(e, 'touches[0].clientX');
        },
        onTouchMove: throttle(function(e){
            if(e.target.tagName === 'TEXTAREA'){
                return false;
            }

            const yDiff = this.touchY - get(e, 'touches[0].clientY');
            const xDiff = this.touchX - get(e, 'touches[0].clientX');
            const isBottom = this.type === 'bottom';

            if(isBottom && yDiff > 0) return;

            if((Math.abs(xDiff) < Math.abs(yDiff)) && this.$refs.modal){
                if(!this.moving) this.moving = true;
                if(isBottom) this.$refs.overlay.style.overflow = 'hidden';
                this.$refs.overlay.style.transition = 'none';
                this.$refs.modal.style.transition = 'none';
                this.$refs.overlay.style.setProperty('--opacity', (100 - Math.abs(Math.ceil(yDiff/4)))/100);
                this.$refs.modal.style.transform = `translateY(${Math.ceil(yDiff/3*-1)}px)`;
            }
        }, 10),
        onTouchEnd(e){
            if(e.target.tagName === 'TEXTAREA'){
                return false;
            }

            const isBottom = this.type === 'bottom';
            const yDiff = this.touchY - get(e, 'changedTouches[0].clientY');
            const offset = Math.abs(Math.ceil(yDiff/3));
            const dashToCloseOffset = isBottom ? 45 : 70;
            const offsetDirection = Math.ceil(yDiff/3) > 0;

            if(offset > dashToCloseOffset){
                if(isBottom && yDiff > 0) return;
                
                this.$refs.overlay.style.removeProperty('overflow');
                this.$refs.overlay.style.removeProperty('transition');
                this.$refs.modal.style.removeProperty('transition');
                this.$refs.modal.style.transform = offsetDirection ? `translateY(-100%)` : `translateY(100%)`;
                this.close(150);
                return;
            }

            this.$refs.overlay.style.removeProperty('overflow');
            this.$refs.overlay.style.removeProperty('transition');
            this.$refs.modal.style.removeProperty('transition');
            this.touchY = this.touchX = null;
            this.moving = false;
            this.$refs.modal.style.transform = 'translateY(0px)';
        }
    }
}
</script>

<style lang="less">
.modal {
    overscroll-behavior-y: contain;
    touch-action: manipulation;

    &__block-touch-detect {
        height: rem(30);
        width: 100%;

        position: absolute;
        z-index: 9;
    }

    &__overlay {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        // min-height: 100vh;
        padding: 0;
        height: 100%;
        width: 100%;

        position: fixed;
        top: 0;
        left: 0;
        z-index: 110;

        overflow-x: hidden;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;

        transform: translateZ(0);
        -webkit-backface-visibility: hidden;
        -webkit-tap-highlight-color: transparent;
        -ms-touch-action: manipulation;
        touch-action: manipulation;
        background: rgba(0, 0, 0, 0.5);

        @{mobile} & {
            background: transparent;

            &::before{
                position: absolute;
                left: 0;
                top: 0;
                bottom: 0;
                right: 0;
                content: '';
                background: rgba(0, 0, 0, 0.5);
            }
        }

        &_loaded {
            background: fade(@color-black, 50%) url('~static/img/loading-popup.gif') no-repeat 50% 50%;
            
            @{mobile} & {
                background: none;
                
                &::before{
                    background: fade(@color-black, 50%) url('~static/img/loading-popup.gif') no-repeat 50% 50%;
                }
            }
        }

        &_prerender{
            @{desktop} & {
                transition: opacity .25s;
            }
        }

        &_hidden{
            pointer-events: none;
            opacity: 0;
            z-index: -1;
        }

        &_swipe-y{
            &::before{
                transition: opacity .15s;
            }
            
            .modal-content{
                &__title,
                &__footer{
                    transition: opacity .15s;
                }
            }
        }

        &_moving{

            &::before{
                opacity: var(--opacity);
                transition: none;
            }

            .modal{
                &__close{
                    opacity: 0;
                }
            }

            .modal__box{
                &:not(.modal__box_bottom){
                    .modal-content{
                        &__title,
                        &__footer{
                            opacity: 0;
                        }
                    }
                }
            }

            .modal-content{
                pointer-events: none;
                opacity: var(--opacity);
            }
        }
    }

    &__box {
        background-color: @color-white;
        border-radius: rem(3);

        max-width: 100%;

        position: relative;

        overflow: hidden;

        transition: transform .5s ease, opacity .5s ease;

        &_unselectable {
            user-select: none;
        }

        &_top {
            align-self: flex-start;

            border-radius: 0 0 rem(8) rem(8);

            height: auto;

            top: @header-height-mobile;

            transition: transform .25s ease, opacity .25s ease;
        }

        &_bottom {
            align-self: flex-end;
            border-radius: rem(8) rem(8) 0 0;
            height: auto;
            transition: transform .25s ease, opacity .25s ease;

            &:before {
                content: '';
                display: block;

                background-color: @color-gray-white;
                border-radius: rem(20);

                height: rem(4);
                width: rem(36);

                position: absolute;
                top: rem(8);
                left: 50%;
                margin-left: rem(-18);
                z-index: 1;

                pointer-events: none;
            }
        }

        &_right {
            position: absolute;
            top: 0;
            right: 0;

            transition: transform .25s ease, opacity .25s ease;

            // transform: translateX(0);

            @{desktop} & {
                .modal__close {
                    top: rem(6);
                    right: rem(9);
                }
            }
        }

        &_no_radius {
            border-radius: 0;
        }

        &_no_bg {
            background: none;
        }

        &_desktop-top {
            bottom: 8.5vh;
        }

        &_perfume{
            .modal-content__title{
                padding-bottom: rem(16);
            }
        }

        &_sticky-footer{
            overflow: visible;
        }
    }

    &__box_bottom &__close {
        display: none;
    }

    &__close {
        cursor: pointer;

        background: none;
        border: none;

        padding: rem(5);

        height: rem(30);
        width: rem(30);

        position: absolute;
        top: pxToRem(11);
        right: pxToRem(15);
        z-index: 3;

        .animate;

        .hover({
            color: @color-gray-light;
        });

        svg {
            height: 100%;
            width: 100%;

            &:before{
                content: '';
                display: block;

                position: absolute;
                top: rem(-5);
                right: rem(-5);
                bottom: rem(-5);
                left: rem(-5);
            }
        }

    }

    &__animate {

        &-top {
            opacity: 0;
            transform: translateY(-50%);
        }

        &-fade {
            opacity: 0;
        }

        &-bottom {
            opacity: 0;
            transform: translateY(50%);
        }

        &-right {
            opacity: 0;
            transform: translateX(600px);
        }

    }

}

.overlay-fade-enter-active{
    transition: all .25s;
}

.overlay-fade-leave-active{
    transition: all .25s;
}

.overlay-fade-enter,
.overlay-fade-leave-active {
    opacity: 0;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
    transition: all .25s;
}

.modal-fade-enter,
.modal-fade-leave-active {
    opacity: 0;
    transform: translateY(-20px);

    .modal__overlay_bottom & {
        transform: translateY(20px);
    }
}
</style>
