export const state = () => ({
    catalogDesktop: {
        perfumes: {
            url: '/perfumes/'
        },
        brands: {
            url: '/brands/'
        },
        noses: {
            url: '/noses/'
        },
        notes: {
            url: '/notes/'
        },
        news: {
            url: '/news/'
        },
        library: {
            url: '/articles/'
        },
        families: {
            icon: 'nav-catalog-groups',
            url: '/families/'
        },
        segments: {
            url: '/segments/'
        },
        concentrations: {
            url: '/concentrations/'
        },
        producers: {
            icon: 'nav-catalog-noses',
            url: '/producers/'
        },
        glossary: {
            icon: 'nav-catalog-noses',
            url: '/glossary/'
        },
        shops: {
            url: '/shops/'
        },
        sets: {
            icon: 'nav-catalog-noses',
            url: '/sets/'
        },
        perfumes_by_year: {
            icon: 'nav-catalog-noses',
            url: '/years/'
        },
        brands_by_country: {
            icon: 'nav-catalog-shops',
            url: '/regions/'
        },
        licensees: {
            url: '/licenseholders/'
        },
        recommendations: {
            isMarked: true,
            url: '/recommendations/'
        }
    },
    catalog: {
        perfumes: {
            url: '/perfumes/',
            showCounter: true
        },
        brands: {
            url: '/brands/',
            showCounter: true
        },
        noses: {
            url: '/noses/'
        },
        notes: {
            url: '/notes/'
        },
        families: {
            url: '/families/'
        },
        segments: {
            url: '/segments/'
        },
        concentrations: {
            url: '/concentrations/'
        },
        producers: {
            url: '/producers/'
        },
        glossary: {
            url: '/glossary/'
        },
        shops: {
            url: '/shops/'
        },
        sets: {
            url: '/sets/'
        },
        perfumes_by_year: {
            icon: 'nav-catalog-years',
            url: '/years/'
        },
        brands_by_country: {
            icon: 'nav-catalog-countries',
            url: '/regions/'
        },
        licensees: {
            icon: 'nav-catalog-licenseholders',
            url: '/licenseholders/'
        },
    },
    footer: {
        perfumes: {
            url: '/perfumes/'
        },
        brands: {
            url: '/brands/'
        },
        shops: {
            url: '/shops/'
        },
        notes: {
            url: '/notes/'
        },
        noses: {
            url: '/noses/'
        },
        families: {
            icon: 'nav-catalog-groups',
            url: '/families/'
        },
        segments: {
            url: '/segments/'
        },
        concentrations: {
            url: '/concentrations/'
        },
        producers: {
            url: '/producers/'
        },
        perfumes_by_year: {
            icon: 'nav-catalog-noses',
            url: '/years/'
        },
        brands_by_country: {
            icon: 'nav-catalog-shops',
            url: '/regions/'
        },
        licensees: {
            url: '/licenseholders/'
        },
        glossary: {
            icon: 'nav-catalog-noses',
            url: '/glossary/'
        },
        recommendations: {
            isMarked: true,
            url: '/recommendations/'
        }
    },
    sections: {
        feed: {
            url: '/feed/'
        },
        news: {
            url: '/news/'
        },
        library: {
            url: '/articles/'
        },
        recommendations: {
            isMarked: true,
            url: '/recommendations/'
        }
        // video: {
        // url: '/video/'
        // }
    },
    pages: {
        about: {
            url: '/about/'
        },
        community: {
            url: '/community/'
        },
        help: {
            url: '/help/'
        },
        cooperation: {
            url: '/cooperation/'
        },
        contacts: {
            url: '/contacts/'
        }
    }
})
