import { Model } from 'vue-api-query';
import Vue from 'vue';
import get from 'lodash/get';
import url from '@/filters/url';


export default function(context, inject) {
    const { app, $axios, redirect } = context;

    if (process.server) {
        const { req } = context;
        app.reqHeaders = req.headers;
        app.reqSocket = req.socket;
    }

    const api = $axios.create({
        https: true,
        debug: false,
        retry: false,
        responseType: 'json'
    });

    /**
     * Проверяем ответы api на евент системы мониторинга
     * @param  {Object} data
     * @return
     */
    const monitoringHandler = async data => {

        const responseData = get(data, 'data', {});

        if (responseData.event === 'monitoring') {
            if (!app.store.state.monitoringResponse) {
                responseData.status = data.status;

                app.store.dispatch('rest/headers/setStatusCode', responseData.status);
                
                if (process.server) {
                    await app.store.dispatch('monitoringResponse', responseData);
                } else {
                    Vue.prototype.$bus.$emit('show-monitoring-response', responseData);
                }
            }

            return true;
        }
    };

    api.setBaseURL(process.env.apiUrl);

    api.interceptors.response.use(x => {
        if (x.headers['last-modified']) {
            app.store.dispatch('rest/headers/add', {
                'url': x.config.url,
                'last-modified': x.headers['last-modified'],
            });
        }

        monitoringHandler(x);

        return x;
    }, async(error) => {

        const monitoringResult = await monitoringHandler(error.response);

        if(monitoringResult)
            return Promise.resolve({error, data: {}});

        return Promise.reject(error);
    })

    api.onError((error) => {

        switch (error && error.response && error.response.status) {
        case 500:
            app.store.dispatch('errors/report', {
                url: context.route.path,
                error: {
                    type: 'fetch/xhr',
                    data: error.response
                }
            });
            break;
        }

        switch (error && error.response && error.response.status) {
        case 401:
            if (error.response.data.code === 'authorization-failed') {
                Vue.prototype.$bus.$emit('show-signin-popup')
            }
            break;
        case 404:
            if (process.server) {
                app.store.dispatch('404/checkUrl', context.route.path)
                    .then(() => {
                        const result = app.store.state['404'].result[context.route.path];
                        if (result) {
                            if (result.code === 'need-redirect') {
                                redirect(result.status, url.modify(result.location, context.route.query));
                            } else{
                                throw new Error('Not found');
                            }
                        }
                    })
            }
            break;
        case 500:
            if (process.browser) {
                Vue.prototype.$alert.error(app.i18n.t('validation.server-error'));
            }
            break;
        };
    });

    api.onRequest((config) => {

        config.headers.Accept = 'application/scentbook-v1.0.0+json';

        if (app.reqHeaders) {
            config.headers['User-Agent'] = app.reqHeaders['user-agent'] || null;
            config.headers['Accept-Language'] = app.reqHeaders['accept-language'] || null;
            config.headers['Accept-Encoding'] = app.reqHeaders['accept-encoding'] || null;
            config.headers.Referer = app.reqHeaders.referer || null;
        }
        
        const token = app.$auth.strategy.token.get() 
            || app.$cookies.get('auth._token.local');

        if (app.$auth.loggedIn || token) {
            config.headers.Authorization = token;
        }

        config.url = encodeURI(config.url);

        if (['post','put','patch','delete'].includes(config.method)) {
            config.headers['Content-Type'] = 'application/x-www-form-urlencoded';

            if (process.server) {
                // eslint-disable-next-line
                const { URLSearchParams } = require('url');
                global.URLSearchParams = URLSearchParams;
            }

            // client side
            if (typeof config.data === 'object') {
                if (typeof FormData === 'undefined' || !(config.data instanceof FormData) ) {
                    const params = new URLSearchParams();
                    for (const key in config.data) {
                        params.append(key, config.data[key]);
                    }
                    config.data = params;
                }
            }
        }
    });

    Model.$http = api;

    // Inject to context as $api
    inject('api', api);
}