/* eslint-disable no-extra-boolean-cast */

export const state = () => ({
    result: [],
    loading: false
})

export const mutations = {
    result(state, value) {
        state.result[value.url] = value;
    },
    loading(state, value) {
        state.loading = value;
    }
}

export const actions = {
    async checkUrl({commit, state, redirect, error: nuxtError}, url) {

        if (!state.loading) {
            commit('loading', true);

            await this.$api.get('services/check-url', {
                params: {
                    url: encodeURIComponent(url)
                },
                progress: false
            })
                .then((response) => {
                    commit('result', {
                        url,
                        code: response.data.code,
                        status: response.data.status,
                        location: response.data.location
                    });
                    commit('loading', false);
                })
                .catch(() => {
                    commit('result', {
                        url,
                        status: 422
                    });
                    commit('loading', false);
                });
        }
    }
}