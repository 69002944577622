/* eslint-disable no-useless-constructor */
import Model from '@/models/Model'

export default class FollowRequest extends Model {
    resource() {
        return 'follow-requests'
    }

    primaryKey() {
        return 'id'
    }
}
