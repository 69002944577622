/* eslint-disable object-shorthand */
/* eslint-disable no-console */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import Vue from 'vue'
import merge from 'lodash/merge'

function Alert(params) {

    /**
     * html-namespace
     * @type {String}
     */
    this.name = 'alert';

    /**
     * Параметры
     * type: info|default, success, error
     * duration: integer, milliseconds, default: 10000
     * text: string,
     * @type {Object}
     */
    this.params = {
        type: 'info',
        duration: 3000,
        text: null,
        showClose: true
    };


    /**
     * Инициализация экземпляра
     * @return {undefined}
     */
    const __init = () => {

        _mixParams();
    };

    /**
     * Показать сообщение
     * @return
     */
    this.show = () => {

        if (!this.params.text) {
            return console.warn('Alert: не задан текст сообщения');
        };

        _create(() => {setTimeout(_show, 10);});
        this.closeTimeout = setTimeout(this.close, this.params.duration);
        return this;
    };

    /**
     * Убрать сообщение
     * @return
     */
    this.close = (e) => {

        !e || e.stopPropagation();

        !this.closeTimeout || clearTimeout(this.closeTimeout);
        _hide(() => {setTimeout(_destroy, 500)});
    };

    /**
     * Подтянуть параметры
     * @return
     */
    const _mixParams = () => {

        if (params && typeof params === 'object') {
            merge(this.params, params);
        };
    };

    /**
     * Создание html-экземпляра сообщения
     * @param  {Function} callback
     * @return
     */
    const _create = (callback) => {

        this.$el = document.body.appendChild(_makeHTML.bind(this)());
        _initListeners.bind(this)();

        typeof callback !== 'function' || callback();

        /**
         * Генерирует html
         * @return html object
         */
        function _makeHTML() {

            const $el = document.createElement('div');
            $el.classList.add(this.name);
            $el.setAttribute('data-type', this.params.type);

            const $inner = document.createElement('span');
            $inner.classList.add(this.name + '__text');
            $inner.innerHTML = this.params.text;
            $el.appendChild($inner);

            if (this.params.showClose) {
                const $close = document.createElement('span');
                $close.classList.add(this.name + '__close');
                $el.appendChild($close);
            }

            return $el;
        };

        /**
         * Устанавливает event listener на кнопку закрыть
         * @return
         */
        function _initListeners() {

            if (this.params.showClose) {
                this.$el.querySelector('.' + this.name + '__close')
                    .addEventListener('click', this.close);
            }
        };
    };

    /**
     * Показать скрытое сообщение
     * @return
     */
    const _show = () => {

        this.$el.setAttribute('data-visible', true);
    };

    /**
     * Скрыть сообщение
     * @return
     */
    const _hide = (callback) => {

        !this.$el || this.$el.setAttribute('data-visible', false);
        typeof callback !== 'function' || callback();
    };

    /**
     * Удаление html-экземпляра
     * @return
     */
    const _destroy = () => {

        const $close = this.$el.querySelector('.' + this.name + '__close');
        if ($close) {
            $close.removeEventListener('click', this.close);
        };

        !this.$el || this.$el.remove();
        this.$el = null;
    };

    __init();
    return this;
};

export default (context, inject) => {
    inject('alert', (() => {

        const show = (options) => {
            return (new Alert(options)).show();
        };

        return {
            show: show,
            success(text, options) {
                return show(merge({
                    text: text,
                    type: 'success'
                }, options));
            },
            info(text, options) {
                return show(merge({
                    text: text,
                    type: 'info'
                }, options));
            },
            error(text, options) {
                return show(merge({
                    text: text,
                    type: 'error'
                }, options));
            }
        };
    })());
}
