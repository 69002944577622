const authOptions = require('@/modules/auth');

export default function({ $auth, $cookies, $api }){

    /**
     * Работа с токеном пользователя
     * 
     * @return {Object}
     */
    const token = (() => {

        /**
         * Внутренние настройки
         * 
         * @type {Object}
         */
        const settings = {
            /**
             * Префикс для кук
             * 
             * @type {String}
             */
            prefix: 'atoken_',
            /**
             * За сколько до устаревания продлевать токен, сек
             * 
             * @type {Number}
             */
            lag: 24 * 3600,
            /**
             * Время жизни кук, которые сохраняем, дн.
             * 
             * @type {Number}
             */
            lifedays: 150,
            /**
             * Ключи хранимым данных
             * 
             * @type {Array}
             */
            keys: ['expiresIn', 'refreshToken']
        };

        /**
         * Обновляет токен
         * 
         * @return {[type]}
         */
        const refresh = () => {
            
            $api.post(authOptions.strategies.local.endpoints.refresh.url, {
                refresh_token: get('refreshToken')
            }).then((response) => {
                $auth.setUserToken(response.data.accessToken, response.data.refreshToken);
                token.store(response.data);
            }).catch(() => {
                token.clear();
            });
        };

        /**
         * Проверяет, заканчивается ли токен
         * 
         * @return {Boolean}
         */
        const expiring = () => {
            
            const expiresIn = get('expiresIn');

            if (expiresIn && get('refreshToken')) {
                return (new Date(expiresIn*1000) - new Date) < settings.lag * 1000
            }

            return false;
        };

        /**
         * Сохраняет данные авторизации (токен и lifetime) для последующих проверок
         * 
         * @param  {Object} data
         */
        const store = (data) => {

            const date = new Date;
            // Refresh-token выдаётся на 150 дней
            const expires = (date.getTime() + settings.lifedays*24*3600*1000);

            date.setTime(expires);

            for (const key in settings.keys) {
                if (data[settings.keys[key]]) {
                    $cookies.set(
                        settings.prefix + settings.keys[key], 
                        data[settings.keys[key]], 
                        {
                            expires: date,
                            path: '/'
                        }
                    );
                }
            }
        };

        /**
         * Удаляет данные авторизации из плагина
         * 
         */
        const clear = () => {
            for (const key in settings.keys) {
                $cookies.remove(settings.prefix + settings.keys[key]);
            }
        }

        /**
         * Возвращает значение из плагина
         * 
         * @param  {String} name
         * @return mixed
         */
        const get = (name) => {

            return $cookies.get(settings.prefix + name);  
        };

        return {
            refresh,
            expiring,
            store,
            clear
        }
    })();

    /**
     * Публичный метод для обновления токена в случае необходимости (проверка встроена)
     */
    $auth.refreshTokenIfNeeded = () => {

        if (token.expiring()) {
            token.refresh();
        }
    };

    /**
     * Публичный метод для сохранения токенов в плагин
     * 
     * @param  {Object} data
     */
    $auth.storeTokens = (data) => {
        token.store(data);
    };

    /**
     * Удаление авторизации пользователя
     */
    $auth.logoutExt = () => {
        token.clear();
        return $auth.logout();
    };
}
