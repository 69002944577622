const getMatchedComponents = function(route, matches = false, prop = 'components') {
    return Array.prototype.concat.apply([], route.matched.map((m, index) => {
        return Object.keys(m[prop]).map((key) => {
            matches && matches.push(index)
            return m[prop][key]
        })
    }))
}

const setScrollRestoration = function(newVal){
    try {
        window.history.scrollRestoration = newVal;
    } catch(e) {}
}

if (process.client) {
    if ('scrollRestoration' in window.history) {
        setScrollRestoration('manual')

        // reset scrollRestoration to auto when leaving page, allowing page reload
        // and back-navigation from other pages to use the browser to restore the
        // scrolling position.
        window.addEventListener('beforeunload', () => {
            setScrollRestoration('auto')
        })

        // Setting scrollRestoration to manual again when returning to this page.
        window.addEventListener('load', () => {
            setScrollRestoration('manual')
        })
    }
}

function shouldScrollToTop(route) {
    const Pages = getMatchedComponents(route)
    if (Pages.length === 1) {
        const { options = {} } = Pages[0]
        return options.scrollToTop !== false
    }
    return Pages.some(({ options }) => options && options.scrollToTop)
}

export default function(to, from, savedPosition){
    // If the returned position is falsy or an empty object, will retain current scroll position
    let position = false
    const isRouteChanged = to !== from

    const nuxt = window.$nuxt

    // savedPosition is only available for popstate navigations (back button)
    const metaScrollPos = nuxt.context.route.meta[0]?.scrollPos

    if (metaScrollPos || savedPosition) {
        position = metaScrollPos || savedPosition;
    } else if (isRouteChanged && shouldScrollToTop(to)) {
        position = { x: 0, y: 0 }
    }

    if (
        // Initial load (vuejs/vue-router#3199)
        !isRouteChanged ||
        // Route hash changes
        (to.path === from.path && to.hash !== from.hash)
    ) {
        nuxt.$nextTick(() => nuxt.$emit('triggerScroll'))
    }

    return new Promise((resolve) => {
        nuxt.$once('triggerScroll', () => {
            resolve(position)
        });
    })
}