/* eslint-disable no-useless-constructor */
import Model from '@/models/Model'
import Like from '@/models/services/like'
import Comment from '@/models/users/Comment'
import CommentRating from '@/models/users/CommentRating'
import CommentPicture from '@/models/users/CommentPicture'
import Perfumer from '@/models/catalog/Perfumer'
import Segment from '@/models/catalog/Segment'
import User from '@/models/users/User';
import Account from '@/models/users/accounts';
import Offer from '@/models/catalog/perfume/Offer'
import Article from '@/models/catalog/Article';
import News from '@/models/catalog/News';
import Page from '@/models/catalog/Page';
import Relationship from '@/models/catalog/perfume/Relationship';
import Property from '@/models/catalog/perfume/Property';
import Rating from '@/models/catalog/Rating';
import Price from '@/models/catalog/Price';

export default class Perfume extends Model {
    resource() {
        return 'perfumes'
    }

    primaryKey() {
        return 'code'
    }

    userLikes() {
        return this.hasMany(Like)
    }

    relatedSegments() {
        return this.hasMany(Segment)
    }

    commentsList() {
        return this.hasMany(Comment)
    }

    /* todo: должно быть perfumers */
    relatedPerfumers() {
        return this.hasMany(Perfumer)
    }

    prices() {
        return this.hasMany(Price)
    }

    shelves() {
        return this.hasMany(Account, 'shelves')
    }

    offers() {
        return this.hasMany(Offer)
    }

    wearers() {
        return this.hasMany(User, 'who-wears')
    }

    userRatings() {
        return this.hasMany(Rating)
    }

    commentsPictures() {
        return this.hasMany(CommentPicture)
    }

    relatedNews() {
        return this.hasMany(News, 'related-content')
    }

    relatedArticles() {
        return this.hasMany(Article, 'related-articles')
    }

    relatedPages() {
        return this.hasMany(Page, 'related-pages')
    }

    relationships() {
        return this.hasMany(Relationship);
    }

    commentsRatings() {
        return this.hasMany(CommentRating)
    }

    properties() {
        return this.hasMany(Property);
    }

    setPerfumesSort() {
        return this.request({
            method: 'POST',
            url: this.endpoint() + '/sorted',
            data: this.getFormData(this),
        }).then(response => {
            if(response) {
                return response.data;
            }
        });
    }

    delete(data) {
        return this.request({
            method: 'DELETE',
            url: this.endpoint(),
            data
        }).then(response => {
            return response.data;
        });
    }
}
