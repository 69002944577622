import get from 'lodash/get'

export default function({ $auth }){

    $auth.userLoggedIn = () => {
        const token = get($auth, 'strategy.token', null)

        return token.status ? token.status().valid() : false;
    }
}
