/* eslint-disable no-undef */
import { Model as BaseModel } from 'vue-api-query'

export default class Model extends BaseModel {

    // define a base url for a REST API
    baseURL() {
        return ''
    }

    constructor(...args) {
        args.length ? super(...args) : super();
    }

    static config(config) {
        const self = this.instance()
        self.config(config)

        return self
    }

    toJSON() {
        const keys = Object.getOwnPropertyNames(this);
        const data = {};

        for (let i = keys.length - 1; i >= 0; i--) {
            data[keys[i]] = this[keys[i]] || null;
        }
        return JSON.stringify(data);
    }

    config(config) {
        this.config = config
        return config
    }

    // implement a default request method
    request(config) {
        Object.assign(config, this.config);
        return this.$http.request(config)
    }

    getFormData(data) {
        const formData = new FormData()
        Object.keys(data).forEach(key => formData.append(key, data[key]))
        return formData
    }

    _create() {
        return this.request({
            method: 'POST',
            url: this.endpoint(),
            data: this.getFormData(this),
        }).then(response => {
            const self = Object.assign(this, response.data)
            return self
        })
    }

    hasMany(model, resource) {
        // eslint-disable-next-line
        const instance = new model;

        const primaryKey = this.getPrimaryKey();
        const primaryStr = primaryKey ? primaryKey + '/' : ''

        const url = `${this.baseURL()}/${this.resource()}/${primaryStr}${(resource || instance.resource())}`;

        instance._from(url);
        return instance;
    }

    parameterNames() {
        return {
            include: 'include',
            filter: 'filters',
            sort: 'sort',
            fields: 'fields',
            append: 'append',
            page: 'page',
            limit: 'limit'
        }
    }

    delete(data) {
        return this.request({
            method: 'DELETE',
            url: this.endpoint(),
            data
        }).then(response => {
            return Object.assign(this, data);
        });
    }

    update(data, up = true) {
        return this.request({
            method: 'PATCH',
            url: this.endpoint(),
            data,
        }).then(response => {
            if (up) {
                return Object.assign(this, data, response.data);
            }
        });
    }

    get() {
        let base = this._fromResource || `${this.baseURL()}/${this.resource()}`;
        base = this._customResource ? `${this.baseURL()}/${this._customResource}` : base;

        const query = this.normalizeQueryFilters(this._builder.query());
        const url = `${base}${query}`;

        return this.request({
            url,
            method: 'GET'
        }).then(response => {
            let collection = response.data.data || response.data;
            collection = Array.isArray(collection) ? collection : [collection];

            collection = collection.map(c => {
                const item = new this.constructor(c);
                Object.defineProperty(item, '_fromResource', { get: () => this._fromResource });

                return item;
            });

            if (response.data.data !== undefined) {
                response.data.data = collection;
            } else {
                response.data = collection;
            }

            return response.data;
        });
    }

    head() {
        let base = this._fromResource || `${this.baseURL()}/${this.resource()}`;
        base = this._customResource ? `${this.baseURL()}/${this._customResource}` : base;

        const query = this.normalizeQueryFilters(this._builder.query());
        const url = `${base}${query}`;

        return this.request({
            url,
            method: 'HEAD'
        });
    }

    normalizeQueryFilters(query) {

        for (const param in this._builder.filters) {
            query = query.replace(this.parameterNames().filter+'['+param+']', param);
        }

        return query;
    }

    params(payload) {
        if (payload === undefined || payload.constructor !== Object) {
            throw new Error('You must pass a payload/object as param.')
        }

        this._builder.payload = payload;

        return this
    }

    for(...args) {
        if (args.length === 0) {
            throw new Error('The for() method takes a minimum of one argument.')
        }

        let url = `${this.baseURL()}`;

        args.forEach(object => {
            const primaryKey = object.getPrimaryKey()

            if (!(object instanceof Model)) {
                throw new TypeError('The object referenced on for() method is not a valid Model.')
            }

            url += `/${object.resource()}`
            if (primaryKey) url += `/${primaryKey}`
        })

        url += `/${this.resource()}`

        this._from(url)

        return this
    }
}
