import Vue from 'vue';
import { ValidationProvider, ValidationObserver, extend, configure } from 'vee-validate';
import { required, email, min, max, confirmed, integer, regex } from 'vee-validate/dist/rules';

// https://logaretm.github.io/vee-validate/guide/rules.html#importing-the-rules
extend('email', email);
extend('required', required);
extend('min', min);
extend('max', max);
extend('confirmed', confirmed);
extend('integer', integer);
extend('regex', regex);
extend('url', {
    validate(value){
        return (/^((http|https):\/\/)?[a-zа-я0-9]+([-.]{1}[a-zа-я0-9]+)*\.[a-zа-я]{2,5}(:[0-9]{1,5})?(\/.*)?$/i.test(value));
    }
});

ValidationProvider.options.props.tag.default = 'div';
Vue.component('FormField', ValidationProvider);

ValidationObserver.options.props.tag.default = 'div';
Vue.component('AppForm', ValidationObserver);

export default ({ app }) => {
    configure({
        defaultMessage: (_, values) => {
            return app.i18n.t(`validation.${values._rule_}`, values);
        },
        mode: "eager"
    });
    // extend('required', {
    //     ...required,
    //     messages: {
    //         email: app.i18n.t('validation.required_email')
    //     }
    // });
}
