import get from 'lodash/get'
import Vue from 'vue'

const requireComponent = require.context(
    '@/components',
    true,
    /Base[A-Z]\w+\.vue$/
)

// For each matching file name...
requireComponent.keys().forEach(fileName => {
    // Get the component config
    const componentConfig = requireComponent(fileName)

    const defaultName = fileName
        .replace(/.*(?=\/)/, '')
    // Remove the "/" from the beginning
        .replace(/^\//, "")
    // Remove the file extension from the end
        .replace(/\.\w+$/, "");

    // Если у компонента указан параметр name, то берём его
    const componentName = get(componentConfig, 'default.name', defaultName)

    // Globally register the component
    Vue.component(componentName, componentConfig.default || componentConfig);
});
