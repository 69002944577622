export default {
    inheritAttrs: false,
    model: {
        prop: 'checked',
    },
    props: {
        name: {
            type: String,
            default: ''
        },
        value: {
            type: [String, Number],
            default: ''
        },
        type: {
            type: String,
            default: 'checkbox'
        },
        required: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        noCheck: {
            type: Boolean,
            default: false
        },
        checked: {
            type: [Boolean, Array, String],
            default: false
        },
    },
    computed: {
        isRadio() {
            return this.type === 'radio'
        },
        isPseudoRadio() {
            return this.type === 'pseudoradio'
        },
        inputType() {
            return this.isRadio ? 'radio' : 'checkbox'
        },
        inputValue: {
            get() {
                return this.checked
            },
            set(value) {
                if (
                    this.isPseudoRadio &&
                    Array.isArray(value) &&
                    value.length > 1
                ) {
                    this.$emit('input', [this.value])
                } else {
                    this.$emit('input', value)
                }
            }
        },
    }
}
