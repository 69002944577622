/* eslint-disable no-console */
import get from 'lodash/get'

export default {
    methods: {
        getRefSize(refName) {
            const el = get(this.$refs, `${refName}`);

            if (!el) return

            // Если компонент, то берем его $el
            // Если нет, то сам элемент
            const $el = get(el, '$el', el)

            const styles = getComputedStyle($el)

            let height, width
            const offsetTop = parseInt($el.offsetTop)

            if (styles.height === 'auto') {
                height = 0
            } else {
                height = parseFloat(styles.height);
            }

            if (styles.width === 'auto') {
                width = 0
            } else {
                width = parseFloat(styles.width);
            }

            const outerHeight = height +
                parseFloat(styles.marginTop) +
                parseFloat(styles.marginBottom)

            const outerWidth = height +
                parseFloat(styles.marginLeft) +
                parseFloat(styles.marginRight)

            return {
                height,
                offsetTop,
                width,
                outerHeight,
                outerWidth
            }
        }
    },
}
