import get from 'lodash/get'
import escape from 'lodash/escape'
import map from 'lodash/map'

export default {
    /**
     * Возвращает длину строки без учета переносов
     * 
     * @return {Number}
     */
    replacePhotoGallery(content = '') {

        const patterns = {
            gallery: /<div.*class\s*=\s*["'].*photo-gallery.*["']\s*>([\s\S]*?)<\/div>/g,
            image: /<img [^>]*src="[^"]*"[^>]*>/gm,
            title: /.*title="([^"]*)".*/,
            src: /.*src="([^"]*)".*/
        };

        return content.replace(patterns.gallery, g => {
            const rawImages = g.match(patterns.image)

            if (rawImages && rawImages.length) {
                const images = map(rawImages, x => {
                    const src = get(x.match(patterns.src), '[1]');
                    const title = escape(get(x.match(patterns.title), '[1]'));
                    const image = {};

                    if (src) {
                        image.src = src;
                        image.url = src;
                    }

                    if (title) {
                        image.title = title;
                    }

                    if (image.src) {
                        return image;
                    }
                });

                return images && images.length ? `<PhotoGallery :images='${JSON.stringify(images)}'/>` : g;
            }
        });
    },

    /**
     * Определяет видимость элемента DOM на странице
     * 
     * @param  {Object} $el
     * @return {Boolean}
     */
    visible($el) {
        return !!($el.offsetWidth || $el.offsetHeight || $el.getClientRects().length)
            && !(getComputedStyle($el).visibility === 'hidden');
    },

    /**
     * Определяет видимость элемента DOM на экране
     * 
     * @param  {Object} $el
     * @return {Boolean}
     */
    inViewport($el) {
        const rect = $el.getBoundingClientRect();

        return (
            rect.top >= 0 &&
            rect.left >= 0 &&
            rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) && /* or $(window).height() */
            rect.right <= (window.innerWidth || document.documentElement.clientWidth) /* or $(window).width() */
        );
    }
};
