<template>
    <button class="button-base"
        :disabled="disabled"
        :class="classes">
        <svg-icon class="button-base__icon"
            v-if="icon"
            :name="icon"/>
        <span
            :class="contentClasses"
            class="button-base__text">
            <slot></slot>
        </span>
    </button>
</template>

<script>
import includes from 'lodash/includes'

export default {
    name: 'ButtonBase',
    props: {
        size: {
            type: String,
            default: 'regular',
            validator(value) {
                return includes(['small', 'regular'], value)
            }
        },
        icon: {
            type: String,
            default: ''
        },
        disabled: {
            type: Boolean,
            default: false
        },
        inverted: {
            type: Boolean,
            default: false
        },
        gray: {
            type: Boolean,
            default: false
        },
        grayBorder: {
            type: Boolean,
            default: false
        },
        showAsLink: {
            type: Boolean,
            default: false
        },
        pending: {
            type: Boolean,
            default: false
        },
        contentLeft: {
            type: Boolean,
            default: false
        },
        shadowed: {
            type: Boolean,
            default: false
        },
        dotsMode: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        classes() {
            return {
                'button-base_small': this.size === 'small',
                'button-base_inverted': this.inverted,
                'button-base_gray': this.gray,
                'button-base_gray-border': this.grayBorder,
                'button-base_shadowed': this.shadowed,
                'button_clear': this.showAsLink,
                'button_dots': this.dotsMode
            }
        },
        contentClasses() {
            return {
                'button-base__text_left': this.contentLeft,
                'button-base__text_pending': this.pending,
            }
        }
    }
}
</script>

<style lang="less">
.button-base {
    display: inline-flex;
    align-items: center;
    justify-content: center;

    background-color: @color-violet;
    border: rem(1) solid transparent;
    border-radius: rem(3);
    color: @color-white;
    font-size: rem(15);
    font-weight: 500;
    outline: none;
    text-align: center;

    padding: rem(9) rem(16);

    transition: opacity .15s ease;

    user-select: none;

    .hover({
        opacity: .8;
    });

    &:active {
        color: @color-white;
    }

    &_shadowed {
        box-shadow: @menu-desktop-box-shadow;

        .hover({
            box-shadow: none
        });
    }

    &__icon {
        --size: rem(18);
        margin-right: rem(8);

        height: var(--size);
        width: var(--size);
    }

    &__text {
        display: block;

        text-align: center;

        padding-top: rem(2);

        width: 100%;

        &_left {
            text-align: left;
        }

        span {
            position: relative;

            &:before {
                content: '';
                display: block;

                background: url('~static/img/loading-sm.gif') no-repeat 50%;

                height: rem(23);
                width: rem(23);

                position: absolute;
                top: rem(-5);
                left: rem(-28);

                opacity: 0;

                transition: opacity .2s ease;
            }
        }

        &_pending span:before {
            opacity: 1;
        }
    }

    &_inverted {
        background-color: @color-white;
        border-color: @color-primary;
        color: @color-primary;

        &:active {
            color: @color-primary;
        }
    }

    &_gray {
        background: @color-gray-light-2;
        color: @color-gray;

        &:active {
            color: @color-gray;
        }
    }

    &_gray-border {
        background: @color-bg-gray;
        border-color: @color-gray-white;
        color: @color-gray-light;

        &:active {
            color: @color-gray-light;
        }
    }

    &_small {
        font-size: rem(13);

        padding: rem(7) rem(16);
    }

    &[disabled],
    &_like-disabled{
        background-color: @color-bg-gray-minor;
        border-color: @color-gray-white;
        color: @color-gray-light;

        .hover({
            opacity: 1;
        });
    }

    &[data-loading="true"]{
        position: relative;
        color: rgba(0,0,0,0);
        pointer-events: none;

        &:after{
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;;
            background: url('~static/img/loading-sm.gif') no-repeat 50% 50%;
        }
    }
}

.button {
    &_clear {
        vertical-align: middle;
        flex-direction: row-reverse;

        background: none;
        color: @color-violet;
        font-weight: normal;

        padding: 0;

        span {
            padding: rem(2) rem(8) 0;
        }

        .icon {
            margin-right: 0;
            padding-bottom: rem(2);

            width: rem(16);
        }

        .hover({
            text-decoration: underline;
        });

        &:active {
            color: @color-violet;
        }
    }

    &_dots {
        position: relative;

        .icon {
            height: rem(20);
            width: rem(44);

            opacity: 0.5;
        }
    }
}
</style>
