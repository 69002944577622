export default function({ $auth, redirect, params, route, app }) {
    if (!$auth.loggedIn || !$auth.strategy.token.status().valid()) {
        if ($auth.loggedIn) {
            $auth.logoutExt();
        }

        const profileUrl = app.router.resolve({
            name: 'users.detail'
        }).href;

        redirect(profileUrl);
    }
}
