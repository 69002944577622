<i18n>
{
    "ru": {
        "min": "мин."
    }
}
</i18n>
<template>
    <label
        class="textarea-validation"
        :class="rootClasses">

        <span
            v-if="label"
            class="textarea-validation__label"
            :class="labelClasses">
            {{ label }}
        </span>

        <HelpButton
            class="textarea-validation__help"
            v-if="tooltip"
            :placement="'right'"
            :content="tooltip" />

        <form-field
            :mode="mode"
            class="form__field"
            :rules="rules"
            v-slot="{ errors, failed }"
            ref="field">

            <div
                class="textarea-validation__wrapper"
                :class="wrapperClasses({ failed, withBg })">

                <textarea
                    ref="textarea"
                    :autofocus="isFocused"
                    class="textarea-validation__area"
                    :placeholder="placeholder"
                    :rows="rows"
                    :style="styles"
                    v-model="val"
                    @blur="onBlur"
                    @input="onInput"
                    @focus="resize"
                    :maxlength="maxlength"></textarea>

                <div
                    class="textarea-validation__counter"
                    :class="counterClasses({ failed, withBg })"
                    v-if="isCounter && countsShow">

                    <span v-if="minlength">{{ $t('min') }} {{ minlength }}</span><span v-if="minlength">/</span><span>{{ textlength }}</span><span v-if="maxlength && !minlength">/</span><span v-if="maxlength && !minlength">{{ maxlength }}</span>

                </div>
            </div>

            <span v-if="failed"
                :data-visible="failed || isValidate"
                class="form__error">
                {{ head(errors) }}
            </span>
        </form-field>
    </label>
</template>

<script>
import get from 'lodash/get'
import head from 'lodash/head'
import includes from 'lodash/includes'

import HelpButton from '@/components/ui/HelpButton';

export default {
    name: 'BaseTextareaValidation',
    components: {
        HelpButton
    },
    props: {
        value: {
            type: [String, Number],
            default: ''
        },
        autosize: {
            type: Boolean,
            default: true
        },
        label: {
            type: [String],
            default: null
        },
        placeholder: {
            type: [String],
            default: null
        },
        minlength: {
            type: [Number, String],
            default: null
        },
        maxlength: {
            type: [Number, String, Boolean],
            default: false
        },
        rows: {
            type: [Number, String],
            default: null
        },
        minHeight: {
            type: [Number],
            default: null
        },
        maxHeight: {
            type: [Number],
            default: 162
        },
        important: {
            type: [Boolean, Array],
            default: false
        },
        countsShow: {
            type: Boolean,
            default: true
        },
        withBg: {
            type: Boolean,
            default: false
        },
        isFocused: {
            type: Boolean,
            default: true
        },
        rules: {
            type: String,
            default: ''
        },
        isValidate: {
            type: Boolean,
            default: false
        },
        replaceEmoji: {
            type: Boolean,
            default: false
        },
        mode: {
            type: String,
            default: 'eager'
        },
        tooltip: {
            type: String,
            default: null
        }
    },
    data() {
        return {
            val: null,
            maxHeightScroll: false,
            height: 'auto',
        }
    },
    computed: {
        required() {
            return includes(this.rules, 'required')
        },
        isCounter() {
            return this.minlength || this.maxlength
        },
        textlength() {
            return get(this.val, 'length', 0)
        },
        labelClasses() {
            return {
                'textarea-validation__label_required': this.required,
                'textarea-validation__label_bg': this.withBg
            }
        },
        rootClasses() {
            return {
                'textarea-validation_bg': this.withBg
            }
        },
        styles() {
            if (!this.autosize) return {}
            return {
                resize: !this.isResizeImportant ? 'none' : 'none !important',
                height: this.height,
                overflow: this.maxHeightScroll ? 'auto' : (!this.isOverflowImportant ? 'hidden' : 'hidden !important')
            }
        },
        isResizeImportant() {
            const imp = this.important
            return imp === true || (Array.isArray(imp) && imp.includes('resize'))
        },
        isOverflowImportant() {
            const imp = this.important
            return imp === true || (Array.isArray(imp) && imp.includes('overflow'))
        },
        isHeightImportant() {
            const imp = this.important
            return imp === true || (Array.isArray(imp) && imp.includes('height'))
        }
    },
    watch: {
        value(val) {
            this.val = val
        },
        val(val) {
            this.$nextTick(this.resize)
            this.$emit('input', val)
        },
        minHeight() {
            this.$nextTick(this.resize)
        },
        maxHeight() {
            this.$nextTick(this.resize)
        },
        autosize(val) {
            if (val) this.resize()
        }
    },
    created() {
        this.val = this.value
    },
    mounted() {
        if(this.isValidate)
            this.$refs.field.validate()

        this.resize()
    },
    methods: {
        head,
        areaClasses(params) {
            const { withBg, countsShow } = params

            return {
                'textarea-validation__area_bg': withBg,
                'textarea-validation__area_with-counter': countsShow,
            }
        },
        wrapperClasses(params) {
            const { failed, withBg, countsShow } = params

            return {
                'form__input_error': failed,
                'textarea-validation__wrapper_error': failed,
                'textarea-validation__wrapper_bg': withBg,
                'textarea-validation__wrapper_with-counter': countsShow,
            }
        },
        counterClasses(params) {
            const { failed, withBg } = params
            return {
                'textarea-validation__counter_error': failed,
                'textarea-validation__counter_bg': withBg,
            }
        },
        onBlur(e) {
            this.$emit('blur', e)
        },
        onInput($event) {
            if(this.replaceEmoji)
                this.val = this.$options.filters.textNoEmoji($event.target.value)

            // const event = document.createEvent('HTMLEvents');
            // event.initEvent('change', true, false);
            // $event.target.dispatchEvent(event);
        },
        resize() {
            this.$emit('focus');
            
            const important = this.isHeightImportant ? 'important' : ''
            this.height = `auto${important ? ' !important' : ''}`
            this.$nextTick(() => {
                let contentHeight = get(this.$refs, 'textarea.scrollHeight', 0) + 1

                if (this.minHeight) {
                    contentHeight = contentHeight < this.minHeight ? this.minHeight : contentHeight
                }

                if (this.maxHeight) {
                    if (contentHeight > this.maxHeight) {
                        contentHeight = this.maxHeight
                        this.maxHeightScroll = true
                    } else {
                        this.maxHeightScroll = false
                    }
                }

                const heightVal = (contentHeight / 15).toFixed(7) + 'rem'
                this.height = `${heightVal}${important ? ' !important' : ''}`
            })

            return this
        }
    }
}
</script>

<style lang="less">
.textarea-validation {
    --textarea-min-height: rem(72);
    width: 100%;

    position: relative;

    &__label {
        color: @color-gray;
        font-weight: 500;

        &_bg {
            display: block;

            margin-bottom: rem(6);
        }

        &_required {
            display: inline-block;

            padding-right: rem(8);

            position: relative;

            &:after {
                content: '*';

                color: @color-red;
                font-size: rem(14);

                position: absolute;
                top: rem(-2);
                right: 0;
            }
        }
    }

    &__wrapper {
        border-radius: rem(3);


        width: 100%;
        min-height: var(--textarea-min-height);

        position: relative;

        &_bg {
            background-color: @color-gray-light-2;

            padding: rem(12) rem(20);
        }

        &_with-counter {
            padding-bottom: rem(40);
        }

        &_error {
            position: relative;
        }
    }

    &__area {
        display: block;

        background: none;
        border: none;
        font-family: inherit;
        font-size: rem(15);
        outline: none;

        width: 100%;
        min-height: var(--textarea-min-height);

        overflow: auto;

        resize: none;
        padding-left: 0;
        padding-right: 0;

        &::placeholder{
            color: @color-gray-light;
        }

        @{desktop} & {
            // фикс overflow-x для safari
            padding-right: rem(2);
            .scrollbar();
        }
    }

    &__counter {
        color: @color-gray-light;
        font-size: rem(13);
        text-align: left;

        position: absolute;
        right: rem(36);
        bottom: rem(12);

        &_bg {
            right: rem(14);
        }

        @{desktop} & {
            font-size: rem(15);
        }
    }

    &__help{
        position: relative;
        top: rem(-1);
        margin-left: rem(2);
    }

    &_bg {
        margin-bottom: rem(20);
    }
}
</style>
