<template>
    <div
        class="base-video"
        :class="rootClasses">

        <iframe
            class="base-video__frame"
            @load="onLoad"
            v-if="url && staticIframe"
            v-bind="bindings"></iframe>

        <div v-if="url && !staticIframe"
            class="base-video__frame base-video__frame_light"
            v-observe-visibility="vsibilityObserverOptions">

            <div class="base-video__frame-cover"
                v-if="!dynamicIframeLoaded"
                :style="'background-image: url('+ cover +');'"
                @click="onCoverClick"></div>

            <iframe
                @load="onLoadDynamicIframe"
                v-if="dynamicIframeLoaded"
                v-bind="bindings"></iframe>
        </div>

        <button
            v-if="removable"
            @click.stop.prevent="remove"
            class="base-video__remove">

            <svg-icon name="icon-close-color"/>

        </button>
    </div>
</template>
<script>
import { ObserveVisibility } from 'vue-observe-visibility';

export default {
    name: 'BaseVideo',
    directives: {
        'observe-visibility': ObserveVisibility
    },
    props: {
        url: {
            type: String,
            default: null
        },
        removable: {
            type: Boolean,
            default: false
        },
        contentPadding: {
            type: Boolean,
            default: false
        },
        /**
         * Отображать сразу iframe
         * 
         * @type {Boolean}
         */
        showIframe: {
            type: [Boolean, String],
            default: true
        },
        showIframeOnVisible: {
            type: [Boolean, String],
            default: false
        }
    },
    data() {
        return {
            loaded: false,
            dynamicIframeLoaded: false,
            vsibilityObserverOptions: {
                callback: this.onVisible,
                throttle: 2200,
                once: true,
                throttleOptions: {
                    leading: 'visible',
                },
                intersection: {
                    threshold: 0,
                },
            }
        }
    },
    computed: {
        staticIframe(){
            return this.showIframe && this.showIframe !== 'false';
        },
        rootClasses() {
            return {
                'base-video_padding': this.contentPadding,
                'base-video_loaded': this.loaded
            }
        },
        videoCode() {
            return this.getYoutubeUrl(this.url)
        },
        videoSrc() {
            return `https://www.youtube.com/embed/${this.videoCode}`
        },
        cover() {
            return ['https://i.ytimg.com/vi', this.videoCode, "hqdefault.jpg"].join('/');
        },
        bindings() {
            return {
                src: this.videoSrc + '?version=3&enablejsapi=1',
                frameborder: 0,
                allowfullscreen: "true",
                allow: "accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture",
                controls: 1,
                rel: 0,
                showinfo: 0,
                fs: 1,
                start: 0
            }
        }
    },
    mounted() {
        if (!this.staticIframe) {
            this.loaded = true;
        }
    },
    methods: {
        onLoadDynamicIframe(event) {
            if (this.staticIframe && !this.showIframeOnVisible) {
                event.target.contentWindow
                    .postMessage('{"event":"command","func":"playVideo","args":""}', '*');
            }
        },
        onVisible(isVisible) {
            if (isVisible && !this.dynamicIframeLoaded) {
                this.dynamicIframeLoaded = true;
            }
        },
        onCoverClick(event) {
            event.stopPropagation();
            this.dynamicIframeLoaded = true;
        },
        onLoad() {
            this.loaded = true
        },
        remove() {
            this.$emit('remove')
        },
        getYoutubeUrl(url) {
            // eslint-disable-next-line
            const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
            const match = url.match(regExp);
            return (match && match[7].length === 11) ? match[7] : false;
        },
    }
}
</script>

<style lang="less">
.base-video {
    --frame-size: rem(189);

    position: relative;

    opacity: 0;

    transition: opacity .3s ease;

    @{desktop} & {
        --frame-size: rem(348);
    }

    &_loaded {
        opacity: 1;
    }

    &__frame {
        height: var(--frame-size);
        width: 100%;

        position: relative;
        z-index: 1;

        display: block;

        @{mobile} &,
        @{desktop} & {
            margin-bottom: 0
        }

        &-cover{
            cursor: pointer;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: 50%;
            height: 100%;

            &:before{
                content: '';
                display: block;
                position: absolute;
                width: rem(68);
                height: rem(48);
                background: url('~static/sprite/svg/youtube-play.svg') no-repeat 50%;
                background-size: contain;
                left: 50%;
                top: 50%;
                margin-left: rem(-68/2);
                margin-top: rem(-48/2);
                .animate(opacity);
            }

            &:after{
                content: '';
                display: block;
                position: absolute;
                width: rem(68);
                height: rem(48);
                background: url('~static/sprite/svg/youtube-play-hover.svg') no-repeat 50%;
                background-size: contain;
                left: 50%;
                top: 50%;
                margin-left: rem(-68/2);
                margin-top: rem(-48/2);
                opacity: 0;
                .animate(opacity);
            }

            &:hover{
                &:before{
                    opacity: 0;
                }
                &:after{
                    opacity: 1;
                }
            }
        }

        &_light{
            iframe{
                height: var(--frame-size);
                width: 100%;

                position: relative;
                z-index: 1;

                display: block;

                @{mobile} &,
                @{desktop} & {
                    margin-bottom: 0
                }
            }
        }
    }

    &_padding {
        padding-top: rem(5.5);
        padding-bottom: rem(12.5);
        .page-padding();
    }

    &__remove {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        background: @color-white;
        border: none;
        border-radius: 50%;
        box-shadow: 0 rem(1) rem(8) rgba(0, 0, 0, 0.1);
        color: @color-black;

        padding: 0;

        height: rem(24);
        width: rem(24);

        position: absolute;
        top: rem(-8);
        right: rem(-8);
        z-index: 2;

        @{desktop} & {
            height: rem(32);
            width: rem(32);

            top: rem(-15);
            right: rem(-16);
        }

        svg {
            color: inherit;

            height: rem(14);
            width: rem(14);

            @{desktop} & {
                height: rem(16);
                width: rem(16);
            }
        }

        .hover({
            color: @color-primary;
        });
    }
}
</style>
