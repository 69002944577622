/* eslint-disable no-useless-constructor */
import Model from '@/models/Model'
import Perfume from "@/models/catalog/perfume";

export default class Shelf extends Model {
    resource() {
        return 'perfume-shelves'
    }

    primaryKey() {
        return 'id'
    }

    perfumes() {
        return this.hasMany(Perfume)
    }

    patchShelf(params) {
        return this.request({
            method: 'PATCH',
            url: this.endpoint(),
            data: params
        }).then(response => {
            if(response) {
                return response.data;
            }
        });
    }

    setShelvesSort() {
        return this.request({
            method: 'POST',
            url: this.endpoint() + '/sorted',
            data: this.getFormData(this),
        }).then(response => {
            if(response) {
                return response.data;
            }
        });
    }
}
