<i18n>
    {
        "en": {},
        "ru": {
            "default_placeholder": "Поиск по энциклопедии...",
            "placeholder": "Поиск по энциклопедии из {total}",
            "perfumes": "Ароматы",
            "brands": "Бренды",
            "content": "В тексте",
            "no_results": "Ничего не найдено"
        }
    }
</i18n>
<template>
    <div class="search-wrapper">
        <transition v-if="isDeviceDesktop" name="overlay-fade">
            <div v-if="showOverlay" class="search__overlay"></div>
        </transition>
        <div v-click-outside="reset"
            class="search"
            :data-state="state">
            <app-form class="form search__form">
                <form @submit.prevent>
                    <form-field
                        rules="required|min:2"
                        class="form__field search__field">
                        <input type="text"
                            @keydown.esc="reset"
                            v-model="q"
                            @click="handleInputClick"
                            :data-state="state"
                            :placeholder="placeholder || $t('default_placeholder')"
                            class="form__input search__input"
                            :class="{'search__input_bnb': state && results}" />

                        <span class="search__reset" @click="reset">
                            <svg-icon name="icon-close-color"  class="search__reset-icon"/>
                        </span>
                    </form-field>
                </form>
            </app-form>

            <SearchResultsColumnWrapper
                v-if="isDeviceDesktop && state && results"
                :results="results"
                :sections="sections"
                @search:goto="reset"
                class="search__results search__results-desktop" />

            <div class="search__results" v-if="!isDeviceDesktop && state && results">
                <BaseTabs mode="fullwh" class="search__tabs">
                    <BaseTab v-for="(section, key) in sections"
                        :id="key"
                        :name="$t(key)"
                        :key="key"
                        :counter="results[key].total">
                        <PaginatedList v-if="results[key].total" :list="results[key]">
                            <template v-slot:item="item" >
                                <component :is="section.component"
                                    :item="item"
                                    :without-rating="true"
                                    @search:goto="reset" />
                            </template>
                        </PaginatedList>
                        <div v-else class="search__empty">
                            {{ $t('no_results') }}
                        </div>
                    </BaseTab>
                </BaseTabs>
            </div>
        </div>
    </div>
</template>

<script>
import trim from 'lodash/trim';
import { mapState, mapGetters } from 'vuex';
import ClickOutside from 'vue-click-outside';

export default {
    name: 'Search',
    components: {
        SearchResultsColumnWrapper:() => import('@/components/layout/search/SearchResultsColumnWrapper'),
        PageBlock:() => import('@/components/layout/AppPageBlock'),
        PaginatedList:() => import('@/components/ui/PaginatedList'),
        SearchPerfume:() => import('@/components/layout/search/SearchPerfume'),
        SearchBrand:() => import('@/components/layout/search/SearchBrand'),
        SearchContent:() => import('@/components/layout/search/SearchContent')
    },
    directives: {
        ClickOutside
    },
    props: {
        delay: {
            type: Number,
            default: 500
        },
    },
    data() {
        return {
            q: null,
            state: null,
            sections: {
                perfumes: {
                    component: 'SearchPerfume'
                },
                brands: {
                    component: 'SearchBrand'
                },
                content: {
                    component: 'SearchContent'
                }
            },
            results: null
        }
    },
    computed: {
        ...mapState('entities', ['counters']),
        ...mapGetters(['isDeviceDesktop']),
        showOverlay() {
            return this.state === 'results' || this.state === 'loading'
        },
        placeholder() {
            if (this.counters.perfumes) {
                return this.$t('placeholder', {
                    total: `${this.$options.filters.numberDeclension(this.counters.perfumes.total, this.$t('declensions.perfumes_search_field'))}`
                }) + '...';
            }

            return null;
        },
        keys() {
            return Object.keys(this.sections);
        }
    },
    watch: {
        q(value, old) {

            value = trim(value);
            !this.timeout || clearTimeout(this.timeout);

            if (!value || value.length < 2) {
                this.state = 'results';
                if(this.results) {
                    this.results = null
                }

                return;
            }

            this.state = 'loading';
            this.timeout = setTimeout(this.request, this.delay);
        }
    },
    methods: {
        handleInputClick() {
            if(this.isDeviceDesktop) {
                this.state = 'results'
            }
        },
        request() {
            const queries = this.keys.map((section) => {
                return this.search(section);
            });
            Promise.all(queries)
                .then((results) => {
                    if(this.q && this.q.length > 1) {
                        this.results = {};
                        results.map((result, i) => {
                            this.results[this.keys[i]] = result.data;
                        });
                    }
                }).finally(this.show);
        },
        search(where) {
            const params = {
                q: this.q,
                section: where
            };
            return this.$api.get('services/search', {params});
        },
        show() {
            this.state = 'results';

            // фикс для баннера в шапке
            if(!this.isDeviceDesktop){
                this.$scrollTo('html', 100)
                setTimeout(() => {
                    this.$body.cut();
                },150)
            }else{
                this.$body.cut();
            }
        },
        hide() {
            this.state = null;
            this.results = null;
            this.$body.uncut();
        },
        reset(e) {
            if (this.state) {
                this.q = null;
                this.results = null;


                this.$nextTick(() => {
                    this.hide();
                })
            }
        }
    },
}
</script>
<style lang="less">
.search {

    @{desktop} & {
        margin-bottom: rem(5);

        position: relative;
    }

    .overlay-fade {
        transition: all .25s;
    }

    &__overlay {
        background: rgba(0,0,0,.5);

        height: 100%;
        width: 100%;

        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        z-index: 110;

        opacity: 1;
    }

    &[data-state="results"], 
    &[data-state="loading"] {

        @{desktop} & {
            z-index: 111;
        }

        .search {
            &__input{
                padding-right: rem(54);
            }
            
            &__reset {
                cursor: pointer;

                pointer-events: auto;

                &-icon {
                    opacity: 1;
                }
            }
        }
    }

    &[data-state="loading"] {
        .search {
            &__reset {
                background: url('~static/img/loading-sm.gif') no-repeat 50%;

                &-icon {
                    opacity: 0;
                }
            }
        }
    }

    &__field {
        position: relative;
        .wi(search, 16, 16, 14, 20);

        @{desktop} & {
            .wi(search, 16, 16, 14, 16);
        }

        &:before {
            z-index: 9;

            pointer-events: none;
        }
    }

    &__input {
        background-color: @color-bg-gray-minor;
        border-bottom: rem(1) solid @color-gray-white;
        border-radius: 0;
        text-overflow: ellipsis;
        white-space: nowrap;

        //transform: translateY(rem(-1));
        padding-right: rem(8);
        padding-left: rem(48);

        overflow: hidden;

        &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
            color: @color-gray-light;
        }
        &::-moz-placeholder { /* Firefox 19+ */
            color: @color-gray-light;
        }
        &:-ms-input-placeholder { /* IE 10+ */
            color: @color-gray-light;
        }
        &:-moz-placeholder { /* Firefox 18- */
            color: @color-gray-light;
        }

        @{desktop} & {
            background-color: @color-white;
            border: rem(1) solid @color-gray-2;
            border-radius: rem(3);

            padding-top: 0;
            padding-right: rem(54);
            padding-left: rem(40);

            height: rem(44);

            &::-webkit-input-placeholder {
                color: @color-gray;
            }
            &::-moz-placeholder {
                color: @color-gray;
            }
            &:-ms-input-placeholder {
                color: @color-gray;
            }
            &:-moz-placeholder {
                color: @color-gray;
            }

            &[data-state="results"], &[data-state="loading"] {
                border-bottom: rem(1) solid @color-gray-white;
                border-color: transparent;

                .hover({
                    border-color: transparent;
                })
            }

            &_bnb{
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
            }

            .hover({
                border-color: @color-gray-light;
            })
        }
    }

    &__reset {
        display: flex;
        align-items: center;
        justify-content: center;

        width: rem(54);

        .wi(icon-close, 16, 16);

        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;

        pointer-events: none;

        @{desktop} & {
            &:hover {
                .search__reset-icon {
                    color: @color-black;

                    transition: color 0.2s;
                }
            }
        }

        &-icon {
            color: @color-black;

            height: rem(16);
            width: rem(16);

            opacity: 0;

            transition: color 0.2s;

            @{desktop} & {
                color: @color-gray-light;
            }
        }

        &:before {
            display: none;
        }
    }

    &__results {
        background-color: @color-white;
        box-sizing: border-box;

        width: 100%;

        position: fixed;
        top: rem(100);

        .with-p-block &{
            top: rem(156);
        }
        bottom: 0;
        left: 0;
        z-index: 5;

        @{desktop} & {
            padding: 0;

            height: rem(602);
            max-height: 78vh;

            position: absolute;
            top: rem(44);
            left: auto;

            overflow: hidden;
        }

        @{mobile} & {
            .tabs{
                &__list{
                    display: flex;
                    padding-left: 0;
                }

                &__tab{
                    display: block;
                    flex-grow: 1;
                    flex-basis: 0;
                    margin: 0;

                    &+.tabs__tab{
                        margin: 0;
                    }
                }

                &__link{
                    display: block;
                }

            }
        }
    }

    &__tabs {
        height: 100%;
    }

    &__empty {
        color: @color-gray;
        text-align: center;

        padding: rem(50);
    }
}
</style>
