/**
 * Форматирует число, разделяя тысячи
 *
 * @param  {Number} value
 * @param  {String} delimeter
 * @return {String}
 */
const format = (value, delimeter = ' ') => {
    return (value || 0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, delimeter);
};

export default {
    format,

    /**
     * Возвращает множественную форму слова
     *
     * @param  {Number}  value
     * @param  {Object}  forms ['единица', 'единицы', 'единиц']
     * @param  {Boolean} printNumber печатать ли само число
     * @return {String}
     */
    declension(value, forms, printNumber = true) {

        if (typeof forms === 'string') {
            forms = [arguments[1],arguments[2],arguments[3]];
            printNumber = arguments[4] || true;
        }

        let result = printNumber ? format(value) : '';
        if (printNumber) {
            if (printNumber === true) {
                result += ' ';
            } else {
                result = `<span class="${printNumber}">${result}</span> `;
            }
        }

        const n = Math.abs(value) % 100;
        const n1 = n % 10;

        if (n > 10 && n < 20) {
            return result + forms[2];
        }

        if (n1 > 1 && n1 < 5) {
            return result + forms[1];
        }

        if (n1 === 1) {
            return result + forms[0];
        }

        return result + forms[2];
    },

    /**
     * Возвращает число в формате 2.4K/3.5M, когда больше 1000
     *
     * @param  {Number} value
     * @return {String}
     */
    shortFormat(value) {

        return Math.abs(value) > 999
            ? Math.sign(value)*((Math.abs(value)/1000).toFixed(1)) + 'k'
            : Math.sign(value)*Math.abs(value);
    },

    formatRatingHalf(value) {
        return Math.round(value * 2) / 2
    },

    formatRating(value) {
        const number = parseFloat(value);
        return number.toFixed(1).toString().replace('.', ',')
    },

    bytesToSize(bytes, seperator = "") {
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
        if (bytes === 0) return 'n/a'
        const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10)
        if (i === 0) return `${bytes}${seperator}${sizes[i]}`
        return `${(bytes / (1024 ** i)).toFixed(1)}${seperator}${sizes[i]}`
    }
};
