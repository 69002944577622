import get from 'lodash/get'
import map from 'lodash/map'
import includes from 'lodash/includes'
import find from 'lodash/find'
import difference from 'lodash/difference'
import isEqual from 'lodash/isEqual'

import Perfume from '@/models/catalog/perfume/'

const state = () => {
    return {
        shopRandewoo: {
            name: 'Randewoo',
            url: 'https://randewoo.ru'
        },
        noImage: '/img/placeholder-photo.svg',
        perfumeNoImage: '/img/perfumeNoImage.svg',
        perfumeNoImageBg: '/img/placeholder-perfume-bg.svg',
        userNoImage: '/img/userNoImage.svg',
        userPlaceholder: '/img/userPlaceholder.svg',
        userNoImageLarge: '/img/userNoImageLarge.svg',
        userNoImageAlt: '/img/placeholder-profile.svg',
        noseNoImage: '/img/placeholder-nose-desktop.svg',
        noseNoImageMobile: '/img/placeholder-nose-mobile.svg',
        noseNoImageAlt: '/img/placeholder-perfumer.svg',
        noteNoImage: '/img/noteNoImage.svg',
        noteNoImageAlt: '/img/placeholder-note-alt.svg',
        noteNoImageMobile: '/img/placeholder-note.svg',
        brandNoImage: '/img/placeholder-brand.svg',
        brandNoImageAlt: '/img/placeholder-brand-alt.svg',
        brandNoImageTransparent: '/img/placeholder-brand-mobile-transparent.svg',
        brandNoImageMobile: '/img/placeholder-brand-mobile.svg',
        userNoImageFilled: '/img/userNoImageFilled.svg',
        noImageNotes: '/img/placeholder-note.svg',
        noImageNotesLarge: '/img/placeholder-note-large.svg',
        pendingEvent: {},
        userLikesRatings: [],
        commentsRatings: [],
        perfumesOffers: [],
        deviceTypeDesktop: false,
        deviceMobileDesktop: false,
        preloader: false,
        userBroadcasts: {},
        socialLinks: {
            'ok': {
                'url': 'https://ok.ru/aromo.ru'
            },
            'vk': {
                'url': 'https://vk.com/aromo_ru'
            },
            'fb': {
                'url': 'https://www.facebook.com/aromo.ru/'
            },
            'in': {
                'url': 'https://www.instagram.com/aromo.ru/'
            },
            'tw': {
                'url': 'https://twitter.com/Aromo_ru'
            },
            'tg': {
                'url': 'https://t.me/aromo_ru'
            },
            'yt': {
                'url': 'https://www.youtube.com/channel/UCmqe3EkR22acM9y_NNMnAHw'
            }
        },
        newSections: [],
        prevPageUrl: null,
        // headerBanner: {
        //     enabled: true,
        //     desktop: '/img/promo/NoJokes-aromo-header-DR-2140x150-nocounter.png',
        //     mobile: '/img/promo/NoJokes-aromo-header-MR-750x112.png',
        //     link: 'https://randewoo.ru/sale/parfyumeriya?utm_source=aromo.ru&utm_medium=banner&utm_campaign=rand-NotJoke'
        // },
        headerBanner: null,
        perfumeBanner: {
            enabled: false,
            desktop: '/img/promo/bigspringsale/FSS2022-aromo-aromabox-D-R-1232x180.png',
            mobile: '/img/promo/bigspringsale/FSS2022-aromo-aromabox-M-R-750x192.png',
            link: 'https://randewoo.ru/category/aroma-box?utm_source=aromo.ru&utm_medium=banner&utm_campaign=banner_sale'
        },
        entityData: null,
        monitoringResponse: null,
        bugReportEnabled: false
    }
};

const getters = {
    getNoImage: (state) => state.noImage,
    getPerfumeNoImage: (state) => state.perfumeNoImage,
    getUserNoImage: (state) => state.userNoImage,
    getNoseNoImage: (state) => {
        return state.deviceTypeDesktop ? state.noseNoImage : state.noseNoImageMobile;
    },
    getNoteNoImage: (state) => state.noteNoImage,
    getUserNoImageLarge: (state) => state.userNoImageLarge,
    getUserNoImageFilled: (state) => state.userNoImageFilled,
    getUserNoImageAlt: (state) => state.userNoImageAlt,
    getNoImageNotes: (state) => {
        return state.deviceTypeDesktop ? state.noteNoImageMobile : state.noteNoImage;
    },
    getSocialLinkUrl: s => code => {
        if(s.socialLinks[code]) {
            return s.socialLinks[code].url
        }
        else {
            return undefined;
        }
    },
    getEntityNoImage: s => (entityType, isLarge) => {
        const largeStr = isLarge ? 'Large' : ''

        const placeholderTypes = {
            'user': s[`userNoImage${largeStr}`],
            'perfume': s[`perfumeNoImage${largeStr}`],
            'perfumeBg': s.perfumeNoImageBg,
            'brand': s[s.deviceTypeDesktop ? 'brandNoImage' : 'brandNoImageMobile'],
            'brand_alt': s.brandNoImageAlt,
            'brand_search': s.brandNoImageTransparent,
            'note': s[`noImageNotes${largeStr}`],
            'note_alt': s.noteNoImageAlt,
            'nose': s[state.deviceTypeDesktop ? `noseNoImage${largeStr}` : `noseNoImageMobile`],
            'nose_alt': s.noseNoImageAlt,
            'noteGroup': s[`noImageNotes${largeStr}`],
            'perfumer': s[state.deviceTypeDesktop ? `noseNoImage${largeStr}` : `noseNoImageMobile`],
            'shop': s.noImage,
            'shop_review': s.noImage,
            'brand_review': s.brandNoImageMobile,
            'perfume_review': s[`perfumeNoImage${largeStr}`]
        }

        return placeholderTypes[entityType] ? placeholderTypes[entityType] : s.userNoImageLarge
    },
    getRatingsByCode: s => (code,entityType) => {
        return find(s.userLikesRatings, r => {
            if (r[entityType]) {
                return isEqual(r[entityType].code, code)
            }
        })
    },
    getCommentsRatingsById: s => id => {
        return find(s.commentsRatings, r => {
            if (r.comment.id) return r.comment.id === id
            return includes(r.comment.url, id)
        })
    },
    getOfferByCode: s => code => {
        return find(s.perfumesOffers, ['code', code]) || null
    },
    getOffersCodes: s => map(s.perfumesOffers, 'code'),
    isDeviceDesktop: (state) => state.deviceMobileDesktop || state.deviceTypeDesktop,
    isDeviceMobileDesktop: (state) => state.deviceMobileDesktop,
    getPreloader: (s) => s.preloader,
    getUserBroadcasts: (s) => s.userBroadcasts,
    getNewSections: (s) => s.newSections,
    getPrevPageUrl: (s) => s.prevPageUrl,
    getHeaderBanner: (s) => s.headerBanner,
    getPerfumeBanner: (s) => s.perfumeBanner,
    getPendingEvent(s) {
        return s.pendingEvent;
    },
    getEntityData: (s) => s.entityData,
    getMonitoringResponse: (s) => s.monitoringResponse,
    getBugReportStatus: (s) => s.bugReportEnabled
};

const mutations = {
    savePendingEvent(s, payload) {
        s.pendingEvent = payload
    },
    deletePendingEvent(s) {
        s.pendingEvent = {}
    },
    setUserLikesRatings(s, array){
        s.userLikesRatings = array
    },
    pushUserLikesRatings(s, array){
        s.userLikesRatings = [
            ...s.userLikesRatings,
            ...array
        ]
    },
    setCommentsRatings(s, array) {
        s.commentsRatings = array
    },
    pushCommentsRatings(s, array) {
        s.commentsRatings = [
            ...s.commentsRatings,
            ...array
        ]
    },
    setDeviceType(s, { isFromPc, isMobilePc }) {
        s.deviceTypeDesktop = isFromPc
        s.deviceMobileDesktop = isMobilePc || false
    },
    setLoader(s, payload) {
        s.preloader = payload
    },
    setPerfumesOffers(s, payload) {
        s.perfumesOffers = [
            ...s.perfumesOffers,
            ...payload
        ]
    },
    setUserBroadcasts(s, payload){
        s.userBroadcasts = payload
    },
    setPrevPageUrl(s, payload){
        s.prevPageUrl = payload
    },
    setHeaderBanner(s, payload){
        s.headerBanner = payload
    },
    setEntityData(s, payload){
        s.entityData = payload
    },
    setMonitoringResponse(s, payload){
        s.monitoringResponse = payload;
    }
}

const actions = {
    nuxtServerInit({ commit, getters }, { req }) {
        const isFromPc = this.$ua.isFromPc()
        const isMobilePc = this.$cookies.get('is_mobile_pc') === 'y'

        commit('setDeviceType', { isFromPc, isMobilePc })
    },
    async queryUserBroadcasts({commit, state}) {
        await this.$api.get('/users/accounts/broadcasts', {
            progress: false
        }).then((response) => {

            const data = get(response, 'data', {});

            if (!this._vm.$ws && data.pull && data.pull.channel) {
                let params = 'CHANNEL_ID=' + (process.env.pullChannel || data.pull.channel);
                params += '&format=json';

                this._vm.$ws = new WebSocket(process.env.wsUrl + '?' + params);
                this._vm.$ws.onmessage = (event) => {
                    if (event.data && event.type === 'message') {
                        const messages = event.data.split('#!NGINXNME!##!NGINXNMS!#');
                        for (const m in messages) {
                            const message = JSON.parse(messages[m].replace('#!NGINXNMS!#','').replace('#!NGINXNME!#',''));
                            this._vm.$bus.$emit('ws-message', message);
                        }
                    }
                };
            }
            
            commit('setUserBroadcasts', data);
        });
    },
    async requestOffers({ commit, getters }, codes) {
        if (!codes) return

        // Нам не нужны те ароматы, которые уже есть в хранилище
        const uniqueCodes = difference(codes, getters.getOffersCodes)

        if (!uniqueCodes || uniqueCodes.length === 0) return

        const code = uniqueCodes.join(',')

        try {
            const result = await new Perfume().params({ code }).get()

            if (result) {
                const perfumes = get(result, '[0].items')

                if (perfumes && perfumes.length) commit('setPerfumesOffers', perfumes)
            }
        } catch(e) {
            console.log(e)
        }
    },
    getTranslation(context, name) {
        console.log(context, name)
    },
    preloaderLoad({ commit }, load ) {
        commit('setLoader', load)
    },
    monitoringResponse({commit}, load){
        commit('setMonitoringResponse', load);
    }
}

export default {
    state,
    getters,
    mutations,
    actions
}
