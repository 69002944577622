export const state = () => ({
    currentUserId: ''
})


export const mutations = {
    getCurrentUserId(state, userId) {
        state.currentUserId = userId
    }
}

export const getters = {
    getUserId: (state) => state.currentUserId
}
