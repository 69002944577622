<template>
    <button class="button-circle"
        :class="classes">

        <div class="button-circle__icon-wrapper">
            <svg-icon
                class="button-circle__icon"
                :name="iconName"/>
        </div>

        <span class="button-circle__label"
            v-if="$slots.default">
            <slot ></slot>
        </span>

    </button>
</template>

<script>
export default {
    name: 'ButtonCircle',
    props: {
        icon: {
            type: String,
            default: ''
        },
        shadow: {
            type: Boolean,
            default: false
        },
        bordered: {
            type: Boolean,
            default: false
        },
        active: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        classes() {
            return {
                'button-circle_bordered': this.bordered,
                'button-circle_shadow': this.shadow,
                'button-circle_active': this.active
            }
        },
        iconName() {
            return this.active ? `${this.icon}-inverted` : this.icon
        }
    }

}
</script>

<style lang="less">
.button-circle {
    --size: rem(40);
    --border-color: @color-black;

    display: block;

    background: none;
    border: none;
    outline: none;

    padding: 0;

    &__icon-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;

        border-radius: 50%;

        height: var(--size);
        width: var(--size);

        transition: background-color .15s ease, border-color .15s ease;
    }

    &__icon {
        height: 100%;
        width: 100%;
    }

    &__label {
        display: block;

        padding-top: rem(4);

        transition: color .15s ease;
    }

    &_bordered &__icon-wrapper {
        border: rem(1) solid var(--border-color);
    }

    &_shadow &__icon-wrapper {
        background: @color-white;
        border: none;
        box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.1);
    }

    &_bordered &__icon {
        height: 40%;
        width: 40%;
    }

    &_active &__icon-wrapper {
        background-color: @color-primary;
        border-color: @color-primary;
    }

    &_active &__label {
        color: @color-primary;
    }

    &[disabled] {
        color: @color-black;

        opacity: .5;

        pointer-events: none;
    }
}
</style>
