<template>

    <div class="sidebar"
        :data-visible="visible"
        @click="avoidClick">
        <div class="sidebar__wrap"
            v-touch:swipe.left.prevent="swipe"
            v-touch-options="{swipeTolerance: 100}">
            <!-- <span class="sidebar__opener" @click="open">
                <svg-icon name="sidebar-opener" class="sidebar__opener-icon" />
            </span> -->
            <div class="sidebar__head">
                <svg-icon :name="'logo-' + $i18n.locale" :class="'sidebar__logo sidebar__logo_'+$i18n.locale" />
            </div>
            <div class="sidebar__body">
                <div class="sidebar__scrolled">
                    <nav-list class="sidebar__nav"
                        @linkclicked="handleClick"
                        :type="'catalog'"
                        :show-items="5"
                        :show-icons="true"
                        :show-counters="true" />

                    <nav-list
                        class="sidebar__sections"
                        @linkclicked="handleClick"
                        :type="'sections'" />

                    <nav-list
                        class="sidebar__pages"
                        @linkclicked="handleClick"
                        :type="'pages'" />
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import isEqual from 'lodash/isEqual'
import NavList from '@/components/layout/nav/NavList'

export default {
    name: 'Sidebar',
    serverCacheKey(props) {
        return 'sidebar';
    },
    components: {
        NavList
    },
    data() {
        return {
            visible: false
        };
    },
    methods: {
        handleClick() {
            this.$nextTick(() => {
                this.close()
                this.$scrollTo('html', 750)
            })
        },
        swipe(direction, $event) {
            this.close();
            return false;
        },
        open() {
            this.visible = true;
            this.$body.cut();
        },
        close() {
            this.visible = false;
            this.$body.uncut();
        },
        avoidClick($event) {
            if (isEqual($event.target, this.$el)) {
                this.close();
            }
        }
    }
}
</script>

<style lang="less">
.sidebar {
    height: 100%;
    width: pxToRem(300);

    position: fixed;
    top: 0;
    left: 0;
    z-index: 101;

    transition: all .2s ease;

    transform: translateX(-100%);

    pointer-events: none;

    &[data-visible="true"] {
        transform: translateX(0%);

        pointer-events: auto;

        &:before{
            opacity: 1;

            pointer-events: auto;
        }

        .sidebar{
            &__opener{
                visibility: hidden;

                transition-delay: 0s;

                pointer-events: none;
            }
        }
    }

    &:before{
        content: '';
        display: block;

        background-color: rgba(0,0,0,.5);

        width: 200vw;

        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;

        opacity: 0;

        transition: opacity .1s linear;

        pointer-events: none;
    }

    &__wrap{
        background-color: @color-white;

        height: 100%;

        position: relative;
        z-index: 1;
    }

    // &__opener{
    //     display: block;

    //     line-height: rem(30);
    //     text-align: center;

    //     height: rem(30);
    //     width: rem(30);

    //     position: absolute;
    //     top: rem((56-30)/2);
    //     right: rem(-34-12);

    //     transition: visibility 0s .19s linear;

    //     pointer-events: auto;

    //     &-icon{
    //         display: inline-block;
    //         vertical-align: middle;
    //         cursor: pointer;

    //         height: rem(20);
    //         width: rem(20);
    //     }
    // }

    &__logo{
        &_ru{
            height: rem(15);
            width: rem(91);
        }

        &_en{
            height: pxToRem(17);
            width: pxToRem(113);
        }
    }

    &__head {
        display: flex;
        align-items: flex-end;

        border-bottom: 1px solid#ECECEC;

        padding: pxToRem(20);

        height: pxToRem(56);
        width: 100%;

        position: absolute;
        top: 0;
        left: 0;
    }

    &__body{
        padding-top: pxToRem(56);

        height: 100%;
    }

    &__scrolled{
        background-color: @color-gray-light-2;

        height: 100%;

        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
    }

    &__nav{
        background-color: @color-white;

        padding-top: rem(12);
        padding-bottom: rem(27);
    }

    &__sections{
        background-color: @color-white;

        margin: rem(8) 0;
        padding: rem(8) 0;
    }

    &__pages{
        background-color: @color-white;

        padding-top: rem(6);
        padding-bottom: rem(30);

        min-height: 45%;

        .nav__link {
            padding-top: rem(11);
            padding-bottom: rem(11);
        }

        .nav__link-text {
            color: @color-gray;
            font-size: rem(15);
            font-weight: 400;
        }
    }

    .link_active, .link_exact-active {
        color: @color-primary;
    }
}
</style>
