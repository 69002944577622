const authOptions = {
    redirect: false,
    watchLoggedIn: true,
    resetOnError: false,
    strategies: {
        local: {
            scheme: 'refresh',
            token: {
                property: 'accessToken',
                name: 'Authorization',
                type: 'Bearer'
            },
            refreshToken: {
                property: 'refreshToken',
                data: 'refreshToken',
                maxAge: 60 * 60 * 24 * 365,
                tokenRequired: true
            },
            user: {
                property: false,
                autoFetch: true
            },
            endpoints: {
                login: {
                    url: '/users/accounts/token',
                    method: 'post',
                    headers: {
                        'Accept': 'application/scentbook-v1.0.0+json',
                        'Content-Type': 'application/x-www-form-urlencoded'
                    }
                },
                refresh: {
                    url: '/users/accounts/token/refreshing',
                    method: 'post',
                    headers: {
                        'Accept': 'application/scentbook-v1.0.0+json',
                        'Content-Type': 'application/x-www-form-urlencoded'
                    }
                },
                logout: false,
                user: {
                    url: '/users/accounts/profile',
                    method: 'get',
                    headers: {
                        'Accept': 'application/scentbook-v1.0.0+json',
                    }
                }
            },
            autoLogout: false
        }
    },
}

module.exports = authOptions
