<i18n>
{
    "ru": {
        "buy": "Купить",
        "all_parfumes": "Вся парфюмерия",
        "online": "онлайн",
        "to_shop": "В магазин"
    }
}
</i18n>

<template>
    <transition name="fade" v-if="shop">
        <div
            class="price-panel"
            v-show="visible && !hidden"
            :class="{'price-panel_nobrand': !brand}">

            <div class="price-panel__inner">

                <html-link
                    v-if="brand && brand.logo"
                    class="price-panel__brand"
                    :href="replaceUtm(brand.htmlUrl)"
                    target="_blank"
                    rel="nofollow noreferrer">
                    <img
                        v-lazy-load :data-src="brand.logo.url"
                        :alt="brand.name" />
                </html-link>

                <html-link v-else
                    :href="replaceUtm('https://randewoo.ru/category/parfyumeriya?aromo_promo=true&utm_medium=partner&utm_source=aromo')"
                    target="_blank"
                    rel="nofollow noreferrer"
                    class="price-panel__all-perfumes">
                    <span>{{ $t('all_parfumes') }}</span>
                    {{ $t('online') }}
                </html-link>

                <div class="price-panel__items" v-if="shop.prices">

                    <html-link 
                        :href="replaceUtm(price.htmlUrl)"
                        target="_blank"
                        rel="nofollow noreferrer"
                        class="price-panel__item"
                        v-for="(price,index) in computedItems"
                        :key="index">
                        <div class="price-panel__item-image">
                            <img
                                v-lazy-load :data-src="price.picture.url"
                                :alt="price.name" />
                        </div>
                        <div class="price-panel__item-content" v-if="isDeviceDesktop">
                            <div class="price-panel__item-name">{{ price.name }}</div>
                            <div class="price-panel__item-desc">
                                <div class="price-panel__item-text">{{ price.description }}</div>
                                <div class="price-panel__item-price">
                                    {{ price.price | numberFormat }} {{ price.currency }}
                                </div>
                            </div>
                        </div>
                    </html-link>

                </div>

                <div class="price-panel__shop">
                    <div class="price-panel__shop-button">
                        <html-link
                            :href="replaceUtm(shop.prices[0].htmlUrl)"
                            target="_blank"
                            rel="nofollow noreferrer"
                            class="button-base">
                            {{ brand ? $t('buy') : $t('to_shop') }}
                        </html-link>
                    </div>
                    <html-link
                        class="price-panel__shop-content"
                        :href="shopUrl"
                        target="_blank">
                        <div class="price-panel__shop-name">{{ shopName }}</div>
                        <div class="price-panel__shop-rating rating"
                            v-if="isDeviceDesktop"
                            :data-value="parseInt(shop.ratings.value)"></div>
                    </html-link>
                </div>

            </div>

        </div>
    </transition>
</template>

<script>
import get from 'lodash/get'
import throttle from 'lodash/throttle'
import { mapGetters } from 'vuex'
import Perfume from '@/models/catalog/perfume';
import Brand from '@/models/catalog/brand';

export default {
    name: 'BlockPricePanel',
    props: {
        type: {
            type: String,
            default: null
        },
        trigger: {
            type: String,
            default: null
        },
        brandCode: {
            type: String,
            default: null
        },
        prices: {
            type: Object,
            default: null
        }
    },
    data(){
        return {
            brand: null,
            shop: null,
            visible: false,
            hidden: false,
            shopName: 'Randewoo',
            shopUrl: '/shops/randewoo/'
        }
    },
    computed:{
        ...mapGetters(['isDeviceDesktop']),
        computedItems(){
            const count = 3
            return this.shop.prices.slice(0, count)
        }
    },
    watch: {
        visible(){
            if(!this.isDeviceDesktop && this.shop){
                if(this.visible){
                    document.body.classList.add('button-up-offset')
                }
            }
        },
        hidden(){
            if(!this.isDeviceDesktop && this.shop){
                if(this.hidden){
                    document.body.classList.remove('button-up-offset')
                }else{
                    document.body.classList.add('button-up-offset')
                }
            }
        }
    },
    mounted(){
        if(this.prices){
            // если мы уже получили цены на деталке аромата
            this.brand = get(this.prices, 'perfume.brand')
            this.shop = get(this.prices, 'shops[0]')
        }else{
            this.fetchData();
        }

        document.addEventListener('scroll', throttle(e => {

            if(this.trigger && document.querySelector(this.trigger)){
                const targetRect = document.querySelector(this.trigger).getBoundingClientRect()
                if(targetRect.top + targetRect.height <= 0)
                    this.visible = true
            }else{
                const height = document.body.offsetHeight <= window.innerHeight * 2 ? 60 : window.innerHeight
                const offset = this.isDeviceDesktop ? height : height*2
                if(window.scrollY >= offset)
                    this.visible = true
            }

            if (this.visible){
                if(this.isDeviceDesktop){
                    this.hidden = window.scrollY - 72 <= 0
                }else{
                    this.hidden = window.scrollY + window.innerHeight >= document.body.offsetHeight - 64
                }
            }

        }, 100))
    },
    methods: {
        get,
        async fetchData(){

            if(this.type === 'perfume'){
                const perfume = new Perfume({
                    code: this.$route.params.code
                })

                await perfume.prices().params({shop_code: 'randewoo'}).get().then(result => {
                    this.brand = get(result, '[0].perfume.brand', null)
                    this.shop = get(result, '[0].shops[0]', null)
                })
            }

            if(this.type === 'brand'){
                const brand = new Brand({
                    code: this.brandCode
                })

                await brand.prices().get().then(result => {
                    this.brand = get(result, '[0].brand', null)
                    this.shop = get(result, '[0].shops[0]', null)
                })
            }

            if(!this.shop){
                await new Brand().prices().get().then(result => {
                    this.brand = false
                    this.shop = get(result, '[0].shops[0]', null)
                })
            }
        },
        replaceUtm(url){
            const urlParams = new URLSearchParams(url)

            // заменяем aromo на aromo.ru
            urlParams.set('utm_source', 'aromo.ru')

            // заменяем utm_medium=partner на utm_medium=strap
            urlParams.set('utm_medium', 'strap')

            // заменяем или добавляем метку utm_campaign на деталке парфюма
            if(this.type === 'perfume')
                urlParams.set('utm_campaign', 'line')

            return decodeURIComponent(urlParams.toString())
        }
    }
}
</script>

<style lang="less">
body.button-up-offset{
    .button-up{
        bottom: rem(72);

        &_higher{
            bottom: rem(139);
        }
    }
    .articles-detail-page__share{
        bottom: rem(64);
    }
}
.price-panel{
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    background-color: white;
    z-index: 109;
    box-shadow: 0 rem(3) rem(16) rgba(0, 0, 0, 0.08);
    -webkit-backface-visibility: hidden;
    overflow: hidden;
    max-width: 100%;

    @{mobile} & {
        bottom: 0;
        top: auto;

        &__inner{
            padding-top: rem(8);
            padding-bottom: rem(8);
            padding-left: rem(16);
            padding-right: rem(16);
            min-width: 0;
        }

        &__brand{
            width: rem(92);
            height: rem(44);
            flex: 0 0 rem(92);
            line-height: rem(44);
            margin-right: 0;
        }

        &__shop{
            text-align: center;
            flex-direction: column;

            &-button{
                margin-right: 0;
                width: rem(82);
                flex: 0 0 100%;

                .button-base{
                    padding: rem(7) rem(10) rem(5);
                    font-size: rem(12);
                    line-height: rem(15);
                    display: block;
                }
            }

            &-name{
                font-size: rem(11);
                line-height: rem(17);
                font-weight: 400;
            }
        }

        &__items{
            margin-right: rem(10);
            flex-wrap: wrap;
            height: rem(44);
            overflow: hidden;
        }

        &__item{
            justify-content: center;
            padding: 0 rem(4);
            margin: 0;
            flex: 0 0 rem(52);

            &-image{
                width: rem(44);
                height: rem(44);
                line-height: rem(44);
                flex: 0 0 rem(44);
                margin-right: 0;
            }
        }

        &__all-perfumes{
            margin-right: rem(10);
        }
    }

    &__inner{
        max-width: rem(1440);
        margin-left: auto;
        margin-right: auto;
        padding: rem(8) rem(40);
        box-sizing: border-box;
        display: flex;
        align-items: center;
        min-width: rem(1110);
    }

    &__brand{
        margin-right: rem(45);
        height: rem(48);
        width: rem(100);
        line-height: rem(48);
        flex: 0 0 rem(100);
        text-align: center;

        &>img{
            display: inline-block;
            vertical-align: middle;
            width: auto;
            max-height: 100%;
            margin: auto;
            max-width: 100%;
        }
    }

    &__all-perfumes{
        margin-right: rem(34);
        width: rem(123);
        flex: 0 0 rem(123);
        text-transform: uppercase;
        font-weight: 500;
        font-size: rem(12);
        line-height: rem(15);
        color: @color-black;
        white-space: nowrap;

        &>span{
            display: block;
            margin: rem(2) 0;
            color: @color-primary;
        }

        &:hover{
            color: @color-primary;
        }
    }

    &__items{
        display: flex;
        flex: 1 1 auto;
        margin-right: rem(66);
        min-width: 0;
    }

    &__item{
        display: flex;
        align-items: center;
        margin: 0 rem(8);
        min-width: 0;
        padding-right: rem(16);
        flex: 0 0 33.333%;
        color: @color-black;
        position: relative;
        z-index: 2;

        &-image{
            width: rem(48);
            height: rem(48);
            text-align: center;
            margin-right: rem(16);
            line-height: rem(48);
            flex: 0 0 rem(48);

            &>img{
                display: inline-block;
                vertical-align: middle;
                max-height: 100%;
                max-width: 100%;
                width: auto;
                margin: auto;
            }
        }

        &-content{
            overflow: hidden;
        }

        &-name{
            font-size: rem(15);
            line-height: rem(23);
            margin-bottom: rem(2);
            font-weight: 500;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }

        &-desc{
            font-size: rem(13);
            line-height: rem(20);
            display: flex;
            white-space: nowrap;
        }

        &-text{
            text-overflow: ellipsis;
            overflow: hidden;
            color: @color-gray;
        }

        &-price{
            flex: 0 0 auto;
            font-weight: 500;
            margin-left: rem(8);
        }

        &:hover{
            .price-panel__item-name{
                color: @color-violet;
            }
        }
    }

    &__shop{
        margin-left: auto;
        display: flex;

        &-content{
            line-height: 0;
            text-decoration: underline;

            &:hover{
                text-decoration: none;
            }
        }

        &-button{
            margin-right: rem(12);
            position: relative;
            z-index: 2;

            .button-base{
                white-space: nowrap;
                padding: rem(9) rem(16) rem(7);
                font-size: rem(13);
                line-height: rem(20);
            }
        }

        &-name{
            font-size: rem(13);
            line-height: rem(20);
        }

        &-rating.rating{
            line-height: 0;
            --star-width: rem(15);
            --star-height: rem(15);
        }
    }

    &_nobrand{
        padding-left: calc(~"(100% - rem(1070)) / 2");

        .price-panel{
            &__inner{
                padding-left: 0;
            }
        }

    }
}
</style>