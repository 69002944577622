<template>
    <div class="paginated-list"
        :data-faded="faded && shadow"
        @scroll="scroll"
        :data-loading="loading">
        <div class="paginated-list__wrap"
            v-if="computedItems && computedItems.length"
            :class="wrapClasses"
            :data-count="columnsCount"
            ref="wrap"
            @scroll.stop="scroll">
            <slot name="item"
                v-for="item in computedItems"
                v-bind="item"></slot>
        </div>
        <div v-else-if="emptyText.length>0" class="paginated-list__wrap is-empty">{{ emptyText }}</div>
    </div>
</template>

<script>
import PaginatedMixin from '@/mixins/paginated.mixin'

export default {
    name: 'PaginatedList',
    mixins: [ PaginatedMixin ],
    props: {
        listClasses: {
            type: Object,
            default: () => {}
        },
        columnsCount: {
            type: Number,
            default: 0
        },
        shadow: {
            type: Boolean,
            default: true
        },
        emptyText: {
            type: String,
            default: ''
        }
    },
    computed: {
        wrapClasses() {
            const classes = {
                columns: this.columnsCount > 0,
                'is-empty': !this.items,
                'is-full': !this.canLoad() &&
                    this.items &&
                    this.items.length
            }

            if (this.listClasses)
                return Object.assign(this.listClasses, classes)

            return classes
        },
    },
    methods: {
        scroll(e) {
            if (this.loading || !this.canLoad()) {
                this.faded = false;
                return false;
            }

            if (e.currentTarget.scrollTop + e.currentTarget.clientHeight >= e.currentTarget.scrollHeight - 100) {
                this.load();
            }
        },
    }
}
</script>
<style lang="less">
.paginated-list{
    height: 100%;

    position: relative;

    &:after{
        content: '';
        display: block;

        background: fade(@color-white, 30%) url('~static/img/loading-sm.gif') no-repeat 50% 97%;

        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 2;

        opacity: 0;

        pointer-events: none;
        .animate(opacity, .1s);
    }

    &:before{
        content: '';
        display: block;

        background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, @color-white 100%);

        height: rem(55);

        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;;
        z-index: 1;

        opacity: 0;

        pointer-events: none;
    }

    &[data-loading="true"] {
        &:after{
            opacity: 1;

            pointer-events: auto;
        }
    }
    &[data-faded="true"] {
        &:before{
            opacity: 1;
        }
    }

    &__wrap{
        width: 100%;

        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;

        overflow-y: auto;
        -webkit-overflow-scrolling: touch;

        .scrollbar();

        @{desktop} & {
            padding-bottom: rem(10);
        }

        &.columns{
            flex-flow: column;
        }
    }
}
</style>
