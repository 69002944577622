<template>
    <div class="amp-layout">
        <AppAmpContainer />
    </div>
</template>

<script>
import AppAmpContainer from '@/components/layout/amp/AppAmpContainer'
export default {
    components: {
        AppAmpContainer
    }
}
</script>

<style lang="less">
@media amp{
    @import '~/assets/styles/layout/amp';
    @import '~/assets/styles/layout/fonts';
}
</style>