/* eslint-disable no-extra-boolean-cast */

export const state = () => ({
    result: [],
    loading: false
})

export const mutations = {
    result(state, value) {
        state.result[value.url] = value;
    },
    loading(state, value) {
        state.loading = value;
    }
}

export const actions = {
    report({commit, state, redirect, error: nuxtError}, params) {

        const errors = {};
        const url = 'services/problem-reports';


        if (params.error && params.error.type === 'fetch/xhr') {

            if (!['post','patch','put'].includes(params.error.data.config.method)
                || [499,1003].includes(params.error.data.status)
                || params.error.data.config.url === url) {
                return;
            }

            let sendData = {};
            if (typeof params.error.data.config.data === 'object') {
                params.error.data.config.data.forEach(function(value, key) {
                    sendData[key] = value;
                });
            } else {
                sendData = params.error.data.config.data;
            }
            
            const request = {
                URI: params.error.data.config.url,
                METHOD: params.error.data.config.method,
                HEADERS: params.error.data.headers || params.error.data.request._header,
                DATA: sendData
            };

            errors['FETCH/XHR'] = {
                REQUEST: request,
                RESPONSE: {
                    CODE: params.error.data.status,
                    HEADERS: params.error.data.headers,
                    BODY: params.error.data.data
                }
            };
        };

        if (params.error && params.error.type === 'console') {
            errors.CONSOLE = params.error.data;
            if (errors.CONSOLE[1] && errors.CONSOLE[1].includes('chrome-extension://')
                || errors.CONSOLE[0] && errors.CONSOLE[0].includes('expected pattern')) {
                return;
            }
        };

        const data = {
            PAGE_URL: params.url,
            // REFERER: null,
            COOKIE: this.$cookies.getAll(),
            USER_AGENT: this.$ua._ua,
            PLATFORM: this.$ua.os() + ' ' + this.$ua.osVersion(),
            MOBILE: this.$ua.isFromPc() ? 'N' : 'Y',
            RESOLUTION: process.client ? [window.screen.availWidth,window.screen.availHeight].join('x') : null,
            USER: {
                ID: this.$auth.loggedIn ? this.$auth.user.id : null
            },
            ERRORS: errors
        };

        if (!state.loading) {
            commit('loading', true);

            this.$api.post(
                url,
                typeof data === 'object' ? JSON.stringify(data) : data,
                {
                    headers: {
                        'Content-Type' : 'application/json' 
                    }
                }
            )
                .finally((response) => {
                    commit('loading', false);
                });
        }
    }
}