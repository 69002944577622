<template>
    <div class="modal-content">

        <transition name="fade">
            <div v-if="loading"
                class="modal-content__preloader">
                <svg-icon
                    class="modal-content__preloader-icon"
                    name="preloader"/>
            </div>
        </transition>

        <div :class="titleClasses"
            class="modal-content__title"
            v-if="$slots.title">

            <slot name="title"></slot>

        </div>

        <div class="modal-content__body"
            :class="bodyClasses"
            @scroll="onScroll"
            ref="modal-body"
            v-if="$slots.body">

            <slot name="body"></slot>

        </div>

        <div class="modal-content__footer"
            v-if="$slots.body"
            :class="footerClasses">

            <slot name="footer"></slot>

        </div>

    </div>
</template>

<script>
import throttle from 'lodash/throttle'

export default {
    name: 'ModalContent',
    props: {
        titleCentered: {
            type: Boolean,
            default: false
        },
        titleBorder: {
            type: Boolean,
            default: false
        },
        footerBorder: {
            type: Boolean,
            default: false
        },
        titleBackground: {
            type: Boolean,
            default: false
        },
        titleWithClose: {
            type: Boolean,
            default: false
        },
        smallBottom: {
            type: Boolean,
            default: false
        },
        withPaddingFooter: {
            type: Boolean,
            default: false
        },
        noPaddingFooter: {
            type: Boolean,
            default: false
        },
        loading: {
            type: Boolean,
            default: false
        },
        noPaddingBody: {
            type: Boolean,
            default: false
        },
        contentOverflowHidden: {
            type: Boolean,
            default: false
        },
        footerShadow: {
            type: Boolean,
            default: false
        },
        grayBody: {
            type: Boolean,
            default: false
        },
        bodyMarginTop: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            isScrolled: false,
        }
    },
    computed: {
        titleClasses() {
            return {
                'modal-content__title_with-border': this.titleBorder,
                'modal-content__title_centered': this.titleCentered,
                'modal-content__title_with-background': this.titleBackground,
                'modal-content__title_with-close': this.titleWithClose,
            }
        },
        footerClasses() {
            return {
                'modal-content__footer_no_padding': this.noPaddingFooter,
                'modal-content__footer_with_padding': this.withPaddingFooter,
                'modal-content__footer_with-shadow': this.footerShadow,
                'modal-content__footer_with-border': this.footerBorder,
            }
        },
        bodyClasses() {
            return {
                'modal-content__body_scrolled': this.isScrolled,
                'modal-content__body_no_padding': this.noPaddingBody,
                'modal-content__body_small_bottom': this.smallBottom,
                'modal-content__body_overflow-hidden': this.contentOverflowHidden,
                'modal-content__body_gray': this.grayBody,
                'modal-content__body_margin-top': this.bodyMarginTop,
            }
        }
    },
    methods: {
        onScroll: throttle(function(e) {
            this.isScrolled = e.target.scrollTop > 0
        }, 150),
        scrollToTop(){
            this.$refs['modal-body'].scrollTop = 0
        }
    }
}
</script>

<style lang="less">
.modal-content {
    display: flex;
    flex-flow: column nowrap;

    height: 100%;

    //overflow: hidden;

    @media @min375 {
        min-width: rem(340);
    }

    @{desktop} & {
        min-width: auto;
    }

    &_signup{
        &+.modal__close{
            right: rem(11);
        }
    }

    &__preloader {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        background: rgba(0, 0, 0, 0.4);
        border-radius: rem(3);

        padding: 0 rem(16);

        position: absolute !important;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1000;
        backdrop-filter: blur(rem(4));
        /* Note: backdrop-filter has minimal browser support */

        &-icon {
            color: @color-white;

            height: rem(24);
            width: rem(24);

            animation: rotate 1.5s linear infinite;
        }
    }

    &__title{
        color: @color-black;
        font-size: rem(18);
        font-weight: 500;

        padding: rem(16) rem(20) rem(11);

        &_centered {

            @{mobile} & {
                padding: rem(12) rem(20) rem(11);
            }
        }

        &_with-close {
            padding-right: rem(30);
        }

        &_with-border {
            border-bottom: 1px solid @color-gray-white;
        }

        &_with-background {
            min-height: rem(144);

            position: relative;

            overflow: hidden;

            &::after {
                content: "";
                display: block;

                background-color: @color-gray-light-2;
                border-radius: 50%;

                height: 120%;
                width: 155%;

                position: absolute;
                top: -20%;
                left: 50%;

                transform: translateX(-50%);
            }
        }
    }

    &__body {
        flex: 1;

        padding: 0 rem(20) rem(24);
        position: relative;
        overflow-y: scroll;

        @{desktop} & {
            overflow-y: auto;

            .scrollbar();
        }

        &:before {
            content: '';
            display: block;

            background: linear-gradient(hsla(0,0%,100%,.6),hsla(0,0%,100%,0) 96%);

            height: rem(23);
            width: 100%;

            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;

            visibility: hidden;
            opacity: 0;

            transition: opacity .15s ease;

            pointer-events: none;

            @{desktop} & {
                display: none;
            }
        }

        &_scrolled:before {
            visibility: visible;
            opacity: 1;
        }

        &_no_padding {
            padding: 0;
        }

        &_small_bottom {
            padding-bottom: rem(12);
            overflow-y: visible;

            @{desktop} &{
                overflow-y: visible;
            }
        }

        &_overflow-hidden {
            overflow: hidden;
        }

        &_gray {
            background-color: @color-bg-gray;
        }

        &_margin-top {
            margin-top: rem(24);
        }
    }

    &__footer {
        .page-padding();

        &_no_padding {
            padding: 0;
        }

        &_with_padding {
            padding-bottom: @dim-page-padding;
        }

        &_with-shadow {
            box-shadow: 0 rem(7) rem(16) rem(8) @color-gray-white;

            position: relative;
        }

        &_with-border {
            border-top: 1px solid @color-gray-white;
        }
    }

    &__note{
        color: @color-gray;
        font-size: rem(13);

        width: rem(300);
    }

    &_subscribe{
        ~.modal__close{
            right: .7333333rem;
        }
    }
}
</style>
