import url from '@/filters/url';

/**
 * Хранилище данных из REST-запросов
 */
export const state = () => ({
    headers: [],
    lastModifiedHeader: null,
    statusCode: null
});

export const getters = {
    get(state, v) {
        return state.headers;
    }
}

export const mutations = {
    add(state, value) {
        state.headers.push(value);
    },
    setLastModifiedHeader(state, value) {
        state.lastModifiedHeader = value;
    },
    setStatusCode(state, value) {
        state.statusCode = value;
    }
};

export const actions = {
    add({commit, state}, value) {
        commit('add', value);
    },
    setStatusCode({commit, state}, value) {
        commit('setStatusCode', value);
    },
    filterLastModified({commit, state}, filter) {

        let value = null;
        let headerUrl;

        for (const i in filter) {
            for (const h in state.headers) {
                if (state.headers[h][i]) {
                    headerUrl = url.directParse(state.headers[h].url).path;
                    if (state.headers[h]['last-modified']
                        && filter[i].includes(headerUrl)) {
                        if (!value 
                            || (new Date(value)).valueOf() < (new Date(state.headers[h]['last-modified'])).valueOf()) {
                            value = state.headers[h]['last-modified'];
                        }
                    }
                }
            }
        }

        commit('setLastModifiedHeader', value);
        return value;
    }
};