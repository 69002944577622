<i18n>
{
    "ru": {
        "no-results": "Нет результатов",
        "add": "Добавить"
    }
}
</i18n>
<template>
    <figure
        :id="selectId"
        class="base-select"
        :class="rootClasses">

        <label
            v-if="label"
            @click="onLabelClick"
            class="base-select__label"
            :class="labelClasses"
            :for="inputId">{{ label }}</label>

        <slot name="label"></slot>

        <div
            v-click-outside="onBlur"
            class="base-select__wrapper"
            v-on="(isInputReadonly || isFlatMode) ? { click: onFocus } : {}">

            <BaseInput
                :mode="inputMode"
                ref="base-input"
                class="base-select__input"
                :class="baseSelectInputClasses"
                v-model="inputValue"
                :id="inputId"
                :disabled="disabled"
                :readonly="isInputReadonly || isFlatMode"
                v-on="!isInputReadonly ? { focus: onFocus } : {}"
                @click.native="onClick"
                cursor-to-end
                :rules="rules"
                :placeholder="placeholder"
                :isBigPhoto="isBigPhoto"
                :autocomplete="'off'"
                :isColorSelect="selectedOptionColorType"
                :replace-emoji="replaceEmoji">

                <div v-if="selectedOptionColorType"
                    slot="icon"
                    :class="'base-select__selected-icon-type_color-' + computedSelectedIconClass"
                    class="base-select__selected-icon-type_color">
                </div>

                <img
                    v-if="selectedOptionPicture"
                    ref="selectedPicture"
                    slot="icon"
                    class="base-select__input-icon"
                    :class="iconClasses"
                    v-lazy-load :data-src="selectedOptionPicture"
                    :alt="selectedOptionName"/>

                <button
                    @click.prevent.stop="triggerExpanded(selectedOption)"
                    class="base-select__button"
                    :data-loading="(apiLoading && inputValue.length > 2) || isLoading"
                    slot="button"
                    :class="{'base-select__button_close': isShowClose && !disabled}">

                    <svg-icon v-if="isShowClose && !disabled" name="icon-close-color"/>
                    <svg-icon v-else-if="isShowChevron" name="icon-chevron-down"/>

                </button>

            </BaseInput>

            <component :is="mobileTransition ? 'TransitionExpand' : 'div'">
                <fieldset
                    class="base-select__options"
                    :class="fieldsetClasses"
                    v-if="expanded">

                    <div v-if="paginated">
                        <PaginatedList
                            class="base-select__list"
                            :class="listClasses"
                            :filtered-items="filteredItems"
                            :q="paginatedQuery"
                            :search-by="searchBy"
                            :list="list"
                            :empty-text="emptyText">

                            <template v-slot:item="option">

                                <BaseCheckbox
                                    class="base-select__option"
                                    :class="checkboxClasses"
                                    no-check
                                    :transparent="isCheckboxTransparent"
                                    @input="onOptionChange(option)"
                                    :picture="get(option, 'picture.url')"
                                    :value="get(option, 'code')">

                                    <slot
                                        name="option"
                                        v-bind="option">

                                        {{ get(option, 'name') }}

                                    </slot>

                                </BaseCheckbox>

                            </template>

                        </PaginatedList>

                        <span v-if="(showAddOption && inputValue && isAddOptionActive) || (inputValue && forceAddOptionButton)"
                            class="base-select__add"
                            @click.prevent="addOption">
                            {{ $t('add') }} “{{ inputValue }}”
                        </span>
                    </div>

                    <div class="base-select__list"
                        v-else-if="isFlatMode">
                        <BaseCheckbox
                            v-for="option in options"
                            :key="option"
                            :class="isOptionSelected(option)"
                            class="base-select__option"
                            :transparent="isCheckboxTransparent"
                            no-check
                            @input="onOptionChange(option)"
                            :value="option">

                            <slot
                                name="option"
                                :option="option">

                                {{ option }}

                            </slot>

                        </BaseCheckbox>
                    </div>

                    <div v-else
                        @scroll="onScrollList"
                        class="base-select__list">

                        <BaseCheckbox
                            v-for="option in filteredOptions"
                            :class="isOptionSelected(option)"
                            :key="option[primaryKey]"
                            class="base-select__option"
                            :transparent="isCheckboxTransparent"
                            no-check
                            @input="onOptionChange(option)"
                            :picture="get(option, 'picture.url')"
                            :value="get(option, 'code')">

                            <slot
                                name="option"
                                :option="option">
                                {{ get(option, 'name') }}
                            </slot>

                        </BaseCheckbox>

                        <span v-if="(showAddOption && inputValue && isAddOptionActive) || (inputValue && forceAddOptionButton)"
                            class="base-select__add"
                            @click.prevent="addOption">
                            {{ $t('add') }} “{{ inputValue }}”
                        </span>
                    </div>

                <!--                 <div class="base-select__option" -->
                <!--                     v-if="!filteredOptions.length"> -->

                <!--                     <slot name="no-results"> -->
                <!--                         {{ $t('no-results') }} -->
                <!--                     </slot> -->

                <!--                 </div> -->

                </fieldset>
            </component>
        </div>

    </figure>
</template>

<script>
import throttle from 'lodash/throttle';
import { mapGetters } from 'vuex';
import ClickOutside from 'vue-click-outside'

import uniqueId from 'lodash/uniqueId'
import get from 'lodash/get'
import includes from 'lodash/includes'
import filter from 'lodash/filter'
import toLower from 'lodash/toLower'

import PaginatedList from '@/components/ui/PaginatedList';

export default {
    name: 'BaseSelect',
    directives: { ClickOutside },
    components: {
        PaginatedList
    },
    model: {
        prop: 'value',
        event: 'change'
    },
    props: {
        searchBy: {
            type: String,
            default: null
        },
        filteredItems: {
            type: Array,
            default: () => null
        },
        value: {
            type: [String, Number, Object, Array],
            default: null
        },
        rules: {
            type: String,
            default: null
        },
        showAddOption: {
            type: Boolean,
            default: false
        },
        mode: {
            type: String,
            default: 'normal',
            validator(value) {
                return includes(['search', 'normal', 'insert', 'flat', 'insertSearch'], value)
            }
        },
        placeholder: {
            type: String,
            default: null
        },
        options: {
            type: Array,
            default: null
        },
        list: {
            type: Object,
            default: null
        },
        label: {
            type: String,
            default: null
        },
        showIcon: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        isBigPhoto: {
            type: Boolean,
            default: false
        },
        iconPrefix: {
            type: String,
            default: ''
        },
        primaryKey: {
            type: String,
            default: 'code'
        },
        disableFilter: {
            type: Boolean,
            default: false
        },
        optionsBorder: {
            type: Boolean,
            default: false
        },
        titleName: {
            type: String,
            default: 'name'
        },
        emptyText: {
            type: String,
            default: ''
        },
        noCloseButton: {
            type: Boolean,
            default: false
        },
        mobileTransition: {
            type: Boolean,
            default: true
        },
        returnObject: {
            type: Boolean,
            default: false
        },
        isInsertInput: {
            type: Boolean,
            default: false
        },
        isLoading: {
            type: Boolean,
            default: false
        },
        trimValue: {
            type: Boolean,
            default: false
        },
        encodeValue: {
            type: Boolean,
            default: false
        },
        replaceEmoji: {
            type: Boolean,
            default: false
        },
        inputMode: {
            type: String,
            default: 'eager'
        },
        forceAddOptionButton: {
            type: Boolean,
            default: false
        },
        isPhoneCodes: {
            type: Boolean,
            default: false
        }
    },
    globalBus: {
        'dropdown-empty'(toggle){
            this.isAddOptionActive = !toggle
        }
    },
    data() {
        return {
            apiLoading: false,
            expanded: false,
            q: null,
            selectId: null,
            selectedOption: null,
            buttonLoading: false,
            showedItems: 50,
            hasErrors: false,
            isAddOptionActive: false
        }
    },
    computed: {
        ...mapGetters(['isDeviceDesktop', 'getUserNoImageLarge']),
        labelClasses() {
            return {
                'base-input__label_required': this.required && this.label
            }
        },
        required() {
            return includes(this.rules, 'required')
        },
        baseSelectInputClasses() {
            return {
                'base-select__input_pointer': this.isFlatMode || this.isNormalMode
            }
        },
        isFlatMode() {
            return this.mode === 'flat';
        },
        computedSelectedIconClass() {
            if(this.iconPrefix) {
                return this.iconPrefix + '-' + this.selectedKey
            }

            return this.selectedKey;
        },
        selectedKey() {
            return get(this.selectedOption, `[${this.primaryKey}]`)
        },
        listClasses() {
            return {
                'base-select__list_searched': this.isSearchMode
            }
        },
        isShowChevron() {
            if (this.isNormalMode || this.isInsertMode || this.isFlatMode) {
                return !this.isShowClose
            }

            return null
        },
        isShowClose() {

            if(this.noCloseButton)
                return false

            if ((this.isNormalMode || this.isInsertMode) && !this.isInsertInput) {
                return !!this.selectedOption
            }

            if (this.isSearchMode || this.isInsertInput) {
                return this.inputValue && this.inputValue.length
            }

            return false
        },
        inputId() {
            return this.selectId + '-input'
        },
        isCheckboxTransparent() {
            return !!get(this.$scopedSlots, 'option', false)
        },
        paginated() {
            return !!this.list
        },
        isInputReadonly() {
            return this.isNormalMode
        },
        isNormalMode() {
            return this.mode === 'normal'
        },
        isSearchMode() {
            return this.mode === 'search'
        },
        isInsertMode() {
            return this.mode === 'insert' || this.mode === 'insertSearch'
        },
        rootClasses() {
            return {
                'base-select_expanded': this.expanded,
                'base-select_disabled': this.disabled,
                'base-select_flags': this.isPhoneCodes
            }
        },
        checkboxClasses() {
            return {
                'base-select__option_transparent': this.isCheckboxTransparent,
            }
        },
        iconClasses() {
            return {
                'base-select__input-icon_photo': this.isBigPhoto
            }
        },
        fieldsetClasses() {
            return {
                'base-select__options_errors': this.hasErrors,
                'base-select__options_with-add': this.showAddOption
            }
        },
        paginatedQuery() {
            if(this.encodeValue)
                return this.inputValue ? encodeURIComponent(this.inputValue) : '';

            return this.inputValue;
        },
        inputValue: {
            get() {
                return this.q
            },
            set(value) {
                if (this.isSearchMode || this.mode === 'insertSearch') {
                    const isExpanded = value && value.length >= 2

                    this.expand(isExpanded, this.isDeviceDesktop)
                }

                this.q = value
                this.$emit('input', value)
            }
        },
        selectedOptionPicture() {
            if (!this.selectedOption) return

            if(this.isPhoneCodes)
                return get(this.selectedOption,'flag.url');

            return get(
                this.selectedOption,
                'photo.url',
                this.isBigPhoto ? this.getUserNoImageLarge : undefined
            )
        },
        selectedOptionColorType() {
            return get(this.selectedOption, 'code') && this.showIcon
        },
        selectedOptionName() {
            return get(this.selectedOption, this.titleName)
        },
        filteredOptions() {
            if(this.disableFilter) return this.options;

            const filterCallback = (this.q && this.mode === 'insert') ?
                o => {
                    return includes(
                        toLower(o.name),
                        toLower(this.trimValue ? this.inputValue.trim() : this.inputValue)
                    )
                } :
                o => o[this.primaryKey] !== this.selectedKey

            const options = filter(this.options, filterCallback)

            return options.slice(0, this.showedItems);
        }
    },
    watch: {
        inputValue(value) {
            if(value < 1) {
                this.selectedOption = this.q = null

                if(this.$refs.selectedPicture) {
                    this.$refs.selectedPicture.remove()
                }
            }
        },
        filteredOptions(value){
            if(!this.list)
                this.isAddOptionActive = value.length < 1
        }
    },
    created() {
        if(this.isFlatMode) {
            if(this.options && this.options.length) {
                this.selectedOption = this.options.find(o => parseInt(o) === parseInt(this.value));
            }
            this.q = this.selectedOption;
        }
        else if(this.isPhoneCodes){
            this.selectedOption = this.value;
            this.q = this.selectedOption.phoneCode;
        }
        else if(this.isInsertMode && !this.options) {
            const listItems = get(this.list, 'items', [])
            this.selectedOption = listItems.find(o => o[this.primaryKey] === this.value);
            this.q = get(this.selectedOption);
        }
        else{
            if(this.options && this.options.length) {
                this.selectedOption = this.options.find(o => o[this.primaryKey] === get(this, 'value.id', this.value));
            }
            this.q = get(this.selectedOption, this.titleName);
        }
    },
    mounted() {
        this.selectId = 'select-' + uniqueId()
        this.$bus.$on('reset', () => {
            this.onReset()
        })
    },
    methods: {
        get,
        isOptionSelected(option) {
            let condition = false;

            if(this.isFlatMode) {
                condition = option === this.selectedOption
            }
            else if(option[this.primaryKey] &&
                this.selectedOption && this.selectedOption[this.primaryKey] &&
                this.mode !== 'insert'){
                condition = option[this.primaryKey] === this.selectedOption[this.primaryKey]
            }
            return {
                'option-selected': condition,
                'base-select__option_bordered': this.optionsBorder,
                'base-select__option_simple': !get(option, 'picture.url')
                    || !get(this.iconPrefix, 'length', 0) > 0
            }
        },
        onScrollList: throttle(function(e) {
            if (e.target.scrollTop + e.target.clientHeight >= e.target.scrollHeight - 100) {
                this.showedItems += 50;
            }
        }, 200),
        onLabelClick(e) {
            if (this.expanded) e.preventDefault()
        },
        addOption() {
            this.$emit('addOption', {
                value: this.inputValue
            });
            this.expand(false, true);
            this.showedItems = 50;
        },
        onOptionChange(option) {

            if (this.isFlatMode) {
                this.selectedOption = option;
                this.q = option
                this.$emit('change', option)
            }

            else if (this.isPhoneCodes){
                this.selectedOption = option;
                this.q = option.phoneCode;
                this.inputValue = option.phoneCode;
                this.$emit('change', option);
            }

            else if (this.returnObject){
                this.selectedOption = option
                this.q = get(option, this.titleName);
                this.$emit('change', option)
            }

            else if (this.isNormalMode || this.isInsertMode) {
                /* Устанавливаем выбранный option */
                this.selectedOption = option

                /* Строка запроса = имя option */
                this.q = get(option, this.titleName)

                /* Эмитим код или объект user option */
                if (option.id && !option.code) {
                    this.$emit('change', option)
                } else {
                    this.$emit('change', get(option, 'code'))
                }
            } else {
                this.q = ''
                /* Эмитим код option */
                this.$emit('change', option)
            }

            this.onBlur();
        },
        onBlur() {
            if (this.expanded) {
                this.showedItems = 50;

                if (!this.isInsertInput){

                    if(!this.selectedOption) {
                        this.q = null;
                    }
                    else if(this.isFlatMode){
                        this.q = this.selectedOption
                    }
                    else {
                        this.q = this.isPhoneCodes ? this.selectedOption.phoneCode : get(this.selectedOption, this.titleName)
                    }

                }
                this.expand(false, true);
            }
        },
        onFocus() {
            if (this.isSearchMode && !this.isShowClose) {
                return
            }

            this.expand(!this.expanded, true)
        },
        onClick(){
            this.$emit('onClick')
        },
        expand(expanded, noScroll) {
            if (this.isInputReadonly || noScroll) {
                setTimeout(() => {
                    this.expanded = expanded
                }, 100)
            } else {
                this.$scrollTo('#' + this.selectId, {
                    offset: -62,
                    onStart: () => {
                        this.expanded = expanded
                    }
                })
            }

        },
        triggerExpanded(selected) {
            if (this.isSearchMode) {
                this.expanded = false
                this.q = null
            } else if(this.isInsertInput){
                if(this.isShowClose){
                    this.expanded = false
                    this.onReset()
                }else{
                    this.expanded = !this.expanded
                }
            } else if(selected === null || selected === undefined || this.isFlatMode || this.noCloseButton) {
                this.expanded = !this.expanded
            } else {
                this.selectedOption = this.q = null
                this.$emit('reset')
            }
        },
        onReset() {
            this.selectedOption = this.q = null
            this.$emit('reset')
        },
        loadingData(isLoading) {
            this.buttonLoading = isLoading
        }
    }
}
</script>

<style lang="less">
.base-select {
    position: relative;

    &_required{
        .base-select__label{
            padding-right: rem(8);

            position: relative;

            &:after {
                content: '*';

                color: @color-red;
                font-size: rem(14);

                position: absolute;
                top: rem(-3);
                right: 0;
            }
        }
    }

    .option-selected {
        background: @color-gray-light-2;
    }

    .base-input + &,
    & + & {
        margin-top: rem(24);
    }

    &_disabled {
        pointer-events: none;

        .base-input{
            &__button{
                color: @color-gray-light;
            }

            &__input{
                opacity: 1;
                background-color: @color-bg-gray-minor;
                color: @color-gray-light;
            }
        }
    }

    &__selected-icon-type_color {
        border-radius: rem(3);

        height: rem(24);
        width: rem(24);

        position: relative;
        right: rem(8);

        .userSkinColorTypes();

        // для цветотипов
        &-eye {
            .userEyeColorTypes();
        }

        &-hair {
            .userHairColorTypes();
        }
    }

    &__list {
        max-height: 50vh;

        overflow: auto;

        .scrollbar();

        &::-webkit-scrollbar {
            display: none;
        }

        &::-webkit-scrollbar-track {
            display: none;
        }

        .paginated-list__wrap {
            padding-bottom: rem(150);

            max-height: 100%;

            position: static;

            &.is-full {
                padding-bottom: 0;
            }

            &.is-empty {
                text-align: center;
                padding: rem(20) 0;
            }

        }

        &:after,
        &:before {
            position: fixed;
        }

        &:after {
            background-position: 50% calc(100% - rem(17));
        }

    }

    &__list_searched &__option {

        @{desktop} & {
            padding-right: @dim-page-padding;
            padding-left: @dim-page-padding;
        }
    }

    &__label {
        color: @color-gray;
        font-weight: 500;

        margin-bottom: rem(1.5);

        &_required {
            padding-right: rem(8);

            position: relative;

            &:after {
                content: '*';

                color: @color-red;
                font-size: rem(14);

                position: absolute;
                top: rem(-3);
                right: 0;
            }
        }
    }

    &__button {
        display: flex;
        align-items: center;
        justify-content: center;

        background: none;
        border: none;
        outline: none;

        height: rem(44);
        width: rem(45);

        transition: transform .15s ease;

        svg {
            height: rem(12);
            width: rem(18);

            transition: opacity .35s ease;
        }

        &[data-loading] {
            background: url('~static/img/loading-sm.gif') no-repeat;
            background-position: center;

            svg {
                opacity: 0;
            }
        }

        &_close{
            .hover({
                svg{
                    opacity: .4;
                }
            })
        }
    }

    &__input {
        margin-bottom: 0;

        &-icon {
            --picture-size: rem(16);

            display: block;

            border-radius: 50%;

            height: var(--picture-size);
            width: var(--picture-size);

            z-index: 1;

            &_photo {
                --picture-size: rem(32);
            }
        }

        &_pointer {
            @{desktop} & {
                cursor: pointer;
            }
        }

        input{
            &:read-only,
            &:disabled{
                pointer-events: none;
            }
        }
    }

    &__options {
        background: @color-white;
        border-radius: rem(3);
        box-shadow: 0 rem(3) rem(16) rgba(0, 0, 0, 0.08);

        // margin-top: rem(6);

        width: 100%;

        position: absolute !important;
        top: rem(83);
        z-index: 90;

        &_errors {
            top: calc(100% - rem(22));
        }

        &.expand-enter-active,
        &.expand-leave-active {
            overflow: hidden;

            transition: height .2s ease, opacity .3s ease;
        }
        &.expand-enter,
        &.expand-leave-to {
            height: 0;

            opacity: .4;

            transition: height .3s ease, opacity .3s ease;
        }
    }

    &__add{
        font-size: rem(15);
        display: block;
        padding: rem(12) rem(20);
        cursor: pointer;
        color: @color-violet;
    }

    &__option {
        --picture-size: rem(16);

        margin: 0;
        padding-top: rem(13);
        padding-bottom: rem(12);

        position: relative;
        .page-padding();

        .hover({
            background: @color-gray-light-2;
            cursor: pointer;
        });

        @{desktop} & {
            .base-checkbox__transparent {
                width: 100%;
            }

            .base-unit__wrapper {
                padding-right: rem(20);
                padding-left: rem(20);
            }
        }

        &_transparent {
            padding-top: rem(6);
            padding-bottom: rem(6);
        }

        &_bordered {
            border-bottom: rem(1) solid @color-gray-white;
        }

        &_simple {
            padding-right: rem(20);
            padding-left: rem(20);

            @{desktop} & {
                padding-right: rem(20);
                padding-left: rem(20);
            }
        }
    }

    &_flags{
        .base-select{
            &__option{
                padding-top: 0;
                padding-bottom: 0;
                box-shadow: inset 0 rem(-1) 0 @color-gray-white;
                width: 100%;
            }
        }

        .base-checkbox{
            &__content-wrapper{
                flex: 1;
            }
        }

        .base-unit{
            padding: rem(12) 0;

            &__wrapper{
                @{desktop} & {
                    .hover({
                        box-shadow: none;
                    });
                }
            }

            &__picture-wrapper,
            &__picture{
                min-width: unset;
                width: rem(18);
                height: rem(18);
            }
        }
    }
}
</style>
