/* eslint-disable no-lonely-if */
/* eslint-disable unicorn/prefer-includes */

import { isScrollable } from './tools';

export default {

    onReady() { 
        window.addEventListener("touchstart", this.onTouchstart, { passive: false });
        window.addEventListener("touchmove", this.onTouchmove, { passive: false });
    },

    onTouchstart(event) {
        this.startY = event.touches ? event.touches[0].screenY : event.screenY;
    },

    onTouchmove(event) {
        const startY = this.startY;
        const zoom = window.innerWidth / window.document.documentElement.clientWidth;

        if (!event.cancelable) {
            return;
        }

        if (event.touches.length > 1 || zoom !== 1) {
            return;
        }

        const el = isScrollable(event.composedPath()[0]);

        if (!el) {
            event.preventDefault();
            return;
        }

        if(el === 'textarea'){
            return
        }

        const style = window.getComputedStyle(el);
        const height = parseInt(style.getPropertyValue("height"), 10);
  
        const curY = event.touches ? event.touches[0].screenY : event.screenY;
  
        const isAtTop = startY <= curY && el.scrollTop === 0;
        const isAtBottom = startY >= curY && el.scrollHeight - el.scrollTop === height;

        if (isAtTop || isAtBottom) {
            event.preventDefault();
        }
    },

    attach() {
        this.onReady();
    },

    detach() {
        window.removeEventListener("touchstart", this.onTouchstart, false);
        window.removeEventListener("touchmove", this.onTouchmove, false);
    }
}