<template>
    <div>
        <template v-if="custom">
            <button type="button"
                @click="onButtonClick">
                <slot></slot>
            </button>
        </template>
        <ButtonCircle
            v-else
            @click.native.prevent="onButtonClick"
            class="button-social"
            :data-type="name"
            :icon="getIconName">
            <slot></slot>
        </ButtonCircle>
    </div>
</template>

<script>
import includes from 'lodash/includes'
import ButtonCircle from '@/components/ui/buttons/ButtonCircle'

export default {
    name: 'ButtonSocial',
    components: {
        ButtonCircle
    },
    props: {
        name: {
            type: String,
            default: '',
        },
        rounded: {
            type: Boolean,
            default: true
        },
        theme: {
            type: String,
            default: 'regular',
            validator(value) {
                return includes(['regular', 'light'], value)
            }
        },
        title: {
            type: String,
            default: ''
        },
        text: {
            type: String,
            default: ''
        },
        url: {
            type: String,
            default: ''
        },
        fullUrl: {
            type: String,
            default: ''
        },
        image: {
            type: String,
            default: ''
        },
        utmSource: {
            type: String,
            default: ''
        },
        utmMedium: {
            type: String,
            default: ''
        },
        utmCampaign: {
            type: String,
            default: ''
        },
        custom: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        classes() {
            return {
                'button-social_rounded': this.rounded
            }
        },
        iconNameAdapter() {
            switch (this.name) {
            case 'facebook':
                return 'fb';
            case 'instagram':
                return 'ins';
            default:
                return this.name
            }
        },
        getIconName() {
            let iconName = `icon-social-${this.iconNameAdapter}`

            if (this.rounded) {
                iconName += '-circle'
            }

            // if (this.theme === 'light') {
            //     iconName += '-light'
            // }

            return iconName
        },
        getSocialLink() {
            return this[`${this.name}Link`]
        },
        vkLink() {
            return 'https://vk.com/share.php'
                + '?url=' + this.getUrl
                + '&title=' + this.encTitle
                + '&description=' + this.encText
                + '&image=' + this.encImage
        },
        fbLink() {
            return 'https://www.facebook.com/sharer.php'
                + '?u=' + this.getUrl
        },
        twitterLink() {
            return 'https://twitter.com/share'
                + '?text=' + this.encTitle
                + '&url=' + this.getUrl
                + '&description' + this.encText
                + '&imageurl=' + this.encImage
        },
        okLink() {
            return 'https://connect.ok.ru/offer'
                + '?url=' + this.getUrl
                + '&title=' + this.encTitle
                + '&description=' + this.encText
                + '&imageUrl=' + this.encImage
        },
        getUrl() {
            let url = this.url ?
                this.url :
                location.Link

            let utm = ''

            if (this.utmSource !== '') {
                utm += '&utm_source=' + this.utmSource
            }
            if (this.utmMedium !== '') {
                utm += '&utm_medium=' + this.utmMedium
            }
            if (this.utmCampaign !== '') {
                utm += '&utm_campaign=' + this.utmCampaign
            }

            if (utm !== '') {
                url = url + '?' + utm
            }

            return url
        },
        encTitle() {
            return encodeURIComponent(this.title)
        },
        encText() {
            return encodeURIComponent(this.text)
        },
        encImage() {
            return encodeURIComponent(this.image)
        }
    },
    methods: {
        onButtonClick() {
            if(this.fullUrl) {
                this.goToLink(this.fullUrl)
            }

            else {
                this.goToLink(this.getSocialLink)
            }
        },
        goToLink(link) {
            window.open(link)
        },
    },
}
</script>

<style lang="less">
.button-social {
    --size: rem(44);
    margin: 0 rem(6);
    padding: 0;

    border: 1px solid @color-black;
    border-radius: rem(50);
    color: @color-black;

    @{mobile} & {
        margin: 0 rem(8);
    }

    .button-circle__icon{
        max-height: rem(20);
        max-width: rem(20);
    }

    &[data-type="vk"] {
        border: 1px solid #4C6C91;
        color: #4C6C91;

        .hover({
            background-color: #4C6C91;
            color: @color-white;
        });
    }

    &[data-type="facebook"],
    &[data-type="fb"]{
        border: 1px solid #3B5998;
        color: #3B5998;

        .hover({
            background-color: #3B5998;
            color: @color-white;
        });
    }

    &[data-type="gmail"] {
        border: 1px solid #B0B0B0;

        .hover({
            background-color: #F43B3B;
            border: 1px solid #F43B3B;
            color: @color-white;
        });
    }

    &[data-type="twitter"] {
        border: 1px solid #55ACEE;
        color: #55ACEE;

        .hover({
            background-color: #55ACEE;
            color: @color-white;
        });
    }

    &[data-type="ok"] {
        border: 1px solid #F68634;
        color: #F68634;

        .hover({
            background-color: #F68634;
            color: @color-white;
        });
    }

    &[data-type="mail"] {
        border: 1px solid @color-gray-light;
        color: @color-gray-light;

        .hover({
            background-color: @color-gray-light;
            color: @color-white;
        });
    }

    &[data-type="instagram"]{
        color: #E14478;
        border-color: #E14478;

        .hover({
            background-color: #E14478;
            color: @color-white;
        });
    }
}
</style>
