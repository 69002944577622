import get from 'lodash/get'

export default ({ $auth, params, route, redirect, app, next, store }) => {
    const isDeviceDesktop = store.getters.isDeviceDesktop

    return app.$api.get('/services/ads/banners', {
        params: {
            type: isDeviceDesktop ? 'TOP_WIDE_DESKTOP' : 'TOP_WIDE_MOBILE'
        }
    }).then(result => {
        store.commit('setHeaderBanner', get(result, 'data.html', null))
    }).catch(e => {})
}
