(function() {
    document.oncopy = function(e) {

        if(e.target.id === 'share-link' || (e.target.parentNode.localName === 'body' && e.target.localName === 'textarea'))
            return

        const bodyElement = document.body;
        const selection = getSelection();
        const href = document.location.href;
        const copyright = "<a href='"+ href +"' style=\"text-indent: -9999px;\">" + href + "</a>";
        const text = selection + ' ' + copyright;
        const divElement = document.createElement('div'); 

        divElement.style.position = 'absolute'; 
        divElement.style.left = '-99999px'; 
        divElement.innerHTML = text; 
        bodyElement.appendChild(divElement); 
        selection.selectAllChildren(divElement); 
        
        setTimeout(function() { 
            bodyElement.removeChild(divElement); 
        }, 0); 
    }; 
})();