/* eslint-disable no-useless-constructor */
import Model from '@/models/Model'
import Alphabet from '@/models/catalog/Alphabet';
import News from '@/models/catalog/News';
import Perfume from '@/models/catalog/perfume';
import Article from '@/models/catalog/Article';
import Page from '@/models/catalog/Page';
import Like from '@/models/services/like';

export default class Perfumer extends Model {
    resource() {
        return 'perfumers'
    }

    primaryKey() {
        return 'code'
    }

    perfumes() {
        return this.hasMany(Perfume);
    }

    perfumersAlphabet() {
        return this.hasMany(Alphabet)
    }

    userLikes() {
        return this.hasMany(Like)
    }

    relatedNews() {
        return this.hasMany(News, 'related-content')
    }

    relatedArticles() {
        return this.hasMany(Article, 'related-articles')
    }

    relatedPages() {
        return this.hasMany(Page, 'related-pages')
    }

    filters() {
        return this.request('/services/filters/perfumers/fields')
    }
}
