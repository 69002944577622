/* eslint-disable no-useless-constructor */

// ! Некорректная модель аккаунтов, рекомендуется использовать accounts/Accounts.js

import Model from '@/models/Model'
import Shelf from '@/models/users/accounts/Shelf'
import Profile from '@/models/users/accounts/Profile'
import CommentRating from '@/models/users/CommentRating'
import Subscription from '@/models/tags/Subscription'
import Follower from "~/models/users/accounts/Follower";
import FollowRequest from "~/models/users/accounts/FollowRequest";
import Guest from "~/models/users/accounts/Guest";
import Milliliter from "~/models/users/accounts/Milliliter";

export default class Account extends Model {

    resource() {
        return 'users/accounts'
    }

    primaryKey() {
        return 'id'
    }

    commentsRatings() {
        return this.hasMany(CommentRating)
    }

    subscriptions() {
        return this.hasMany(Subscription)
    }

    subscriptionsRemove(id) {
        return this.hasMany(Subscription, `subscriptions/${id}`)
    }

    shelf() {
        return this.hasMany(Shelf)
    }

    shelfPerfumesCollections() {
        return this.hasMany(Shelf, 'perfume-shelves')
    }

    shelfPerfumes() {
        return this.hasMany(Shelf, 'perfume-shelves/perfumes')
    }

    profile() {
        return this.hasMany(Profile, 'profile')
    }

    followers() {
        return this.hasMany(Follower)
    }

    followersRequest() {
        return this.hasMany(FollowRequest)
    }

    followersRequestAdd(id) {
        return this.hasMany(FollowRequest, `follow-requests/${id}/followers`)
    }

    followersRequestRemove(id) {
        return this.hasMany(FollowRequest, `follow-requests/${id}`)
    }

    guests() {
        return this.hasMany(Guest)
    }

    milliliters() {
        return this.hasMany(Milliliter)
    }

    millilitersExchange() {
        return this.hasMany(Milliliter, 'ml/exchange')
    }

    millilitersDonate() {
        return this.hasMany(Milliliter, 'ml/donate')
    }

    entitiesViewHistory(params) {
        return this.request({
            method: 'GET',
            url: this.endpoint() + '/view-history',
            params
        }).then(response => {
            if(response) {
                return response.data;
            }
        });
    }

    setPerfumeStatus(params) {

        return this.request({
            method: 'POST',
            url: this.endpoint() + '/status-perfumes',
            data: params
        }).then(response => {
            if(response) {
                return response.data;
            }
        });
    }

    unsetPerfumeStatus() {
        return this.request({
            method: 'DELETE',
            url: this.endpoint() + '/status-perfumes'
        }).then(response => {
            if(response) {
                return response.data;
            }
        });
    }
}
