<template>
    <div class="page-block"
        :id="id"
        :class="rootClasses">

        <header class="page-block__header"
            :class="headerClasses"
            v-if="title.length">
            
            <component 
                :is="headingTag"
                class="page-block__title page-block__title_selectable"
                v-if="titleSelectable"
                @click="$emit('select-click')">
                {{ title }}
                <svg-icon name="icon-chevron-down"
                    class="page-block__title-select-icon"/>
            </component>

            <div class="page-block__title-wrapper"
                v-else-if="counterInTitle">
                <!--eslint-disable-next-line-->
                <component
                    :is="headingTag"
                    class="page-block__title"
                    :class="titleClasses">
                    {{ title }}
                    <span class="page-block__title-caption">{{ titleCaption }}</span>
                </component>

                <!-- eslint-disable-next-line -->
                <span v-html="counter"
                    class="page-block__counter"
                    v-if="counter"
                    :class="counterClasses">
                </span>
            </div>

            <!-- eslint-disable-next-line -->
            <component v-html="title"
                v-else
                :is="headingTag"
                class="page-block__title"
                :class="titleClasses" />

            <div class="page-block__title-select"
                @click="$emit('select-click')"
                v-if="$slots['title-select'] && !titleSelectable">
                <slot name="title-select"></slot>
                <svg-icon name="icon-chevron-down"
                    class="page-block__title-select-icon"/>
            </div>

            <HelpButton
                class="page-block__help-button"
                v-if="tooltipTitle || tooltipContent"
                :title="tooltipTitle"
                :content="tooltipContent"
                :tooltip-auto-hide="tooltipAutoHide" />

            <img class="page-block__icon"
                v-if="titlePicture"
                v-lazy-load :data-src="titlePicture" />

            <!--eslint-disable-next-line-->
            <span v-html="counter"
                class="page-block__counter"
                v-if="counter && !counterInTitle"
                :class="counterClasses">
            </span>

            <div class="page-block__title-button" v-if="$slots['title-button']">
                <slot name="title-button"></slot>
            </div>

        </header>

        <div class="page-block__caption-wrap" v-if="captionCount !== null">
            <p class="page-block__caption-count">{{ captionCount }}</p>
            <p v-if="caption" class="page-block__caption">{{ caption }}</p>
        </div>

        <p v-else-if="caption" class="page-block__caption">{{ caption }}</p>
        <p v-else-if="bottomCaption" class="page-block__bottom-caption">{{ bottomCaption }}</p>

        <ShowMore
            v-if="description || $slots.description"
            :collapse-to="collapseTo"
            :collapse-from="collapseTo"
            class="page-block__description-wrapper"
            :class="descriptionWrapperClasses"
            :maxHeight="descriptionMaxHeight"
            :type="descriptionTypeShow"
            :max-rows="maxRows">

            <!-- eslint-disable-next-line -->
            <p v-html="description"
                class="page-block__description html-content"
                v-if="description"
                :class="descriptionClasses"
                :id="descriptionId"></p>

            <div class="page-block__description"
                v-else-if="$slots.description"
                :class="descriptionClasses"
                :id="descriptionId">
                <slot name="description"></slot>
            </div>
        </ShowMore>

        <div class="page-block__content"
            :class="contentClasses" >
            <slot ></slot>
        </div>
        <div v-if="$slots.footer" class="page-block__footer">
            <slot name="footer"></slot>
        </div>
        <div v-else-if="footerLink" class="page-block__footer">
            <html-link class="page-block__footer-link" :url="footerLink">
                {{ footerLinkName }}
                <svg-icon :name="footerLinkIcon" class="page-block__footer-link-icon" />
            </html-link>
        </div>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
import HelpButton from '@/components/ui/HelpButton'

export default {
    name: 'PageBlock',
    components: {
        HelpButton
    },
    props: {
        counterInTitle: {
            type: Boolean,
            default: false
        },
        id: {
            type: String,
            default: null
        },
        title: {
            type: [String, Boolean],
            default: ''
        },
        titleCaption: {
            type: String,
            default: null
        },
        bottomCaption: {
            type: String,
            default: null
        },
        titlePicture: {
            type: String,
            default: ''
        },
        titleSelectable: {
            type: Boolean,
            default: false
        },
        tooltipTitle: {
            type: String,
            default: ''
        },
        tooltipContent: {
            type: String,
            default: ''
        },
        noMargin: {
            type: Boolean,
            default: false
        },
        smallHeader: {
            type: Boolean,
            default: false
        },
        profile: {
            type: Boolean,
            default: false
        },
        tags: {
            type: Boolean,
            default: false
        },
        descriptionPadding: {
            type: Boolean,
            default: false
        },
        bigTitle: {
            type: Boolean,
            default: false
        },
        smallTitle: {
            type: Boolean,
            default: false
        },
        smallDescription: {
            type: Boolean,
            default: false
        },
        grayDescription: {
            type: Boolean,
            default: false
        },
        profileDescription: {
            type: Boolean,
            default: false
        },
        contentVisible: {
            type: Boolean,
            default: false
        },
        contentPadding: {
            type: Boolean,
            default: false
        },
        maxRows: {
            type: [Number, String],
            default: 6
        },
        counter: {
            type: [Number, String],
            default: ''
        },
        description: {
            type: String,
            default: ''
        },
        caption: {
            type: [String, Number],
            default: ''
        },
        captionCount: {
            type: [Number, String],
            default: null
        },
        footerLink: {
            type: String,
            default: null
        },
        footerLinkName: {
            type: String,
            default: null
        },
        footerLinkIcon: {
            type: String,
            default: null
        },
        regionsDescription: {
            type: Boolean,
            default: false
        },
        descriptionMaxHeight: {
            type: Number,
            default: null
        },
        descriptionTypeShow: {
            type: String,
            default: 'text'
        },
        profileContentWidth: {
            type: Boolean,
            default: false
        },
        headingTag: {
            type: String,
            default: 'h2'
        },
        tooltipAutoHide: {
            type: Boolean,
            default: true
        }
    },
    computed: {
        ...mapGetters(['isDeviceDesktop']),
        descriptionId() {
            if (this.id) {
                return this.id + '_description'
            }

            return null
        },
        collapseTo() {
            if (this.descriptionId) {
                return '#' + this.descriptionId
            }

            return null
        },
        descriptionWrapperClasses() {
            return {
                'page-block__description-wrapper_padding': this.descriptionPadding,
                'page-block__description-wrapper_regions': this.regionsDescription,
                'page-block__description-wrapper_small': this.smallDescription,
            }
        },
        descriptionClasses() {
            return {
                'page-block__description_padding': this.descriptionPadding,
                'page-block__description_gray': this.grayDescription,
                'page-block__description_profile': this.profileDescription,
            }
        },
        rootClasses() {
            return {
                'page-block_no-margin': this.noMargin,
                'page-block_desktop': this.isDeviceDesktop,
                'page-block_profile-content': this.profileContentWidth
            }
        },
        headerClasses() {
            return {
                'page-block__header_small-padding': this.smallHeader,
                'page-block__header_selectable': this.titleSelectable,
                'page-block__header_captioned': this.titleCaption && this.titleCaption.length,
                'page-block__header_profile': this.profile,
                'page-block__header_tags': this.tags
            }
        },
        titleClasses() {
            return {
                'page-block__title_big': this.bigTitle,
                'page-block__title_small': this.smallTitle
            }
        },
        counterClasses() {
            return {
                'page-block__counter_big': this.bigTitle
            }
        },
        contentClasses() {
            return {
                'page-block__content_padding': this.contentPadding,
                'page-block__content_padding-profile': this.profile,
                'page-block__content_visible': this.contentVisible,
                'page-block__content_regions': this.regionsDescription,
            }
        }
    }
}
</script>
<style lang="less">

.page-block {
    --desktop-header-padding-top: rem(11);
    --desktop-header-padding-bottom: rem(7);

    background-color: @color-white;

    margin-bottom: rem(16);

    &_no-margin {
        margin-bottom: 0;
    }

    &_profile-content {
        width: @widthProfileContent;
    }

    &_recent{
        @{mobile} & {
            padding-bottom: rem(20);
            padding-top: rem(20);

            .page-block{
                &__header{
                    padding-top: 0;
                    padding-bottom: 0;
                    margin-bottom: rem(12);
                }

                &__title{
                    margin-bottom: 0;
                }
            }
        }

        @{desktop} & {
            .page-block{
                &__header{
                    padding: 0;
                    margin-bottom: rem(20);
                }

                &__title{
                    font-size: rem(24);
                    line-height: rem(31);
                    margin: 0;
                }
            }
        }
    }

    &__aromabox{
        margin-bottom: rem(40);

        @{mobile} & {
            margin: 0;
            border-top: rem(16) solid @color-bg-gray;
            border-bottom: rem(16) solid @color-bg-gray;
        }
    }

    &__aromabox-detail{
        margin-bottom: rem(32);

        @{mobile} & {
            margin-bottom: rem(16);
        }
    }

    &__header {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        text-rendering: optimizeSpeed;

        /* justify-content: space-between; */

        color: @color-black;

        padding-top: rem(23);
        padding-bottom: rem(5);

        .page-padding();

        @{desktop} & {
            padding-top: var(--desktop-header-padding-top);
            padding-bottom: var(--desktop-header-padding-bottom);
        }

        &_small-padding {
            padding-top: rem(3);

            @{desktop} & {
                padding-bottom: rem(8);
            }
        }

        &_profile {
            @{desktop} & {
                padding-top: 0;
            }
        }

        &_captioned {
            padding-bottom: rem(20);
        }

        &_tags{
            .page-block{
                &__title-caption{
                    position: static;
                    font-size: rem(24);
                    line-height: inherit;
                    font-weight: 400;
                    vertical-align: middle;
                    margin-left: rem(2);

                    @{mobile} & {
                        font-size: rem(15);
                    }
                }
            }
        }
    }

    &__bottom-caption{
        color: @color-gray-light;
        font-size: rem(15);
        margin-top: rem(-8);
        .page-padding();
    }

    &__header_selectable ~ &__description-wrapper {
        padding-bottom: rem(14);
    }

    &__footer {
        border-top: rem(1) solid @color-gray-white;

        padding: rem(12) 0;

        position: relative;

        .page-padding();

        &-link {
            color: @color-primary;

            &-icon {
                height: rem(18);
                width: rem(18);

                position: absolute;
                right: rem(16);

                transform: translateY(rem(3));
            }

            &:after {
                height: 100%;
                width: 100%;

                position: absolute;
            }
        }
    }

    &__title {
        font-size: rem(18);
        line-height: rem(27);
        font-weight: 500;
        margin-bottom: rem(4);

        @{mobile} & {
            margin-bottom: rem(2);
        }

        position: relative;

        &-button {
            margin-left: auto;

            position: relative;
            top: rem(-2.5);
        }

        &-sup{
            color: @color-gray;
            font-size: rem(15);
            font-weight: 400;
            display: block;
            line-height: rem(23);
            margin-bottom: rem(6);

            @{mobile} & {
                margin-bottom: rem(4)
            }
        }

        &-caption {
            color: @color-gray-light;
            font-size: rem(15);
            font-weight: 400;
            white-space: nowrap;

            width: 100%;

            position: absolute;
            top: calc(100% + rem(10));
            left: 0;
        }

        @{desktop} & {
            font-size: rem(28);
            line-height: 1.3;
        }

        &_big {
            font-size: rem(20);

            @{desktop} & {
                font-size: rem(24);
            }
        }

        &_small {
            font-size: rem(16);

            @{desktop} & {
                font-size: rem(18);
            }
        }
    }

    &__title-wrapper {
        margin-bottom: rem(10);
    }

    &__title-wrapper &__title {
        display: inline;

        margin-right: rem(3);
    }

    &__title-wrapper &__counter {
        display: inline-block;

        white-space: nowrap;

        margin-left: 0;

        transform: translateY(rem(0.3));
    }

    &__counter {
        // color: @color-gray-light;
        color: @color-gray-contrast;
        font-size: rem(13);
        font-weight: 500;

        margin-left: rem(6);

        transform: translateY(rem(-1));

        &_big {
            font-size: rem(15);

            @{desktop} & {
                font-size: rem(16);

                padding-left: 2px;

                transform: none;
            }
        }
    }

    &__description {

        &:not(.html-content){

            font-size: rem(15);
            line-height: 1.5;

            margin-bottom: 0;

            b, strong {
                font-weight: 500;
            }

            a {
                color: @color-primary;
            }

            &_gray {
                color: @color-gray;
            }

            br:last-of-type {
                display: none;
            }

            @{desktop} & {
                line-height: 1.7;
            }

            p:last-of-type {
                margin-bottom: 0;
            }

        }

        &-wrapper {
            padding-top: rem(6);
            padding-bottom: rem(20);

            .page-padding();

            &_padding {
                padding-bottom: rem(16)
            }

            &_grey {
                color: @color-gray;
            }

            &_small {
                padding-bottom: rem(4);
            }

            .button-more{
                &:not(.button-more_centered){
                    padding-top: 0;
                    padding-bottom: 0;
                }
            }
        }

        &_padding {
            padding-top: rem(21);
        }

        &_profile {
            color: @color-gray;
            font-size: rem(14);

            margin-bottom: rem(20);
        }
    }

    &__caption-wrap {
        display: flex;
        align-items: center;

        margin-top: rem(5);

        .page-padding();

        .page-block__caption {
            padding-left: rem(8);
        }

        @{desktop} & {
            margin-top: 0;
        }
    }

    &__caption-count {
        color: @color-violet;
        font-size: rem(20);
        font-weight: 500;

        transform: translateY(rem(2));

        @{desktop} & {
            font-size: rem(20);

            margin-right: rem(8);
            margin-bottom: rem(12);

            transform: translateY(rem(-1));
        }
    }

    &__caption-count + &__caption {
        margin-bottom: rem(8);
    }

    &__caption {
        color: @color-gray;
        font-size: rem(13);
        line-height: 1.2;

        margin-bottom: rem(12);

        .page-padding();

        @{desktop} & {
            margin-bottom: rem(11);
        }
    }

    &__content {
        position: relative;

        overflow: hidden;

        &_padding {
            padding-top: rem(10);
            .page-padding();
        }

        &_padding-profile {
            .page-padding();
            padding-top: rem(16);
        }

        &_visible {
            overflow: visible;
        }

        &_regions {
            padding-top: rem(21);

            overflow: hidden;

            @{desktop} & {
                padding-top: 0;

                overflow: visible;
            }
        }

        .button-more{
            &:not(.button-more_centered){
                padding-top: 0;
            }
        }
    }

    &__icon {
        display: inline-block;

        border-radius: 50%;

        height: rem(16);
        width: rem(16);

        position: relative;
        top: rem(-3);
        right: rem(-6);

        @{desktop} & {
            height: rem(24);
            width: rem(24);
        }
    }

    &__title-select {
        font-size: rem(15);
        line-height: 1;
        white-space: nowrap;

        margin-left: rem(17);

        position: relative;
        top: rem(-1.5);

        &-icon {
            margin-left: rem(1);

            height: rem(12);
            width: rem(12);
        }
    }

    &__help-button {
        margin-left: rem(7);

        position: relative;
        top: rem(-2);

        svg {
            --size: rem(16);
        }
    }
}
</style>
