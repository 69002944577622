/* eslint-disable no-useless-constructor */
import Model from '@/models/Model'

export default class Profile extends Model {
    resource() {
        return 'points'
    }

    pointsPlan() {
        return this.request({
            method: 'GET',
            url: '/users/accounts/points-plan'
        }).then(response => {
            if(response) {
                return response.data;
            }
        });
    }
}
