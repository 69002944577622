import forEach from 'lodash/forEach'

import Vue from 'vue'

const eventBus = {}

eventBus.install = function(Vue) {
    Vue.prototype.$bus = new Vue()

    Vue.mixin({
        mounted() {
            const events = this.$options.globalBus

            if (typeof events === 'object') {
                forEach(
                    Object.keys(events),
                    eventName => {
                        const callback = events[eventName].bind(this)

                        if (typeof callback === 'function') {
                            this.$bus.$on(eventName, callback)

                            /* Снимаем обработчики при разрушении компонента */
                            this.$once('hook:beforeDestroy', () => {
                                this.$bus.$off(eventName, callback)
                            })
                        }
                    }
                )
            }
        },
    })
}

Vue.use(eventBus)

