const settings = () => import('./settings').then(m => m.default || m);
const gallery = () => import('./gallery').then(m => m.default || m);
const tags = () => import('./tags').then(m => m.default || m);
const crop = () => import('./crop').then(m => m.default || m);
const preview = () => import('./preview').then(m => m.default || m);
const points = () => import('./points').then(m => m.default || m);
const info = () => import('./info').then(m => m.default || m);
const RubricsRequest = () => import('./RubricsRequest').then(m => m.default || m);
const Editor = () => import('@/components/editor').then(m => m.default || m);

export default {
    'content-editor-rubrics-request': {
        component: RubricsRequest,
        options: {
            type: 'centered',
            draggable: false
        },
    },
    'content-editor-points': {
        component: points,
        options: {
            type: 'centered',
            draggable: false
        },
    },
    'content-editor-crop': {
        component: crop,
        options: {
            fullscreen: true,
            draggable: false,
            classes: 'modal__box_no_radius',
            height: '100%',
            width: '100%',
        },
        optionsDesktop: {
            draggable: false,
            type: 'centered',
            width: '707'
        }
    },
    'content-editor-info': {
        component: info,
        options: {
            fullscreen: true,
            draggable: false,
            classes: 'modal__box_no_radius',
            height: '100%',
            width: '100%',
        },
        optionsDesktop: {
            type: 'centered',
            width: '700'
        }
    },
    'content-editor-tags': {
        component: tags,
        options: {
            fullscreen: true,
            draggable: false,
            classes: 'modal__box_no_radius',
            height: '100%',
            width: '100%',
        },
        optionsDesktop: {
            type: 'centered',
            width: '700'
        }
    },
    'content-editor-settings': {
        component: settings,
        options: {
            fullscreen: true,
            draggable: false,
            classes: 'modal__box_no_radius',
            height: '100%',
            width: '100%',
        },
        optionsDesktop: {
            fullscreen: false,
            draggable: false,
            classes: 'modal__box_no_radius',
            height: '100%',
            width: '100%',
            showClose: false
        }
    },
    'content-editor-preview': {
        component: preview,
        options: {
            fullscreen: true,
            draggable: false,
            classes: 'modal__box_no_radius',
            height: '100%',
            width: '100%',
            showClose: false
        },
        optionsDesktop: {
            fullscreen: false,
            draggable: false,
            classes: 'modal__box_no_radius',
            height: '100%',
            width: '100%',
            showClose: false
        }
    },
    'content-editor': {
        component: Editor,
        options: {
            fullscreen: true,
            draggable: false,
            classes: 'modal__box_no_radius',
            height: '100%',
            width: '100%',
            showClose: false
        },
        optionsDesktop: {
            fullscreen: false,
            draggable: false,
            classes: 'modal__box_no_radius',
            height: '100%',
            width: '100%',
            showClose: false
        }
    },
    'editor-gallery': {
        component: gallery,
        options: {
            fullscreen: true,
            classes: 'modal__box_no_radius',
            height: '100%',
            width: '100%'
        },
        optionsDesktop: {
            fullscreen: false,
            draggable: false,
            type: 'centered',
            width: '888'
        }
    },
}
