import * as number from './number';
import * as url from './url';
import * as meta from './meta';
import * as date from './date';
import * as currency from './currency';
import * as notice from './notice';
import * as list from './list';
import * as text from './text';
import * as content from './content';

export default {
    meta,
    number,
    url,
    date,
    currency,
    notice,
    list,
    text,
    content
}
