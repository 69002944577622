<template>
    <label class="base-checkbox" :class="{'base-checkbox_disabled': disabled}">

        <span class="base-checkbox__content-wrapper">
            <span class="base-checkbox__wrapper"
                :class="wrapperClasses">

                <input class="base-checkbox__input"
                    :type="inputType"
                    :disabled="disabled"
                    :value="value"
                    :required="required"
                    v-model="inputValue" />

                <span class="base-checkbox__checkbox">
                    <svg-icon name="icon-check"
                        class="base-checkbox__icon" />
                </span>

            </span>

            <span
                v-if="transparent"
                class="base-checkbox__transparent">
                <slot ></slot>
            </span>

            <span
                v-else
                :class="labelClasses"
                class="base-checkbox__label">

                <img class="base-checkbox__picture"
                    v-if="picture"
                    v-lazy-load :data-src="picture || getNoImage" />

                <html-link
                    class="base-checkbox__link"
                    @click.prevent
                    v-if="url"
                    :url="url">
                    <slot ></slot>
                </html-link>

                <span class="base-checkbox__name">
                    <slot></slot>
                </span>

                <div class="base-checkbox__caption">
                    {{ caption }}
                </div>
            </span>
        </span>

        <HelpButton
            class="base-checkbox__help-button"
            v-if="tooltipTitle || tooltipContent"
            :title="tooltipTitle"
            :content="tooltipContent"
            :inside="!!tooltipInside"
            :placement="tooltipInside" />

    </label>
</template>

<script>
import { mapGetters } from 'vuex';

import CheckboxMixin from '@/mixins/checkbox.mixin.js'
import HelpButton from '@/components/ui/HelpButton'

export default {
    name: 'BaseCheckbox',
    components: {
        HelpButton
    },
    mixins: [CheckboxMixin],
    props: {
        tooltipTitle: {
            type: String,
            default: ''
        },
        tooltipContent: {
            type: String,
            default: ''
        },
        tooltipInside: {
            type: [String,Boolean],
            default: false
        },
        picture: {
            type: String,
            default: ''
        },
        bold: {
            type: Boolean,
            default: false
        },
        transparent: {
            type: Boolean,
            default: false
        },
        caption: {
            type: String,
            default: null
        },
        url: {
            type: String,
            default: null
        }
    },
    computed: {
        ...mapGetters(['getNoImage']),
        captioned() {
            return this.caption && this.caption.length
        },
        labelClasses() {
            return {
                'base-checkbox__label_pictured': !!this.picture,
                'base-checkbox__label_captioned': this.captioned,
                'base-checkbox__label_bold': this.bold,
            }
        },
        wrapperClasses() {
            return {
                'base-checkbox__wrapper_hidden': this.noCheck,
                'base-checkbox__wrapper_captioned': this.captioned
            }
        }
    },
}
</script>

<style lang="less">
.base-checkbox {
    --size: rem(20);
    --picture-size: rem(32);

    display: flex;
    justify-content: space-between;

    user-select: none;

    &__content-wrapper {
        display: flex;
        align-items: center;
        flex: 0 0 auto;
        max-width: 100%;
    }

    &__wrapper {
        display: block;
        flex-shrink: 0;
        cursor: pointer;

        height: var(--size);
        width: var(--size);

        position: relative;

        &_hidden {
            width: 0;

            opacity: 0;
        }

        &_captioned {
            align-self: flex-start;
        }
    }

    &__wrapper_hidden ~ &__label {
        padding-left: 0;
    }

    &__input {
        cursor: pointer;

        height: 100%;
        width: 100%;

        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1;

        opacity: 0;
    }

    &__content-wrapper:hover &__checkbox {
        @media @hover {
            border-color: @color-primary;
        }
    }

    &__content-wrapper:hover &__label {
        @media @hover {
            color: @color-primary;
        }
    }

    &__checkbox {
        display: flex;
        align-items: center;
        justify-content: center;

        background-color: @color-white;
        border: rem(1) solid @color-black;
        border-radius: rem(3);

        height: 100%;
        width: 100%;

        transition: background-color .1s ease, border-color .1s ease;
    }

    &__icon {
        height: 60%;
        width: 60%;
    }

    &__input:checked ~ &__checkbox {
        background-color: @color-primary;
        border-color: @color-primary;

        &:after {
            transform: scale(1.25);
        }
    }

    &__input:focus ~ &__checkbox {
        border-color: @color-primary;
    }

    &__input:checked:focus ~ &__checkbox {
        border-color: @color-black;
    }

    &__label {
        cursor: pointer;

        font-size: rem(15);

        padding-left: rem(12);

        width: 100%;

        transform: translateY(rem(1));

        &_pictured {
            white-space: nowrap;

            .base-checkbox__name{
                display: inline-block;
                text-overflow: ellipsis;
                overflow: hidden;
                
                vertical-align: middle;
                max-width: rem(260);

                @{desktop} &{
                    max-width: rem(205);
                }
            }
        }
    }

    &__label_captioned &__name,
    &__label_bold &__name {
        font-weight: 500;
    }

    &__caption {
        color: @color-gray;
        font-size: rem(14);
    }

    &__help-button {
        margin-left: rem(7);
    }

    &__picture {
        border-radius: 50%;

        margin-top: rem(-2);
        margin-right: rem(12);
        margin-bottom: rem(0);
        margin-left: rem(8);

        height: var(--picture-size);
        width: var(--picture-size);

        &:first-child {
            margin-right: rem(8);
        }
    }

    &__link {
        position: absolute;
        z-index: -1;

        opacity: 0;

        pointer-events: none;
    }

    &_prechecked{
        .base-checkbox{
            &__name{
                font-weight: 400;
            }

            &__input&:checked,
            &__input{
                & ~ .base-checkbox__checkbox{
                    background-color: @color-gray;
                    border-color: @color-gray;
                }
            }

            &__checkbox{
                background-color: @color-gray;
                border-color: @color-gray;
            }

            &__caption{
                margin-top: rem(5);
                background-color: @color-bg-gray;
                padding: rem(15);
                font-size: rem(13);
                line-height: rem(18);
                border-radius: rem(3);
            }
        }
    }

    &_checked{
        .base-checkbox{
            &__input:checked,
            &__input{
                & ~ .base-checkbox__checkbox {
                    background-color: @color-primary;
                    border-color: @color-primary;
                }
            }
        }
    }

    &_unchecked{
        .base-checkbox{
            &__input:checked,
            &__input{
                & ~ .base-checkbox__checkbox {
                    background-color: @color-white;
                    border: rem(1) solid @color-black;
                }
            }
        }
    }

    &_disabled{
        cursor: default;
        pointer-events: none;

        .base-checkbox{
            &__input:checked{
                & ~ .base-checkbox__checkbox{
                    border-color: @color-gray-light;
                    background-color: @color-gray-light;
                }
            }

            &__name{
                color: @color-gray-contrast;
            }

            &__label,
            &__input{
                cursor: default;
            }
        }

        &.base-checkbox_unchecked{
            .base-checkbox{
                &__input:checked{
                    & ~ .base-checkbox__checkbox{
                        background-color: @color-white;
                    }
                }
            }
        }
    }
}
</style>
