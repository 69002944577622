// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../static/img/loading-sm.gif");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".modal-document{padding-bottom:64px;height:70vh;width:300px;min-width:280px;max-width:80vw;position:relative}html.view_desktop .modal-document{width:740px}.modal-document[data-loading=true]{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat 50%}.modal-document__text{font-size:14px;max-height:100%;overflow-x:hidden;overflow-y:auto}.modal-document__bottom{padding-top:20px;height:64pxpx;width:100%;position:absolute;bottom:0;left:0}.modal-document__close{display:block;margin:0 auto;max-width:300px}.modal-document_full-width{width:100%;min-width:0;max-width:none}@media only screen and (orientation:landscape){.modal-document{height:60vh}}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
