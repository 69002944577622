<template>
    <section
        class="tabs-panel"
        v-if="!renderWhenActive || isActive"
        :class="classes"
        :id="computedId"
        :aria-hidden="!isActive"
        role="tabpanel">
        <slot></slot>
    </section>
</template>

<script>
import uniqueId from 'lodash/uniqueId'
import forEach from 'lodash/forEach'

export default {
    name: 'BaseTab',
    props: {
        id: {
            type: String,
            default: '',
            required: true
        },
        link: {
            type: [String, null],
            default: null,
            required: false
        },
        name: {
            type: String,
            default: '',
            required: true
        },
        // Используется в родителе
        icon: {
            type: String,
            default: null
        },
        counterBig: {
            type: Boolean,
            default: false
        },
        counter: {
            type: Number,
            default: 0,
            required: false
        },
        uniqueId: {
            type: Boolean,
            default: false
        },
        contentPadding: {
            type: Boolean,
            default: false
        },
        contentNoPadding: {
            type: Boolean,
            default: false
        },
        selected: {
            type: Boolean,
            default: false
        },
        forceUpdate: {
            type: Boolean,
            default: false
        },
        collapseOnChange: {
            type: Boolean,
            default: true
        },
        isGrid: {
            type: Boolean,
            default: false
        },
        /**
         * Рендерит и оставляет в html код только при активности вкладки
         * @type Boolean
         */
        renderWhenActive: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            isActive: false
        }
    },
    computed: {
        computedId() {
            if (this.uniqueId)
                return uniqueId(this.id + '-')

            return this.id
        },
        classes() {
            return {
                'tabs-panel_content-padding': this.contentPadding,
                'tabs-panel_grid': this.isGrid,
                'tabs-panel_content-no-padding': this.contentNoPadding,
            }
        },
        hash() {
            return '#' + this.computedId;
        },
    },
    watch: {
        '$parent.activeTabId': {
            immediate: true,
            handler(activeTabId) {
                if (!this.$parent.clientRender && process.server) {
                    this.isActive = this.id === activeTabId;

                    return
                }

                this.$nextTick(() => {
                    this.isActive = this.id === activeTabId;

                    if (this.forceUpdate && this.isActive){
                    // Обновляем вложенный компонент
                        if(this.$children[0] && this.$children[0].$forceUpdate) {
                            this.$children[0].$forceUpdate()
                        }
                    }

                    if (this.collapseOnChange && !this.isActive) {
                    // Сворачиваем дочерний компонент, если у него есть метод collapse
                        forEach(this.$children, child => {
                            if (typeof child.collapse === 'function')
                                child.collapse()
                        })
                    }
                })
            }
        }
    },
    created() {
        if (this.selected) {
            this.isActive = true
        }
    },
};
</script>
<style lang="less">
.tabs-panel{
    height: 100%;

    transition: opacity .25s ease, visibility .25s ease;

    &_content-padding {
        .page-padding();
    }

    &_content-no-padding {
        padding-top: 0;

        @{desktop} & {
            // TODO костыль
            padding-top: 0 !important;
        }
    }

    &_grid {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
    }

    &[aria-hidden=true] {
        height: 0;
        width: 0;

        position: absolute;
        top: 0;
        z-index: -1;

        overflow: hidden;

        opacity: 0;

        transform: translateX(-200vw);

        visibility: hidden;
    }
}
</style>
