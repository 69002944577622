<template>
    <component
        :is="tag"
        @scroll="onScroll"
        :class="classes"
        class="scroll-slider">
        <slot></slot>
    </component>
</template>

<script>
import throttle from 'lodash/throttle'

export default {
    name: 'BaseScrollSlider',
    props: {
        noScroll: {
            type: Boolean,
            default: true
        },
        shadowed: {
            type: Boolean,
            default: false
        },
        items: {
            type: Array,
            default: () => {}
        },
        tag: {
            type: String,
            default: 'div'
        }
    },
    data() {
        return {
            isScrolled: false
        }
    },
    computed: {
        classes() {
            return {
                'scroll-slider_no-scroll': this.noScroll,
                'scroll-slider_shadowed': this.shadowed,
                'scroll-slider_scrolled': this.isScrolled && this.shadowed,
            }
        }
    },
    methods: {
        onScroll: throttle(function(e) {
            // Пока только для вертикального
            this.isScrolled = e.target.scrollTop > 80
        }, 0),
    }
};
</script>

<style lang="less">

.scroll-slider {
    display: flex;

    overflow-x: scroll;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;

    -webkit-transform: translateZ(0);

    -ms-overflow-style: none;
    scrollbar-width: none;

    .page-padding();

    &::-webkit-scrollbar {
        background: transparent;

        height: 0;
        width: 0;
    }

    &_no-scroll {
        .scrollbar-hidden();
    }

    &_shadowed {
        position: relative;

        &:before {
            .gradient(top);
            height: 0;

            top: rem(-1);

            // transition: height .075s ease;
        }

        &:after {
            .gradient(bottom);
            bottom: rem(-1);
        }

        &:before,
        &:after {
            display: block;

            position: sticky;
            z-index: 1;

            pointer-events: none;
        }

    }

    &_scrolled {

        &:before {
            height: rem(30);
        }
    }

    &__slide {
        flex-shrink: 0;

        padding-right: rem(12);

        &:last-of-type {
            padding-right: rem(20);
        }
    }
}
</style>
