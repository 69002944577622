<template>
    <component
        :is="url ? 'html-link' : 'figure'"
        class="base-note"
        :url="url"
        :event="preventLink ? [] : 'click'"
        :class="rootClasses"
        :data-title="name">

        <picture class="base-note__picture" :class="{'base-note__picture_placeholder': isNoPicture}">
            <img
                v-lazy-load :data-src="photoUrl || getNoImageNotes"
                :alt="name" />
        </picture>

        <div class="base-note__label"
            v-if="label">
            {{ label }}
        </div>

        <slot name="label"></slot>

        <figcaption class="base-note__name">
            {{ name }}
        </figcaption>

    </component>
</template>

<script>
import includes from 'lodash/includes'
import { mapGetters } from 'vuex';

export default {
    name: 'BaseNote',
    props: {
        name: {
            type: String,
            default: ''
        },
        preventLink: {
            type: Boolean,
            default: false
        },
        photoUrl: {
            type: String,
            default: ''
        },
        url: {
            type: String,
            default: ''
        },
        big: {
            type: Boolean,
            default: false
        },
        label: {
            type: String,
            default: ''
        },
        layout: {
            type: String,
            default: 'block',
            validator(value) {
                return includes(['block', 'row'], value)
            }
        },
        hoverShadow: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        ...mapGetters(['getNoImageNotes']),
        rootClasses() {
            return {
                'base-note_big': this.big,
                'base-note_row': this.layout === 'row',
                'base-note_shadow': this.hoverShadow
            }
        },
        isNoPicture() {
            return this.photoUrl.length < 1
        }
    }
}
</script>

<style lang="less">

.base-note {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0;
    position: relative;
    color: @color-black;
    cursor: pointer;

    // &:hover &__link,
    // &:focus &__link {
    //     @media @hover {
    //         box-shadow: 0 rem(1) rem(8) rgba(0, 0, 0, 0.1);
    //     }
    // }
    
    @{desktop} & {
        padding: rem(8);

        &_shadow{

            &::before{
                content: attr(data-title);
                position: absolute;
                padding: rem(76) rem(5) rem(10);
                background-color: white;
                z-index: -1;
                pointer-events: none;
                top: 0;
                min-width: 100%;
                text-align: center;
                font-weight: 500;
                line-height: 1.2;
                transition: box-shadow .2s ease;
                max-width: rem(120);
                overflow: hidden;
                text-overflow: ellipsis;
                color: transparent;
                font-size: rem(13);
            }

            &:hover,
            &:focus{
                z-index: 2;

                &::before{
                    color: inherit;
                    box-shadow: 0 rem(1) rem(8) rgba(0, 0, 0, 0.1);
                }

                .base-note{
                    &__name{
                        visibility: hidden;
                    }
                }
            }
        }
    }

    // &:hover &__name,
    // &:focus &__name {
    //     @{desktop} & {
    //         white-space: normal;
    //         max-width: none;
    //         position: absolute;
    //         height: auto;
    //         width: auto;
    //         min-width: 100%;
    //         min-height: 100%;
    //         background-color: white;
    //         left: 50%;
    //         transform: translateX(-50%);
    //         padding-top: rem(68);
    //         z-index: 0;
    //         overflow: visible;

    //         &::before{
    //             opacity: 1;
    //             visibility: visible;
    //         }
    //     }
    // }

    &__label {
        --size: rem(32);

        display: flex;
        align-items: center;
        justify-content: center;

        background-color: @color-white;
        border: rem(1) solid @color-gray-white;
        border-radius: 50%;
        color: @color-black;
        font-size: rem(11);
        font-weight: 500;

        padding-top: rem(2);

        height: var(--size);
        width: var(--size);

        position: absolute;
        top: rem(-6);
        right: rem(10);
        z-index: 2;

        @{desktop} & {
            top: rem(2);
        }
    }

    // &__link {
    //     min-width: 100%;

    //     position: absolute;
    //     top: 0;
    //     bottom: 0;
    //     z-index: 1;

    //     transition: box-shadow .2s ease;

    //     span {
    //         visibility: hidden;
    //     }
    // }

    &__picture {
        --size: rem(56);

        display: block;

        border-radius: 50%;
        text-align: center;

        margin-bottom: rem(10);

        height: var(--size);
        width: var(--size);

        overflow: hidden;

        object-position: 100%;
        position: relative;
        z-index: 1;

        img {
            object-fit: contain;

            height: 100%;
            width: 100%;
            border-radius: 50%;
        }

        &_placeholder {
            display: flex;
            align-items: center;
            justify-content: center;

            background-color: @color-gray-light-2;

            padding: rem(8);
        }

        @{desktop} & {
            --size: rem(56);
            margin-bottom: rem(12);
        }
    }

    &_big &__picture {

        @{desktop} & {
            --size: rem(118);
            margin-bottom: rem(20);
        }
    }

    &_big &__picture_placeholder {
        padding: rem(15);
    }

    &_big {

        @{desktop} & {
            padding-top: rem(10);
            padding-bottom: rem(18);
            
            &::before{
                font-size: rem(15);
                padding: rem(148) rem(15) rem(18);
            }
        }
    }

    &__name {
        font-size: rem(13);
        font-weight: 500;
        line-height: 1.2;
        text-align: center;
        text-overflow: ellipsis;

        max-width: rem(68);

        overflow: hidden;

        @{desktop} & {
            max-width: 90%;
        }
    }

    &_big &__name {
        @{desktop} & {
            font-size: rem(15);

            max-width: 90%;
        }
    }

    &_row {
        flex-direction: row;

        position: relative;

        @{desktop} & {
            padding: rem(10) 0;
        }
    }

    &_row:hover &__name,
    &_row:focus &__name {
        color: @color-primary;
    }

    &_row &__link {
        box-shadow: none !important;

        right: 0;
        left: 0;
    }

    &_row &__picture {
        --size: rem(32);
        margin-right: rem(16);
        margin-bottom: 0;

        @{desktop} & {
            --size: rem(32);
            margin-bottom: 0;
        }
    }

    &_row &__name {
        font-size: rem(15);
        font-weight: 400;
        text-align: left;

        max-width: 66% !important;
    }

    &_row &__label {
        @{desktop} & {
            top: 50%;
            right: 0;

            transform: translateY(-50%);
        }
    }
}
</style>
