export const state = () => ({
    helpType: 'sections',
})

export const mutations = {
    mutationType(state, type) {
        state.helpType = type
    },
}

export const actions = {
    changeType({ commit }, { type }) {
        commit('mutationType', type)
    },
}

export const getters = {
    helpType: (s) => s.helpType
}
