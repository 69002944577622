/* eslint-disable no-useless-constructor */
import Model from '@/models/Model'
import Point from "@/models/services/Point";

export default class Profile extends Model {
    resource() {
        return 'profile'
    }

    points() {
        return this.hasMany(Point);
    }
}
