export default {
    format(code) {
        switch (code) {
        case 'rub':
            return '₽'
        case 'usd':
            return '$'
        }
    }
}
