<template>
    <div>
        <div
            class="recaptcha"
            :class="{'recaptcha_loading': loading}"
            :id="cid">
        </div>
    </div>
</template>

<script>
export default {
    name: 'ReCaptcha',
    props: {
        cid: {
            type: String,
            default: null,
            required: true
        },
        publicKey: {
            type: String,
            default: null,
            required: true
        },
        monitoringEntryId: {
            type: String,
            default: null
        }
    },
    data(){
        return{
            loading: true,
            reCaptchaInstance: null,
            reCaptchaResponse: null
        }
    },
    mounted(){
        this.initReCaptcha();
    },
    methods: {
        initReCaptcha(){
            if (window.grecaptcha){
                this.onReCaptchaLoad();
            } else {
                const script = document.createElement('script');
                script.setAttribute('src', 'https://www.google.com/recaptcha/api.js');
                script.setAttribute('async', 'true');
                script.setAttribute('defer', 'true');
                document.head.appendChild(script);

                script.onload = () => {
                    this.onReCaptchaLoad();
                }
            }
        },
        onReCaptchaLoad(){
            const reCaptcha = window.grecaptcha;

            reCaptcha.ready(() => {
                if(document.getElementById(this.cid)){
                    const opts = {
                        'sitekey' : this.publicKey,
                        'callback': this.onReCaptchaVerify,
                        'expired-callback': this.onReCaptchaExpired,
                        'size': 'invisible'
                    };

                    this.reCaptchaInstance = reCaptcha.render(this.cid, opts);
                    this.loading = false;

                    this.$emit('onReady');
                }
            });
        },
        async onReCaptchaVerify(res){
            if(res){
                const params = {
                    check_code: res
                }

                if(this.monitoringEntryId)
                    params.monitoring_entry_id = this.monitoringEntryId

                await this.$api.post('/services/re-captcha/checking', params).then(result => {
                    this.reCaptchaResponse = res;
                    this.$emit('onVerify', res);
                }).catch(err => {
                    this.reCaptchaResponse = null;
                    this.$emit('onVerify', false);
                    this.$alert.error(err.response.data.message);
                });
            }else{
                this.reCaptchaResponse = null;
                this.$emit('onVerify', false);
            }
        },
        onReCaptchaExpired(){
            this.reCaptchaResponse = null;
            this.$emit('onVerify', false);
        },
        reset(){
            if(window.grecaptcha){
                this.reCaptchaResponse = null;
                window.grecaptcha.reset(this.reCaptchaInstance);
            }
        },
        async execute(){
            if(this.reCaptchaResponse)
                return this.$emit('onVerify', true);

            if(window.grecaptcha)
                await window.grecaptcha.execute(this.reCaptchaInstance);
        }
    }
}
</script>

<style lang="less" scoped>
.recaptcha{
    // background-color: @color-bg-gray;
    // min-height: rem(78);
    // width: rem(304);
    // margin: 0 auto;
    // width: auto;
    // min-height: 0;
    // height: 0;

    &_loading{
        background-image: url('~static/img/loading-btn-transparent.gif');
        background-repeat: no-repeat;
        background-position: center;
        background-size: rem(24) rem(24);
    }
}
</style>