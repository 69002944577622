/* eslint-disable no-useless-constructor */
import Model from '@/models/Model'
import Collection from '@/models/catalog/Collection'
import Article from '@/models/catalog/Article';
import Alphabet from '@/models/catalog/Alphabet';
import News from '@/models/catalog/News';
import Comment from '@/models/users/Comment'
import CommentRating from '@/models/users/CommentRating'
import CommentPicture from '@/models/users/CommentPicture'
import Perfumer from '@/models/catalog/Perfumer'
import Segment from '@/models/catalog/Segment'
import Page from '@/models/catalog/Page';
import Like from '@/models/services/like'
import Note from '@/models/catalog/perfume/Note';
import Rating from '@/models/catalog/Rating';
import Catalog from '@/models/catalog/perfume/Catalog';
import PerfumePrices from '@/models/catalog/PerfumePrices';

export default class Brand extends Model {
    resource() {
        return 'brands'
    }

    primaryKey() {
        return 'code'
    }

    alphabet() {
        return this.hasMany(Alphabet)
    }

    commentsPictures() {
        return this.hasMany(CommentPicture)
    }

    relatedSegments() {
        return this.hasMany(Segment)
    }

    /* todo: должно быть perfumers */
    relatedPerfumers() {
        return this.hasMany(Perfumer)
    }

    commentsRatings() {
        return this.hasMany(CommentRating)
    }

    userLikes() {
        return this.hasMany(Like)
    }

    userRatings() {
        return this.hasMany(Rating)
    }

    commentsList() {
        return this.hasMany(Comment)
    }

    collectionsItems() {
        return this.hasMany(Collection)
    }

    bestPerfumesCollection() {
        return this.hasMany(Collection).where('best_perfumes', 'y')
    }

    relatedNews() {
        return this.hasMany(News, 'related-content')
    }

    relatedArticles() {
        return this.hasMany(Article, 'related-articles')
    }

    relatedPages() {
        return this.hasMany(Page, 'related-pages')
    }

    notesCollection() {
        return this.hasMany(Note)
    }

    catalog() {
        return this.hasMany(Catalog)
    }

    prices() {
        return this.hasMany(PerfumePrices)
    }
}
