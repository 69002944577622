import Vue from 'vue'

Vue.prototype.$authEmit = function(eventName, params) {
    const loggedIn = this.$auth.userLoggedIn();

    const clearSessionPendingEvent = () => {
        if (process.client && sessionStorage) {
            if (Object.prototype.hasOwnProperty.call(sessionStorage, 'authPendingEvent')) {
                sessionStorage.removeItem('authPendingEvent');
            }
        }
    };

    if (loggedIn) {
        this.$bus.$emit(eventName, params);
        clearSessionPendingEvent();
    } else {
        this.$store.commit('savePendingEvent', { eventName, params })

        if (process.client && sessionStorage) {
            sessionStorage.setItem('authPendingEvent', JSON.stringify({
                eventName,
                params,
                url: window.location.pathname
            }));
        }

        this.showPopup('signin', {
            type: 'continious'
        })

        this.$bus.$on('fetch-complete', () => {
            const { pendingEvent } = this.$store.state;
            if (pendingEvent && pendingEvent.eventName) {
                this.$bus.$emit(pendingEvent.eventName, pendingEvent.params)
                this.$store.commit('deletePendingEvent');
                clearSessionPendingEvent();
            }
        })
    }
}


