import dayjs from 'dayjs';
const calendar = require('dayjs/plugin/calendar');
const duration = require('dayjs/plugin/duration');
const utc = require('dayjs/plugin/utc');
const timezone = require('dayjs/plugin/timezone');

require('dayjs/locale/ru')

export default {
    // toUrlFormat,
    /**
     * Возвращаем строку с датой и временем, в зависимости от даты строка содержит слова вчера/сегодня
     *
     * @param  {Object} date
     * @return {String}
    */
    post(date, translations, locale = 'en', format = 'DD.MM.YYYY', noTime = false, noDate = false) {
        dayjs.extend(calendar);
        dayjs.extend(duration);
        dayjs.extend(utc);
        dayjs.extend(timezone);

        let time = '';
        let sameDay = `[${translations.today_in}]`;
        let lastDay = `[${translations.lastday_in}]`;

        if (!noTime) {
            time = translations.in + ' HH:mm'
        }

        if(!noDate){
            sameDay = sameDay + ' HH:mm';
            lastDay = lastDay + ' HH:mm';
        }

        return dayjs(date).locale(locale).calendar(null, {
            sameDay,
            lastDay,
            lastWeek: `${format} ${time}`,
            sameElse: `${format} ${time}`
        })
    },
    diff(date) {
        return dayjs.duration(dayjs().diff(dayjs(date)));
    },
    formatted(date, translations, sameDayWithoutNumbers = false) {
        dayjs.extend(calendar);
        dayjs.extend(utc);
        dayjs.extend(timezone);

        return dayjs(date).calendar(null, {
            sameDay: sameDayWithoutNumbers ? `[${translations.today_in}]` : `[${translations.today_in}] HH:mm`,
            lastDay: `[${translations.lastday_in}] HH:mm`,
            lastWeek: `DD.MM.YYYY`,
            sameElse: `DD.MM.YYYY`
        })
    },
    message(date, translations, sameDayWithoutNumbers = false) {
        dayjs.extend(calendar);
        dayjs.extend(utc);
        dayjs.extend(timezone);
        
        return dayjs(date).calendar(null, {
            sameDay: sameDayWithoutNumbers ? `[${translations.today_in}]` : `[${translations.today_in}] HH:mm`,
            lastDay: `[${translations.lastday_in}]`,
            lastWeek: `DD.MM.YYYY`,
            sameElse: `DD.MM.YYYY`
        })
    },
    /**
     * Возвращает дату и время в atom-формате
     * 
     * @param  {Object} date Date
     * @return {String}
     */
    atom(date) {
        date = date || new Date;
        return dayjs(date).format('YYYY-MM-DDTHH:mm:ssZ');
    }
}
