const subjects = {
    alert: {
        'email-confirmed': [],
        'new-email-confirmed': [],
        'incorrect-code': {type: 'error'},
        'email-already-confirmed': {type: 'info'}
    },
    popup: {
        'subscribe-confirmed': {
            id: 'popup-subscription',
            params: {typeModal: 'thanks'}
        },
        'unsubscribe-confirmed': {
            id: 'popup-subscription',
            params: {typeModal: 'unsubscribe'}
        },
        'signin': {
            id: 'signin',
            params: {}
        },
    },
    auth_pending: {

    }
};

export default {

    methods: {
        readAndExecuteHash(clear = true) {
            
            const hash = this.$route.hash.replace('#','');
            const [subject] = hash.split('-');

            if (!subject || !Object.keys(subjects).includes(subject)) {
                return;
            }

            const id = hash.replace(subject + '-', '');
            const instance = subjects[subject][id];

            switch (subject) {
            case 'alert':
                if (instance) {
                    this.$alert.show({
                        type: instance.type || 'success',
                        text: this.$t('alerts.'+id)
                    });
                }
                break;
            case 'popup':
                if (instance) {
                    this.$nextTick(() => {
                        this.showPopup(instance.id, instance.params);
                    });
                }
                break;

            case 'auth_pending':
                if (this.$auth.userLoggedIn()) {
                    this.executeAuthPendingEvent();
                }
                break;
            }
            
            !clear || this.$router.push({hash: null});
        }
    }
}