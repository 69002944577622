<i18n>
{
    "ru": {
        "unsubscribe": "Отписаться"
    }
}
</i18n>
<template>
    <div class="base-unit base-unit__wrapper" :class="[wrapperClasses, {'base-unit_no-image': !picture}]">
        <div class="base-unit__picture-wrapper">
            <img
                class="base-unit__picture"
                :class="{'base-unit__picture_no-image': !picture}"
                v-lazy-load :data-src="getPicture"
                :alt="alt || name" />
        </div>
        <div class="base-unit__content">
            <div class="base-unit__name"
                :class="nameClasses">
                {{ name }}
            </div>
            <div class="base-unit__caption">
                {{ caption }}
            </div>
        </div>
        <div v-if="withCounter" class="base-unit__panel-right">
            <div class="base-unit__counter"> {{ counter }}</div>
        </div>

        <slot name="side-right"></slot>

        <button
            v-if="canRemove && !isDeviceDesktop"
            @click.prevent="onRemove"
            class="base-unit__remove">

            <svg-icon v-tooltip="tooltipOptions"
                class="base-unit__remove_icon"
                name="icon-close"/>
        </button>

        <div v-if="canRemove && isDeviceDesktop"
            class="base-unit__remove-wrapper">
            <button
                @click="onRemove"
                class="base-unit__remove">

                <svg-icon
                    v-tooltip="tooltipOptions"
                    class="base-unit__remove_icon"
                    name="icon-close"/>
            </button>
        </div>

        <html-link
            v-if="url"
            :url="url"
            class="base-unit__link"
            :aria-label="name" />
        <html-link
            v-else-if="routeTo"
            :to="routeTo"
            class="base-unit__link"
            :aria-label="name" />
        <a v-else-if="link"
            rel="nofollow"
            target="_blank"
            :href="link"
            :aria-label="name"
            class="base-unit__link"></a>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import includes from 'lodash/includes';
import OfferMixin from '@/mixins/catalog/offers.mixin'

export default {
    name: 'BaseUnit',
    mixins: [ OfferMixin ],
    props: {
        canRemove: {
            type: Boolean,
            default: false
        },
        counter: {
            type: [Number, String],
            default: 0
        },
        rectanglePicture: {
            type: Boolean,
            default: false
        },
        squarePicture: {
            type: Boolean,
            default: false
        },
        isColumn: {
            type: Boolean,
            default: false
        },
        size: {
            type: String,
            default: 'regular',
            validator(value) {
                return includes(['small', 'regular', 'xs', 'large', 'medium', 'small-large'], value)
            }
        },
        namePadding: {
            type: Boolean,
            default: false
        },
        entityType: {
            type: String,
            default: 'user'
        },
        removeTooltipText: {
            type: String,
            default: null
        },
        alt: {
            type: String,
            default: null
        }
    },
    data: () => ({
        showActions: false
    }),
    computed: {
        ...mapGetters(['getNoImage', 'isDeviceDesktop']),
        nameClasses() {
            return {
                'base-unit__name_column': this.isColumn,
                'base-unit__name_full': !this.canRemove && !this.$slots['side-right'] && !this.withCounter,
                'base-unit__name_padding': this.namePadding
            }
        },
        wrapperClasses() {
            return {
                'base-unit__wrapper_regular' : this.size === 'regular',
                'base-unit__wrapper_small' : this.size === 'small',
                'base-unit__wrapper_xs' : this.size === 'xs',
                'base-unit__wrapper_large' : this.size === 'large',
                'base-unit__wrapper_medium' : this.size === 'medium',
                'base-unit__wrapper_small-large' : this.size === 'small-large',
                'base-unit__wrapper_square-picture': this.squarePicture,
                'base-unit__wrapper_rectangle-picture': this.rectanglePicture,
                'base-unit__wrapper_column': this.isColumn,
            }
        },
        tooltipOptions() {
            const desktopOptions = {
                content: this.removeTooltipText ? this.removeTooltipText : this.$t('unsubscribe'),
                delay: { show: 400, hide: 50 },
                offset: 6,
                defaultPlacement: 'top'
            }
            return this.isDeviceDesktop ? desktopOptions : {}
        }
    },
    methods: {
        onRemove() {
            this.$emit('remove', this.$props)
        }
    }
};
</script>

<style lang="less">

@base-unit-small : ~".base-unit__wrapper_small";
@square-picture : ~".base-unit__wrapper_square-picture";
@rectangle-picture : ~".base-unit__wrapper_rectangle-picture";
@base-unit-xs : ~".base-unit__wrapper_xs";
@base-unit-large : ~".base-unit__wrapper_large";
@base-unit-column : ~".base-unit__wrapper_column";
@base-unit-medium : ~".base-unit__wrapper_medium";
@base-unit-small-large : ~".base-unit__wrapper_small-large";

.base-unit {
    --caption-width-small-screen: rem(125);
    --caption-width: rem(160);

    .hover({
        cursor: pointer;
    });

    &_nose{
        .base-unit{
            &__content{
                max-width: 50vw;
            }
        }
    }

    &__wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;

        padding-top: rem(16);
        padding-bottom: rem(16);

        width: 100%;

        position: relative;

        .page-padding();

        transition: box-shadow 0.2s;

        &@{base-unit-column} {
            flex-flow: column nowrap;

            padding-top: rem(20);
            padding-bottom: rem(20);
        }

        @{desktop} & {
            .hover({
                box-shadow: @menu-desktop-box-shadow;

                .base-unit__name_column{
                    display: block;
                }
            })
        }
    }

    &__picture-wrapper {
        display: flex;
        justify-content: center;

        width: rem(56);
        min-width: rem(56);

        position: relative;

        overflow: hidden;

        @{base-unit-small} &,
        @{base-unit-xs} & {
            align-items: center;

            width: rem(40);
            min-width: 0;
        }

        @{base-unit-large} & {
            width: rem(118);
        }

        @{rectangle-picture} & {
            width: rem(71);
        }

        @{base-unit-column} & {
            margin-bottom: rem(16);
        }

        @{base-unit-medium} & {
            width: rem(88);
        }

        @{base-unit-small-large} & {
            width: rem(104);
        }
    }

    &__picture {
        object-fit: cover;

        border-radius: 50%;

        height: rem(56);
        width: rem(56);

        &_no-image {
            object-fit: contain;
        }

        @{base-unit-xs} & {
            object-fit: cover;
        }

        @{square-picture} &,
        @{rectangle-picture} & {
            object-fit: contain;

            border-radius: 0;
        }

        @{base-unit-small} &,
        @{base-unit-xs} & {
            height: rem(40);
            width: rem(40);
        }

        @{base-unit-large} & {
            height: rem(118);
            width: rem(118);
        }

        @{rectangle-picture} & {
            width: rem(71);
        }

        @{base-unit-medium} & {
            height: rem(88);
            width: rem(88);
        }

        @{base-unit-small-large} & {
            height: rem(104);
            width: rem(104);
        }
    }

    &__rating {
        &.rating {
            --star-width: rem(15);
            --star-height: rem(13);

            top: rem(8)
        }
    }

    &__name {
        font-weight: 500;
        text-overflow: ellipsis;
        white-space: nowrap;

        max-width: rem(125);

        overflow: hidden;

        transition: color 0.3s;

        @media @min375 {
            max-width: rem(220);
        }

        @{base-unit-column} & {
            margin-bottom: rem(2);
        }

        &_full {
            max-width: rem(185);

            @media @min375 {
                max-width: rem(240);
            }
        }

        &_padding {
            @{desktop} & {
                padding: 0 rem(15);
            }
        }

        &_column {
            text-align: center;
            white-space: normal;

            padding: 0 rem(10);

            max-width: 100%;

            .text-ellipsis(1);

            .hover({
                .text-ellipsis(2);
            });
        }
    }

    &__caption {
        color: @color-gray;
        font-size: rem(13);
        text-overflow: ellipsis;
        white-space: nowrap;

        overflow: hidden;

        @media @min375 {
            font-size: rem(14);
        }

        @{mobile} {
            max-width: var(--caption-width-small-screen);
        }

        @{base-unit-small} & {
            font-size: rem(13);
        }

        @{base-unit-column} & {
            font-size: rem(13);

            margin-bottom: rem(7);
        }
    }

    &__content {
        margin-right: auto;
        /*padding-top: rem(7);*/
        padding-left: rem(16);

        @{base-unit-small} & {
            /*padding-top: rem(3);*/
            padding-left: rem(16);
        }

        @{base-unit-column} & {
            text-align: center;

            margin: 0;
            padding: 0;
        }
    }

    &__link {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        @{desktop} & {
            @{base-unit-column} & {
                transition: box-shadow 0.2s;

                &:hover,
                &:focus {
                    box-shadow: 0 rem(1) rem(8) rgba(0, 0, 0, 0.1);

                    transition: box-shadow 0.2s;
                }
            }
        }
    }

    &__panel-right {
        display: flex;
        align-items: center;
    }

    &__counter {
        color: @color-gray;
        font-size: rem(12);
        text-overflow: ellipsis;
        white-space: nowrap;

        max-width: rem(80);

        overflow: hidden;

        @media @min375 {
            font-size: rem(13);

            max-width: rem(120);
        }
    }

    &__remove {
        display: flex;
        align-items: center;
        justify-content: center;

        background: none;
        border: none;
        color: @color-gray-light;
        outline: none;

        padding: 0;

        height: 100%;
        width: rem(28);

        position: absolute;
        top: 0;
        right: rem(-5);
        z-index: 2;

        @{desktop} & {
            cursor: default;

            outline: none;

            transition: color 0.2s;
        }

        &_icon {
            vertical-align: baseline;

            outline: none;

            height: rem(18);
            width: rem(18);

            @{desktop} & {
                cursor: pointer;
            }
        }
    }

    &__remove-wrapper {
        height: 100%;
        width: rem(28);

        position: absolute;
        top: 0;
        right: 0;

        &:hover {
            svg {
                .animate(color);
                color: @color-black;
            }
        }

    }

    &__tooltip {
        cursor: pointer;

        background-color: @color-white;
        box-shadow: 0 rem(3) rem(16) rgba(0, 0, 0, 0.08);
        font-size: rem(13);
        line-height: 1.5;
        text-align: center;

        padding: rem(8) rem(16);

        min-width: rem(108);

        position: absolute;
        bottom: 70%;
        left: 50%;
        z-index: 3;

        transform: translateX(-50%);
    }
}

@{base-unit-xs} {
    padding: rem(12) 0;
}

</style>
