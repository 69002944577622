<template>
    <NavList class="main-menu__nav"
        :type="isDeviceDesktop ? 'catalogDesktop' : 'catalog'"
        :show-items="isDeviceDesktop ? 6 : 5" />
</template>

<script>
import { mapGetters } from 'vuex'
import NavList from '@/components/layout/nav/NavList';
export default {
    name: 'AppMainMenu',
    components: {NavList},
    computed: {
        ...mapGetters(['isDeviceDesktop']),
    }
};
</script>

<style lang="less">
.main-menu {
    &__nav {
        display: flex;

        @{desktop} & {
            min-width: rem(642);
        }
    }
}
</style>
