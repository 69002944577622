<template>
    <button
        key="button-up"
        :aria-hidden="!visible"
        @click.prevent="onClick"
        class="button-up"
        :class="rootClasses">
        <span
            v-if="isDeviceDesktop"
            class="button-up__btn">
            <svg-icon :name="iconName"/>
        </span>

        <svg-icon
            v-else
            :name="iconName"/>

    </button>
</template>

<script>
import { mapGetters } from 'vuex'

import get from 'lodash/get'
import throttle from 'lodash/throttle'

export default {
    name: 'ButtonUp',
    data() {
        return {
            visible: false
        }
    },
    computed: {
        ...mapGetters(['isDeviceDesktop']),
        iconName() {
            return this.isDeviceDesktop ? 'up' : 'arrow-up'
        },
        rootClasses() {
            return this.$route.name === 'NewsDetail' || this.$route.name === 'ArticlesDetail' ? 'button-up_higher' : ''
        }
    },
    mounted() {
        document.addEventListener('scroll', throttle(e => {
            const scrollTop = get(e, 'target.scrollingElement.scrollTop')
            this.visible = scrollTop >= 1000
        }, 100))
    },
    methods: {
        onClick() {
            this.$scrollTo('html', 750)
        },
    }
}
</script>

<style lang="less">
.button-up {
    background: @color-white;
    border: none;
    border-radius: 50%;
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.1);
    outline: 0;

    padding: 0;

    height: rem(48);
    width: rem(48);

    position: fixed;
    right: rem(20);
    bottom: rem(20);
    z-index: 2;

    opacity: 1;

    transition: background-color .15s ease, opacity .2s ease, visibility .2s ease;

    &[aria-hidden] {
        opacity: 0;

        pointer-events: none;

        visibility: hidden;
    }

    svg {
        height: rem(22);
        width: rem(22);
    }

    &:after {
        content: '';
        display: none;

        height: 200vh;
        width: calc(100% + rem(40));

        position: absolute;
        top: -50vh;
        right: rem(-20);
        z-index: 1;
    }

    &_higher {
        bottom: rem(84);
    }

    &__btn {
        @{desktop} & {
            display: flex;
            align-items: center;
            justify-content: center;

            height: 100vh;
            width: rem(54);
            min-height: 100%;

            transition: background-color 0.2s;
        }
    }

    @{desktop} & {
        background: transparent;
        border: none;
        border-radius: 0;
        box-shadow: none;

        height: 100vh;
        width: calc((100vw - @widthWrapperDesktop) / 4);
        min-height: 100%;

        top: 0;
        bottom: 0;
        left: 0;

        &:hover {
            .button-up__btn {
                background-color: @color-bg-gray;

                transition: background-color 0.2s;
            }
        }
    }
}
</style>
