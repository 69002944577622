<template>
    <div class="social-buttons">
        <p> {{ caption }}</p>

        <div class="social-buttons__container">
            <ButtonSocial
                class="social-buttons__button"
                name="vk"
                :theme="theme"
                :title="title"
                :text="text"
                :url="url"
                :image="image"
                :rounded="false"
                :utm-source="utmSource"
                :utm-medium="utmMedium"
                :utm-campaign="utmCampaign"/>

            <ButtonSocial
                class="social-buttons__button"
                name="fb"
                :theme="theme"
                :title="title"
                :text="text"
                :url="url"
                :image="image"
                :rounded="false"
                :utm-source="utmSource"
                :utm-medium="utmMedium"
                :utm-campaign="utmCampaign"/>

            <ButtonSocial
                class="social-buttons__button"
                name="twitter"
                :theme="theme"
                :title="title"
                :text="text"
                :url="url"
                :image="image"
                :rounded="false"
                :utm-source="utmSource"
                :utm-medium="utmMedium"
                :utm-campaign="utmCampaign"/>

            <ButtonSocial
                class="social-buttons__button"
                name="ok"
                :theme="theme"
                :title="title"
                :text="text"
                :url="url"
                :image="image"
                :rounded="false"
                :utm-source="utmSource"
                :utm-medium="utmMedium"
                :utm-campaign="utmCampaign"/>
        </div>

    </div>
</template>

<script>
import ButtonSocial from '@/components/ui/buttons/ButtonSocial'

export default {
    name: 'SocialButtons',
    components: {
        ButtonSocial
    },
    props: {
        theme: {
            type: String,
            default: ''
        },
        caption: {
            type: String,
            default: ''
        },
        title: {
            type: String,
            default: ''
        },
        text: {
            type: String,
            default: ''
        },
        image: {
            type: String,
            default: ''
        },
        utmSource: {
            type: String,
            default: ''
        },
        utmMedium: {
            type: String,
            default: ''
        },
        utmCampaign: {
            type: String,
            default: ''
        },
    },
    data() {
        return {
            url: ''
        }
    },
    mounted() {
        this.url = window.location.origin + this.$route.fullPath
    }
}
</script>

<style lang="less">
.social-buttons {

    p {
        color: @color-gray;
        font-size: rem(14);

        margin-bottom: rem(22);
    }

    &__container {
        display: flex;
        justify-content: center;
    }

    &__button {
        --size: rem(44);

        margin: 0 rem(8);
    }
}
</style>
