/* eslint-disable no-useless-constructor */
import Model from '../Model'
import Rating from './Rating'
import Like from "@/models/services/like";

export default class Comment extends Model {
    resource() {
        return 'comments'
    }

    ratings() {
        return this.hasMany(Rating)
    }

    likes() {
        return this.hasMany(Like)
    }
}
