/* eslint-disable no-useless-constructor */
import Model from '@/models/Model'
import Like from "@/models/services/like";

export default class News extends Model {
    resource() {
        return 'news'
    }

    likes() {
        return this.hasMany(Like)
    }
}
