/* eslint-disable no-useless-constructor */
import Model from '../Model'

export default class Rating extends Model {
    resource() {
        return 'ratings'
    }

    _create() {
        return this.request({
            method: 'POST',
            url: this.endpoint(),
            data: this.getFormData(this),
            progress: false
        }).then(response => {
            const self = Object.assign(this, response.data)
            return self
        })
    }

    delete(data) {
        return this.request({
            method: 'DELETE',
            url: this.endpoint(),
            data,
            progress: false
        }).then(response => {
            return Object.assign(this, data);
        });
    }
}
