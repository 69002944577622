<i18n>
    {
        "en": {
            "button_text": "Show more"
        },
        "ru": {
            "button_text": "Показать больше"
        }
    }
</i18n>
<template>
    <div class="info-table">

        <div v-if="isDisabled || withoutShowMore"
            class="info-table__main"
            ref="main">
            <slot></slot>
        </div>

        <ShowMore
            v-else
            button-centered
            :type="'block'"
            :collapse-to="collapseTo"
            :collapse-from="collapseFrom"
            no-gradient
            :button-text="buttonText"
            :max-height="maxHeight">

            <div class="info-table__main" ref="main">
                <slot></slot>
            </div>

        </ShowMore>

    </div>
</template>
<script>
import get from 'lodash/get'

export default {
    name: 'InfoTable',
    props: {
        withoutCounter: {
            type: Boolean,
            default: false
        },
        showedRows: {
            type: Number,
            default: 3
        },
        collapseFrom: {
            type: String,
            default: ''
        },
        collapseTo: {
            type: String,
            default: ''
        },
        withoutShowMore: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            maxHeight: 0,
            rowsCount: 0,
            isDisabled: true
        }
    },
    computed: {
        buttonText() {
            if (this.withoutCounter) return this.$t('button_text')

            return `${this.$t('button_text')} (${this.hiddenRows})`
        },
        hiddenRows() {
            return this.rowsCount - this.showedRows
        },
    },
    async mounted() {
        await this.$nextTick()
        this.updateData()
    },
    // updated() {
    //     /* todo: оптимизировать */
    //     this.updateData()
    // },
    methods: {
        updateData() {
            // await this.$nextTick()
            this.rowsCount = this.getRowsCount()
            this.maxHeight = this.getMaxHeight()

            if(this.rowsCount > this.showedRows)
                this.isDisabled = false
        },
        // collapse() {
        //     if(!this.withoutShowMore)
        //         this.$refs.showmore.collapse()
        // },
        getRowsCount() {
            return get(
                this.$refs,
                'main.children',
                []
            ).length
        },
        getMaxHeight() {
            // Отступ сверху n-потомка
            return get(
                this.$refs,
                `main.children[${this.showedRows}].offsetTop`,
                1000
            )
        }
    },
}
</script>
<style lang="less">
.info-table {

    &__main {
        position: relative;
    }

    .show-more{
        &__button{
            padding: rem(12) 0;
        }
    }

    // &__button {
    //     background: none;
    //     border: none;
    //     color: @color-violet;
    //     outline: none;

    //     padding: 12px 0px;
    // }

    // &__button-block {
    //     display: flex;
    //     align-items: center;
    //     justify-content: center;
    // }
}
</style>
