<i18n>
{
    "ru": {
        "mobile_offer": {
            "title": "Создайте свой профиль!",
            "text": "Войдите на Aromo, чтобы получить доступ ко всем функциям сайта и найти коллег по увлечению.",
            "button": "Войти"
        }
    }
}
</i18n>

<template>
    <div>
        <transition name="fade" v-if="!$auth.loggedIn && !isDeviceDesktop">
            <div class="m-offer" v-show="offerVisible">
                <div class="m-offer__text">
                    <div class="m-offer__title">{{ $t('mobile_offer.title') }}</div>
                    {{ $t('mobile_offer.text') }}
                </div>
                <div class="m-offer__button">
                    <div @click.prevent="showPopup('signin')" class="btn btn_white">{{ $t('mobile_offer.button') }}</div>
                </div>
            </div>
        </transition>
        <DefaultLayout>
            <nuxt/>
        </DefaultLayout>
    </div>
</template>

<script>

import throttle from 'lodash/throttle'
import { mapGetters } from 'vuex'

import DefaultLayout from '../layouts/default';

export default {
    name: "LayoutProfile",
    components: {
        DefaultLayout
    },
    data(){
        return{
            offerVisible: false
        }
    },
    computed: {
        ...mapGetters(['isDeviceDesktop']),
        isMobileOfferShow(){
            return !this.$auth.loggedIn && !this.isDeviceDesktop
        }
    },
    mounted(){
        if(this.isMobileOfferShow){
            this.offerVisible = true

            document.addEventListener('scroll', throttle(e => {
                this.offerVisible = window.scrollY < document.body.offsetHeight - window.innerHeight
            }, 100))
        }

        this.$watch('offerVisible', () => {
            if(this.offerVisible && this.isMobileOfferShow){
                document.body.classList.add('button-up-offset-p')
            }else{
                document.body.classList.remove('button-up-offset-p')
            }
        }, {
            immediate: true
        })
    },
    beforeDestroy(){
        document.body.classList.remove('button-up-offset')
    },
    head() {
        return {
            meta: [
                {
                    hid: 'robots',
                    name: 'robots',
                    content: 'noindex, nofollow'
                }
            ]
        }
    }
}
</script>

<style lang="less">

.m-offer{
    position: fixed;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: @color-primary;
    color: @color-white;
    padding: rem(20);
    display: flex;
    justify-content: space-between;
    font-size: rem(12);
    line-height: rem(18);
    z-index: 8;

    &__button{
        margin-left: rem(20);
        flex: 0 0 rem(73);

        .btn{
            background-color: @color-white;
            color: @color-primary;
            display: block;
            font-size: rem(13);
            line-height: rem(20);
            padding: rem(9) rem(8) rem(7);
            height: rem(36);
            font-weight: 500;
        }
    }

    &__title{
        line-height: rem(21);
        font-size: rem(14);
        font-weight: 500;
        margin-bottom: rem(4);
    }
}

body.button-up-offset-p{
    .button-up{
        bottom: rem(139);
    }
}

</style>
