<i18n>
    {
        "en": {
            "title": "Newsletter subscription",
            "placeholder": "Enter your email",
            "subscription-already-exists": "You are already subscribed"
        },
        "ru": {
            "title": "Подписка на email-рассылку",
            "placeholder": "Ваш email",
            "success": "Вы успешно подписаны на e-mail рассылку. Проверьте вашу почту!",
            "subscription-already-exists": "Вы уже подписаны"
        }
    }
</i18n>

<template>
    <div class="subscription">
        <div class="subscription__title">{{ $t('title') }}</div>
        <app-form class="form subscription__form"
            v-slot="{ valid, handleSubmit }"
            ref="form">
            <form @submit.prevent="handleSubmit(onSubmit)"
                class="form__main">
                <form-field
                    :mode="'passive'"
                    rules="required|email"
                    v-slot="{ errors, failed, invalid, pristine }"
                    class="form__field"
                    vid="email">
                    <div class="form-group" :class="{'form-group_error': failed}">
                        <input type="text"
                            v-model="form.email"
                            @input="onInput(failed)"
                            spellcheck="false"
                            :placeholder="$t('placeholder')"
                            class="form__input form-group__inner" />
                        <span class="form-group__append">
                            <button type="submit"
                                class="btn btn_inline-primary form__submit"
                                :disabled="pristine"
                                aria-label="submit">
                                <svg-icon name="arrow-right" class="btn__icon form__submit-icon" />
                            </button>
                        </span>
                    </div>
                    <span :data-visible="isFailed(failed)" class="form__error">{{ head(errors) }}</span>
                </form-field>
            </form>
        </app-form>
    </div>
</template>

<script>
import head from 'lodash/head'
import Subscription from '@/models/services/mailings/subscriptions'

export default {
    name: 'Subscription',
    data() {
        return {
            form: {
                email: '',
            }
        }
    },
    computed: {
        head() {
            return head
        }
    },
    watch: {
        '$route'(){
            this.form.email = ''
            this.$refs.form.reset()
        }
    },
    methods: {
        onInput(failed) {
            this.form.email = this.form.email.trim();
            
            if(failed)
                this.$refs.form.reset();
        },
        isFailed(failed) {
            return failed;
        },
        onSubmit() {
            new Subscription(this.form)
                .save()
                .then((response) => {
                    this.form.email = null;
                    this.$refs.form.reset();
                    this.$alert.success(this.$t('success'));
                })
                .catch((error) => {

                    if (error.response.status === 422) {
                        return this.$refs.form.setErrors({
                            'email': this.$t('subscription-already-exists')
                        });
                    };
                });
        }
    }
}
</script>

<style lang="less">
.subscription{

    @{desktop} & {
        display: flex;
        align-items: center;

    }

    &__title {
        color: @color-gray;
        font-size: rem(15);
        font-weight: 500;

        @{desktop} & {
            display: flex;

            margin-right: rem(46);
            padding-top: rem(11);

            height: 100%;
        }
    }

    &__form {
        @{desktop} & {
            width: rem(343);
        }
    }

    .form {
        &__error {
            position: static;
        }

        &__main {
            margin-top: rem(8);

            @{desktop} & {
                margin-top: 0;
            }
        }

        &-group {
            @{desktop} & {
                border: rem(1) solid transparent;

                transition: border-color .15s ease;

                &:focus,
                &:hover {
                    border-color: @color-primary;
                }
            }
        }

        &__submit:disabled {
            background-color: @color-primary;

            opacity: 1;
        }
    }
}
</style>
