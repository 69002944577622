<i18n>
    {
        "ru": {
            "copyright": "Aromo.ru&nbsp;&mdash; Энциклопедия парфюмерии",
            "rights": "Все права защищены, (с)",
            "nav": {
                "encyclopedia": "Энциклопедия",
                "journal": "Журнал",
                "pages": "Другие разделы"
            },
            "docs": {
                "terms-of-use": "Пользовательское соглашение",
                "privacy-policy": "Политика конфиденциальности",
                "copyright-holders-policy": "Правообладателям"
            },
            "site-map": "Карта сайта",
            "pc": "Полная версия сайта",
            "mobile": "Вернуться в мобильную версию",
            "confirm_text": "Внимание! Вы действительно хотите покинуть мобильный сайт и перейти к полной версии?"
        },
        "en": {
            "copyright": "Perfume Community",
            "rights": "All rights reserved ©",
            "nav": {
                "catalog": "Navigation",
                "pages": "Other pages"
            }
        }
    }
</i18n>

<template>
    <footer class="footer">
        <div class="footer__blocks-column">

            <div class="footer__document-desktop"
                v-if="isDeviceMobileDesktop">
                <button
                    @click.prevent="goDesktop"
                    class="btn btn_bordered">
                    {{ $t('mobile') }}
                </button>
            </div>

            <div class="footer-nav__wrapper">
                <div class="footer__nav footer-nav" :data-opened="nav.encyclopedia.opened">
                    <div class="footer-nav__head" @click="toggleNav('encyclopedia')">{{ $t('nav.encyclopedia') }}</div>
                    <div class="footer-nav__body">
                        <nav-list class="footer__catalog"
                            :type="'catalog'"
                            is-footer
                            :mixin-type="'sections'"
                            :mode="'footer-sections'"
                            :sort="isDeviceDesktop ? nav.encyclopedia.sortDesktop : nav.encyclopedia.sort"/>
                    </div>
                </div>

                <div class="footer__nav footer-nav" :data-opened="nav.journal.opened">
                    <div class="footer-nav__head" @click="toggleNav('journal')">{{ $t('nav.journal') }}</div>
                    <div class="footer-nav__body">
                        <nav-list class="footer__pages"
                            :type="'sections'"
                            :mode="'footer-pages'"
                            :sort="nav.journal.sort"/>
                    </div>
                </div>

                <div class="footer__nav footer-nav" :data-opened="nav.pages.opened">
                    <div class="footer-nav__head" @click="toggleNav('pages')">{{ $t('nav.pages') }}</div>
                    <div class="footer-nav__body">
                        <nav-list class="footer__pages"
                            :type="'pages'"
                            :mode="'footer-pages'"
                            :sort="nav.pages.sort"/>
                    </div>
                </div>

                <div v-if="!isDeviceDesktop" class="footer__documents">
                    <div class="footer__document">
                        <html-link
                            to="/legal/terms-of-use/"
                            class="footer__document-link">
                            {{ $t('docs.terms-of-use') }}
                        </html-link>
                    </div>
                    <div class="footer__document">
                        <html-link
                            to="/legal/privacy-policy/"
                            class="footer__document-link">
                            {{ $t('docs.privacy-policy') }}
                        </html-link>
                    </div>
                    <div class="footer__document">
                        <html-link
                            to="/legal/for-right-holgers/"
                            class="footer__document-link">
                            {{ $t('docs.copyright-holders-policy') }}
                        </html-link>
                    </div>
                    <div class="footer__document">
                        <nuxt-link
                            to="/sitemap/"
                            class="footer__document-link">
                            {{ $t('site-map') }}
                        </nuxt-link>
                    </div>
                    <div class="footer__document"
                        v-if="!isDeviceMobileDesktop">
                        <button
                            @click.prevent="goDesktop"
                            class="footer__document-link">
                            {{ $t('pc') }}
                        </button>
                    </div>
                </div>
            </div>

            <div class="footer__desktop-row footer__desktop-row_bordered">
                <div class="footer__wrapper-social">
                    <subscription class="footer__subscription" />

                    <div class="social-block social-block_links footer__social-block">
                        <a v-if="getSocialLinkUrl('yt')"
                            target="_blank"
                            rel="nofollow noreferrer"
                            :href="getSocialLinkUrl('yt')"
                            class="social-block__link"
                            aria-label="youtube">
                            <svg-icon name="icon-social-youtube" class="social-block__icon" />
                        </a>
                        <!-- <a v-if="getSocialLinkUrl('in')"
                            target="_blank"
                            rel="nofollow noreferrer"
                            :href="getSocialLinkUrl('in')"
                            class="social-block__link"
                            aria-label="instagram">
                            <svg-icon name="icon-social-ins" class="social-block__icon" />
                        </a> -->
                        <a v-if="getSocialLinkUrl('tg')"
                            target="_blank"
                            rel="nofollow noreferrer"
                            :href="getSocialLinkUrl('tg')"
                            class="social-block__link"
                            aria-label="telegram">
                            <svg-icon name="icon-social-tg" class="social-block__icon" />
                        </a>
                        <!-- <a v-if="getSocialLinkUrl('fb')"
                            target="_blank"
                            rel="nofollow noreferrer"
                            :href="getSocialLinkUrl('fb')"
                            class="social-block__link"
                            aria-label="facebook">
                            <svg-icon name="icon-social-fb" class="social-block__icon" />
                        </a> -->
                        <a v-if="getSocialLinkUrl('vk')"
                            target="_blank"
                            rel="nofollow noreferrer"
                            :href="getSocialLinkUrl('vk')"
                            class="social-block__link"
                            aria-label="vkontakte">
                            <svg-icon name="icon-social-vk" class="social-block__icon" />
                        </a>
                        <!-- <a v-if="getSocialLinkUrl('tw')"
                            target="_blank"
                            rel="nofollow noreferrer"
                            :href="getSocialLinkUrl('tw')"
                            class="social-block__link"
                            aria-label="twitter">
                            <svg-icon name="icon-social-tw" class="social-block__icon" />
                        </a> -->
                        <a v-if="getSocialLinkUrl('ok')"
                            target="_blank"
                            rel="nofollow noreferrer"
                            :href="getSocialLinkUrl('ok')"
                            class="social-block__link"
                            aria-label="odnoklassniki">
                            <svg-icon name="icon-social-ok-dark" class="social-block__icon" />
                        </a>

                    </div>
                </div>
            </div>
        </div>
        <div class="footer__bottom">
            <div v-if="isDeviceDesktop" class="footer__documents">
                <div class="footer__document">
                    <html-link
                        to="/legal/terms-of-use/"
                        class="footer__document-link">
                        {{ $t('docs.terms-of-use') }}
                    </html-link>
                </div>
                <div class="footer__document">
                    <html-link
                        to="/legal/privacy-policy/"
                        class="footer__document-link">
                        {{ $t('docs.privacy-policy') }}
                    </html-link>
                </div>
                <div class="footer__document">
                    <html-link
                        to="/legal/for-right-holgers/"
                        class="footer__document-link">
                        {{ $t('docs.copyright-holders-policy') }}
                    </html-link>
                </div>
                <div class="footer__document">
                    <nuxt-link
                        to="/sitemap/"
                        class="footer__document-link">
                        {{ $t('site-map') }}
                    </nuxt-link>
                </div>
            </div>

            <div v-if="isDeviceDesktop" class="footer__copyright">
                <!-- eslint-disable-next-line -->
                <p v-html="$t('copyright')"></p>
                <!-- eslint-disable-next-line -->
                <p><span v-html="$t('rights')"></span> <span itemprop="copyrightYear">{{ new Date().getFullYear() }}</span></p>
            </div>
            <!-- eslint-disable-next-line -->
            <p v-if="!isDeviceDesktop" v-html="$t('copyright')"></p>
            <!-- eslint-disable-next-line -->
            <p v-if="!isDeviceDesktop"><span v-html="$t('rights')"></span> <span itemprop="copyrightYear">{{ new Date().getFullYear() }}</span></p>
        </div>
    </footer>
</template>

<script>
import { mapGetters } from 'vuex';
import Subscription from '@/components/forms/Subscription'
import NavList from '@/components/layout/nav/NavList';

export default {
    name: 'AppFooter',
    serverCacheKey(props) {
        return 'desktop:' + props.viewDesktop;
    },
    components: {
        NavList,
        Subscription
    },
    props: {
        viewDesktop: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            nav: {
                encyclopedia: {
                    opened: false,
                    sort: [
                        'perfumes',
                        'producers',
                        'brands',
                        'glossary',
                        'noses',
                        'shops',
                        'notes',
                        'sets',
                        'segments',
                        'perfumes_by_year',
                        'families',
                        'brands_by_country',
                        'concentrations',
                        'licensees',
                        'recommendations'
                    ],
                    sortDesktop: [
                        'perfumes',
                        'brands',
                        'noses',
                        'notes',
                        'families',
                        'segments',
                        'concentrations',
                        'producers',
                        'glossary',
                        'shops',
                        'sets',
                        'perfumes_by_year',
                        'brands_by_country',
                        'licensees',
                        'recommendations'
                    ]
                },
                journal: {
                    opened: false,
                    sort: [
                        'feed',
                        'news',
                        'library',
                        'video'
                    ],
                },
                pages: {
                    opened: false,
                    sort: [
                        'about',
                        'community',
                        'help',
                        'cooperation',
                        'contacts',
                    ],
                },
            },
            documentsList: []
        }
    },
    computed: {
        ...mapGetters(['getSocialLinkUrl', 'isDeviceDesktop', 'isDeviceMobileDesktop']),
    },
    mounted() {
        if (this.isDeviceDesktop) {
            this.nav.encyclopedia.opened = true;
            this.nav.journal.opened = true;
            this.nav.pages.opened = true;
        }
    },
    methods: {
        goDesktop() {
            const value = this.isDeviceMobileDesktop ? 'n' : 'y'
            const accepted = this.isDeviceMobileDesktop ?
                true :
                confirm(this.$t('confirm_text'))

            if (accepted) {
                this.$cookies.set('is_mobile_pc', value)
                window.location.reload(true)
            }
        },
        toggleNav(type) {
            if (!this.isDeviceDesktop) {
                this.nav[type].opened = !this.nav[type].opened;
            }
        }
    }
}
</script>

<style lang="less">
.footer {
    background: @color-white;

    padding: rem(12) rem(20) rem(32);

    width: 100%;

    content-visibility: auto;

    @{desktop} & {
        padding-bottom: rem(12);
        min-width: rem(1110);
        padding-left: 0;
        padding-right: 0;
    }

    &__copyright {
        font-size: rem(13);
        text-align: right;
    }

    &__blocks-column {
        @{desktop} & {
            display: flex;
            flex-direction: column-reverse;
            border-bottom: rem(1) solid #ececec;
        }
    }

    &__nav{
        margin-top: rem(8);
    }

    &__catalog {
        @{desktop} & {
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;

            height: rem(182);
        }
    }

    &__documents{
        margin-top: rem(26);

        @{desktop} & {
            display: flex;
            flex-flow: row nowrap;

            margin-top: rem(10);
        }
    }

    &__document{
        margin-top: rem(13);

        @{desktop} & {
            margin-top: 0;

            &:not(:last-of-type) {
                margin-right: rem(28);
            }
        }

        &-link{
            cursor: pointer;

            background: none;
            border: none;
            color: @color-gray;
            font-size: rem(15);
            text-decoration: underline;

            padding: 0;


            .hover({
                text-decoration: none;
            });

            @{desktop} & {
                font-size: rem(13);
                text-decoration: none;

                .hover({
                    text-decoration: underline;
                });
            }
        }

        &-desktop {
            display: flex;
            justify-content: center;
            width: @widthWrapperDesktop;
            margin: 0 auto rem(24);
        }
    }

    &__pages {
        .nav__link-text {
            color: @color-gray;
            font-weight: 300;
        }
    }

    &__bottom {
        color: @color-gray;
        font-size: pxToRem(13);
        text-align: center;

        margin-top: rem(24);

        p {
            margin-bottom: 0;

            &:last-child {
                margin-top: pxToRem(2);

                @{desktop} & {
                    margin-top: 0;
                }
            }

        }

        @{desktop} & {
            display: flex;
            justify-content: space-between;

            margin: rem(12) auto 0;
            padding: 0 rem(20);

            width: @widthWrapperDesktop;
        }
    }

    &__bottom-links{
        padding-top: rem(12);
    }

    &__bottom-link{
        padding-top: rem(3);

        a,button{
            cursor: pointer;

            background: none;
            border: none;
            color: @color-gray;
            font-size: rem(13);
            text-decoration: underline;

            padding: 0;
        }
    }

    &__subscription{
        margin-top: rem(32);

        @{desktop} & {
            margin-top: 0;
        }
    }

    &__desktop-row {

        &_bordered {
            @{desktop} & {
                border-bottom: rem(1) solid #ececec;
                border-top: rem(1) solid #ececec;

                padding: rem(22) 0;
            }
        }
    }

    &__social-block {
        margin-top: rem(25);

        @{desktop} & {
            margin-top: 0;
        }
    }

    .social-block {
        &__link {
            @{desktop} & {
                .animate(background);
                .animate(color);

                .hover({
                    background: @color-black;
                    color: @color-white;
                })
            }
        }
    }

    &__wrapper-social {
        @{desktop} & {
            display: flex;
            justify-content: space-between;

            margin: 0 auto;
            padding: 0 rem(13) 0 rem(20);

            width: @widthWrapperDesktop;
        }
    }
}

.footer-nav {
    border-bottom: rem(1) solid #ececec;

    &__wrapper {
        @{desktop} & {
            display: flex;
            justify-content: space-between;

            margin: rem(18) auto 0;
            padding: 0 rem(48) rem(10) rem(20);

            width: @widthWrapperDesktop;
        }
    }

    @{desktop} & {
        border: none;

        &:first-child {
            width: 59%;
        }

        &:nth-child(2) {
            margin-left: rem(26);

            width: 15%;

            .nav_sections {
                padding-top: 0;
            }
        }

        &:nth-child(3) {
            width: 14.7%;
        }
    }

    &[data-opened="true"] {
        .footer-nav {
            &__head {
                &:before {
                    .rotate(180deg);
                }
            }

            &__body {
                padding: rem(6) 0 rem(16);

                max-height: rem(300);

                visibility: visible;

                @{desktop} & {
                    padding-top: 0;
                }
            }
        }
    }

    &__head {
        font-size: rem(16);
        font-weight: 500;

        padding: rem(5) rem(20) rem(5) 0;

        .wi(icon-chevron-down, 12, 12, @right: 0);

        &:before{
            background-size: contain;

            margin-top: rem(-12/2);

            top: 50%;

            transition: .2s;

            @{desktop} & {
                display: none
            }
        }
    }

    &__body {
        padding: rem(5) 0 0;

        max-height: 0;

        overflow: hidden;

        visibility: hidden;

        transition: .2s;
    }
}
</style>
