<template>
    <transition-group
        class="transition_no-gradient"
        tag="div"
        v-if="type === 'group'"
        name="expand"
        @enter="onEnter"
        @after-enter="afterEnter"
        @leave="onLeave">
        <slot ></slot>
    </transition-group>
    <transition
        v-else
        name="expand"
        :appear="appear"
        @enter="onEnter"
        @after-enter="afterEnter"
        @leave="onLeave">
        <slot ></slot>
    </transition>
</template>

<script>
import includes from "lodash/includes";

export default {
    name: 'TransitionExpand',
    props: {
        type: {
            type: String,
            default: 'single',
            validator(value) {
                return includes(['group', 'single'], value)
            }
        },
        appear: {
            type: Boolean,
            default: false
        },
        noGradient: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        onEnter(el) {
            // Элемент абсолютно позиционируется,
            // чтобы можно было посчитать его auto высоту
            // и не повлиять на остальные элементы на странице
            const { width } = getComputedStyle(el);

            el.style.opacity = 0;
            el.style.width = width;
            el.style.position = `absolute`;
            el.style.visibility = `hidden`;
            el.style.height = `auto`;

            // Теперь получаем высоту
            const { height } = getComputedStyle(el);

            // Сбрасываем стили
            el.style.opacity = null;
            el.style.width = null;
            el.style.position = null;
            el.style.visibility = null;
            el.style.height = 0;

            // Force repaint
            // eslint-disable-next-line no-unused-expressions
            getComputedStyle(el).height;

            // Запуск анимации
            // Используем `requestAnimationFrame`
            // чтобы знать точно,
            // что браузер закончил отрисовку
            // после установки height: 0
            requestAnimationFrame(() => {
                el.style.height = height;
            });
        },
        onLeave(el) {
            el.setAttribute('aria-expanded', 'false')

            const { height } = getComputedStyle(el);
            el.style.height = height;

            // eslint-disable-next-line no-unused-expressions
            getComputedStyle(el).height;

            requestAnimationFrame(() => {
                el.style.height = 0;

                if(this.type === 'group') {
                    el.style.paddingTop = 0;
                    el.style.paddingBottom = 0;
                }
            });
        },
        afterEnter(el) {
            el.style.height = `auto`;

            el.setAttribute('aria-expanded', 'true')
        },
    }
}
</script>

<style scoped lang="less">
* {
    position: relative;

    transform: translateZ(0);
    will-change: height, opacity;
    backface-visibility: hidden;
    perspective: rem(2000);

    &:after {
        content: '';
        display: block;

        background: linear-gradient(rgba(255, 255, 255, 0), white);

        height: rem(50);
        width: 100%;

        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 0;

        opacity: 1;

        transition: opacity .2s ease, height .2s ease, padding .2s;
    }

    &[aria-expanded=true]:after {
        height: 0;

        opacity: 0;

        transition: opacity .1s ease, height .1s .1s ease, padding .1s;
    }

    .transition_no-gradient {
        &:after {
            content: none;
        }
    }
}
</style>

<style>
.expand-enter-active,
.expand-leave-active {
    overflow: hidden;

    transition: height .7s ease, opacity 1s ease, padding .7s ease;
}
.expand-enter,
.expand-leave-to {
    height: 0;

    opacity: .4;

    transition: height .7s ease, opacity 1s ease, padding .7s ease, margin .7s;
}
</style>
