<i18n>
    {
        "en": {},
        "ru": {
            "not-found": "Документ не найден",
            "close": "Закрыть"
        }
    }
</i18n>
<template>
    <modal-content>
        <template #title>{{ title }}</template>

        <template #body>
            <div class="modal-document"
                :class="modalDocumentClasses"
                :data-loading="!text">
                <!-- eslint-disable-next-line -->
                <div class="modal-document__text" v-html="text" />
                <div class="modal-document__bottom"
                    v-if="text">
                    <span class="btn btn_primary modal-document__close"
                        @click="close">
                        {{ $t('close') }}
                    </span>
                </div>
            </div>
        </template>
    </modal-content>
</template>
<script>
import ModalContent from '@/components/modal/ModalContent'
import Document from '@/models/document'
export default {
    name: 'BaseDocument',
    components: {
        ModalContent
    },
    props: {
        id: {
            type: String,
            required: true
        },
        documentFullWidth: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            title: null,
            text: null
        }
    },
    computed: {
        modalDocumentClasses() {
            return {
                'modal-document_full-width': this.documentFullWidth
            }
        }
    },
    watch: {
        text(text) {
            if (text.search('<br>') === 0) {
                this.text = text.replace('<br>', '');
            }
        }
    },
    mounted() {
        Document.find(this.id)
            .then((doc) => {
                this.title = doc.name;
                this.text = doc.description;
            }).catch(() => {
                this.text = this.$t('not-found')
            });
    },
    methods: {
        close() {
            this.$parent.close();
        }
    }
}
</script>

<style lang="less">
.modal-document {
    padding-bottom: rem(64);

    height: 70vh;
    width: rem(300);
    min-width: rem(280);
    max-width: 80vw;

    position: relative;

    @media @landscape{
        height: 60vh;
    }

    @{desktop} & {
        width: 740px;
    }

    &[data-loading="true"] {
        background: url('~static/img/loading-sm.gif') no-repeat 50%;
    }

    &__text {
        font-size: rem(14);

        max-height: 100%;

        overflow-x: hidden;
        overflow-y: auto;
    }

    &__bottom{
        padding-top: rem(20);

        height: rem(64px);
        width: 100%;

        position: absolute;
        bottom: 0;
        left: 0;
    }

    &__close{
        display: block;

        margin: 0 auto;
        
        max-width: 300px;
    }

    &_full-width {
        width: 100%;
        min-width: initial;
        max-width: initial;
    }
}
</style>
