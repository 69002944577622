// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../static/img/loading-btn-transparent.gif");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app-monitoring-block{text-align:center;min-height:100vh;background-color:#fff;z-index:109;padding:120px 0;display:flex;justify-content:center;align-items:center;position:relative}.app-monitoring-block__logo{width:156px;height:26px;position:absolute;top:56px;left:auto;right:auto;margin:0 auto}.app-monitoring-block__content{max-width:888px;margin:0 auto 40px;padding:100px 116px;flex:1;box-sizing:border-box;background-color:#fafafa}.app-monitoring-block__content-message{font-weight:500;font-size:18px;line-height:27px}.app-monitoring-block__content-button{width:220px;margin:40px auto 0}.app-monitoring-block__content-button[disabled]{background-color:#fff}.app-monitoring-block__content-button[data-loading=true]{pointer-events:none;background-color:#fff;border-color:#ececec}.app-monitoring-block__content-button[data-loading=true]:after{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat 50%;background-size:24px;border:1px solid transparent}.app-monitoring-block__content-container{margin-top:24px}.app-monitoring-block__footer{position:absolute;bottom:40px;left:0;right:0}.app-monitoring-block__footer p{margin-bottom:4px}.app-monitoring-block__footer p:last-child{margin-bottom:0}.app-monitoring-block__footer-link{cursor:pointer;color:#613ae7}html.view_mobile .app-monitoring-block{padding-left:20px;padding-right:20px}html.view_mobile .app-monitoring-block__content{padding:24px 24px 32px}html.view_mobile .app-monitoring-block__content-button{margin-top:32px}@media (hover),(min--moz-device-pixel-ratio:0),(min-width:0\\0){.app-monitoring-block__footer-link:hover{color:#141414}}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
