/* eslint-disable object-shorthand */
/* eslint-disable no-console */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import Vue from 'vue';
// Global plugins
import url from '@/filters/url';
import ScrollLock from '@/plugins/scroll-lock/index'
import HashReader from '@/mixins/hash.reader';

const Mixins = {

    install(Vue) {
        Vue.mixin(HashReader)
    }

}

const Url = {

    install(Vue) {
        Vue.prototype.$url = (() => {
            return {
                set(path, params, name = null) {
                    if (process.browser) {
                        const urlPath = url.modify(path, params);
                        window.history.pushState({refreshed: name, urlPath}, null, urlPath);
                    }
                },
                modify(params, name = null) {
                    if (process.browser) {
                        const path = url.modify(window.location.href, params);
                        window.history.pushState({refreshed: name, path}, null, path);
                    }
                }
            }
        })();
    }
}

const Body = {

    install(Vue, app) {
        Vue.prototype.$body = (() => {

            const $body = document.querySelector('.body');
            const isDesktop = app.store.state.deviceTypeDesktop;

            return {
                $el: $body,
                cut() {
                    const isCutted = $body.getAttribute('data-cutted') === 'true';

                    if (isCutted) return;

                    if(isDesktop){
                        $body.style.top = '-' + window.scrollY + 'px';
                        document.querySelector('html').style.setProperty('height', window.innerHeight +'px', 'important');
                    }else{
                        ScrollLock.attach(document.querySelector('.modal__overlay'));
                    }
                    
                    $body.setAttribute('data-cutted', true);
                },
                uncut() {
                    $body.setAttribute('data-cutted', false);

                    if(isDesktop){
                        window.scrollTo(0, Math.abs(parseInt($body.style.top)));
                        $body.removeAttribute('style');
                        document.querySelector('html').style.height = '100%';
                    }else{
                        ScrollLock.detach(document.querySelector('.modal__overlay'));
                    }

                    $body.classList.remove('fixed-header', 'fixed-header-hidden', 'fixed-header-show');
                },
                filterOpen(){
                    $body.classList.add('filter-open')
                },
                filterClose(){
                    $body.classList.remove('filter-open')
                },
                isVisible() {
                    if (document.hidden !== undefined ) {
                        return document.hidden;
                    }

                    return true;
                }
            }
        })();

    }
};

/**
 * Действия пользователя
 * @type {Object}
 */
const Actions = {

    install(Vue) {
        Vue.prototype.$actions = (() => {

            /**
             * Время блокировки по умолчанию, сек.
             * 
             * @type {Number}
             */
            const timeout = 30;

            return {
                /**
                 * Проверяет отсутствие блокировки действия и блокирует.
                 * В случае наличия возвращает сразу false;
                 * 
                 * @param  {Mixed} id уникальная строка
                 * @return {Boolean}
                 */
                checkOrLock(id) {
                    
                    if (this.locked(id)) {
                        return false;
                    }

                    this.lock(id);
                    return true;
                },
                /**
                 * Проверяет наличие блокировки действия
                 * 
                 * @param  {Mixed} id
                 * @return {Boolean}
                 */
                locked(id) {
                    const exists = sessionStorage.getItem(id);

                    if (exists) {
                        if ((Date.now() - exists) < timeout*1000) {
                            return true;
                        }

                        this.unlock(id); // удаление устаревших блокировок
                    }

                    return false;
                },
                /**
                 * Устанавливает блокировку по id
                 * 
                 * @param  {Mixed} id
                 */
                lock(id) {
                    sessionStorage.setItem(id, Date.now());
                },
                /**
                 * Снимает блокировку по id
                 * 
                 * @param  {Mixed} id
                 */
                unlock(id){
                    sessionStorage.removeItem(id);
                }
            }
        })();

    }
};

export default ({app}, inject) => {
    Vue.use(Url);
    Vue.use(Body, app);
    Vue.use(Mixins);
    Vue.use(Actions);
}
