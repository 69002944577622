<template>
    <button
        v-tooltip="tooltipOptions"
        class="help-button"
        :class="{'help-button_autohide': !tooltipAutoHide}"
        aria-label="help"
        @click.prevent="showTooltip">
        <svg-icon
            class="help-button__icon"
            name="help-circle"/>
    </button>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'HelpButton',
    props: {
        title: {
            type: String,
            default: ''
        },
        content: {
            type: String,
            default: ''
        },
        buttonText: {
            type: String,
            default: null
        },
        container: {
            type: String,
            default: null
        },
        buttonEvent: {
            type: String,
            default: null
        },
        force: {
            type: Boolean,
            default: false
        },
        tooltipDirection: {
            type: String,
            default: ''
        },
        tooltipAutoHide: {
            type: Boolean,
            default: true
        },
        tooltipClasses: {
            type: [String, Object, Array],
            default() {
                return []
            }
        },
        inside: {
            type: Boolean,
            default: false
        },
        placement: {
            type: [String,Boolean],
            default: 'top'
        }
    },
    computed: {
        ...mapGetters(['isDeviceDesktop']),
        tooltipOptions() {
            const desktopOptions = {
                content: this.contentComputed,
                delay: { show: 150, hide: 100 },
                offset: 8,
                autoHide: this.tooltipAutoHide,
                classes: this.tooltipClasses
            };

            if (this.inside) {
                desktopOptions.container = false;
            };

            if (this.placement) {
                desktopOptions.placement = this.placement;
            };

            if (this.container) {
                desktopOptions.container = this.container
                desktopOptions.boundariesElement = this.container
            }

            return this.isDeviceDesktop ? desktopOptions : {}
        },
        contentComputed() {

            let content = this.content;
            if (this.buttonText) {
                content += '<span class="btn btn_primary tooltip__bottom-btn"'
                if (this.buttonEvent) {
                    content += ' onclick="'+ this.buttonEvent +'"';
                }
                content += '>' + this.buttonText + '</span>';
            }

            return content;
        }
    },
    methods: {
        showTooltip() {
            if(!this.isDeviceDesktop || this.force) {
                this.showPopup('tooltip', {
                    title: this.title,
                    content: this.contentComputed
                })
            }
        }
    }
}
</script>

<style lang="less">
.help-button {

    --size: rem(16);

    background: none;
    border: none;
    outline: none;

    padding: 0;

    position: relative;

    &__icon {
        background-color: white;
        border-radius: 50%;
        color: @color-gray-light;

        height: var(--size);
        width: var(--size);

        transition: color .15s ease;

        .hover({
            color: @color-black;
        });
    }

    &__content {
        background: @color-white;
        box-shadow: @menu-desktop-box-shadow;
        font-size: rem(13);

        padding: rem(8, 16);

        width: rem(281);

        position: absolute;
        left: rem(-106);
        z-index: 10;

        visibility: hidden;
        opacity: 0;

        .animate(opacity, .2s, .2s);
    }

    .hover({
        &__content {
            visibility: visible;
            opacity: 1;
        }
    });

    &_autohide{
        @{desktop} & {
            &:hover{
                &::before{
                    width: 100%;
                    height: rem(20);
                    top: rem(-8);
                    content: '';
                    position: absolute;
                }
            }
        }
    }
}

</style>
