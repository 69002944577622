<template>
    <div
        class="info-table-row"
        :class="rootClasses">
        <div v-if="$slots.date" class="info-table-row__date"><slot name="date"></slot></div>

        <div
            v-if="multipleRow && $slots.rows"
            class="info-table-row__items">
            <slot name="rows"></slot>
        </div>

        <div v-if="$slots.title" class="info-table-row__title">
            <slot name="title"></slot>
        </div>

        <div v-if="$slots.entry"
            class="info-table-row__entry"
            :class="entryClasses">
            <slot name="entry"></slot>
        </div>

        <div :class="contentClasses"
            class="info-table-row__content"
            v-if="$slots.content">
            <slot name="content"></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: 'InfoTableRow',
    props: {
        contentNoMargin: {
            type: Boolean,
            default: false
        },
        contentSmall: {
            type: Boolean,
            default: false
        },
        entryToBottom: {
            type: Boolean,
            default: false
        },
        multipleRow: {
            type: Boolean,
            default: false
        },
        largePadding: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        rootClasses() {
            return {
                "info-table-row_multiple": this.multipleRow,
                "info-table-row_large-padding": this.largePadding,
            }
        },
        contentClasses() {
            return {
                "info-table-row__content_no-margin": this.contentNoMargin,
                "info-table-row__content_small": this.contentSmall
            }
        },
        entryClasses() {
            return {
                "info-table-row__entry_to-bottom": this.entryToBottom
            }
        }
    }
}
</script>

<style lang="less">
.info-table-row {
    --content-margin-top: rem(20);
    --title-margin-top: 0;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;

    border-bottom: 1px solid @color-gray-white;
    color: @color-black;

    padding: rem(12.5) rem(0) rem(12);

    @{mobile} & {
        overflow-x: scroll;

        .scrollbar-hidden();
    }

    &:first-of-type {
        padding-top: rem(7);
    }

    &:last-of-type {
        @{mobile} &{
            border-bottom: none;
        }
    }

    @{desktop} & {
        padding: rem(13) rem(0) rem(11.5);
    }

    &__title {
        font-size: rem(15);
        font-weight: 500;

        margin-top: var(--title-margin-top);

        @{desktop} & {
            line-height: rem(22.5);
        }
    }

    &__entry {
        font-size: rem(15);
        text-align: right;
        white-space: nowrap;

        max-width: 70%;

        a,
        &-link {
            color: inherit;
            text-decoration: underline;

            transition: color .15s ease;

            .hover({
                color: @color-primary;
            });
        }

        &_to-bottom {
            display: flex;

            text-align: initial;
            white-space: normal;

            margin-top: rem(6);

            width: 100%;
            max-width: none;

            @{desktop} & {
                margin-bottom: rem(3);
            }
        }
    }

    &__content {
        margin-top: var(--content-margin-top);

        width: 100%;

        &_no-margin {
            margin-top: 0;
        }

        &_small {
            color: @color-gray;
            font-size: rem(13);
        }

        &-row{
            display: block;
            margin-bottom: rem(8);

            &:first-child{
                margin-top: rem(5);
            }

            &:last-child{
                margin-bottom: 0;
            }
        }
    }

    &__date {
        color: @color-gray;
        font-size: rem(13);

        width: 100%;
    }

    &__items {
        width: 100%;
    }

    &_multiple {
        padding: rem(15) rem(0) rem(14);

        &:first-of-type {
            padding-top: rem(20);
        }

        &:last-of-type {
            border-bottom: 1px solid @color-gray-white;
        }
    }

    &_large-padding {
        padding-top: rem(16);
        padding-bottom: rem(16);
    }
}
</style>
