import forEach from "lodash/forEach";

/**
 * Возвращает массив, разбитый на колонки 
 * для display: inline-block
 *
 * @param  {Object} obj
 * @return {Object}
 */
const toInlineColumns = (list, numberOfColumns = 2) => {
    
    const result = [];
    const columns = [];
    const perColumn = list.length%numberOfColumns === 0 ? list.length/numberOfColumns : Math.floor(list.length/numberOfColumns)+1;

    forEach(list, (item, i) => {
        if (item) {
            const index = Math.floor(i/perColumn);
            
            if (!columns[index]) {
                columns[index] = [];
            }
            columns[index].push(item);
        }
    });

    for (let y = 0; y < perColumn; y++) {
        forEach(columns, column => {
            if (column[y]) {
                result.push(column[y]);
            }
        });
    }

    return result;
};

export default {
    toInlineColumns
}