
/* eslint-disable no-useless-constructor */
import Model from '@/models/Model'

export default class Like extends Model {
    resource() {
        return 'likes'
    }

    _create() {
        return this.request({
            method: 'POST',
            url: this.endpoint(),
            data: this.getFormData(this),
            progress: false
        }).then(response => {
            const self = Object.assign(this, response.data)
            return self
        })
    }

    delete(data) {
        return this.request({
            method: 'DELETE',
            url: this.endpoint(),
            data,
            progress: false
        }).then(response => {
            const self = Object.assign(this, response.data)
            return self
        });
    }
}
