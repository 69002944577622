import qs from 'qs';
import forEach from 'lodash/forEach';

/**
 * Разбирает URL
 *
 * @param  {String} value
 * @return {Object}
 */
const parse = (value) => {
    if (typeof URL === 'function') {
        let url = '';
        try {
            url = (new URL(value));
        } catch (error) {
            console.log(value);
        }
        return url;
    }

    // IE client support
    return document.getElementsByTagName('a')[0];
};
    
/**
 * Модифицирует URL
 * 
 * @param  {String} url
 * @param  {Object} queryParams
 * @param  {Object} slugParams
 * @return {String}
 */
const modify = (url, queryParams = {}, slugParams = {}) => {

    const partOfSlug = ['page'];
    const { query, hash } = directParse(url);
    let { path } = directParse(url);

    for (const key in queryParams) {
        if (!partOfSlug.includes(key) && !slugParams[key]) {
            if (!queryParams[key]) {
                delete query[key];
            } else {
                query[key] = queryParams[key];
            }
        }
    };

    if (queryParams.page !== undefined) {
        const page = getSlugParameter(url, 'page');
        if (page) {
            path = path.replace('/page/' + page, queryParams.page > 1 ? '/page/' + queryParams.page : '');
        } else {
            path += queryParams.page > 1 ? 'page/' + queryParams.page + '/' : '';
        }
    }

    for (const key in slugParams) {
        const value = getSlugParameter(path, key);
        if (value) {
            path = path.replace('/'+ key +'/' + value, slugParams[key] ? '/'+ key +'/' + slugParams[key] : '');
        } else {
            path += slugParams[key] ? '/'+ key +'/' + slugParams[key] : '';
        }
    };
        
    const queryString = serialize(query);

    return path 
        + (queryString.length > 1 ? '?' + queryString : '')
        + (hash ? '#' + hash : '');
};

/**
 * Форматирует объект с параметрами в формат для REST
 * 
 * @param  {Object} params
 * @return {Object}
 */
const restQueryObject = (params) => {

    const result = {};
    for (const key in params) {
        const value = params[key];

        if (Array.isArray(value)) {
            if (value.length > 0) {
                result[key] = value.join(',');
            }
        } else if (value && (typeof value !== 'string' || value.length > 0)) {
            if (typeof value === 'boolean') {
                result[key] = 'y';
            } else {
                result[key] = value;
            }
        }
    }

    return result;
};

/**
 * Форматирует объект с параметрами в формат для REST
 * 
 * @param  {Object} params
 * @param  {Object} additionalParams
 * @return {Object}
 */
const restQueryString = (params = {}) => {

    const result = [];
    const data = restQueryObject(params);

    for (const key in data) {
        result.push(key + "=" + data[key]);
    }

    return result.join('&');
};

/**
 * Склеивает многомерный query-объект в плоский
 *
 * @param  {Object} query
 * @return {Object}
 */
const flatQuery = (query) => {

    const result = {};

    for (const top in query) {
        if (typeof query[top] === 'object') {
            for (const e in query[top]) {
                if (typeof query[top][e] === 'object') {
                    for (const i in query[top][e]) {
                        result[top + '['+ e +']['+ i +']'] = query[top][e][i];
                    }
                } else {
                    result[top + '['+ e +']'] = query[top][e];
                }
            }
        } else {
            result[top] = query[top];
        }
    }

    return result;
};

/**
 * Возвращает строку параметров для parse-url из GET
 * 
 * @param  {Object} query
 * @return {String}
 */
const queryFilterToRestString = (query) => {

    const { filter } = qs.parse(query);

    if (filter) {
        const params = [];

        forEach(filter, (values, key) => {
            params.push(key + ':' + values.join(','));
        });

        return params.join('|');
    }

    return null;
};


/**
 * Склеивает параметры и значения из объекта в query-строку
 * 
 * @param  {Object} params
 * @return {String}
 */
const serialize = (params) => {
    const query = [];
    const options = flatQuery(params);

    for (const key in options) {
        if (Object.prototype.hasOwnProperty.call(options, key)) {
            query.push(encodeURIComponent(key) + "=" + encodeURIComponent(options[key]));
        }
    }

    return query.join("&");
};

/**
 * Возвращает разборанный относительный URL
 *
 * @param  {String} value
 * @return {Object}
 */
const directParse = (value) => {
    if (!value) return

    const [url, hash] = value.split('#');
    const [path, queryString] = url.split('?');
    const query = qs.parse(queryString);

    return {
        path,
        query,
        hash
    };
};

/**
 * Возвращает объект с параметрами из query
 * 
 * @param  {Object} query
 * @return {Object}
 */
const parseUrlQuery = (url) => {
    
    return directParse(url).query;
};

/**
 * Возвращает строку, заменяя в ней параметры запроса
 * 
 * @param  {String} url
 * @return {Object}
 */
const modifyApiQuery = (url, queryParams = {}) => {
    
    const { path, query } = directParse(url);

    for (const key in queryParams) {
        if (!queryParams[key]) {
            delete query[key];
        } else {
            query[key] = queryParams[key];
        }
    };

    const queryString = restQueryString(query);

    return path + (queryString.length > 0 ? '?' + queryString : '');
};
    
/**
 * Возвращает параметр slug
 * 
 * @param  {String} url
 * @param  {String} key
 * @return {Number}
 */
const getSlugParameter = (url, key) => {

    let value = null;
    const { path } = directParse(url);
    const parts = path.split('/').filter((part) => part);

    const penultimateKey = parts[parts.length-2];
    if (penultimateKey) {
        if (penultimateKey === key) {
            value = parts[parts.length-1];
        }
    }

    return value;
};
    
/**
 * Возвращает номер страницы из slug
 * 
 * @param  {String} url
 * @param  {String} key
 * @return {Number}
 */
const getPageNumber = (url, key = 'page') => {

    return parseInt(getSlugParameter(url, key) || 1);
};

export default {
    parse,
    flatQuery,
    restQueryObject,
    restQueryString,
    queryFilterToRestString,
    parseUrlQuery,
    directParse,
    modify,
    modifyApiQuery,
    serialize,
    getPageNumber,

    /**
     * Формирует строку адреса с параметрами
     * 
     * @param  {String} url
     * @param  {Object} params
     * @return {String}
     */
    restQueryUrl(url, params = {}) {

        const query = restQueryString(params);
        return (url + (query.length > 1 ? '?' + query : ''));
    },

    /**
     * Возвращает относительный URL
     *
     * @param  {String} value
     * @return {String}
     */
    relative(value) {

        const url = parse(value);
        return url.pathname + (url.search || '') + (url.hash || '');
    },

    /**
     * Возвращает относительный URL для API
     *
     * @param  {String} value
     * @return {String}
     */
    api(value) {

        const url = parse(value);
        return url.pathname.replace('/api', '') + (url.search || '');
    }
};
