/* eslint-disable import/default */
/* eslint-disable no-console */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import get from 'lodash/get'
import includes from 'lodash/includes'
import Vue from 'vue'
import Router from 'vue-router'
import scrollBehavior from './router.scrollBehavior.js';

Vue.use(Router)

const AccountConfirmation = () => import('@/components/accounts/Confirmation').then(m => m.default || m);
const EmailConfirmation = () => import('@/components/accounts/EmailConfirmation').then(m => m.default || m);
const PasswordRecoveryRoute = () => import('@/components/accounts/PasswordRecoveryRoute').then(m => m.default || m);

const Articles = () => import('@/pages/articles').then(m => m.default || m)
const ArticlesRubric = () => import('@/pages/articles/rubric').then(m => m.default || m)
const ArticlesDetail = () => import('@/pages/articles/detail').then(m => m.default || m)
const ArticlesDetailAmp = () => import('@/pages/articleAmp').then(m => m.default || m)
const Brand = () => import('@/pages/catalog/brands/detail').then(m => m.default || m)
const BrandCatalog = () => import('@/pages/catalog/brands/').then(m => m.default || m)
const Concentrations = () => import('@/pages/catalog/concentrations/concentrations').then(m => m.default || m)
const ConcentrationsDetail = () => import('@/pages/catalog/concentrations/concentrationsDetail').then(m => m.default || m)
const CountryBrand = () => import('@/pages/catalog/regions/brands').then(m => m.default || m)
const Community = () => import('@/pages/community/').then(m => m.default || m)
const Families = () => import('@/pages/catalog/families/families').then(m => m.default || m)
const FamiliesDetail = () => import('@/pages/catalog/families/familiesDetail').then(m => m.default || m)
const Feed = () => import('@/pages/feed/feed').then(m => m.default || m)
const Glossary = () => import('@/pages/catalog/glossary/glossary').then(m => m.default || m)
const GlossaryDetail = () => import('@/pages/catalog/glossary/glossaryDetail').then(m => m.default || m)
const Home = () => import('@/pages').then(m => m.default || m)
const Licenseholders = () => import('@/pages/catalog/licenseholders/licenseholders').then(m => m.default || m)
const LicenseholdersDetail = () => import('@/pages/catalog/licenseholders/licenseholdersDetail').then(m => m.default || m)
const Notes = () => import('@/pages/catalog/notes/catalog').then(m => m.default || m)
const NoteGroups = () => import('@/pages/catalog/notes/groups').then(m => m.default || m)
const NoteDetail = () => import('@/pages/catalog/notes/detail').then(m => m.default || m)
const FragranceSelection = () => import('@/pages/catalog/notes/fragranceselection').then(m => m.default || m)
const Noses = () => import('@/pages/catalog/noses/noses').then(m => m.default || m)
const NosesDetail = () => import('@/pages/catalog/noses/nosesDetail').then(m => m.default || m)
const News = () => import('@/pages/news/').then(m => m.default || m)
const NewsRubric = () => import('@/pages/news/rubric').then(m => m.default || m)
const NewsDetail = () => import('@/pages/news/detail').then(m => m.default || m)
const NewsDetailAmp = () => import('@/pages/articleAmp').then(m => m.default || m)
const Notifications = () => import('@/pages/profile/notifications/notifications').then(m => m.default || m)
const Milliliters = () => import('@/pages/profile/milliliters').then(m => m.default || m)
const MillilitersExchange = () => import('@/pages/profile/milliliters/exchange').then(m => m.default || m)
const MillilitersGift = () => import('@/pages/profile/milliliters/gift').then(m => m.default || m)
const Messages = () => import('@/pages/profile/messages/messages').then(m => m.default || m)
const CreateMessage = () => import('@/pages/profile/messages/createMessage').then(m => m.default || m)
const UserDialog = () => import('~/pages/profile/messages/userDialog').then(m => m.default || m)
const Producers = () => import('@/pages/catalog/producers').then(m => m.default || m)
const ProducersDetail = () => import('@/pages/catalog/producers/producer').then(m => m.default || m)
const Perfumes = () => import('@/pages/catalog/perfumes/').then(m => m.default || m)
const Perfume = () => import('@/pages/catalog/perfumes/detail/').then(m => m.default || m)
const PerfumesSets = () => import('@/pages/catalog/sets/sets').then(m => m.default || m)
const PerfumesSetsDetail = () => import('@/pages/catalog/sets/setsDetail').then(m => m.default || m)
const PopularTags = () => import('@/pages/tags/popular').then(m => m.default || m)
const Points = () => import('@/pages/profile/points').then(m => m.default || m)
const Regions = () => import('@/pages/catalog/regions').then(m => m.default || m)
const RegionCountries = () => import('@/pages/catalog/regions/countries').then(m => m.default || m)
const RegionArabic = () => import('@/pages/catalog/regions/arabic').then(m => m.default || m)
const Segments = () => import('@/pages/catalog/segments/segments').then(m => m.default || m)
const SegmentsDetail = () => import('@/pages/catalog/segments/segmentsDetail').then(m => m.default || m)
const ShopsCatalog = () => import('@/pages/catalog/shops/index').then(m => m.default || m)
const ShopsDetail = () => import('@/pages/catalog/shops/shopsDetail').then(m => m.default || m)
const Subscribers = () => import('@/pages/profile/subscribers').then(m => m.default || m)
const Tag = () => import('@/pages/tags/detail').then(m => m.default || m)
const Wardrobe = () => import('@/pages/profile/wardrobe').then(m => m.default || m)
const WardrobeDetail = () => import('@/pages/profile/wardrobe/detail').then(m => m.default || m)
const WardrobeAddPerfume = () => import('@/pages/profile/wardrobe/addPerfume').then(m => m.default || m)
const WardrobeEditPerfume = () => import('@/pages/profile/wardrobe/editPerfume').then(m => m.default || m)
const User = () => import('@/pages/profile').then(m => m.default || m)
const UserSettings = () => import('@/pages/profile/settings').then(m => m.default || m)
const UsersPost = () => import('@/pages/profile/post').then(m => m.default || m)
const UserFavorites = () => import('@/pages/profile/favorites').then(m => m.default || m)
const UserSubcriptions = () => import('@/pages/profile/subscriptions').then(m => m.default || m)
const Years = () => import('@/pages/catalog/years/years').then(m => m.default || m)
const Year = () => import('@/pages/catalog/years/year').then(m => m.default || m)
const About = () => import('@/pages/info/about').then(m => m.default || m)
const SiteMap = () => import('@/pages/info/site-map').then(m => m.default || m)
const Cooperation = () => import('@/pages/info/cooperation').then(m => m.default || m)
const Legal = () => import('@/pages/info/legal').then(m => m.default || m)
const Contacts = () => import('@/pages/info/contacts').then(m => m.default || m)
const Help = () => import('@/pages/info/help').then(m => m.default || m)
const ErrorPage = () => import('@/layouts/error').then(m => m.default || m)
const Diagnostic = () => import('@/pages/info/diagnostic').then(m => m.default || m)

const pathToRegexpOptions = { strict: true }
const metaScrollPos = {scrollPos: {x: 0, y: 0}}

export function createRouter(context) {
    const router = new Router({
        mode: 'history',
        linkActiveClass: 'link_active',
        linkExactActiveClass: 'link_exact-active',
        scrollBehavior,
        routes: [
            {
                path: '/',
                name: 'Home',
                pathToRegexpOptions,
                component: Home
            },
            {
                path: '/community/',
                name: 'Community',
                pathToRegexpOptions,
                component: Community,
            },
            {
                path: '/perfumes/',
                alias: '/perfumes/*',
                name: 'Perfumes',
                pathToRegexpOptions,
                component: Perfumes,
                meta: metaScrollPos
            },
            {
                path: '/brands/',
                alias: '/brands/page/:page/',
                name: 'Brands',
                pathToRegexpOptions,
                component: BrandCatalog,
                meta: metaScrollPos
            },
            {
                path: '/brands/:code/',
                name: 'Brand',
                pathToRegexpOptions,
                component: Brand,
            },
            {
                path: '/shops/:code/',
                name: 'shopsDetail',
                pathToRegexpOptions,
                component: ShopsDetail
            },
            {
                path: '/shops/:code/comments/',
                name: 'shopsDetailComments',
                pathToRegexpOptions,
                component: ShopsDetail
            },
            {
                path: '/shops/:code/owner-confirmation/:request_id/:confirm_code/',
                name: 'shopsDetailConfirmation',
                pathToRegexpOptions,
                component: ShopsDetail
            },
            {
                path: '/shops/',
                alias: '/shops/page/:page/',
                name: 'shops',
                pathToRegexpOptions,
                component: ShopsCatalog,
                meta: metaScrollPos
            },
            {
                path: '/fragrance/:code/',
                name: 'Perfume',
                pathToRegexpOptions,
                component: Perfume
            },
            {
                path: '/users/:userId/posts/:code/',
                name: 'UsersPost',
                pathToRegexpOptions,
                component: UsersPost
            },
            {
                path: '/users/:userId/',
                name: 'users.detail',
                pathToRegexpOptions,
                component: User
            },
            {
                path: '/users/:userId/subscriptions/',
                name: 'users.subscriptions',
                pathToRegexpOptions,
                component: UserSubcriptions
            },
            {
                path: '/users/:userId/favorites/',
                name: 'users.favorites',
                pathToRegexpOptions,
                component: UserFavorites
            },
            {
                path: '/users/:userId/settings/',
                name: 'users.settings',
                pathToRegexpOptions,
                component: UserSettings
            },
            {
                path: '/users/:userId/subscribers/',
                name: 'users.subscribers',
                pathToRegexpOptions,
                component: Subscribers
            },
            {
                path: '/users/:userId/messages/create/',
                name: 'users.createMessage',
                pathToRegexpOptions,
                component: CreateMessage
            },
            {
                path: '/users/:userId/messages/',
                name: 'users.messages',
                pathToRegexpOptions,
                component: Messages
            },
            {
                path: '/users/:userId/messages/archive/',
                name: 'users.messages.archive',
                pathToRegexpOptions,
                component: Messages,
                props: { archiveMode: true }
            },
            {
                path: '/users/:userId/messages/:secondUserId/',
                name: 'users.messages.dialog',
                pathToRegexpOptions,
                component: UserDialog,
                props: { showHeader: false, showFooter: false }
            },
            {
                path: '/feed/',
                name: 'Feed',
                pathToRegexpOptions,
                component: Feed
            },
            {
                path: '/licenseholders/',
                alias: '/licenseholders/page/:page/',
                name: 'Licenseholders',
                pathToRegexpOptions,
                component: Licenseholders,
                meta: metaScrollPos
            },
            {
                path: '/licenseholders/:code/',
                name: 'Licenseholders.detail',
                pathToRegexpOptions,
                component: LicenseholdersDetail
            },
            {
                path: '/years/',
                name: 'Years',
                alias: '/years/page/:page/',
                pathToRegexpOptions,
                component: Years,
                meta: metaScrollPos
            },
            {
                path: '/years/:code/',
                alias: '/years/:code/page/:page/',
                name: 'Year',
                pathToRegexpOptions,
                component: Year
            },
            {
                path: '/notes/',
                alias: '/notes/page/:page/',
                name: 'Notes',
                pathToRegexpOptions,
                component: Notes,
                meta: metaScrollPos
            },
            {
                path: '/notes/fragranceselection/',
                alias: '/notes/fragranceselection/page/:page/',
                name: 'FragranceSelection',
                pathToRegexpOptions,
                component: FragranceSelection,
                meta: metaScrollPos
            },
            {
                path: '/notes/:group/',
                pathToRegexpOptions,
                name: 'NoteGroups',
                component: NoteGroups
            },
            {
                path: '/notes/:group/:code/',
                name: 'NoteDetail',
                pathToRegexpOptions,
                component: NoteDetail
            },
            {
                path: '/noses/',
                alias: '/noses/page/:page/',
                name: 'Noses',
                pathToRegexpOptions,
                component: Noses,
                meta: metaScrollPos
            },
            {
                path: '/noses/:code(\\D[^\\/,%]{1,}?)/',
                name: 'NosesDetail',
                pathToRegexpOptions,
                component: NosesDetail
            },
            {
                path: '/noses/:letter/',
                alias: '/noses/:letter/page/:page/',
                name: 'NosesLetterList',
                pathToRegexpOptions,
                component: Noses,
                meta: metaScrollPos
            },
            {
                path: '/families/',
                name: 'Families',
                pathToRegexpOptions,
                component: Families
            },
            {
                path: '/families/:code/',
                name: 'FamiliesDetail',
                pathToRegexpOptions,
                component: FamiliesDetail
            },
            {
                path: '/segments/',
                name: 'Segments',
                pathToRegexpOptions,
                component: Segments
            },
            {
                path: '/segments/:code/',
                name: 'SegmentsDetail',
                pathToRegexpOptions,
                component: SegmentsDetail
            },
            {
                path: '/producers/',
                name: 'Producers',
                pathToRegexpOptions,
                component: Producers
            },
            {
                path: '/producers/:code/',
                name: 'ProducersDetail',
                pathToRegexpOptions,
                component: ProducersDetail
            },
            {
                path: '/glossary/',
                name: 'Glossary',
                pathToRegexpOptions,
                component: Glossary
            },
            {
                path: '/glossary/:code(\\D[^\\/,%]{1,}?)/',
                name: 'GlossaryDetail',
                pathToRegexpOptions,
                component: GlossaryDetail
            },
            {
                path: '/glossary/:letter/',
                name: 'GlossaryLetter',
                pathToRegexpOptions,
                component: Glossary
            },
            {
                path: '/concentrations/',
                name: 'Concentrations',
                pathToRegexpOptions,
                component: Concentrations
            },
            {
                path: '/concentrations/:code/',
                name: 'ConcentrationsDetail',
                pathToRegexpOptions,
                component: ConcentrationsDetail
            },
            {
                path: '/sets/',
                name: 'Sets',
                pathToRegexpOptions,
                component: PerfumesSets
            },
            {
                path: '/sets/:code/',
                name: 'SetsDetail',
                pathToRegexpOptions,
                component: PerfumesSetsDetail
            },
            {
                path: '/regions/',
                name: 'Regions',
                pathToRegexpOptions,
                component: Regions
            },
            {
                path: '/regions/middle-east/arabic/',
                alias: '/regions/middle-east/arabic/page/:page/',
                name: 'RegionArabic',
                pathToRegexpOptions,
                component: RegionArabic
            },
            {
                path: '/regions/:region/',
                name: 'RegionCountries',
                pathToRegexpOptions,
                component: RegionCountries
            },
            {
                path: '/regions/:region/:country/',
                alias: '/regions/:region/:country/page/:page/',
                name: 'CountryBrand',
                pathToRegexpOptions,
                component: CountryBrand,
                meta: metaScrollPos
            },
            {
                path: '/accounts/password-recovery/:email/:code/',
                component: PasswordRecoveryRoute,
                pathToRegexpOptions,
            },
            {
                path: '/accounts/confirmation/:email/:code/',
                component: AccountConfirmation,
                pathToRegexpOptions,
            },
            {
                path: '/accounts/new-email-confirmation/:email/:code/',
                component: EmailConfirmation,
                pathToRegexpOptions,
            },
            {
                path: '/articles/',
                alias: '/articles/page/:page/',
                name: 'Articles',
                pathToRegexpOptions,
                component: Articles,
                meta: metaScrollPos
            },
            {
                path: '/articles/:subsection(users|all)/',
                alias: '/articles/:subsection(users|all)/page/:page/',
                name: 'ArticlesSub',
                pathToRegexpOptions,
                component: Articles,
                meta: metaScrollPos
            },
            {
                path: '/articles/:rubric/',
                alias: '/articles/:rubric/page/:page/',
                name: 'ArticlesRubric',
                pathToRegexpOptions,
                component: Articles,
                meta: metaScrollPos
            },
            {
                path: '/articles/:rubric/:subsection(users|all)/',
                alias: '/articles/:rubric/:subsection(users|all)/page/:page/',
                name: 'ArticlesRubricSub',
                pathToRegexpOptions,
                component: Articles,
                meta: metaScrollPos
            },
            {
                path: '/articles/:rubric/:code/',
                name: 'ArticlesDetail',
                pathToRegexpOptions,
                component: ArticlesDetail
            },
            {
                path: '/articles/:rubric/:code/amp/',
                name: 'ArticlesDetailAmp',
                pathToRegexpOptions,
                component: ArticlesDetailAmp
            },
            {
                path: '/news/',
                alias: '/news/page/:page/',
                name: 'News',
                pathToRegexpOptions,
                component: News,
                meta: metaScrollPos
            },
            {
                path: '/news/:rubric/',
                alias: '/news/:rubric/page/:page/',
                name: 'NewsRubric',
                pathToRegexpOptions,
                component: News,
                meta: metaScrollPos
            },
            {
                path: '/news/:rubric/:code/',
                name: 'NewsDetail',
                pathToRegexpOptions,
                component: NewsDetail
            },
            {
                path: '/news/:rubric/:code/amp/',
                name: 'NewsDetailAmp',
                pathToRegexpOptions,
                component: NewsDetailAmp
            },
            {
                path: '/tags/',
                name: 'PopularTags',
                pathToRegexpOptions,
                component: PopularTags
            },
            {
                path: '/tags/:code/',
                alias: '/tags/:code/page/:page/',
                name: 'Tag',
                pathToRegexpOptions,
                component: Tag
            },
            {
                path: '/users/:userId/wardrobe/',
                name: 'Wardrobe',
                pathToRegexpOptions,
                component: Wardrobe
            },
            {
                path: '/users/:userId/wardrobe/:shelfId/',
                name: 'WardrobeDetail',
                pathToRegexpOptions,
                component: WardrobeDetail
            },
            {
                path: '/users/:userId/wardrobe/shelf/:shelfId/add-perfume/',
                name: 'WardrobeAddPerfume',
                pathToRegexpOptions,
                component: WardrobeAddPerfume
            },
            {
                path: '/users/:userId/wardrobe/shelf/:shelfId/add-perfumes/',
                name: 'WardrobeEditPerfume',
                pathToRegexpOptions,
                component: WardrobeEditPerfume
            },
            {
                path: '/users/:userId/notifications/',
                name: 'Notifications',
                pathToRegexpOptions,
                component: Notifications
            },
            {
                path: '/users/:userId/ml/',
                name: 'users.milliliters',
                pathToRegexpOptions,
                component: Milliliters
            },
            {
                path: '/users/:userId/ml/exchange/',
                name: 'users.millilitersExchange',
                pathToRegexpOptions,
                component: MillilitersExchange
            },
            {
                path: '/users/:userId/ml/gift/',
                name: 'users.millilitersGift',
                pathToRegexpOptions,
                component: MillilitersGift
            },
            {
                path: '/users/:userId/points/',
                name: 'users.points',
                pathToRegexpOptions,
                component: Points
            },
            {
                path: '/about/',
                name: 'About',
                pathToRegexpOptions,
                component: About
            },
            {
                path: '/sitemap/',
                name: 'SiteMap',
                pathToRegexpOptions,
                component: SiteMap
            },
            {
                path: '/cooperation/',
                name: 'Cooperation',
                pathToRegexpOptions,
                component: Cooperation
            },
            {
                path: '/legal/:code/',
                name: 'Legal',
                pathToRegexpOptions,
                component: Legal
            },
            {
                path: '/contacts/',
                name: 'Contacts',
                pathToRegexpOptions,
                component: Contacts
            },
            {
                path: '/help/',
                name: 'Help',
                pathToRegexpOptions,
                meta: metaScrollPos,
                component: Help
            },
            {
                path: '/help/:code/',
                name: 'HelpSection',
                pathToRegexpOptions,
                meta: metaScrollPos,
                component: Help,
            },
            {
                path: '/recommendations/',
                name: 'Diagnostic',
                pathToRegexpOptions,
                component: Diagnostic,
            },
            {
                path: '*',
                name: 'ErrorPage',
                pathToRegexpOptions,
                component: ErrorPage
            }
        ]
    });

    router.afterEach((to, from) => {
        if (from && from.name) {
            if (typeof sessionStorage !== 'undefined') {
                sessionStorage.prevPage = from.name
            }
        }
    })
    return router;
}
