<template>
    <div
        class="tabs"
        :class="blockMode">
        <div class="tabs__nav-wrapper" :class="navClasses">
            <nav ref="navTab"
                @scroll="onScroll"
                class="tabs__nav"
                :class="{'tabs__nav_with-line': !isShowLine || lineWidth > 0}">

                <transition-group class="tabs__list"
                    v-if="asyncTabs"
                    tag="ul"
                    ref="list"
                    role="tablist"
                    name="slide-fade">

                    <li v-for="tab in tabs"
                        class="tabs__tab"
                        :key="tab.hash"
                        role="presentation">

                        <a :href="tab.link || tab.hash"
                            class="tabs__link"
                            :ref="tab.hash"
                            @click.prevent="selectTab(tab.hash)"
                            :aria-controls="tab.id"
                            :aria-selected="isTabSelected(tab.id)"
                            role="tab">

                            {{ tab.name }}

                            <span class="tabs__counter tabs__counter_async"
                                :data-visible="countersVisible"
                                :data-big="tab.counterBig"
                                v-if="tab.counter">
                                {{ tab.counter | numberShortFormat }}
                            </span>

                            <svg-icon
                                class="tabs__icon"
                                v-if="tab.icon"
                                :name="tab.icon" />
                        </a>
                    </li>

                </transition-group>

                <ul v-else
                    class="tabs__list"
                    role="tablist">

                    <li v-for="tab in tabs"
                        class="tabs__tab"
                        :key="tab.hash"
                        role="presentation">

                        <a :href="tab.link || tab.hash"
                            class="tabs__link"
                            :ref="tab.hash"
                            @click.prevent="selectTab(tab.hash)"
                            :aria-controls="tab.id"
                            :aria-selected="isTabSelected(tab.id)"
                            role="tab">
                            {{ tab.name }}
                            <span class="tabs__counter"
                                :data-big="tab.counterBig"
                                v-if="tab.counter">
                                {{ tab.counter | numberShortFormat }}
                            </span>

                            <svg-icon
                                class="tabs__icon"
                                v-if="tab.icon"
                                :name="tab.icon" />
                        </a>
                    </li>
                </ul>

                <span
                    v-if="isShowLine"
                    class="tabs__line"
                    :style="lineStyle"></span>
            </nav>
        </div>
        <header v-if="$slots.header">
            <slot name="header"></slot>
        </header>

        <div class="tabs__panels">
            <slot></slot>
        </div>
    </div>
</template>

<script>
import get from 'lodash/get'
import filter from 'lodash/filter'
import forEach from 'lodash/forEach'
import map from 'lodash/map'
import throttle from 'lodash/throttle'
import find from 'lodash/find'

export default {
    name: 'BaseTabs',
    model: {
        event: 'change'
    },
    props: {
        hashClick: {
            type: Boolean,
            default: false
        },
        onlyClick: {
            type: Boolean,
            default: false
        },
        mode: {
            type: String,
            default: null,
            required: false
        },
        scrollNavHorizontal: {
            type: Boolean,
            default: false
        },
        asyncTabs: {
            type: Boolean,
            default: false
        },
        clientRender: {
            type: Boolean,
            default: false
        },
        forceActiveTabHash: {
            type: String,
            default: null
        }
    },
    data() {
        return {
            tabs: [],
            isScrolled: false,
            lineWidth: 0,
            lineOffset: 0,
            countersVisible: false,
            activeTabId: null
        }
    },
    computed: {
        isShowLine() {
            return this.mode !== 'black'
        },
        activeTab() {
            return find(this.tabs, ['id', this.activeTabId])
        },
        activeTabHash() {
            const tabHash = get(this.activeTab, 'hash')

            return tabHash
        },
        lineStyle() {
            return {
                width: this.lineWidth + 'px',
                transform: `translateX(${this.lineOffset}px)`
            }
        },
        blockMode() {
            return (this.mode ? 'tabs_' + this.mode : null);
        },
        isFullWidth() {
            // eslint-disable-next-line
            return ['fullwh', 'fullwidth'].indexOf(this.mode) > -1;
        },
        navClasses() {
            return {
                'tabs__nav-wrapper_scroll-x' : this.scrollNavHorizontal,
                'tabs__nav-wrapper_scroll-x_scrolled' : this.scrollNavHorizontal && this.isScrolled,
            }
        }
    },
    watch: {
        activeTab(value) {
            if (value && value.id && !this.forceActiveTabHash)
                this.$emit('change', value.id)
        }
    },
    created() {
        this.init()
    },
    mounted() {
        // this.$nextTick(() => {
        //     setTimeout(() => {
        //         this.moveLineToTab(this.activeTabHash)
        //     }, 1000);
        // })
    },
    globalBus: {
        'shelf-tab-change'(hash){
            this.selectTab(hash, true)
        },
        'update-tab-name'(data){
            const tabIndex = this.tabs.findIndex(tab => {
                return isNaN(data.tabId) ? tab.id === data.tabId : parseInt(tab.id) === parseInt(data.tabId);
            });
            if(tabIndex>0){
                this.tabs[tabIndex].name = data.name
                if(data.moveLine) this.moveLineToTab('#'+data.tabId);
            }
        }
    },
    methods: {
        onScroll: throttle(function(e) {
            this.isScrolled = e.target.scrollLeft > 0
        }, 50),
        isTabSelected(id) {
            // Не подсвечиваем табу на сервере, если нет детей с selected
            if (process.server)  {
                const isHasNotSelected = !find(this.tabs, 'selected')

                if (isHasNotSelected) return
            }

            return id === this.activeTabId
        },
        init() {
            const tabSlots = filter(this.$slots.default, 'tag')
            const tabs = []

            // Проходим по вложенным табам
            forEach(tabSlots, el => {
                const tab = get(el, 'componentOptions.propsData', null);

                if (tab) {
                    tab.hash = '#' + tab.id
                    /* аккуратнее, route.hash не доступен на серваке */
                    tab.isActive = tab.selected || this.$route.hash === tab.hash

                    if(tab.isActive) {
                        this.activeTabId = tab.id

                        setTimeout(() => {
                            this.$nextTick(() => {
                                const selectedTabLink = get(this.$refs, `[${tab.hash}][0]`, null);

                                if(selectedTabLink) {
                                    const { offsetLeft, offsetWidth } = selectedTabLink
                                    const { scrollLeft } = this.$refs.navTab
                                    const innerWidth = get(this.$refs, 'navTab.clientWidth', 0)
                                    const navScrollWidth = get(this.$refs, 'navTab.scrollWidth', 0)
                                    let left = scrollLeft + offsetWidth + 75 + offsetLeft / 2

                                    if(offsetLeft + offsetWidth * 2 >= navScrollWidth) {
                                        left = scrollLeft + offsetWidth + 160 + offsetLeft / 2
                                    }

                                    if(offsetLeft + offsetWidth > innerWidth) {
                                        this.$refs.navTab.scrollTo({
                                            left,
                                            behavior: "smooth"
                                        });
                                    }
                                }
                            })
                        }, 1000);
                    }

                    tabs.push(tab)
                }
            });

            if (tabs.length) {
                const activeTab = find(tabs, 'isActive')

                // Если нет активного таба
                if (!activeTab) {
                    tabs[0].isActive = true;
                    this.activeTabId = tabs[0].id
                }

                this.$set(this, 'tabs', tabs)
            }

            this.$nextTick(() => {
                this.countersVisible = true;
            })
        },
        changeParentScroll(offsetLeft, offsetWidth, currentPos) {
            const positionLeft = offsetLeft + offsetWidth
            const positionLeftClear = offsetLeft

            this.$nextTick(() => {
                let left = 0
                const innerWidth = get(this.$refs, 'navTab.clientWidth', 0)
                const { scrollLeft } = this.$refs.navTab

                if(positionLeft > innerWidth - 5) {
                    if(currentPos.x < offsetWidth) {
                        left = positionLeft - (offsetWidth + 36)
                    }
                    else {
                        left = scrollLeft + (innerWidth / 2) - offsetWidth
                    }
                } else if(positionLeftClear - 2 < 0) {
                    left = scrollLeft - innerWidth / 2
                }

                if(this.forceActiveTabHash)
                    left = positionLeft - (offsetWidth + 36)

                this.$refs.navTab.scrollTo({
                    left,
                    behavior: "smooth"
                });
            })
        },
        findTab(hash) {
            return find(this.tabs, { hash })
        },
        selectTab(hash, noEmit = false) {

            const selectedTab = this.findTab(hash);
            const selectedTabLink = get(this.$refs, `[${hash}][0]`, 0);

            const { offsetLeft, offsetWidth } = selectedTabLink

            if (typeof selectedTabLink.getBoundingClientRect === 'function' && noEmit) {
                const currentPos = selectedTabLink.getBoundingClientRect();
                this.changeParentScroll( offsetLeft, offsetWidth, currentPos );
            }

            if (!selectedTab) return

            if (this.onlyClick && !noEmit) {
                this.$emit('change', selectedTab.id)

                return
            }

            // Активируем указанный, деактивируем остальные
            this.$set(this, 'tabs', map(this.tabs, tab => {
                tab.isActive = (tab.hash === selectedTab.hash)

                if (tab.isActive) {
                    // Перемещаем полоску
                    this.moveLineToTab(tab.hash)
                    this.activeTabId = tab.id
                }

                return tab;
            }))

            if (this.hashClick) {

                // не нужно класть путь с хэшем как отдельный роут
                this.$router.replace({
                    path: this.$route.path,
                    hash: this.activeTabHash
                })
            }

        },
        moveLine(offset, width) {

            this.lineOffset = offset;
            this.lineWidth = width;
        },
        moveLineToTab(hash) {
            let tabLink = get(this.$refs, [hash, 0]);

            if (this.isFullWidth) {
                tabLink = tabLink.parentNode;
            }

            if (tabLink) {
                this.$nextTick(() => {
                    const offset = tabLink.offsetLeft;
                    const width = tabLink.offsetWidth;
                    this.moveLine(offset, width);
                })
            }
        },
    },
};
</script>

<style scoped>
.slide-fade-enter-active {
    transition: width 0.1s ease, opacity 1s ease;
}
.slide-fade-leave-active {
    transition: width 0.4s ease-out, opacity 0.2s ease-out;
}
.slide-fade-enter, .slide-fade-leave-to {
    width: 0;

    opacity: 0;
}
</style>

<style lang="less">
.tabs {
    --size-tabs-panels: @dim-page-padding;

    &__nav {
        white-space: nowrap;

        margin-bottom: rem(15);
        padding-left: rem(20);

        position: relative;

        overflow-x: scroll;
        -webkit-overflow-scrolling: touch;

        @{mobile} & {
            padding-left: 0;
            .scrollbar-hidden();
        }

        @{desktop} & {
            padding-left: 0;
            overflow: auto;
        }

        &_with-line{
            .tabs__link{
                &:after{
                    display: none;
                }
            }
        }
    }

    &__nav-wrapper {
        position: relative;

        &_scroll-x {

            &:before {
                .gradient(left);

                height: 87%;

                z-index: 1;

                opacity: 0;

                transition: opacity .075s ease;

                pointer-events: none;
            }

            &::after {
                .gradient(right);

                height: 87%;

                pointer-events: none;

                @{desktop} & {
                    right: rem(-1);
                }
            }

            &_scrolled:before {
                opacity: 1;
            }
        }
    }

    &__line {
        background-color: @color-primary;

        height: rem(2);

        position: absolute;
        bottom: 0;
        left: 0;
        will-change: transform;
        transition: transform .3s ease, width .2s ease;
        -webkit-backface-visibility: hidden;
    }

    &__list {
        display: inline-flex;

        list-style: none;

        margin: 0;
        padding: 0;

        min-width: 100%;

        @{desktop} & {
            border-bottom: rem(1) solid @color-gray-white;
        }

        @{mobile} & {
            position: relative;
            padding-left: rem(20);

            &::after{
                position: absolute;
                left: 0;
                width: 100%;
                height: rem(1);
                bottom: 0;
                content: '';
                background-color: @color-gray-white;
                flex: none;
            }
        }
    }

    &__tab {
        padding: rem(2) 0;

        &:last-child {
            padding-right: rem(20);
        }
    }

    &__tab + &__tab {
        margin-left: rem(24)
    }

    &__link {
        display: inline-block;

        // color: @color-gray-light;
        color: @color-gray-contrast;
        font-size: rem(15);
        font-weight: 500;

        padding: rem(13) 0 rem(11);
        position: relative;
        transition: color .1s ease;

        &:after{
            content: '';
            display: block;
            height: rem(2);
            left: 0;
            width: 100%;
            bottom: rem(-3);
            position: absolute;
            background: @color-primary;
            opacity: 0;
            visibility: hidden;
            z-index: 1;
            .animate;

            @{mobile} & {
                bottom: rem(-2);
            }
        }

        @{desktop} & {
            padding: rem(13) 0 rem(10);
        }

        &[aria-selected] {
            color: @color-primary;

            &:after{
                opacity: 1;
                visibility: visible;
            }
        }

        .hover({
            color: @color-primary;
        });
    }

    &__panels {
        padding-right: var(--size-tabs-panels);
        padding-left: var(--size-tabs-panels);

        position: relative;
    }

    &__counter{
        display: inline-block;
        vertical-align: super;

        font-size: 60%;

        position: relative;
        top: 2px;

        &[data-big] {
            vertical-align: baseline;

            font-size: inherit;

            top: 0;
            left: rem(-2);

            &:before {
                content: '(';

                position: relative;
                right: rem(-3);
            }

            &:after {
                content: ')';

                position: relative;
                left: rem(-3);
            }
        }

        &_async {
            opacity: 0;
            .animate(opacity, 1s);

            &[data-visible="true"] {
                opacity: 1;
            }
        }
    }

    &_nopadding {
        .tabs {
            &__panels {
                padding: 0;
            }

            &__nav {
                margin-bottom: 0;
            }
        }
    }

    &_fullwidth,
    &_fullwh{
        .tabs{
            &__panels{
                padding: 0;
            }
            &__nav{
                padding-left: 0;
            }
            &__list{
                display: table;

                table-layout: fixed;

                width: 100%;
            }
            &__tab{
                display: table-cell;

                text-align: center;

                &:last-child{
                    padding-right: 0;
                }
            }
        }
    }

    &_fullwh{
        .tabs{
            &__nav{
                margin-bottom: 0;
            }
            &__panels{
                height: calc(100% - rem(48));
            }
        }
    }

    &__counter + &__icon {
        left: rem(-2);
    }

    &__icon {
        height: rem(14);
        width: rem(14);

        position: relative;
        top: rem(-1);
        left: rem(2);
    }

    &_black &__link {
        color: @color-gray-light;
        font-size: rem(16);

        transition: color .2s ease;

        &[aria-selected=true] {
            color: @color-black;
            font-size: rem(24);
            font-weight: 500;
            line-height: 130%;
        }

        &:not([aria-selected]) {
            .hover({
                color: @color-gray;
            })
        }
    }

    &_black &__list {
        display: flex;
        align-items: flex-end;
        
        @{mobile} & {
            &::after{
                display: none;
            }
        }

        @{desktop} & {
            border-bottom: 0;
        }
    }
}
</style>
