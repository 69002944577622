const emojiRegex = require('emoji-regex');

export default {
    /**
     * Возвращает длину строки без учета переносов
     * 
     * @return {Number}
     */
    length(string = '') {
        return string ? string.replace(/(?:\r\n|\r|\n)/g, '').length : 0;
    },

    /**
     * Возвращает строку без переносов через html-тег br
     * 
     * @param  {String} text
     * @return {String}
     */
    noBr(text = '') {
        return text.replace(/<br\s*\/?>/gi,' ').replace(/\s\s+/g, ' ');
    },

    /**
     * Возвращает строку без emoji
     * 
     * @param  {String} text
     * @return {String}
     */
    noEmoji(text = ''){
        if(text){
            const regex = emojiRegex();
            return text.replace(regex, '')
        }

        return ''
    }
};
