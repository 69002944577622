<i18n>
{
    "ru": {
        "min": "мин."
    }
}
</i18n>
<template>
    <label
        class="base-textarea"
        :class="rootClasses">

        <span
            v-if="label"
            class="base-textarea__label"
            :class="labelClasses">
            {{ label }}
        </span>

        <textarea
            :autofocus="isFocused"
            ref="textarea"
            class="base-textarea__area"
            :class="areaClasses"
            :placeholder="placeholder"
            :style="styles"
            :rows="rows"
            :maxlength="maxlength"
            v-model="val"
            wrap="hard"
            @blur="onBlur"
            @focus="onFocus"></textarea>

        <div
            class="base-textarea__counter"
            :class="counterClasses"
            v-if="isCounter && countsShow">

            <span v-if="minlength">{{ $t('min') }} {{ minlength }}</span><span v-if="minlength">/</span><span>{{ textlength }}</span><span v-if="maxlength && !minlength">/</span><span v-if="maxlength && !minlength">{{ maxlength }}</span>

        </div>
    </label>
</template>

<script>
import get from 'lodash/get'

export default {
    name: 'BaseTextarea',
    props: {
        value: {
            type: [String, Number],
            default: ''
        },
        autosize: {
            type: Boolean,
            default: true
        },
        label: {
            type: [String],
            default: null
        },
        placeholder: {
            type: [String],
            default: null
        },
        minlength: {
            type: [Number, String],
            default: null
        },
        maxlength: {
            type: [Number, String],
            default: null
        },
        rows: {
            type: [Number, String],
            default: null
        },
        minHeight: {
            type: [Number],
            default: null
        },
        maxHeight: {
            type: [Number],
            default: 162
        },
        important: {
            type: [Boolean, Array],
            default: false
        },
        countsShow: {
            type: Boolean,
            default: true
        },
        withBg: {
            type: Boolean,
            default: false
        },
        isFocused: {
            type: Boolean,
            default: false
        },
        replaceEmoji: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            val: null,
            maxHeightScroll: false,
            height: 'auto'
        }
    },
    computed: {
        isCounter() {
            return this.minlength || this.maxlength
        },
        textlength() {
            return this.$options.filters.textLength(this.val)
        },
        labelClasses() {
            return {
                'base-textarea__label_required': this.required,
                'base-textarea__label_bg': this.withBg
            }
        },
        areaClasses() {
            return {
                'base-textarea__area_bg': this.withBg
            }
        },
        counterClasses() {
            return {
                'base-textarea__counter_bg': this.withBg
            }
        },
        rootClasses() {
            return {
                'base-textarea_bg': this.withBg
            }
        },
        styles() {
            if (!this.autosize) return {}
            return {
                resize: !this.isResizeImportant ? 'none' : 'none !important',
                height: this.height,
                overflow: this.maxHeightScroll ? 'auto' : (!this.isOverflowImportant ? 'hidden' : 'hidden !important')
            }
        },
        isResizeImportant() {
            const imp = this.important
            return imp === true || (Array.isArray(imp) && imp.includes('resize'))
        },
        isOverflowImportant() {
            const imp = this.important
            return imp === true || (Array.isArray(imp) && imp.includes('overflow'))
        },
        isHeightImportant() {
            const imp = this.important
            return imp === true || (Array.isArray(imp) && imp.includes('height'))
        }
    },
    watch: {
        value(val) {
            this.val = val
        },
        val(val) {
            this.$nextTick(this.resize)

            if(this.replaceEmoji)
                this.val = val = this.$options.filters.textNoEmoji(val)

            this.$emit('input', val)
        },
        minHeight() {
            this.$nextTick(this.resize)
        },
        maxHeight() {
            this.$nextTick(this.resize)
        },
        autosize(val) {
            if (val) this.resize()
        }
    },
    created() {
        this.val = this.value
    },
    mounted() {
        this.resize()
    },
    methods: {
        onBlur(e) {
            this.$emit('blur', e)
        },
        onFocus(e) {
            this.$emit('focus', e)
            this.resize()
        },
        resize() {
            const important = this.isHeightImportant ? 'important' : ''
            this.height = `auto${important ? ' !important' : ''}`
            this.$nextTick(() => {
                let contentHeight = get(this.$refs, 'textarea.scrollHeight', 0) + 1

                if (this.minHeight) {
                    contentHeight = contentHeight < this.minHeight ? this.minHeight : contentHeight
                }

                if (this.maxHeight) {
                    if (contentHeight > this.maxHeight) {
                        contentHeight = this.maxHeight
                        this.maxHeightScroll = true
                    } else {
                        this.maxHeightScroll = false
                    }
                }

                const heightVal = (contentHeight / 15).toFixed(7) + 'rem'
                this.height = `${heightVal}${important ? ' !important' : ''}`
            })

            return this
        }
    }
}
</script>

<style lang="less">
.base-textarea {
    --textarea-min-height: rem(72);
    width: 100%;

    position: relative;

    &_error{
        .base-textarea__area{
            box-shadow: 0px 0px 0px rem(1) @color-red;
        }
    }

    &__label {
        color: @color-gray;
        font-weight: 500;

        &_required {
            padding-right: rem(8);

            position: relative;

            &:after {
                content: '*';

                color: @color-red;
                font-size: rem(14);

                position: absolute;
                top: rem(-3);
                right: 0;
            }
        }

        &_bg {
            display: block;

            margin-bottom: rem(6);
        }
    }

    &__area {
        display: block;
        background: none;
        border: none;
        font-family: inherit;
        font-size: rem(15);
        outline: none;
        padding: 0;
        width: 100%;
        min-height: var(--textarea-min-height);
        text-size-adjust: 100%;

        @{desktop} & {
            // фикс overflow-x для safari
            padding-right: rem(2);
            .scrollbar();
        }

        &::placeholder{
            color: @color-gray-light;
        }

        &_bg {
            background-color: @color-gray-light-2;

            padding: rem(12) rem(20);
        }
    }

    &__counter {
        color: @color-gray-light;
        font-size: rem(13);
        font-weight: 400;
        text-align: left;

        position: absolute;
        right: rem(16);
        bottom: rem(12);

        &_bg {
            right: rem(14);
        }

        @{desktop} & {
            font-size: rem(15);
        }
    }

    &_bg {
        margin-bottom: rem(20);
    }
}
</style>
