export default {
    /**
     * Получаем имя иконок в зависимости от типа уведомления
     *
     * @param  {Object} notice
     * @param  {Array} types
     * @param  {Object} icons
     * @return {Object}
     */
    getIconName(notice, types, icons) {
        const elem = types.filter(el => el.name === notice.type)
        
        const iconType = elem.length ? elem[0].type : false

        return iconType ? icons[iconType] : 'notice-info'
    }
}
