const middleware = {}

middleware['adjustScroll'] = require('../middleware/adjustScroll.js')
middleware['adjustScroll'] = middleware['adjustScroll'].default || middleware['adjustScroll']

middleware['banner'] = require('../middleware/banner.js')
middleware['banner'] = middleware['banner'].default || middleware['banner']

middleware['checkCurrentUser'] = require('../middleware/checkCurrentUser.js')
middleware['checkCurrentUser'] = middleware['checkCurrentUser'].default || middleware['checkCurrentUser']

middleware['profile'] = require('../middleware/profile.js')
middleware['profile'] = middleware['profile'].default || middleware['profile']

middleware['slash-redirect'] = require('../middleware/slash-redirect.js')
middleware['slash-redirect'] = middleware['slash-redirect'].default || middleware['slash-redirect']

export default middleware
