/* eslint-disable no-useless-constructor */
import Model from '@/models/Model'
import Article from '@/models/catalog/Article';
import Page from '@/models/catalog/Page';
import News from '@/models/catalog/News';
import Perfume from '@/models/catalog/perfume';
import Like from '@/models/services/like';

export default class Segment extends Model {
    resource() {
        return 'segments'
    }

    primaryKey() {
        return 'code'
    }

    userLikes() {
        return this.hasMany(Like)
    }

    perfumes() {
        return this.hasMany(Perfume, 'perfumes')
    }

    relatedNews() {
        return this.hasMany(News, 'related-content')
    }

    relatedArticles() {
        return this.hasMany(Article, 'related-articles')
    }

    relatedPages() {
        return this.hasMany(Page, 'related-pages')
    }
}
