import differenceBy from 'lodash/differenceBy';
import throttle from 'lodash/throttle'
import debounce from 'lodash/debounce'

export default {
    model: {
        prop: 'loading',
        event: 'pending'
    },
    props: {
        list: {
            type: Object,
            default() {
                return {};
            },
            required: true
        },
        q: {
            type: String,
            default: ''
        },
        searchBy: {
            type: String,
            default: 'q'
        },
        filteredItems: {
            type: Array,
            default: () => null
        },
        forceFaded: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            loading: false,
            faded: false,
            items: [],
            lastPage: 1,
            currentPage: 1,
            totalItemsLength: 0,
            nextPageUrl: null,
            baseUrl: null,
            queryInternal: null
        }
    },
    watch: {
        list(value) {
            this.read(value, true);
        },
        filteredItems(value) {
            this.read(this.list, true)
        },
        q(value) {
            this.query = value || ''
        },
        loading(value) {
            this.$emit('pending', value)
        },
        items(value) {
            const val = value ? value.length : value
            this.$bus.$emit('dropdown-empty', !!val)
        }
    },
    created() {
        if (this.q && this.q.length) {
            this.read(this.list, true, true);
            this.query = this.q
        } else {
            this.read(this.list);
        }
    },
    computed: {
        query: {
            get() {
                return this.queryInternal
            },
            set: debounce(function(value) {
                this.queryInternal = value || ''

                this.load(true)
            }, 500)
        },
        url() {
            return this.$options.filters.urlApi(this.nextPageUrl);
        },
        queryPageUrl() {
            const separator = this.baseUrl.includes('?') ? '&' : '?'
            const url = `${this.baseUrl}${separator}${this.searchBy}=${this.query}`

            return this.$options.filters.urlApi(url);
        },
        computedItems() {
            return this.items;
        }
    },
    mounted() {
        if (this.forceFaded) {
            this.faded = true;
        } else {
            setTimeout(this.detectFaded, 100);
        }
    },
    methods: {
        detectFaded() {
            if (this.$refs.wrap) {
                this.faded = this.$refs.wrap.scrollHeight > this.$refs.wrap.clientHeight;
            }
        },
        read(list, refresh = false, onlyMeta = false) {
            let listItems = [];
            if(this.filteredItems) {
                listItems = differenceBy(list.items, this.filteredItems, (obj) => {
                    return obj.code
                });
            }
            else {
                listItems = list.items
            }

            if (onlyMeta) {
                this.items = []
            } else {
                this.items = refresh ? listItems : this.items.concat(listItems);
            }

            this.totalItemsLength = list.total
            this.lastPage = list.lastPage;
            this.currentPage = list.currentPage;
            this.nextPageUrl = list.nextPageUrl;

            if (!this.baseUrl)
                this.baseUrl = list.currentPageUrl;

            this.detectFaded();

            this.$emit('items-loaded', list.items);
        },
        /**
         * Запрашивает данные
         *
         * @param  {Boolean} isQuery
         */
        load: throttle(function(isQuery, customUrl) {
            this.loading = true;

            let url = null

            if (customUrl) {
                url = customUrl
            } else {
                url = isQuery ? this.queryPageUrl : this.url
            }

            this.$api.get(url)
                .then((response) => {
                    this.read(response.data, isQuery);
                }).finally(() => {
                    this.loading = false;
                    this.$emit('loaded');
                });
        }, 750),
        canLoad() {
            return this.currentPage < this.lastPage && this.nextPageUrl;
        },
    }
}
