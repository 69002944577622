<template>
    <ul class="pagination pagination__list"
        :class="rootClasses">

        <li v-if="!(firstPageSelected() && hidePrevNext)"
            class="pagination__item"
            :class="[firstPageSelected() ? disabledClass : '', !moreSecondPageSelected() && 'default-margin-right']">
            <span @click="prevPage()"
                class="pagination__item-link"
                @keyup.enter="prevPage()"
                :tabindex="firstPageSelected() ? -1 : 0">
                <svg-icon name="chevron-right" class="pagination__btn pagination__btn_prev" />
            </span>
        </li>

        <li v-for="page in pages"
            class="pagination__item"
            :key="page.content"
            :class="[
                page.selected ? activeClass : '', 
                page.disabled ? disabledClass : '',
                page.mobileHidden ? 'pagination__item_mobile-hidden' : ''
            ]">
            <span v-if="page.breakView"
                class="pagination__item-link"
                tabindex="0"><slot name="breakViewContent">{{ breakViewText }}</slot></span>
            <span v-else-if="page.disabled"
                class="pagination__item-link"
                tabindex="0">{{ page.content }}</span>
            <a v-else
                :href="getPageLink(page)"
                class="pagination__item-link"
                @click.prevent="handlePageSelected(page.index + 1)"
                @keyup.enter.prevent="handlePageSelected(page.index + 1)"
                tabindex="0">{{ page.content }}</a>
        </li>

        <li v-if="!(lastPageSelected() && hidePrevNext)"
            class="pagination__item"
            :class="[lastPageSelected() ? disabledClass : '', !moreSecondPageSelected() && 'default-margin-left']">
            <span class="pagination__item-link"
                @click="nextPage()"
                @keyup.enter="nextPage()"
                :tabindex="lastPageSelected() ? -1 : 0">
                <svg-icon name="chevron-right" class="pagination__btn pagination__btn_next" />
            </span>
        </li>
    </ul>
</template>

<script>
import includes from 'lodash/includes';
import url from '@/filters/url';

export default {
    name: 'BasePagination',
    props: {
        value: {
            type: Number,
            default: 1
        },
        pageCount: {
            type: Number,
            required: true
        },
        clickHandler: {
            type: Function,
            default: () => { }
        },
        pageRange: {
            type: Number,
            default: 3
        },
        marginPages: {
            type: Number,
            default: 1
        },
        theme: {
            type: String,
            default: 'base',
            validator(value) {
                return includes(['white', 'base'], value)
            }
        },
        prevText: {
            type: String,
            default: '<'
        },
        nextText: {
            type: String,
            default: '>'
        },
        breakViewText: {
            type: String,
            default: '…'
        },
        activeClass: {
            type: String,
            default: 'active'
        },
        disabledClass: {
            type: String,
            default: 'disabled'
        },
        firstLastButton: {
            type: Boolean,
            default: false
        },
        firstButtonText: {
            type: String,
            default: 'First'
        },
        lastButtonText: {
            type: String,
            default: 'Last'
        },
        hidePrevNext: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            innerValue: 1,
        }
    },
    computed: {
        rootClasses() {
            return {
                'pagination_white': this.theme === 'white'
            }
        },
        selected: {
            get() {
                return this.value || this.innerValue
            },
            set(newValue) {
                this.innerValue = newValue
            }
        },
        pages() {
            const items = {}
            if (this.pageCount <= this.pageRange) {
                for (let index = 0; index < this.pageCount; index++) {
                    const page = {
                        index,
                        content: index + 1,
                        selected: index === (this.selected - 1)
                    }
                    items[index] = page
                }
            } else {
                const halfPageRange = Math.floor(this.pageRange / 2)
                const setPageItem = index => {
                    const page = {
                        index,
                        content: index + 1,
                        selected: index === (this.selected - 1)
                    }
                    items[index] = page
                }
                const setBreakView = index => {
                    const breakView = {
                        disabled: true,
                        breakView: true
                    }
                    items[index] = breakView
                }
                const setMobileHidden = index => {
                    const mobileHidden = {
                        mobileHidden: true,
                        index,
                        content: index + 1,
                        selected: index === (this.selected - 1)
                    }
                    items[index] = mobileHidden
                }
                // 1st - loop thru low end of margin pages
                for (let i = 0; i < this.marginPages; i++) {
                    setPageItem(i);
                }
                // 2nd - loop thru selected range
                let selectedRangeLow = 0;
                if (this.selected - halfPageRange > 0) {
                    selectedRangeLow = this.selected - 1 - halfPageRange;
                }
                let selectedRangeHigh = selectedRangeLow + this.pageRange - 1;
                if (selectedRangeHigh >= this.pageCount) {
                    selectedRangeHigh = this.pageCount - 1;
                    selectedRangeLow = selectedRangeHigh - this.pageRange + 1;
                }

                for (let i = selectedRangeLow; i <= selectedRangeHigh && i <= this.pageCount - 1; i++) {
                    setPageItem(i);
                }
                // Check if there is breakView in the left of selected range
                if (selectedRangeLow > this.marginPages) {
                    setBreakView(selectedRangeLow - 1);
                    setMobileHidden(selectedRangeLow);
                }
                // Check if there is breakView in the right of selected range
                if (selectedRangeHigh + 1 < this.pageCount - this.marginPages) {
                    setBreakView(selectedRangeHigh + 1);
                    if (selectedRangeHigh > 2) {
                        setMobileHidden(selectedRangeHigh);
                    }
                }

                // if (selectedRangeLow > this.marginPages 
                //     && selectedRangeHigh + 1 < this.pageCount - this.marginPages) {
                    
                    
                // }
                // 3rd - loop thru high end of margin pages
                for (let i = this.pageCount - 1; i >= this.pageCount - this.marginPages; i--) {
                    setPageItem(i);
                }
            }
            return items
        }
    },
    beforeUpdate() {
        if (this.forcePage === undefined) return
        if (this.forcePage !== this.selected) {
            this.selected = this.forcePage
        }
    },
    methods: {
        handlePageSelected(selected) {
            if (this.selected === selected) return
            this.innerValue = selected
            this.$emit('input', selected)
            this.clickHandler(selected)
        },
        prevPage() {
            if (this.selected <= 1) return
            this.handlePageSelected(this.selected - 1)
        },
        nextPage() {
            if (this.selected >= this.pageCount) return
            this.handlePageSelected(this.selected + 1)
        },
        firstPageSelected() {
            return this.selected === 1
        },
        moreSecondPageSelected() {
            return this.selected > 2
        },
        lastPageSelected() {
            return (this.selected === this.pageCount) || (this.pageCount === 0)
        },
        selectFirstPage() {
            if (this.selected <= 1) return
            this.handlePageSelected(1)
        },
        selectLastPage() {
            if (this.selected >= this.pageCount) return
            this.handlePageSelected(this.pageCount)
        },
        getPageLink(page) {
            const path = process.server ? this.$route.fullPath : window.location.href;
            return url.modify(path, {page: page.content});
        }
    }
}
</script>

<style lang="less">
.pagination {

    &__list {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: center;

        list-style: none;

        margin-bottom: 0;
        padding: 0 rem(20);

        width: 100%;
        max-height: 50px;

        position: relative;

        @{desktop} & {
            flex-flow: row nowrap;

            padding: 0;
        }
    }

    &__item {
        display: flex;
        align-items: center;
        justify-content: center;

        border: rem(1) solid @color-gray-white;
        border-radius: rem(3);
        font-size: rem(13);

        margin: 0 rem(6);

        height: rem(32);
        min-width: rem(32);

        position: relative;

        &>a{
            color: inherit;
        }

        &_mobile-hidden{
            @{mobile} & {
                pointer-events: none;
                display: none;
            }
        }

        &.disabled {
            pointer-events: none;
        }

        @{mobile} & {
            margin: 0 rem(4);
        }

        @{desktop} & {
            cursor: pointer;

            &:hover {
                border-color: @color-violet;
                color: @color-violet;

                transition: border-color 0.2s, color 0.2s;
            }
        }

        &:first-child {
            align-self: flex-start;

            margin-right: auto !important;
            margin-left: 0 !important;
        }
        &:last-child {
            align-self: flex-end;

            margin-right: 0 !important;
            margin-left: auto !important;
        }

        &:first-child,
        &:last-child{
            @{mobile} & {
                .pagination__item-link{
                    padding-left: 0;
                    padding-right: 0;
                }
            }
        }

        &:not(:last-child):not(:first-child) {
            padding-top: rem(1);
        }

        &.active {
            background: @color-violet;
            border-color: transparent;
            color: @color-white;

            @{desktop} & {
                &:hover {
                    color: @color-white;
                }
            }

            &>a{
                color: @color-white;
            }
        }
    }

    &__item-link {
        outline: none;
        text-align: center;

        padding: rem(1) rem(6.5) 0;

        overflow: hidden;

        &:before {
            content: '';
            display: block;

            height: 100%;
            width: 100%;

            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
        }

        @{mobile} & {
            padding-left: rem(10);
            padding-right: rem(10);
        }
    }

    &__btn {
        height: rem(18);
        width: rem(18);

        &_prev {
            transform: rotate(180deg);
        }
    }

    .default-margin-left {
        margin-left: rem(12);

        .pagination__item-link {
            display: flex;
            align-items: center;
            justify-content: center;

            padding: 0;
        }

        @{desktop} & {
            margin-left: auto;
        }
    }

    .default-margin-right {
        margin-right: rem(12);

        .pagination__item-link {
            display: flex;
            align-items: center;
            justify-content: center;

            padding: 0;
        }

        @{desktop} & {
            margin-right: auto;
        }
    }

    &_white &__item {
        border-color: transparent;
        font-size: rem(15);
        font-weight: 500;

        &:first-child,
        &:last-child {
            border-color: @color-gray-white;

            .pagination__item-link {
                top: 0;
            }
        }

        &-link {
            top: rem(1);
        }

        &.active {
            background-color: @color-white;
            border-color: @color-violet;
            color: @color-violet;
        }
    }

    .disabled {
        color: @color-gray-light;
    }
}
</style>
