// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../static/img/loading-sm.gif");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".paginated-list{height:100%;position:relative}.paginated-list:after{background:hsla(0,0%,100%,.3) url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat 50% 97%;top:0;z-index:2;transition:opacity .1s ease-in-out 0s;-webkit-backface-visibility:hidden;backface-visibility:hidden;transform:translateZ(0)}.paginated-list:after,.paginated-list:before{content:\"\";display:block;position:absolute;right:0;bottom:0;left:0;opacity:0;pointer-events:none}.paginated-list:before{background:linear-gradient(180deg,hsla(0,0%,100%,0),#fff);height:55px;z-index:1}.paginated-list[data-loading=true]:after{opacity:1;pointer-events:auto}.paginated-list[data-faded=true]:before{opacity:1}.paginated-list__wrap{width:100%;position:absolute;top:0;bottom:0;left:0;overflow-y:auto;-webkit-overflow-scrolling:touch}.paginated-list__wrap:hover::-webkit-scrollbar-thumb{background-color:rgba(0,0,0,.1)}.paginated-list__wrap::-webkit-scrollbar{background-color:#fff;width:12px}.paginated-list__wrap::-webkit-scrollbar-track{background-color:#fff}.paginated-list__wrap::-webkit-scrollbar-thumb{background-color:#fff;border:3px solid #fff;border-radius:10px;-webkit-transition:background-color .15s ease;transition:background-color .15s ease}.paginated-list__wrap::-webkit-scrollbar-button{display:none}html.view_desktop .paginated-list__wrap{padding-bottom:10px}.paginated-list__wrap.columns{flex-flow:column}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
