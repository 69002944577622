<template>
    <label class="base-input">

        <span
            v-if="label"
            class="base-input__label"
            :class="labelClasses">{{ label }}</span>

        <slot name="label"></slot>

        <form-field
            ref="field"
            :mode="mode"
            class="base-input__field form__field"
            :class="formFieldClasses"
            :rules="rules"
            v-slot="{ errors, failed }">

            <component
                :is="isColorSelect ? 'div' : 'picture'"
                class="base-input__icon"
                v-if="$slots.icon">
                <slot name="icon"></slot>
            </component>

            <input
                :type="getInputType"
                :id="id"
                v-model="valueInternal"
                @focus="onFocus"
                :disabled="disabled"
                ref="input"
                :name="name"
                @blur="onBlur"
                @input="setValue"
                :readonly="readonly"
                :placeholder="placeholder"
                class="form__input base-input__input"
                :style="inputStyles"
                :class="inputClasses({ failed, amount, isBigPhoto })"
                :autocomplete="autocomplete" />

            <span class="form__error"
                :data-visible="isFailed(failed)">
                {{ head(errors) }}
            </span>

            <span class="form__error"
                v-if="customError"
                :data-visible="customError">
                {{ customErrorText }} 
            </span>

            <span
                v-if="amount"
                class="base-input__amount"
                :class="amountClasses({ failed })">{{ amount }}</span>

            <div
                v-if="$slots.button"
                ref="button"
                class="base-input__button">
                <slot name="button"></slot>
            </div>
        </form-field>

    </label>
</template>

<script>
import head from 'lodash/head'
import includes from 'lodash/includes'
import HelpButton from '@/components/ui/HelpButton';

import RefMixin from '@/mixins/ref.mixin'

export default {
    name: 'BaseInput',
    components: {HelpButton},
    mixins: [RefMixin],
    inheritAttrs: false,
    props: {
        name: {
            type: String,
            default: null
        },
        disabled: {
            type: Boolean,
            default: false
        },
        value: {
            type: [String, Number],
            default: null
        },
        cursorToEnd: {
            type: Boolean,
            default: false
        },
        readonly: {
            type: Boolean,
            default: false
        },
        rules: {
            type: [String, Object],
            default: null
        },
        placeholder: {
            type: String,
            default: null
        },
        id: {
            type: String,
            default: null
        },
        label: {
            type: String,
            default: null
        },
        amount: {
            type: String,
            default: ''
        },
        isBigPhoto: {
            type: Boolean,
            default: false
        },
        type: {
            type: String,
            default: 'text'
        },
        maxLength: {
            type: Number,
            default: 10000
        },
        autocomplete: {
            type: String,
            default: null
        },
        isColorSelect: {
            type: Boolean,
            default: false
        },
        customError: {
            type: Boolean,
            default: false
        },
        customErrorText: {
            type: String,
            default: null
        },
        replaceEmoji: {
            type: Boolean,
            default: false
        },
        mode: {
            type: String,
            default: 'eager'
        },
        tooltip: {
            type: String,
            default: null
        }
    },
    data() {
        return {
            buttonWidth: 0
        }
    },
    computed: {
        formFieldClasses() {
            return {
                'form__field_disabled': this.disabled
            }
        },
        getInputType() {
            return this.type
        },
        required() {
            return includes(this.rules, 'required') || this.rules.required
        },
        inputStyles() {
            const styles = {}

            if (this.buttonWidth)
                styles['padding-right'] = this.buttonWidth + 'px'

            return styles
        },
        labelClasses() {
            return {
                'base-input__label_required': this.required
            }
        },
        valueInternal: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit('input', value)
            }
        }
    },
    mounted() {
        if (this.$refs.button) {
            this.buttonWidth = this.getRefSize('button').width
        }
    },
    methods: {
        onBlur(e) {
            this.$emit('blur', e)
        },
        onFocus(e) {
            // Устанавливаем курсор в конец инпута
            if (this.value && this.cursorToEnd) {
                setTimeout(() => {
                    this.$refs.input.setSelectionRange(this.value.length, this.value.length)
                }, 10)
            }

            this.$emit('focus', e)
        },
        isFailed(failed) {
            return failed;
        },
        inputClasses(params) {
            const { failed, amount, isBigPhoto } = params

            return {
                'form__input_error': failed || this.customError,
                'base-input__input_amount': amount,
                'base-input__input_big-photo': isBigPhoto
            }
        },
        amountClasses(params) {
            const { failed } = params

            return {
                'base-input__amount_error': failed
            }
        },
        setValue(event) {
            if(this.type === 'number') {
                this.valueInternal = event.target.value = event.target.value.replace(/\D/g, '')
            }

            if(this.replaceEmoji)
                this.valueInternal = this.$options.filters.textNoEmoji(event.target.value)
        },
        head
    }
}
</script>

<style lang="less">
.base-input {
    display: block;

    /* box-shadow fix */
    padding: rem(1);

    position: relative;


    &__icon {
        display: flex;
        align-items: center;

        height: calc(100% - rem(6));

        position: absolute;
        top: rem(6);
        left: rem(20);
    }

    &__icon + &__input {
        padding-left: rem(44);

        &_big-photo {
            padding-left: rem(64);
        }
    }

    .base-select + &,
    & + & {
        margin-top: rem(24);

        @{desktop} & {
            margin-top: rem(16);
        }
    }

    &__label {
        color: @color-gray;
        font-weight: 500;

        &_required {
            padding-right: rem(8);

            position: relative;

            &:after {
                content: '*';

                color: @color-red;
                font-size: rem(14);

                position: absolute;
                top: rem(-3);
                right: 0;
            }
        }
    }

    &__field {
        padding-top: rem(6);

        width: 100%;
    }

    &__input {
        height: rem(47);
        width: 100%;

        &_amount {
            padding-right: rem(110);
        }

        &[type="number"]::-webkit-outer-spin-button,
        &[type="number"]::-webkit-inner-spin-button {
            -webkit-appearance: none;

            margin: 0;
        }

        &[disabled]{
            opacity: 1;
            background-color: @color-bg-gray-minor;
            color: @color-gray-light;
        }
    }

    &__button {
        position: absolute;
        top: rem(8);
        right: 0;
    }

    &__amount {
        color: @color-gray-light;
        font-size: rem(13);

        position: absolute;
        top: calc(50% + rem(4));
        right: rem(20);

        transform: translateY(-50%);

        &_error {
            transform: translateY(calc(-50% - rem(14)));
        }
    }

    &__help{
        position: relative;
        top: rem(-1);
        margin-left: rem(2);
    }

    // .form__field {
    //     &_disabled {
    //         opacity: 0.7;
    //     }
    // }
}
</style>
