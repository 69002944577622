/* eslint-disable no-useless-constructor */
import Model from '@/models/Model'
import Article from '@/models/catalog/Article';
import News from '@/models/catalog/News';
import Page from '@/models/catalog/Page';
import Brand from '@/models/catalog/brand'

export default class Country extends Model {
    resource() {
        return 'countries'
    }

    primaryKey() {
        return 'code'
    }

    brandList() {
        return this.hasMany(Brand)
    }

    relatedNews() {
        return this.hasMany(Article, 'related-news')
    }

    relatedArticles() {
        return this.hasMany(News, 'related-articles')
    }

    relatedPages() {
        return this.hasMany(Page, 'related-pages')
    }
}
