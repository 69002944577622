<template>
    <router-link
        :to="link"
        :event="event"
        :exact="exact" >
        <slot></slot>
    </router-link>
</template>

<script>
export default {
    name: 'HtmlLink',
    props: {
        event: {
            type: [ Array, String ],
            default: 'click'
        },
        exact: {
            type: Boolean,
            default: false
        },
        url: {
            type: String,
            default: null,
            // required() {
            // return !this.to;
            // }
        },
        to: {
            type: [ Object, String ],
            default: null,
            required: false
        }
    },
    computed: {
        link() {
            if (this.url && this.url.length) {
                return this.$options.filters.urlRelative(this.url);
            }

            return this.to || {};
        }
    },
};
</script>
