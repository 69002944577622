/* eslint-disable object-shorthand */
/* eslint-disable no-console */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import Vue from 'vue'
// Global plugins
import Modal from '@/plugins/ui/modal/index'
import MixinModal from '@/mixins/modals.mixins'

const Mixins = {

    install(Vue) {
        Vue.mixin(MixinModal)
    }
}

export default ({app}, inject) => {
    Vue.use(Mixins)
    Vue.use(Modal, { dynamic: true })
}
