export default ({ params, route, redirect, app, next }) => {
    if(params.letter) {
        /* для корректно отображения возможных
        кириллических символов в компонентах декодируем их */

        params.letter = decodeURI(params.letter)
    }

    const isSlash = route.path.slice(-1) === '/'
    if (!isSlash) {
        /* роут может содержать кириллицу,
        чтобы сервер не упал, кодируем символы в нужный формат */

        const encodedPath = encodeURI(route.path);

        const { hash, query } = route

        let redirectPath = encodedPath + '/'

        if (hash) redirectPath += hash

        redirect(301, route.path.replace(route.path, redirectPath), query)
    }
}
