/* eslint-disable no-useless-constructor */
import Model from '@/models/Model'
import Draft from "@/models/drafts/BaseDraft";
import Like from "@/models/services/like";

export default class Post extends Model {
    resource() {
        return 'posts'
    }

    primaryKey() {
        return 'code'
    }

    drafts() {
        return this.hasMany(Draft)
    }

    likes() {
        return this.hasMany(Like)
    }
}
