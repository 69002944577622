import get from "lodash/get";

/**
 * Возвращает объект с meta из API-объекта
 *
 * @param  {Object} obj
 * @param  {Object} defaultObj
 * @return {Object}
 */
const apiExtract = (obj, defaultObj = {}) => {
    
    const meta = [
        {
            hid: 'description',
            name: 'description',
            content: get(obj, 'meta.description', defaultObj.description)
        },
        {
            hid: 'keywords',
            name: 'keywords',
            content: get(obj, 'meta.keywords', defaultObj.keywords)
        },
        {
            hid: 'og:title',
            name: 'og:title',
            content: get(obj, 'meta[og:title]')
        },
        {
            hid: 'og:image',
            name: 'og:image',
            content: get(obj, 'meta[og:image]')
        },
        {
            hid: 'og:description',
            name: 'og:description',
            content: get(obj, 'meta[og:description]')
        },
        {
            hid: 'robots',
            name: 'robots',
            content: get(obj, 'meta.robots')
        },
    ];
    
    return meta.filter(item => item.content);
};

const sitenameTitle = (title) => {
    return  title + ' | Aromo.ru'
}

export default {
    apiExtract,
    sitenameTitle
}