/**
* Check if  element has scrollable content
* @param {Node} node
* @returns {Boolean}
*/
export const hasScrollbars = function(node) {
    const overflowY = getComputedStyle(node)["overflow-y"];
    const overflowX = getComputedStyle(node)["overflow-x"];
    const vertical = (overflowY === "scroll" || overflowY === "auto") && Math.abs(node.scrollHeight - node.clientHeight) > 1;
    const horizontal = (overflowX === "scroll" || overflowX === "auto") && Math.abs(node.scrollWidth - node.clientWidth) > 1;

    return vertical || horizontal;
};

/**
* Check if element or one of the parents is scrollable
* @param {Node} node  DOM Node element
* @returns {Boolean}
*/
export const isScrollable = function(node) {
    if(node.tagName === 'TEXTAREA'){
        return 'textarea';
    }
    
    if(node.hasAttribute('contenteditable')){
        return isScrollable(node.parentNode);
    }

    if (!node || !(typeof node === "object" && node instanceof Element) || node === document.body) {
        return false;
    }

    if (hasScrollbars(node)) {
        return node;
    }

    return isScrollable(node.parentNode);
};