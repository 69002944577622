/* eslint-disable no-useless-constructor */
import Model from '../Model'
import Profile from "@/models/users/accounts/Profile";
import Shelf from "@/models/users/accounts/Shelf";
import Account from "@/models/users/accounts";
import Badge from "@/models/services/Badge";
import Post from "@/models/Post";
import Favorite from "@/models/users/accounts/Favorite";
import Milliliter from "~/models/users/accounts/Milliliter";
import Subscription from "~/models/tags/Subscription";
import Country from "@/models/catalog/Country";
import Notification from "@/models/users/accounts/Notification";
import Follower from "@/models/users/accounts/Follower";
import FollowRequest from "@/models/users/accounts/FollowRequest";
import Guest from "@/models/users/accounts/Guest";

export default class User extends Model {
    resource() {
        return 'users'
    }

    primaryKey() {
        return 'id'
    }

    accounts() {
        return this.hasMany(Account, 'accounts')
    }

    profile() {
        return this.hasMany(Profile, 'accounts/profile')
    }

    patchProfile(params) {
        return this.request({
            method: 'PATCH',
            url: this.endpoint() + '/accounts/profile',
            data: params
        }).then(response => {
            if(response) {
                return response.data;
            }
        });
    }

    updatePassword(params) {
        return this.request({
            method: 'POST',
            url: this.endpoint() + '/accounts/profile/password',
            data: params
        }).then(response => {
            if(response) {
                return response.data;
            }
        });
    }

    sendEmailConfirmation() {
        return this.request({
            method: 'POST',
            url: this.endpoint() + '/accounts/profile/email-confirmation/code',
        }).then(response => {
            if(response) {
                return response.data;
            }
        });
    }

    shelf() {
        return this.hasMany(Shelf)
    }

    favorites() {
        return this.hasMany(Favorite, 'accounts/favorites')
    }

    badges() {
        return this.hasMany(Badge, 'accounts/badges')
    }

    posts() {
        return this.hasMany(Post)
    }

    countries() {
        return this.hasMany(Country)
    }

    recommended() {
        return this.hasMany(Account, 'recommended')
    }

    hideUserFromRecommended(id) {
        return this.request({
            method: 'POST',
            url: '/users/recommended/hidden',
            data: { user_id: id }
        })
    }

    milliliters() {
        return this.hasMany(Milliliter, 'accounts/ml')
    }

    millilitersExchange() {
        return this.hasMany(Milliliter, 'accounts/ml/exchange')
    }

    millilitersDonate() {
        return this.hasMany(Milliliter, 'accounts/ml/donate')
    }

    subscriptions() {
        return this.hasMany(Subscription, 'accounts/subscriptions')
    }

    notifications() {
        return this.hasMany(Notification, 'accounts/notifications')
    }

    notificationsViewed() {
        return this.hasMany(Notification, 'accounts/notifications/viewed')
    }

    notificationViewed(id) {
        return this.hasMany(Notification, `accounts/notifications/${id}/viewed`)
    }

    notificationSetViewed(id) {
        let url = this.endpoint() + `/accounts/notifications/viewed`;
        if(id) {
            url = this.endpoint() + `/accounts/notifications/${id}/viewed`;
        }

        return this.request({
            method: 'POST',
            url,
            data: this.getFormData(this),
            progress: false
        }).then(response => {
            const self = Object.assign(this, response.data)
            return self
        })
    }

    followers() {
        return this.hasMany(Follower, 'accounts/followers')
    }

    followersRequest() {
        return this.hasMany(FollowRequest, 'accounts/follow-requests')
    }

    guests() {
        return this.hasMany(Guest, 'accounts/guests')
    }

    splitAccoutns() {
        return this.hasMany(Profile, 'accounts/split-accounts')
    }
}
